import { API_URL } from '@app/constants';
import { HttpMethods } from '@app/types';
import {
  CheckoutLinkRequestDto,
  CheckoutPaymentMethodResponseDto,
  PaymentMethodResponseDto,
} from '@app/api';
import { baseApi } from '../baseApi';

export const bankCardApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBankCardData: builder.query<PaymentMethodResponseDto, void>({
      query: () => ({
        url: API_URL.getBankCardData,
        method: HttpMethods.get,
      }),
      providesTags: ['bankCardData'],
    }),
    getCheckoutUrl: builder.query<
      CheckoutPaymentMethodResponseDto,
      CheckoutLinkRequestDto
    >({
      query: (payload) => ({
        url: API_URL.getCheckoutUrl,
        method: HttpMethods.post,
        body: payload,
      }),
      providesTags: ['checkoutUrl'],
    }),
    deleteBankCard: builder.mutation<PaymentMethodResponseDto, void>({
      query: () => ({
        url: API_URL.deleteBankCard,
        method: HttpMethods.post,
      }),
      invalidatesTags: ['bankCardData', 'checkoutUrl'],
    }),
  }),
});

export const {
  useGetBankCardDataQuery,
  useGetCheckoutUrlQuery,
  useDeleteBankCardMutation,
  useLazyGetCheckoutUrlQuery,
} = bankCardApi;
