import React, { FC, ReactNode, useEffect } from 'react';
import {
  BasicModalCloseBtn,
  BasicModalContainer,
  BasicModalContent,
} from './BasicModal.styled';

interface Props {

  children: ReactNode;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  backgroundColor?: string;
  padding?: string;
  mobilePadding?: string;
  margin?: string | null;
  mobileRadius?: string | null;
  mobileWidth?: string | null;
  mobileHeight?: string | null;
  mobilePosition?: string | null;
  closeButtonColor?: string;
  widthSvg?: string
  heightSvg?: string;
  mobileWidthSvg?: string;
  mobileHeightSvg?: string;
  positionSvgRight?: string;
  positionSvgTop?: string;
  positionMobileSvgRight?: string;
  positionMobileSvgTop?: string;
  mobileMarginTop?: string;
  mobileMarginBottom?: string;
  overflow?: string;

}

const BasicModal: FC<Props> = ({

  children,
  backgroundColor,
  padding,
  mobilePadding,
  margin, mobileRadius,
  mobileWidth,
  mobileHeight,
  mobilePosition,
  visible,
  widthSvg,
  heightSvg,
  mobileWidthSvg,
  mobileHeightSvg,
  setVisible,
  closeButtonColor,
  positionSvgRight,
  positionSvgTop,
  positionMobileSvgRight,
  positionMobileSvgTop,
  mobileMarginTop,
  mobileMarginBottom,
  overflow,

}) => {

  useEffect(() => {

    if ( visible ) {

      document.body.style.overflow = 'hidden';

    } else {

      document.body.style.overflow = 'unset';

    }

    return () => {

      document.body.style.overflow = 'unset';

    };

  }, [ visible ]);

  return (

    <BasicModalContainer overflow  = { overflow } active = { visible }>

      <BasicModalContent

        backgroundColor = { backgroundColor }
        onClick = { (e: React.MouseEvent) => e.stopPropagation() }
        padding = { padding }
        mobilePadding = { mobilePadding }
        margin = { margin }
        mobileRadius = { mobileRadius }
        mobileWidth = { mobileWidth }
        mobileHeight = { mobileHeight }
        mobilePosition = { mobilePosition }
        mobileMarginTop = { mobileMarginTop }
        mobileMarginBottom = { mobileMarginBottom }

       >

        { children }

        <BasicModalCloseBtn

          color = { closeButtonColor }
          widthSvg = { widthSvg }
          heightSvg = { heightSvg }
          mobileWidthSvg = { mobileWidthSvg }
          mobileHeightSvg = { mobileHeightSvg }
          onClick = { () => setVisible(false) }
          positionSvgRight={positionSvgRight}
          positionSvgTop={positionSvgTop}
          positionMobileSvgRight={positionMobileSvgRight}
          positionMobileSvgTop={positionMobileSvgTop}

        />

      </BasicModalContent>

    </BasicModalContainer>

  );
};

export default BasicModal;
