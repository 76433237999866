import { StaticImageData } from 'next/image';
import { SVGProps } from 'react';
export * from './blog.page';

export enum BreakpointsEnum {
  lg = 1440,
  lgm = 1200,
  md = 1024,
  sm = 768,
  s = 576,
  xl = 385,
  xs = 320,

  tablet = 1100,
  mb = 500,
}

export type BreakpointsType = keyof typeof BreakpointsEnum;

export interface IconComponent extends SVGProps<SVGSVGElement> {
  color?: string;
}

export enum InputState {
  Success = 'success',
  Error = 'error',
}

export type Therapist = {
  id: number;
  image: StaticImageData;
  name: string;
  time: string;
  experience: string;
  sessions: string;
  video?: string | null;
};

export type CommonTypes = { className?: string };

export type Roles = 'client' | 'therapist' | 'business';

export type Maybe<T> = T | null;

export type Empty<T> = T | undefined;

export type MaybeEmpty<T> = T | null | undefined;
