import { AnchorHTMLAttributes, FC } from 'react';
import styled, { css } from 'styled-components';
import { TYPOGRAPHY, COLORS } from '@landing/constants';
import Link from 'next/link';
import { ParsedUrlQueryInput } from 'querystring';
import apx from '@landing/constants/adaptivepx';

type StyleButton = 'primary' | 'outline';

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  styleType?: StyleButton;
  className?: string;
  isLarge?: boolean;
  href?: string;
  query?: string | null | ParsedUrlQueryInput | undefined;
}

const ButtonLink: FC<Props> = ({
  styleType = 'primary',
  className,
  isLarge = false,
  href,
  query,
  ...props
}) => {
  return (
    <Link href={{ pathname: href, query: query }} passHref>
      <Root
        $type={styleType}
        $isLarge={isLarge}
        className={className}
        {...props}
      >
        {props.children}
      </Root>
    </Link>
  );
};

export default ButtonLink;

interface StyleProps {
  $type: StyleButton;
  $isLarge: boolean;
}

const Root = styled.a<StyleProps>`
  ${TYPOGRAPHY.bodySemibold16};
  border-radius: ${apx.d(8)};
  display: inline-block;
  text-align: center;
  transition: all 0.3s ease;

  &:active {
    outline: 0;
    box-shadow: 0 0 0 ${apx.d(8)} ${COLORS.elementsStatesFocused};
  }

  ${({ $type }) => {
    if ($type === 'primary') {
      return css`
        background-color: ${COLORS.mint};
        border-color: ${COLORS.mint};
        color: ${COLORS.white};

        &:not([disabled]) {
          &:hover {
            opacity: 0.8;
          }

          &:active {
            background-color: ${COLORS.mint};
          }
        }
      `;
    }

    if ($type === 'outline') {
      return css`
        border: ${apx.d(2)} solid ${COLORS.mint};
        background-color: transparent;
        color: ${COLORS.nativeBlack100};

        &:not([disabled]) {
          &:hover {
            color: white;
            background-color: ${COLORS.mint};
          }

          &:active {
            color: white;
            border-color: ${COLORS.mint};
          }
        }
      `;
    }
  }};

  ${({ $isLarge }) => {
    if ($isLarge) {
      return css`
        border-radius: ${apx.d(20)};
        ${TYPOGRAPHY.headline4Semibold24};
      `;
    }
  }};
`;
