/* tslint:disable */
/* eslint-disable */
/**
 * Pogovorim API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface AddDiseaseGroupRequestDto
 */
export interface AddDiseaseGroupRequestDto {
  /**
   *
   * @type {string}
   * @memberof AddDiseaseGroupRequestDto
   */
  name: string;
}
/**
 *
 * @export
 * @interface AddDiseaseRequestDto
 */
export interface AddDiseaseRequestDto {
  /**
   *
   * @type {string}
   * @memberof AddDiseaseRequestDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AddDiseaseRequestDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof AddDiseaseRequestDto
   */
  diseasesGroupId: string;
}
/**
 *
 * @export
 * @interface AddFaqGroupRequestDto
 */
export interface AddFaqGroupRequestDto {
  /**
   *
   * @type {string}
   * @memberof AddFaqGroupRequestDto
   */
  title: string;
}
/**
 *
 * @export
 * @interface AddFaqQuestionRequestDto
 */
export interface AddFaqQuestionRequestDto {
  /**
   *
   * @type {string}
   * @memberof AddFaqQuestionRequestDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof AddFaqQuestionRequestDto
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof AddFaqQuestionRequestDto
   */
  groupId: string;
}
/**
 *
 * @export
 * @interface AddPromocodesToCompany
 */
export interface AddPromocodesToCompany {
  /**
   *
   * @type {Array<CreateCompanyPromocodeInfoRequestDto>}
   * @memberof AddPromocodesToCompany
   */
  promocodes: Array<CreateCompanyPromocodeInfoRequestDto>;
  /**
   *
   * @type {string}
   * @memberof AddPromocodesToCompany
   */
  promocodeExpireDate: string;
}
/**
 *
 * @export
 * @interface AddSessionOutcomeRequestDto
 */
export interface AddSessionOutcomeRequestDto {
  /**
   *
   * @type {boolean}
   * @memberof AddSessionOutcomeRequestDto
   */
  liked: boolean;
  /**
   *
   * @type {string}
   * @memberof AddSessionOutcomeRequestDto
   */
  comment: string;
}
/**
 *
 * @export
 * @interface AddTherapyMethodRequestDto
 */
export interface AddTherapyMethodRequestDto {
  /**
   *
   * @type {string}
   * @memberof AddTherapyMethodRequestDto
   */
  name: string;
}
/**
 *
 * @export
 * @interface AdminAddFaqGroupRequestDto
 */
export interface AdminAddFaqGroupRequestDto {
  /**
   *
   * @type {string}
   * @memberof AdminAddFaqGroupRequestDto
   */
  title: string;
}
/**
 *
 * @export
 * @interface AdminAddFaqQuestionRequestDto
 */
export interface AdminAddFaqQuestionRequestDto {
  /**
   *
   * @type {string}
   * @memberof AdminAddFaqQuestionRequestDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof AdminAddFaqQuestionRequestDto
   */
  text: string;
  /**
   *
   * @type {string}
   * @memberof AdminAddFaqQuestionRequestDto
   */
  groupId: string;
}
/**
 *
 * @export
 * @interface AdminCompanyResponseDto
 */
export interface AdminCompanyResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminCompanyResponseDto
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminCompanyResponseDto
   */
  isArchive: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyResponseDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyResponseDto
   */
  ownerId?: string;
  /**
   *
   * @type {string}
   * @memberof AdminCompanyResponseDto
   */
  createdAt?: string;
}
/**
 *
 * @export
 * @interface AdminCreateCompanyRequestResponseDto
 */
export interface AdminCreateCompanyRequestResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminCreateCompanyRequestResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdminCreateCompanyRequestResponseDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminCreateCompanyRequestResponseDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminCreateCompanyRequestResponseDto
   */
  phone: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminCreateCompanyRequestResponseDto
   */
  contactByPhone: boolean;
  /**
   *
   * @type {number}
   * @memberof AdminCreateCompanyRequestResponseDto
   */
  numberOfEmployee: number;
  /**
   *
   * @type {string}
   * @memberof AdminCreateCompanyRequestResponseDto
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof AdminCreateCompanyRequestResponseDto
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface AdminDiseaseGroupResponseDto
 */
export interface AdminDiseaseGroupResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminDiseaseGroupResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdminDiseaseGroupResponseDto
   */
  name: string;
  /**
   *
   * @type {Array<DiseaseResponseDto>}
   * @memberof AdminDiseaseGroupResponseDto
   */
  items: Array<DiseaseResponseDto>;
}
/**
 *
 * @export
 * @interface AdminDiseaseGroupsResponseDto
 */
export interface AdminDiseaseGroupsResponseDto {
  /**
   *
   * @type {Array<AdminDiseaseGroupResponseDto>}
   * @memberof AdminDiseaseGroupsResponseDto
   */
  data: Array<AdminDiseaseGroupResponseDto>;
}
/**
 *
 * @export
 * @interface AdminDiseasesResponseDto
 */
export interface AdminDiseasesResponseDto {
  /**
   *
   * @type {Array<DiseaseResponseDto>}
   * @memberof AdminDiseasesResponseDto
   */
  data: Array<DiseaseResponseDto>;
}
/**
 *
 * @export
 * @interface AdminFaqGroupWithQuestionsResponseDto
 */
export interface AdminFaqGroupWithQuestionsResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminFaqGroupWithQuestionsResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdminFaqGroupWithQuestionsResponseDto
   */
  title: string;
  /**
   *
   * @type {Array<FaqQuestionResponseDto>}
   * @memberof AdminFaqGroupWithQuestionsResponseDto
   */
  questions: Array<FaqQuestionResponseDto>;
}
/**
 *
 * @export
 * @interface AdminFaqGroupsWithQuestionsResponseDto
 */
export interface AdminFaqGroupsWithQuestionsResponseDto {
  /**
   *
   * @type {Array<AdminFaqGroupWithQuestionsResponseDto>}
   * @memberof AdminFaqGroupsWithQuestionsResponseDto
   */
  data: Array<AdminFaqGroupWithQuestionsResponseDto>;
}
/**
 *
 * @export
 * @interface AdminFaqQuestionResponseDto
 */
export interface AdminFaqQuestionResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminFaqQuestionResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdminFaqQuestionResponseDto
   */
  groupId: string;
  /**
   *
   * @type {string}
   * @memberof AdminFaqQuestionResponseDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof AdminFaqQuestionResponseDto
   */
  text: string;
}
/**
 *
 * @export
 * @interface AdminFaqQuestionsResponseDto
 */
export interface AdminFaqQuestionsResponseDto {
  /**
   *
   * @type {Array<AdminFaqQuestionResponseDto>}
   * @memberof AdminFaqQuestionsResponseDto
   */
  data: Array<AdminFaqQuestionResponseDto>;
}
/**
 *
 * @export
 * @interface AdminPaginatedCompaniesResponseDto
 */
export interface AdminPaginatedCompaniesResponseDto {
  /**
   *
   * @type {number}
   * @memberof AdminPaginatedCompaniesResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof AdminPaginatedCompaniesResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof AdminPaginatedCompaniesResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof AdminPaginatedCompaniesResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<AdminCompanyResponseDto>}
   * @memberof AdminPaginatedCompaniesResponseDto
   */
  data: Array<AdminCompanyResponseDto>;
}
/**
 *
 * @export
 * @interface AdminPaginatedCompanyCreateRequestResponseDto
 */
export interface AdminPaginatedCompanyCreateRequestResponseDto {
  /**
   *
   * @type {number}
   * @memberof AdminPaginatedCompanyCreateRequestResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof AdminPaginatedCompanyCreateRequestResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof AdminPaginatedCompanyCreateRequestResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof AdminPaginatedCompanyCreateRequestResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<AdminCreateCompanyRequestResponseDto>}
   * @memberof AdminPaginatedCompanyCreateRequestResponseDto
   */
  data: Array<AdminCreateCompanyRequestResponseDto>;
}
/**
 *
 * @export
 * @interface AdminPaginatedPatientsResponseDto
 */
export interface AdminPaginatedPatientsResponseDto {
  /**
   *
   * @type {number}
   * @memberof AdminPaginatedPatientsResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof AdminPaginatedPatientsResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof AdminPaginatedPatientsResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof AdminPaginatedPatientsResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<AdminPatientResponseDto>}
   * @memberof AdminPaginatedPatientsResponseDto
   */
  data: Array<AdminPatientResponseDto>;
}
/**
 *
 * @export
 * @interface AdminPatientResponseDto
 */
export interface AdminPatientResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminPatientResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdminPatientResponseDto
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof AdminPatientResponseDto
   */
  lastname?: string;
  /**
   *
   * @type {string}
   * @memberof AdminPatientResponseDto
   */
  avatarKey?: string;
  /**
   *
   * @type {string}
   * @memberof AdminPatientResponseDto
   */
  dateOfBirth?: string;
  /**
   *
   * @type {SessionOutcomeResponseDto}
   * @memberof AdminPatientResponseDto
   */
  outcome: SessionOutcomeResponseDto;
}
/**
 *
 * @export
 * @interface AdminPaymentResponseDto
 */
export interface AdminPaymentResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminPaymentResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdminPaymentResponseDto
   */
  dealId?: string;
  /**
   *
   * @type {number}
   * @memberof AdminPaymentResponseDto
   */
  value: number;
  /**
   *
   * @type {number}
   * @memberof AdminPaymentResponseDto
   */
  discountValue: number;
  /**
   *
   * @type {string}
   * @memberof AdminPaymentResponseDto
   */
  currency: string;
  /**
   *
   * @type {string}
   * @memberof AdminPaymentResponseDto
   */
  dealStartDate: string;
  /**
   *
   * @type {string}
   * @memberof AdminPaymentResponseDto
   */
  cancellationDetails?: string;
  /**
   *
   * @type {string}
   * @memberof AdminPaymentResponseDto
   */
  payoutStatus: AdminPaymentResponseDtoPayoutStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AdminPaymentResponseDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof AdminPaymentResponseDto
   */
  status: AdminPaymentResponseDtoStatusEnum;
}

export const AdminPaymentResponseDtoPayoutStatusEnum = {
  Pending: 'pending',
  Succeeded: 'succeeded',
  Canceled: 'canceled',
  Awaiting: 'awaiting',
  AwaitingPromocode: 'awaiting_promocode',
} as const;

export type AdminPaymentResponseDtoPayoutStatusEnum =
  typeof AdminPaymentResponseDtoPayoutStatusEnum[keyof typeof AdminPaymentResponseDtoPayoutStatusEnum];
export const AdminPaymentResponseDtoStatusEnum = {
  Pending: 'pending',
  WaitingForCapture: 'waiting_for_capture',
  Succeeded: 'succeeded',
  Canceled: 'canceled',
  Awaiting: 'awaiting',
  Retrying: 'retrying',
} as const;

export type AdminPaymentResponseDtoStatusEnum =
  typeof AdminPaymentResponseDtoStatusEnum[keyof typeof AdminPaymentResponseDtoStatusEnum];

/**
 *
 * @export
 * @interface AdminPromocodeResponseDto
 */
export interface AdminPromocodeResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminPromocodeResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdminPromocodeResponseDto
   */
  discountType: AdminPromocodeResponseDtoDiscountTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AdminPromocodeResponseDto
   */
  code: string;
  /**
   *
   * @type {number}
   * @memberof AdminPromocodeResponseDto
   */
  amount: number | null;
  /**
   *
   * @type {number}
   * @memberof AdminPromocodeResponseDto
   */
  percentage: number | null;
  /**
   *
   * @type {string}
   * @memberof AdminPromocodeResponseDto
   */
  expireDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminPromocodeResponseDto
   */
  firstUseOnly: boolean;
  /**
   *
   * @type {number}
   * @memberof AdminPromocodeResponseDto
   */
  timesUsed: number;
  /**
   *
   * @type {string}
   * @memberof AdminPromocodeResponseDto
   */
  createdAt: string;
}

export const AdminPromocodeResponseDtoDiscountTypeEnum = {
  Amount: 'amount',
  Percentage: 'percentage',
} as const;

export type AdminPromocodeResponseDtoDiscountTypeEnum =
  typeof AdminPromocodeResponseDtoDiscountTypeEnum[keyof typeof AdminPromocodeResponseDtoDiscountTypeEnum];

/**
 *
 * @export
 * @interface AdminPromocodesResponseDto
 */
export interface AdminPromocodesResponseDto {
  /**
   *
   * @type {number}
   * @memberof AdminPromocodesResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof AdminPromocodesResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof AdminPromocodesResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof AdminPromocodesResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<AdminPromocodeResponseDto>}
   * @memberof AdminPromocodesResponseDto
   */
  data: Array<AdminPromocodeResponseDto>;
}
/**
 *
 * @export
 * @interface AdminRecommendationInfoResponseDto
 */
export interface AdminRecommendationInfoResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminRecommendationInfoResponseDto
   */
  recommendationText: string | null;
  /**
   *
   * @type {Array<UserFileResponseDto>}
   * @memberof AdminRecommendationInfoResponseDto
   */
  recommendationFiles: Array<UserFileResponseDto> | null;
}
/**
 *
 * @export
 * @interface AdminRejectTherapistRequestDto
 */
export interface AdminRejectTherapistRequestDto {
  /**
   *
   * @type {string}
   * @memberof AdminRejectTherapistRequestDto
   */
  message: string;
}
/**
 *
 * @export
 * @interface AdminResponseDto
 */
export interface AdminResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdminResponseDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof AdminResponseDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminResponseDto
   */
  role: AdminResponseDtoRoleEnum;
}

export const AdminResponseDtoRoleEnum = {
  Admin: 'admin',
  Superadmin: 'superadmin',
  Askona: 'askona',
  Medsi: 'medsi',
} as const;

export type AdminResponseDtoRoleEnum =
  typeof AdminResponseDtoRoleEnum[keyof typeof AdminResponseDtoRoleEnum];

/**
 *
 * @export
 * @interface AdminSessionResponseDto
 */
export interface AdminSessionResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminSessionResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdminSessionResponseDto
   */
  therapistId: string;
  /**
   *
   * @type {string}
   * @memberof AdminSessionResponseDto
   */
  availabilityId: string;
  /**
   *
   * @type {string}
   * @memberof AdminSessionResponseDto
   */
  status: AdminSessionResponseDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof AdminSessionResponseDto
   */
  type: AdminSessionResponseDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof AdminSessionResponseDto
   */
  cancelerId?: string;
  /**
   *
   * @type {string}
   * @memberof AdminSessionResponseDto
   */
  cancelReason?: string;
  /**
   *
   * @type {AvailabilityResponseDto}
   * @memberof AdminSessionResponseDto
   */
  availability: AvailabilityResponseDto;
  /**
   *
   * @type {Array<AdminPatientResponseDto>}
   * @memberof AdminSessionResponseDto
   */
  patients: Array<AdminPatientResponseDto>;
  /**
   *
   * @type {AdminPaymentResponseDto}
   * @memberof AdminSessionResponseDto
   */
  payment: AdminPaymentResponseDto;
  /**
   *
   * @type {boolean}
   * @memberof AdminSessionResponseDto
   */
  isTherapistVisited: boolean;
}

export const AdminSessionResponseDtoStatusEnum = {
  Awaiting: 'awaiting',
  Live: 'live',
  Complete: 'complete',
  Canceled: 'canceled',
} as const;

export type AdminSessionResponseDtoStatusEnum =
  typeof AdminSessionResponseDtoStatusEnum[keyof typeof AdminSessionResponseDtoStatusEnum];
export const AdminSessionResponseDtoTypeEnum = {
  Personal: 'personal',
  Group: 'group',
} as const;

export type AdminSessionResponseDtoTypeEnum =
  typeof AdminSessionResponseDtoTypeEnum[keyof typeof AdminSessionResponseDtoTypeEnum];

/**
 *
 * @export
 * @interface AdminSessionsResponseDto
 */
export interface AdminSessionsResponseDto {
  /**
   *
   * @type {number}
   * @memberof AdminSessionsResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof AdminSessionsResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof AdminSessionsResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof AdminSessionsResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<AdminSessionResponseDto>}
   * @memberof AdminSessionsResponseDto
   */
  data: Array<AdminSessionResponseDto>;
}
/**
 *
 * @export
 * @interface AdminSignInDto
 */
export interface AdminSignInDto {
  /**
   *
   * @type {string}
   * @memberof AdminSignInDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminSignInDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface AdminTherapistProfileUpdateResponseDto
 */
export interface AdminTherapistProfileUpdateResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminTherapistProfileUpdateResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistProfileUpdateResponseDto
   */
  therapistId: string;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistProfileUpdateResponseDto
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistProfileUpdateResponseDto
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistProfileUpdateResponseDto
   */
  lastname: string;
  /**
   * createdAt of profile update
   * @type {string}
   * @memberof AdminTherapistProfileUpdateResponseDto
   */
  createdAt: string;
  /**
   * deletedAt of therapist
   * @type {string}
   * @memberof AdminTherapistProfileUpdateResponseDto
   */
  deletedAt?: string;
  /**
   *
   * @type {PublicTherapistResponseDto}
   * @memberof AdminTherapistProfileUpdateResponseDto
   */
  update: PublicTherapistResponseDto;
}
/**
 *
 * @export
 * @interface AdminTherapistProfileUpdatesResponseDto
 */
export interface AdminTherapistProfileUpdatesResponseDto {
  /**
   *
   * @type {number}
   * @memberof AdminTherapistProfileUpdatesResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof AdminTherapistProfileUpdatesResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof AdminTherapistProfileUpdatesResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof AdminTherapistProfileUpdatesResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<AdminTherapistProfileUpdateResponseDto>}
   * @memberof AdminTherapistProfileUpdatesResponseDto
   */
  data: Array<AdminTherapistProfileUpdateResponseDto>;
}
/**
 *
 * @export
 * @interface AdminTherapistRequestResponseDto
 */
export interface AdminTherapistRequestResponseDto {
  /**
   *
   * @type {boolean}
   * @memberof AdminTherapistRequestResponseDto
   */
  isApproved: boolean;
  /**
   *
   * @type {TherapistPersonalInfoResponseDto}
   * @memberof AdminTherapistRequestResponseDto
   */
  personalInfo: TherapistPersonalInfoResponseDto;
  /**
   *
   * @type {TherapistEducationInfoResponseDto}
   * @memberof AdminTherapistRequestResponseDto
   */
  educationInfo: TherapistEducationInfoResponseDto;
  /**
   *
   * @type {TherapistWorkingInfoResponseDto}
   * @memberof AdminTherapistRequestResponseDto
   */
  workingInfo: TherapistWorkingInfoResponseDto;
  /**
   *
   * @type {TherapistTherapiesInfoResponseDto}
   * @memberof AdminTherapistRequestResponseDto
   */
  therapiesInfo: TherapistTherapiesInfoResponseDto;
  /**
   *
   * @type {TherapistSupervisionInfoResponseDto}
   * @memberof AdminTherapistRequestResponseDto
   */
  supervisionInfo: TherapistSupervisionInfoResponseDto;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistRequestResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistRequestResponseDto
   */
  createdAt: string;
  /**
   *
   * @type {AdminRecommendationInfoResponseDto}
   * @memberof AdminTherapistRequestResponseDto
   */
  recommendationInfo: AdminRecommendationInfoResponseDto;
  /**
   *
   * @type {AdminTherapistUserResponseDto}
   * @memberof AdminTherapistRequestResponseDto
   */
  user: AdminTherapistUserResponseDto;
}
/**
 *
 * @export
 * @interface AdminTherapistRequestsResponseDto
 */
export interface AdminTherapistRequestsResponseDto {
  /**
   *
   * @type {number}
   * @memberof AdminTherapistRequestsResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof AdminTherapistRequestsResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof AdminTherapistRequestsResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof AdminTherapistRequestsResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<AdminTherapistRequestResponseDto>}
   * @memberof AdminTherapistRequestsResponseDto
   */
  data: Array<AdminTherapistRequestResponseDto>;
}
/**
 *
 * @export
 * @interface AdminTherapistResponseDto
 */
export interface AdminTherapistResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminTherapistResponseDto
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminTherapistResponseDto
   */
  isApproved: boolean;
  /**
   *
   * @type {TherapistPersonalInfoResponseDto}
   * @memberof AdminTherapistResponseDto
   */
  personalInfo: TherapistPersonalInfoResponseDto;
  /**
   *
   * @type {TherapistEducationInfoResponseDto}
   * @memberof AdminTherapistResponseDto
   */
  educationInfo: TherapistEducationInfoResponseDto;
  /**
   *
   * @type {TherapistWorkingInfoResponseDto}
   * @memberof AdminTherapistResponseDto
   */
  workingInfo: TherapistWorkingInfoResponseDto;
  /**
   *
   * @type {TherapistTherapiesInfoResponseDto}
   * @memberof AdminTherapistResponseDto
   */
  therapiesInfo: TherapistTherapiesInfoResponseDto;
  /**
   *
   * @type {TherapistSupervisionInfoResponseDto}
   * @memberof AdminTherapistResponseDto
   */
  supervisionInfo: TherapistSupervisionInfoResponseDto;
  /**
   *
   * @type {AdminTherapistUserResponseDto}
   * @memberof AdminTherapistResponseDto
   */
  user: AdminTherapistUserResponseDto;
  /**
   *
   * @type {AdminRecommendationInfoResponseDto}
   * @memberof AdminTherapistResponseDto
   */
  recommendationInfo: AdminRecommendationInfoResponseDto;
  /**
   *
   * @type {number}
   * @memberof AdminTherapistResponseDto
   */
  totalSessions: number;
  /**
   *
   * @type {number}
   * @memberof AdminTherapistResponseDto
   */
  visitedSessions: number;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistResponseDto
   */
  deletedAt: string | null;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistResponseDto
   */
  createdAt: string;
  /**
   *
   * @type {number}
   * @memberof AdminTherapistResponseDto
   */
  completedCount: number;
  /**
   *
   * @type {number}
   * @memberof AdminTherapistResponseDto
   */
  awaitingCount: number;
  /**
   *
   * @type {number}
   * @memberof AdminTherapistResponseDto
   */
  canceledCount: number;
}
/**
 *
 * @export
 * @interface AdminTherapistUserResponseDto
 */
export interface AdminTherapistUserResponseDto {
  /**
   *
   * @type {string}
   * @memberof AdminTherapistUserResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistUserResponseDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistUserResponseDto
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistUserResponseDto
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistUserResponseDto
   */
  lastname?: string;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistUserResponseDto
   */
  gender: AdminTherapistUserResponseDtoGenderEnum;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistUserResponseDto
   */
  avatarKey?: string;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistUserResponseDto
   */
  miniAvatarKey?: string;
  /**
   *
   * @type {boolean}
   * @memberof AdminTherapistUserResponseDto
   */
  isPhoneConfirmed: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistUserResponseDto
   */
  paymentMethodId?: string;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistUserResponseDto
   */
  utmSource?: string;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistUserResponseDto
   */
  utmMedium?: string;
  /**
   *
   * @type {string}
   * @memberof AdminTherapistUserResponseDto
   */
  utmCampaign?: string;
}

export const AdminTherapistUserResponseDtoGenderEnum = {
  Male: 'male',
  Female: 'female',
  Transgender: 'transgender',
  Unknown: 'unknown',
} as const;

export type AdminTherapistUserResponseDtoGenderEnum =
  typeof AdminTherapistUserResponseDtoGenderEnum[keyof typeof AdminTherapistUserResponseDtoGenderEnum];

/**
 *
 * @export
 * @interface AdminTherapistsResponseDto
 */
export interface AdminTherapistsResponseDto {
  /**
   *
   * @type {number}
   * @memberof AdminTherapistsResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof AdminTherapistsResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof AdminTherapistsResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof AdminTherapistsResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<AdminTherapistResponseDto>}
   * @memberof AdminTherapistsResponseDto
   */
  data: Array<AdminTherapistResponseDto>;
}
/**
 *
 * @export
 * @interface AdminUpdateFaqGroupRequestDto
 */
export interface AdminUpdateFaqGroupRequestDto {
  /**
   *
   * @type {string}
   * @memberof AdminUpdateFaqGroupRequestDto
   */
  title?: string;
}
/**
 *
 * @export
 * @interface AdminUpdateFaqQuestionRequestDto
 */
export interface AdminUpdateFaqQuestionRequestDto {
  /**
   *
   * @type {string}
   * @memberof AdminUpdateFaqQuestionRequestDto
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateFaqQuestionRequestDto
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof AdminUpdateFaqQuestionRequestDto
   */
  groupId?: string;
}
/**
 *
 * @export
 * @interface AdminWithTokenResponseDto
 */
export interface AdminWithTokenResponseDto {
  /**
   *
   * @type {AdminResponseDto}
   * @memberof AdminWithTokenResponseDto
   */
  admin: AdminResponseDto;
  /**
   *
   * @type {string}
   * @memberof AdminWithTokenResponseDto
   */
  accessToken: string;
}
/**
 *
 * @export
 * @interface AdminsResponseDto
 */
export interface AdminsResponseDto {
  /**
   *
   * @type {Array<AdminResponseDto>}
   * @memberof AdminsResponseDto
   */
  admins: Array<AdminResponseDto>;
}
/**
 *
 * @export
 * @interface AvailabilitiesCreateResponseDto
 */
export interface AvailabilitiesCreateResponseDto {
  /**
   *
   * @type {Array<AvailabilityResponseDto>}
   * @memberof AvailabilitiesCreateResponseDto
   */
  availabilities: Array<AvailabilityResponseDto>;
  /**
   *
   * @type {Array<AvailabilityRecurringResponseDto>}
   * @memberof AvailabilitiesCreateResponseDto
   */
  availabilityRecurrings: Array<AvailabilityRecurringResponseDto>;
}
/**
 *
 * @export
 * @interface AvailabilitiesResponseDto
 */
export interface AvailabilitiesResponseDto {
  /**
   *
   * @type {Array<AvailabilityResponseDto>}
   * @memberof AvailabilitiesResponseDto
   */
  availabilities: Array<AvailabilityResponseDto>;
}
/**
 *
 * @export
 * @interface AvailabilityRecurringResponseDto
 */
export interface AvailabilityRecurringResponseDto {
  /**
   *
   * @type {string}
   * @memberof AvailabilityRecurringResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AvailabilityRecurringResponseDto
   */
  therapistId: string;
  /**
   *
   * @type {string}
   * @memberof AvailabilityRecurringResponseDto
   */
  startTime: string;
  /**
   *
   * @type {string}
   * @memberof AvailabilityRecurringResponseDto
   */
  type: AvailabilityRecurringResponseDtoTypeEnum;
}

export const AvailabilityRecurringResponseDtoTypeEnum = {
  Day: 'every_day',
  Weekday: 'every_weekday',
  Monday: 'every_monday',
  Tuesday: 'every_tuesday',
  Wednesday: 'every_wednesday',
  Thursday: 'every_thursday',
  Friday: 'every_friday',
  Saturday: 'every_saturday',
  Sunday: 'every_sunday',
} as const;

export type AvailabilityRecurringResponseDtoTypeEnum =
  typeof AvailabilityRecurringResponseDtoTypeEnum[keyof typeof AvailabilityRecurringResponseDtoTypeEnum];

/**
 *
 * @export
 * @interface AvailabilityRequestDto
 */
export interface AvailabilityRequestDto {
  /**
   *
   * @type {string}
   * @memberof AvailabilityRequestDto
   */
  startPeriodDate?: string;
  /**
   *
   * @type {string}
   * @memberof AvailabilityRequestDto
   */
  startTime?: string;
  /**
   *
   * @type {string}
   * @memberof AvailabilityRequestDto
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof AvailabilityRequestDto
   */
  type?: AvailabilityRequestDtoTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof AvailabilityRequestDto
   */
  isRecurring: boolean;
  /**
   *
   * @type {number}
   * @memberof AvailabilityRequestDto
   */
  offsetHours?: number;
}

export const AvailabilityRequestDtoTypeEnum = {
  Day: 'every_day',
  Weekday: 'every_weekday',
  Monday: 'every_monday',
  Tuesday: 'every_tuesday',
  Wednesday: 'every_wednesday',
  Thursday: 'every_thursday',
  Friday: 'every_friday',
  Saturday: 'every_saturday',
  Sunday: 'every_sunday',
} as const;

export type AvailabilityRequestDtoTypeEnum =
  typeof AvailabilityRequestDtoTypeEnum[keyof typeof AvailabilityRequestDtoTypeEnum];

/**
 *
 * @export
 * @interface AvailabilityResponseDto
 */
export interface AvailabilityResponseDto {
  /**
   *
   * @type {string}
   * @memberof AvailabilityResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AvailabilityResponseDto
   */
  therapistId: string;
  /**
   *
   * @type {string}
   * @memberof AvailabilityResponseDto
   */
  availabilityRecurringId?: string;
  /**
   *
   * @type {string}
   * @memberof AvailabilityResponseDto
   */
  startDate: string;
}
/**
 *
 * @export
 * @interface BookSessionRequestDto
 */
export interface BookSessionRequestDto {
  /**
   *
   * @type {string}
   * @memberof BookSessionRequestDto
   */
  availabilityId: string;
  /**
   *
   * @type {string}
   * @memberof BookSessionRequestDto
   */
  type: BookSessionRequestDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof BookSessionRequestDto
   */
  promocode?: string;
  /**
   *
   * @type {number}
   * @memberof BookSessionRequestDto
   */
  notificationBeforeTime?: number;
}
/**
 *
 * @export
 * @interface BookSessionByTherapistRequestDto
 */
export interface BookSessionByTherapistRequestDto {
  /**
   *
   * @type {string}
   * @memberof BookSessionByTherapistRequestDto
   */
  patientId: string;
  /**
   *
   * @type {string}
   * @memberof BookSessionByTherapistRequestDto
   */
  availabilityId: string;
  /**
   *
   * @type {string}
   * @memberof BookSessionByTherapistRequestDto
   */
  type: BookSessionRequestDtoTypeEnum;
}

export const BookSessionRequestDtoTypeEnum = {
  Personal: 'personal',
  Group: 'group',
} as const;

export type BookSessionRequestDtoTypeEnum =
  typeof BookSessionRequestDtoTypeEnum[keyof typeof BookSessionRequestDtoTypeEnum];

/**
 *
 * @export
 * @interface CancelSessionRequestDto
 */
export interface CancelSessionRequestDto {
  /**
   *
   * @type {string}
   * @memberof CancelSessionRequestDto
   */
  cancelReason: string;
}
/**
 *
 * @export
 * @interface CancelSupervisionRequestDto
 */
export interface CancelSupervisionRequestDto {
  /**
   *
   * @type {string}
   * @memberof CancelSupervisionRequestDto
   */
  cancelReason: string;
}
/**
 *
 * @export
 * @interface CheckAuthTermResponseDto
 */
export interface CheckAuthTermResponseDto {
  /**
   *
   * @type {boolean}
   * @memberof CheckAuthTermResponseDto
   */
  isAvailable: boolean;
  /**
   *
   * @type {number}
   * @memberof CheckAuthTermResponseDto
   */
  secondsBeforeNextAttempt: number;
  /**
   *
   * @type {boolean}
   * @memberof CheckAuthTermResponseDto
   */
  hasPassword: boolean;
}
/**
 *
 * @export
 * @interface CheckEmailRequestDto
 */
export interface CheckEmailRequestDto {
  /**
   *
   * @type {string}
   * @memberof CheckEmailRequestDto
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof CheckEmailRequestDto
   */
  isNeedVerification?: boolean;
}
/**
 *
 * @export
 * @interface CheckPasswordRequestDto
 */
export interface CheckPasswordRequestDto {
  /**
   *
   * @type {string}
   * @memberof CheckPasswordRequestDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface CheckPhoneNumberVerificationDto
 */
export interface CheckPhoneNumberVerificationDto {
  /**
   *
   * @type {string}
   * @memberof CheckPhoneNumberVerificationDto
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof CheckPhoneNumberVerificationDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface CheckPhoneRequestDto
 */
export interface CheckPhoneRequestDto {
  /**
   *
   * @type {string}
   * @memberof CheckPhoneRequestDto
   */
  phone: string;
  /**
   *
   * @type {boolean}
   * @memberof CheckPhoneRequestDto
   */
  isNeedVerification?: boolean;
}
/**
 *
 * @export
 * @interface CheckVerificationCodeDto
 */
export interface CheckVerificationCodeDto {
  /**
   *
   * @type {boolean}
   * @memberof CheckVerificationCodeDto
   */
  confirmed: boolean;
}
/**
 *
 * @export
 * @interface CheckoutLinkRequestDto
 */
export interface CheckoutLinkRequestDto {
  /**
   *
   * @type {string}
   * @memberof CheckoutLinkRequestDto
   */
  returnUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CheckoutLinkRequestDto
   */
  availabilityId?: string;
  /**
   *
   * @type {boolean}
   * @memberof CheckoutLinkRequestDto
   */
  isOutsider?: boolean;
}
/**
 *
 * @export
 * @interface CheckoutPaymentMethodResponseDto
 */
export interface CheckoutPaymentMethodResponseDto {
  /**
   *
   * @type {string}
   * @memberof CheckoutPaymentMethodResponseDto
   */
  redirectUrl: string;
}
/**
 *
 * @export
 * @interface CreateAdminRequestDto
 */
export interface CreateAdminRequestDto {
  /**
   *
   * @type {string}
   * @memberof CreateAdminRequestDto
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof CreateAdminRequestDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateAdminRequestDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateAdminRequestDto
   */
  role?: CreateAdminRequestDtoRoleEnum;
}

export const CreateAdminRequestDtoRoleEnum = {
  Admin: 'admin',
  Superadmin: 'superadmin',
  Askona: 'askona',
  Medsi: 'medsi',
} as const;

export type CreateAdminRequestDtoRoleEnum =
  typeof CreateAdminRequestDtoRoleEnum[keyof typeof CreateAdminRequestDtoRoleEnum];

/**
 *
 * @export
 * @interface CreateAvailabilitiesRequestDto
 */
export interface CreateAvailabilitiesRequestDto {
  /**
   *
   * @type {Array<AvailabilityRequestDto>}
   * @memberof CreateAvailabilitiesRequestDto
   */
  availabilities: Array<AvailabilityRequestDto>;
}
/**
 *
 * @export
 * @interface CreateCompanyCompanyInfoRequestDto
 */
export interface CreateCompanyCompanyInfoRequestDto {
  /**
   *
   * @type {string}
   * @memberof CreateCompanyCompanyInfoRequestDto
   */
  name: string;
}
/**
 *
 * @export
 * @interface CreateCompanyCreateRequestRequestDto
 */
export interface CreateCompanyCreateRequestRequestDto {
  /**
   *
   * @type {string}
   * @memberof CreateCompanyCreateRequestRequestDto
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof CreateCompanyCreateRequestRequestDto
   */
  numberOfEmployee: number;
  /**
   *
   * @type {string}
   * @memberof CreateCompanyCreateRequestRequestDto
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof CreateCompanyCreateRequestRequestDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateCompanyCreateRequestRequestDto
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateCompanyCreateRequestRequestDto
   */
  contactByPhone: boolean;
}
/**
 *
 * @export
 * @interface CreateCompanyPromocodeInfoRequestDto
 */
export interface CreateCompanyPromocodeInfoRequestDto {
  /**
   *
   * @type {number}
   * @memberof CreateCompanyPromocodeInfoRequestDto
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof CreateCompanyPromocodeInfoRequestDto
   */
  percentage?: number;
  /**
   *
   * @type {number}
   * @memberof CreateCompanyPromocodeInfoRequestDto
   */
  quantity: number;
  /**
   *
   * @type {boolean}
   * @memberof CreateCompanyPromocodeInfoRequestDto
   */
  firstUseOnly?: boolean;
}
/**
 *
 * @export
 * @interface CreateCompanyRequestDto
 */
export interface CreateCompanyRequestDto {
  /**
   *
   * @type {CreateCompanyUserInfoDto}
   * @memberof CreateCompanyRequestDto
   */
  user: CreateCompanyUserInfoDto;
  /**
   *
   * @type {CreateCompanyCompanyInfoRequestDto}
   * @memberof CreateCompanyRequestDto
   */
  company: CreateCompanyCompanyInfoRequestDto;
  /**
   *
   * @type {Array<CreateCompanyPromocodeInfoRequestDto>}
   * @memberof CreateCompanyRequestDto
   */
  promocodes: Array<CreateCompanyPromocodeInfoRequestDto>;
  /**
   *
   * @type {string}
   * @memberof CreateCompanyRequestDto
   */
  promocodeExpireDate: string;
  /**
   *
   * @type {string}
   * @memberof CreateCompanyRequestDto
   */
  requestId: string;
  /**
   *
   * @type {string}
   * @memberof CreateCompanyRequestDto
   */
  adminId?: string;
}
/**
 *
 * @export
 * @interface CreateCompanyUserInfoDto
 */
export interface CreateCompanyUserInfoDto {
  /**
   *
   * @type {string}
   * @memberof CreateCompanyUserInfoDto
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof CreateCompanyUserInfoDto
   */
  lastname: string;
  /**
   *
   * @type {string}
   * @memberof CreateCompanyUserInfoDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateCompanyUserInfoDto
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof CreateCompanyUserInfoDto
   */
  avatarKey: string;
}
/**
 *
 * @export
 * @interface CreateFileRequestDto
 */
export interface CreateFileRequestDto {
  /**
   * File name
   * @type {string}
   * @memberof CreateFileRequestDto
   */
  fileName: string;
  /**
   * File content type
   * @type {string}
   * @memberof CreateFileRequestDto
   */
  contentType?: string;
  /**
   *
   * @type {string}
   * @memberof CreateFileRequestDto
   */
  mediaType: CreateFileRequestDtoMediaTypeEnum;
}

export const CreateFileRequestDtoMediaTypeEnum = {
  Avatars: 'avatars',
  Videos: 'videos',
  Documents: 'documents',
} as const;

export type CreateFileRequestDtoMediaTypeEnum =
  typeof CreateFileRequestDtoMediaTypeEnum[keyof typeof CreateFileRequestDtoMediaTypeEnum];

/**
 *
 * @export
 * @interface CreatePromocodeRequestDto
 */
export interface CreatePromocodeRequestDto {
  /**
   *
   * @type {number}
   * @memberof CreatePromocodeRequestDto
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof CreatePromocodeRequestDto
   */
  percentage?: number;
  /**
   *
   * @type {string}
   * @memberof CreatePromocodeRequestDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof CreatePromocodeRequestDto
   */
  expireDate?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof CreatePromocodeRequestDto
   */
  firstUseOnly?: boolean;
}
/**
 *
 * @export
 * @interface CreateRecommendedFilterRequestDto
 */
export interface CreateRecommendedFilterRequestDto {
  /**
   *
   * @type {string}
   * @memberof CreateRecommendedFilterRequestDto
   */
  condition: CreateRecommendedFilterRequestDtoConditionEnum;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendedFilterRequestDto
   */
  urgency: CreateRecommendedFilterRequestDtoUrgencyEnum;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendedFilterRequestDto
   */
  gender?: CreateRecommendedFilterRequestDtoGenderEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateRecommendedFilterRequestDto
   */
  diseases?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreateRecommendedFilterRequestDto
   */
  religion?: CreateRecommendedFilterRequestDtoReligionEnum;
  /**
   *
   * @type {boolean}
   * @memberof CreateRecommendedFilterRequestDto
   */
  workWithLgbt?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateRecommendedFilterRequestDto
   */
  workWithEthnicGroups?: boolean;
}

export const CreateRecommendedFilterRequestDtoConditionEnum = {
  Bad: 'bad',
  Normal: 'normal',
  Good: 'good',
} as const;

export type CreateRecommendedFilterRequestDtoConditionEnum =
  typeof CreateRecommendedFilterRequestDtoConditionEnum[keyof typeof CreateRecommendedFilterRequestDtoConditionEnum];
export const CreateRecommendedFilterRequestDtoUrgencyEnum = {
  Low: 'low',
  High: 'high',
} as const;

export type CreateRecommendedFilterRequestDtoUrgencyEnum =
  typeof CreateRecommendedFilterRequestDtoUrgencyEnum[keyof typeof CreateRecommendedFilterRequestDtoUrgencyEnum];
export const CreateRecommendedFilterRequestDtoGenderEnum = {
  Male: 'male',
  Female: 'female',
  Any: 'any',
} as const;

export type CreateRecommendedFilterRequestDtoGenderEnum =
  typeof CreateRecommendedFilterRequestDtoGenderEnum[keyof typeof CreateRecommendedFilterRequestDtoGenderEnum];
export const CreateRecommendedFilterRequestDtoReligionEnum = {
  Christianity: 'christianity',
  Islamism: 'islamism',
  Judaism: 'judaism',
  Buddhism: 'buddhism',
  Any: undefined,
} as const;

export type CreateRecommendedFilterRequestDtoReligionEnum =
  typeof CreateRecommendedFilterRequestDtoReligionEnum[keyof typeof CreateRecommendedFilterRequestDtoReligionEnum];

/**
 *
 * @export
 * @interface CreateSupervisionRequestDto
 */
export interface CreateSupervisionRequestDto {
  /**
   *
   * @type {string}
   * @memberof CreateSupervisionRequestDto
   */
  availabilityId: string;
  /**
   *
   * @type {string}
   * @memberof CreateSupervisionRequestDto
   */
  type: CreateSupervisionRequestDtoTypeEnum;
}

export const CreateSupervisionRequestDtoTypeEnum = {
  Personal: 'personal',
  Group: 'group',
} as const;

export type CreateSupervisionRequestDtoTypeEnum =
  typeof CreateSupervisionRequestDtoTypeEnum[keyof typeof CreateSupervisionRequestDtoTypeEnum];

/**
 *
 * @export
 * @interface CreateTestSessionResponse
 */
export interface CreateTestSessionResponse {
  /**
   *
   * @type {string}
   * @memberof CreateTestSessionResponse
   */
  accessToken: string;
  /**
   *
   * @type {number}
   * @memberof CreateTestSessionResponse
   */
  uid: number;
  /**
   *
   * @type {string}
   * @memberof CreateTestSessionResponse
   */
  channelName: string;
}
/**
 *
 * @export
 * @interface CreateTherapistProfileRequestDto
 */
export interface CreateTherapistProfileRequestDto {
  /**
   *
   * @type {string}
   * @memberof CreateTherapistProfileRequestDto
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof CreateTherapistProfileRequestDto
   */
  email?: string;
  /**
   *
   * @type {TherapistPersonalInfoRequestDto}
   * @memberof CreateTherapistProfileRequestDto
   */
  personalInfo: TherapistPersonalInfoRequestDto;
  /**
   *
   * @type {TherapistEducationInfoRequestDto}
   * @memberof CreateTherapistProfileRequestDto
   */
  educationInfo: TherapistEducationInfoRequestDto;
  /**
   *
   * @type {TherapistWorkingInfoRequestDto}
   * @memberof CreateTherapistProfileRequestDto
   */
  workingInfo: TherapistWorkingInfoRequestDto;
  /**
   *
   * @type {TherapistTherapiesInfoRequestDto}
   * @memberof CreateTherapistProfileRequestDto
   */
  therapiesInfo: TherapistTherapiesInfoRequestDto;
  /**
   *
   * @type {TherapistSupervisionInfoRequestDto}
   * @memberof CreateTherapistProfileRequestDto
   */
  supervisionInfo: TherapistSupervisionInfoRequestDto;
  /**
   *
   * @type {TherapistRecommendationInfoRequestDto}
   * @memberof CreateTherapistProfileRequestDto
   */
  recommendationInfo?: TherapistRecommendationInfoRequestDto;
}
/**
 *
 * @export
 * @interface DayLimitsSearchStatisticsResponseDto
 */
export interface DayLimitsSearchStatisticsResponseDto {
  /**
   *
   * @type {string}
   * @memberof DayLimitsSearchStatisticsResponseDto
   */
  dayLimit1: string;
  /**
   *
   * @type {string}
   * @memberof DayLimitsSearchStatisticsResponseDto
   */
  dayLimit3: string;
  /**
   *
   * @type {string}
   * @memberof DayLimitsSearchStatisticsResponseDto
   */
  dayLimit7: string;
  /**
   *
   * @type {string}
   * @memberof DayLimitsSearchStatisticsResponseDto
   */
  dayLimit30: string;
}
/**
 *
 * @export
 * @interface DeleteAvailabilityRequestDto
 */
export interface DeleteAvailabilityRequestDto {
  /**
   *
   * @type {string}
   * @memberof DeleteAvailabilityRequestDto
   */
  deleteMode?: DeleteAvailabilityRequestDtoDeleteModeEnum;
  /**
   *
   * @type {string}
   * @memberof DeleteAvailabilityRequestDto
   */
  deleteDate?: string;
}

export const DeleteAvailabilityRequestDtoDeleteModeEnum = {
  All: 'all',
  OnlyThis: 'only_this',
  ThisAndNext: 'this_and_next',
} as const;

export type DeleteAvailabilityRequestDtoDeleteModeEnum =
  typeof DeleteAvailabilityRequestDtoDeleteModeEnum[keyof typeof DeleteAvailabilityRequestDtoDeleteModeEnum];

/**
 *
 * @export
 * @interface DiseaseGroupResponseDto
 */
export interface DiseaseGroupResponseDto {
  /**
   *
   * @type {string}
   * @memberof DiseaseGroupResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DiseaseGroupResponseDto
   */
  name: string;
  /**
   *
   * @type {Array<DiseaseResponseDto>}
   * @memberof DiseaseGroupResponseDto
   */
  items: Array<DiseaseResponseDto>;
}
/**
 *
 * @export
 * @interface DiseaseResponseDto
 */
export interface DiseaseResponseDto {
  /**
   *
   * @type {string}
   * @memberof DiseaseResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DiseaseResponseDto
   */
  name: string;
  /**
    /**
     *
     * @type {string}
     * @memberof DiseaseResponseDto
     */
  url: string;
  /**
   *
   * @type {string}
   * @memberof DiseaseResponseDto
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof DiseaseResponseDto
   */
  diseasesGroupId: string;
}
/**
 *
 * @export
 * @interface DiseasesSearchStatisticsResponseDto
 */

export interface iDiseaseGroupParams { isChild?: boolean }
export interface DiseasesSearchStatisticsResponseDto {
  /**
   *
   * @type {string}
   * @memberof DiseasesSearchStatisticsResponseDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DiseasesSearchStatisticsResponseDto
   */
  percentage: string;
}
/**
 *
 * @export
 * @interface FaqGroupResponseDto
 */
export interface FaqGroupResponseDto {
  /**
   *
   * @type {string}
   * @memberof FaqGroupResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FaqGroupResponseDto
   */
  title: string;
  /**
   *
   * @type {Array<FaqQuestionResponseDto>}
   * @memberof FaqGroupResponseDto
   */
  questions: Array<FaqQuestionResponseDto>;
}
/**
 *
 * @export
 * @interface FaqQuestionResponseDto
 */
export interface FaqQuestionResponseDto {
  /**
   *
   * @type {string}
   * @memberof FaqQuestionResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FaqQuestionResponseDto
   */
  groupId: string;
  /**
   *
   * @type {string}
   * @memberof FaqQuestionResponseDto
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof FaqQuestionResponseDto
   */
  text: string;
}
/**
 *
 * @export
 * @interface FaqResponseDto
 */
export interface FaqResponseDto {
  /**
   *
   * @type {Array<FaqGroupResponseDto>}
   * @memberof FaqResponseDto
   */
  groups: Array<FaqGroupResponseDto>;
}
/**
 *
 * @export
 * @interface GendersSearchStatisticsResponseDto
 */
export interface GendersSearchStatisticsResponseDto {
  /**
   *
   * @type {string}
   * @memberof GendersSearchStatisticsResponseDto
   */
  male: string;
  /**
   *
   * @type {string}
   * @memberof GendersSearchStatisticsResponseDto
   */
  female: string;
}
/**
 *
 * @export
 * @interface GenerateTokenRequestDto
 */
export interface GenerateTokenRequestDto {
  /**
   *
   * @type {string}
   * @memberof GenerateTokenRequestDto
   */
  medsiUserId: string;
  /**
   *
   * @type {string}
   * @memberof GenerateTokenRequestDto
   */
  phone: string;
}
/**
 *
 * @export
 * @interface GenerateTokenResponseDto
 */
export interface GenerateTokenResponseDto {
  /**
   *
   * @type {string}
   * @memberof GenerateTokenResponseDto
   */
  medsiToken: string;
  /**
   *
   * @type {string}
   * @memberof GenerateTokenResponseDto
   */
  redirectUrl: string;
}
/**
 *
 * @export
 * @interface GetApiKeyResponseDto
 */
export interface GetApiKeyResponseDto {
  /**
   *
   * @type {string}
   * @memberof GetApiKeyResponseDto
   */
  apiKey: string;
}
/**
 *
 * @export
 * @interface GetPromocodesResponseDto
 */
export interface GetPromocodesResponseDto {
  /**
   *
   * @type {Array<PromocodePackageResponseDto>}
   * @memberof GetPromocodesResponseDto
   */
  packages: Array<PromocodePackageResponseDto>;
}
/**
 *
 * @export
 * @interface GetSearchStatisticsRequestDto
 */
export interface GetSearchStatisticsRequestDto {
  /**
   *
   * @type {string}
   * @memberof GetSearchStatisticsRequestDto
   */
  dateFrom?: string;
  /**
   *
   * @type {string}
   * @memberof GetSearchStatisticsRequestDto
   */
  dateTo?: string;
}
/**
 *
 * @export
 * @interface GetStatisticsResponseDto
 */
export interface GetStatisticsResponseDto {
  /**
   *
   * @type {Array<StatisticsMonth>}
   * @memberof GetStatisticsResponseDto
   */
  months: Array<StatisticsMonth>;
}
/**
 *
 * @export
 * @interface JoinSessionResponseDto
 */
export interface JoinSessionResponseDto {
  /**
   *
   * @type {string}
   * @memberof JoinSessionResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof JoinSessionResponseDto
   */
  therapistId: string;
  /**
   *
   * @type {string}
   * @memberof JoinSessionResponseDto
   */
  availabilityId: string;
  /**
   *
   * @type {string}
   * @memberof JoinSessionResponseDto
   */
  status: JoinSessionResponseDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof JoinSessionResponseDto
   */
  type: JoinSessionResponseDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof JoinSessionResponseDto
   */
  cancelerId?: string;
  /**
   *
   * @type {string}
   * @memberof JoinSessionResponseDto
   */
  cancelReason?: string;
  /**
   *
   * @type {string}
   * @memberof JoinSessionResponseDto
   */
  accessToken: string;
  /**
   *
   * @type {number}
   * @memberof JoinSessionResponseDto
   */
  uid: number;
  /**
   *
   * @type {string}
   * @memberof JoinSessionResponseDto
   */
  channelName: string;
  /**
   *
   * @type {SessionAvailabilityResponseDto}
   * @memberof JoinSessionResponseDto
   */
  availability: SessionAvailabilityResponseDto;
  /**
   *
   * @type {SessionTherapistResponseDto}
   * @memberof JoinSessionResponseDto
   */
  therapist: SessionTherapistResponseDto;
  /**
   *
   * @type {Array<SessionPatientResponseDto>}
   * @memberof JoinSessionResponseDto
   */
  patients: Array<SessionPatientResponseDto>;
}

export const JoinSessionResponseDtoStatusEnum = {
  Awaiting: 'awaiting',
  Live: 'live',
  Complete: 'complete',
  Canceled: 'canceled',
} as const;

export type JoinSessionResponseDtoStatusEnum =
  typeof JoinSessionResponseDtoStatusEnum[keyof typeof JoinSessionResponseDtoStatusEnum];
export const JoinSessionResponseDtoTypeEnum = {
  Personal: 'personal',
  Group: 'group',
} as const;

export type JoinSessionResponseDtoTypeEnum =
  typeof JoinSessionResponseDtoTypeEnum[keyof typeof JoinSessionResponseDtoTypeEnum];

/**
 *
 * @export
 * @interface JoinSupervisionResponseDto
 */
export interface JoinSupervisionResponseDto {
  /**
   *
   * @type {string}
   * @memberof JoinSupervisionResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof JoinSupervisionResponseDto
   */
  supervisorId: string;
  /**
   *
   * @type {string}
   * @memberof JoinSupervisionResponseDto
   */
  availabilityId: string;
  /**
   *
   * @type {string}
   * @memberof JoinSupervisionResponseDto
   */
  status: JoinSupervisionResponseDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof JoinSupervisionResponseDto
   */
  type: JoinSupervisionResponseDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof JoinSupervisionResponseDto
   */
  cancelerId?: string;
  /**
   *
   * @type {string}
   * @memberof JoinSupervisionResponseDto
   */
  cancelReason?: string;
  /**
   *
   * @type {string}
   * @memberof JoinSupervisionResponseDto
   */
  accessToken: string;
  /**
   *
   * @type {number}
   * @memberof JoinSupervisionResponseDto
   */
  uid: number;
  /**
   *
   * @type {string}
   * @memberof JoinSupervisionResponseDto
   */
  channelName: string;
}

export const JoinSupervisionResponseDtoStatusEnum = {
  Awaiting: 'awaiting',
  Live: 'live',
  Complete: 'complete',
  Canceled: 'canceled',
} as const;

export type JoinSupervisionResponseDtoStatusEnum =
  typeof JoinSupervisionResponseDtoStatusEnum[keyof typeof JoinSupervisionResponseDtoStatusEnum];
export const JoinSupervisionResponseDtoTypeEnum = {
  Personal: 'personal',
  Group: 'group',
} as const;

export type JoinSupervisionResponseDtoTypeEnum =
  typeof JoinSupervisionResponseDtoTypeEnum[keyof typeof JoinSupervisionResponseDtoTypeEnum];

/**
 *
 * @export
 * @interface MedsiSignUpRequestDto
 */
export interface MedsiSignUpRequestDto {
  /**
   *
   * @type {string}
   * @memberof MedsiSignUpRequestDto
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof MedsiSignUpRequestDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface MessageSuccessResponseDto
 */
export interface MessageSuccessResponseDto {
  /**
   *
   * @type {string}
   * @memberof MessageSuccessResponseDto
   */
  message: string;
  /**
   *
   * @type {boolean}
   * @memberof MessageSuccessResponseDto
   */
  success: boolean;
}
/**
 *
 * @export
 * @interface NotificationResponseDto
 */
export interface NotificationResponseDto {
  /**
   *
   * @type {string}
   * @memberof NotificationResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof NotificationResponseDto
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof NotificationResponseDto
   */
  event: NotificationResponseDtoEventEnum;
  /**
   *
   * @type {string}
   * @memberof NotificationResponseDto
   */
  popUpDate: string;
  /**
   *
   * @type {boolean}
   * @memberof NotificationResponseDto
   */
  isSended: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NotificationResponseDto
   */
  isViewed: boolean;
  /**
   *
   * @type {object}
   * @memberof NotificationResponseDto
   */
  metadata: object;
}

export const NotificationResponseDtoEventEnum = {
  ConsultationCreated: 'consultation-created',
  ConsultationCanceled: 'consultation-canceled',
  ConsultationAwaiting: 'consultation-awaiting',
  ConsultationShift: 'consultation-shift',
  ConsultationStart: 'consultation-start',
  ConsultationNote: 'consultation-note',
  Profile: 'profile',
  Payment: 'payment',
  Payout: 'payout',
  Approve: 'approve',
  NewPromocodes: 'new-promocodes',
  PromocodesRunningOut: 'promocodes-running-out',
  PromocodesExpiring: 'promocodes-expiring',
  TherapistVerify: 'therapist-verify',
  PaymentMethod: 'payment-method',
  GreetingsTherapist: 'greetings-therapist',
  GreetingsPatient: 'greetings-patient',
} as const;

export type NotificationResponseDtoEventEnum =
  typeof NotificationResponseDtoEventEnum[keyof typeof NotificationResponseDtoEventEnum];

/**
 *
 * @export
 * @interface NotificationTimeResponseDto
 */
export interface NotificationTimeResponseDto {
  /**
   *
   * @type {string}
   * @memberof NotificationTimeResponseDto
   */
  timeInHours: NotificationTimeResponseDtoTimeInHoursEnum;
}

export const NotificationTimeResponseDtoTimeInHoursEnum = {
  _0: '0',
  _1: '1',
  _8: '8',
  _16: '16',
  _24: '24',
  _48: '48',
} as const;

export type NotificationTimeResponseDtoTimeInHoursEnum =
  typeof NotificationTimeResponseDtoTimeInHoursEnum[keyof typeof NotificationTimeResponseDtoTimeInHoursEnum];

/**
 *
 * @export
 * @interface NotificationsResponseDto
 */
export interface NotificationsResponseDto {
  /**
   *
   * @type {number}
   * @memberof NotificationsResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof NotificationsResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof NotificationsResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof NotificationsResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<NotificationResponseDto>}
   * @memberof NotificationsResponseDto
   */
  data: Array<NotificationResponseDto>;
  /**
   *
   * @type {number}
   * @memberof NotificationsResponseDto
   */
  notViewedCount: number;
}
/**
 *
 * @export
 * @interface PaginatedSessionResponseDto
 */
export interface PaginatedSessionResponseDto {
  /**
   *
   * @type {number}
   * @memberof PaginatedSessionResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedSessionResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedSessionResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedSessionResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<SessionResponseDto>}
   * @memberof PaginatedSessionResponseDto
   */
  data: Array<SessionResponseDto>;
}
/**
 *
 * @export
 * @interface PartialPublicPatientProfileResponseDto
 */
export interface PartialPublicPatientProfileResponseDto {
  /**
   *
   * @type {string}
   * @memberof PartialPublicPatientProfileResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PartialPublicPatientProfileResponseDto
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof PartialPublicPatientProfileResponseDto
   */
  lastname?: string;
  /**
   *
   * @type {string}
   * @memberof PartialPublicPatientProfileResponseDto
   */
  avatarKey?: string;
  /**
   *
   * @type {string}
   * @memberof PartialPublicPatientProfileResponseDto
   */
  dateOfBirth?: string;
}
/**
 *
 * @export
 * @interface PatientOnboardingRequestDto
 */
export interface PatientOnboardingRequestDto {
  /**
   *
   * @type {string}
   * @memberof PatientOnboardingRequestDto
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOnboardingRequestDto
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof PatientOnboardingRequestDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOnboardingRequestDto
   */
  phone?: string;
  /**
   *
   * @type {boolean}
   * @memberof PatientOnboardingRequestDto
   */
  hasTherapyExperience?: boolean;
}
/**
 *
 * @export
 * @interface PatientProfileResponseDto
 */
export interface PatientProfileResponseDto {
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  lastname?: string;
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  role: PatientProfileResponseDtoRoleEnum;
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  gender: PatientProfileResponseDtoGenderEnum;
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  avatarKey?: string;
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  miniAvatarKey?: string;
  /**
   *
   * @type {boolean}
   * @memberof PatientProfileResponseDto
   */
  isEmailConfirmed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatientProfileResponseDto
   */
  isPhoneConfirmed: boolean;
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  notificationBeforeTime?: string;
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  paymentMethodId?: string;
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  utmSource?: string;
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  utmMedium?: string;
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  utmCampaign?: string;
  /**
   *
   * @type {string}
   * @memberof PatientProfileResponseDto
   */
  dateOfBirth?: string;
}

export const PatientProfileResponseDtoRoleEnum = {
  Patient: 'patient',
  Therapist: 'therapist',
  Company: 'company',
} as const;

export type PatientProfileResponseDtoRoleEnum =
  typeof PatientProfileResponseDtoRoleEnum[keyof typeof PatientProfileResponseDtoRoleEnum];
export const PatientProfileResponseDtoGenderEnum = {
  Male: 'male',
  Female: 'female',
  Transgender: 'transgender',
  Unknown: 'unknown',
} as const;

export type PatientProfileResponseDtoGenderEnum =
  typeof PatientProfileResponseDtoGenderEnum[keyof typeof PatientProfileResponseDtoGenderEnum];

/**
 *
 * @export
 * @interface PatientTherapistShortInfoResponseDto
 */
export interface PatientTherapistShortInfoResponseDto {
  /**
   *
   * @type {string}
   * @memberof PatientTherapistShortInfoResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PatientTherapistShortInfoResponseDto
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof PatientTherapistShortInfoResponseDto
   */
  avatarKey: string;
  /**
   *
   * @type {string}
   * @memberof PatientTherapistShortInfoResponseDto
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof PatientTherapistShortInfoResponseDto
   */
  lastname: string;
  /**
   *
   * @type {string}
   * @memberof PatientTherapistShortInfoResponseDto
   */
  totalSessions: string;
  /**
   *
   * @type {string}
   * @memberof PatientTherapistShortInfoResponseDto
   */
  firstSessionDate: string;
  /**
   *
   * @type {string}
   * @memberof PatientTherapistShortInfoResponseDto
   */
  lastSessionDate: string;
}
/**
 *
 * @export
 * @interface PatientTherapistsShortInfosResponseDto
 */
export interface PatientTherapistsShortInfosResponseDto {
  /**
   *
   * @type {number}
   * @memberof PatientTherapistsShortInfosResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof PatientTherapistsShortInfosResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof PatientTherapistsShortInfosResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PatientTherapistsShortInfosResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<PatientTherapistShortInfoResponseDto>}
   * @memberof PatientTherapistsShortInfosResponseDto
   */
  data: Array<PatientTherapistShortInfoResponseDto>;
}
/**
 *
 * @export
 * @interface PaymentListResponseDto
 */
export interface PaymentListResponseDto {
  /**
   *
   * @type {string}
   * @memberof PaymentListResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PaymentListResponseDto
   */
  dealId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentListResponseDto
   */
  status: PaymentListResponseDtoStatusEnum;
  /**
   *
   * @type {number}
   * @memberof PaymentListResponseDto
   */
  value: number;
  /**
   *
   * @type {number}
   * @memberof PaymentListResponseDto
   */
  discountValue: number;
  /**
   *
   * @type {string}
   * @memberof PaymentListResponseDto
   */
  currency: string;
  /**
   *
   * @type {string}
   * @memberof PaymentListResponseDto
   */
  dealStartDate: string;
  /**
   *
   * @type {string}
   * @memberof PaymentListResponseDto
   */
  cancellationDetails?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentListResponseDto
   */
  payoutStatus: PaymentListResponseDtoPayoutStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentListResponseDto
   */
  createdAt: string;
  /**
   *
   * @type {ShortUserResponseDto}
   * @memberof PaymentListResponseDto
   */
  sender: ShortUserResponseDto;
  /**
   *
   * @type {ShortUserResponseDto}
   * @memberof PaymentListResponseDto
   */
  receiver: ShortUserResponseDto;
  /**
   *
   * @type {string}
   * @memberof PaymentListResponseDto
   */
  startDate: string;
}

export const PaymentListResponseDtoStatusEnum = {
  Processing: 'processing',
  Succeeded: 'succeeded',
  Refunded: 'refunded',
} as const;

export type PaymentListResponseDtoStatusEnum =
  typeof PaymentListResponseDtoStatusEnum[keyof typeof PaymentListResponseDtoStatusEnum];
export const PaymentListResponseDtoPayoutStatusEnum = {
  Pending: 'pending',
  Succeeded: 'succeeded',
  Canceled: 'canceled',
  Awaiting: 'awaiting',
  AwaitingPromocode: 'awaiting_promocode',
} as const;

export type PaymentListResponseDtoPayoutStatusEnum =
  typeof PaymentListResponseDtoPayoutStatusEnum[keyof typeof PaymentListResponseDtoPayoutStatusEnum];

/**
 *
 * @export
 * @interface PaymentMethodResponseDto
 */
export interface PaymentMethodResponseDto {
  /**
   *
   * @type {string}
   * @memberof PaymentMethodResponseDto
   */
  last4: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodResponseDto
   */
  expiryYear: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodResponseDto
   */
  expiryMonth: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethodResponseDto
   */
  cardType: PaymentMethodResponseDtoCardTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof PaymentMethodResponseDto
   */
  foreign: boolean;
}

export const PaymentMethodResponseDtoCardTypeEnum = {
  MasterCard: 'MasterCard',
  Visa: 'Visa',
  Mir: 'Mir',
  UnionPay: 'UnionPay',
  Jcb: 'JCB',
  AmericanExpress: 'AmericanExpress',
  DinersClub: 'DinersClub',
  DiscoverCard: 'DiscoverCard',
  InstaPayment: 'InstaPayment',
  InstaPaymentTm: 'InstaPaymentTM',
  Laser: 'Laser',
  Dankort: 'Dankort',
  Solo: 'Solo',
  Switch: 'Switch',
  Unknown: 'Unknown',
} as const;

export type PaymentMethodResponseDtoCardTypeEnum =
  typeof PaymentMethodResponseDtoCardTypeEnum[keyof typeof PaymentMethodResponseDtoCardTypeEnum];

/**
 *
 * @export
 * @interface PaymentResponseDto
 */
export interface PaymentResponseDto {
  /**
   *
   * @type {string}
   * @memberof PaymentResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PaymentResponseDto
   */
  dealId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentResponseDto
   */
  status: PaymentResponseDtoStatusEnum;
  /**
   *
   * @type {number}
   * @memberof PaymentResponseDto
   */
  value: number;
  /**
   *
   * @type {number}
   * @memberof PaymentResponseDto
   */
  discountValue: number;
  /**
   *
   * @type {string}
   * @memberof PaymentResponseDto
   */
  currency: string;
  /**
   *
   * @type {string}
   * @memberof PaymentResponseDto
   */
  dealStartDate: string;
  /**
   *
   * @type {string}
   * @memberof PaymentResponseDto
   */
  cancellationDetails?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentResponseDto
   */
  payoutStatus: PaymentResponseDtoPayoutStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentResponseDto
   */
  createdAt: string;
  /**
   *
   * @type {ShortUserResponseDto}
   * @memberof PaymentResponseDto
   */
  sender: ShortUserResponseDto;
  /**
   *
   * @type {ShortUserResponseDto}
   * @memberof PaymentResponseDto
   */
  receiver: ShortUserResponseDto;
}

export const PaymentResponseDtoStatusEnum = {
  Processing: 'processing',
  Succeeded: 'succeeded',
  Refunded: 'refunded',
} as const;

export type PaymentResponseDtoStatusEnum =
  typeof PaymentResponseDtoStatusEnum[keyof typeof PaymentResponseDtoStatusEnum];
export const PaymentResponseDtoPayoutStatusEnum = {
  Pending: 'pending',
  Succeeded: 'succeeded',
  Canceled: 'canceled',
  Awaiting: 'awaiting',
  AwaitingPromocode: 'awaiting_promocode',
} as const;

export type PaymentResponseDtoPayoutStatusEnum =
  typeof PaymentResponseDtoPayoutStatusEnum[keyof typeof PaymentResponseDtoPayoutStatusEnum];

/**
 *
 * @export
 * @interface PaymentStatisticsMonth
 */
export interface PaymentStatisticsMonth {
  /**
   *
   * @type {string}
   * @memberof PaymentStatisticsMonth
   */
  month: string;
  /**
   *
   * @type {number}
   * @memberof PaymentStatisticsMonth
   */
  income: number;
}
/**
 *
 * @export
 * @interface PaymentStatisticsResponseDto
 */
export interface PaymentStatisticsResponseDto {
  /**
   *
   * @type {Array<PaymentStatisticsMonth>}
   * @memberof PaymentStatisticsResponseDto
   */
  months: Array<PaymentStatisticsMonth>;
}
/**
 *
 * @export
 * @interface PaymentsListResponseDto
 */
export interface PaymentsListResponseDto {
  /**
   *
   * @type {number}
   * @memberof PaymentsListResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof PaymentsListResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof PaymentsListResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaymentsListResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<PaymentListResponseDto>}
   * @memberof PaymentsListResponseDto
   */
  data: Array<PaymentListResponseDto>;
}
/**
 *
 * @export
 * @interface PromocodePackageResponseDto
 */
export interface PromocodePackageResponseDto {
  /**
   *
   * @type {string}
   * @memberof PromocodePackageResponseDto
   */
  discountType: PromocodePackageResponseDtoDiscountTypeEnum;
  /**
   *
   * @type {number}
   * @memberof PromocodePackageResponseDto
   */
  amount: number | null;
  /**
   *
   * @type {number}
   * @memberof PromocodePackageResponseDto
   */
  percentage: number | null;
  /**
   *
   * @type {string}
   * @memberof PromocodePackageResponseDto
   */
  expireDate: string;
  /**
   *
   * @type {boolean}
   * @memberof PromocodePackageResponseDto
   */
  firstUseOnly: boolean;
  /**
   *
   * @type {number}
   * @memberof PromocodePackageResponseDto
   */
  used: number;
  /**
   *
   * @type {number}
   * @memberof PromocodePackageResponseDto
   */
  availableForSending: number;
  /**
   *
   * @type {number}
   * @memberof PromocodePackageResponseDto
   */
  total: number;
}

export const PromocodePackageResponseDtoDiscountTypeEnum = {
  Amount: 'amount',
  Percentage: 'percentage',
} as const;

export type PromocodePackageResponseDtoDiscountTypeEnum =
  typeof PromocodePackageResponseDtoDiscountTypeEnum[keyof typeof PromocodePackageResponseDtoDiscountTypeEnum];

/**
 *
 * @export
 * @interface PromocodeRequestRequestDto
 */
export interface PromocodeRequestRequestDto {
  /**
   *
   * @type {Array<CreateCompanyPromocodeInfoRequestDto>}
   * @memberof PromocodeRequestRequestDto
   */
  promocodes: Array<CreateCompanyPromocodeInfoRequestDto>;
}
/**
 *
 * @export
 * @interface PromocodeResponseDto
 */
export interface PromocodeResponseDto {
  /**
   *
   * @type {string}
   * @memberof PromocodeResponseDto
   */
  code: string;
  /**
   *
   * @type {number}
   * @memberof PromocodeResponseDto
   */
  amount: number | null;
  /**
   *
   * @type {number}
   * @memberof PromocodeResponseDto
   */
  percentage: number | null;
  /**
   *
   * @type {string}
   * @memberof PromocodeResponseDto
   */
  expireDate: string | null;
}
/**
 *
 * @export
 * @interface PublicPatientProfileResponseDto
 */
export interface PublicPatientProfileResponseDto {
  /**
   *
   * @type {string}
   * @memberof PublicPatientProfileResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PublicPatientProfileResponseDto
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof PublicPatientProfileResponseDto
   */
  lastname?: string;
  /**
   *
   * @type {string}
   * @memberof PublicPatientProfileResponseDto
   */
  avatarKey?: string;
  /**
   *
   * @type {string}
   * @memberof PublicPatientProfileResponseDto
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof PublicPatientProfileResponseDto
   */
  firstSessionDate: string;
  /**
   *
   * @type {string}
   * @memberof PublicPatientProfileResponseDto
   */
  lastSessionDate: string;
  /**
   *
   * @type {number}
   * @memberof PublicPatientProfileResponseDto
   */
  sessionCount: number;
}
/**
 *
 * @export
 * @interface PublicTherapistResponseDto
 */
export interface PublicTherapistResponseDto {
  isMedsi: boolean | undefined;
  /**
   *
   * @type {string}
   * @memberof PublicTherapistResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PublicTherapistResponseDto
   */
  url: string;
  /**
   *
   * @type {boolean}
   * @memberof PublicTherapistResponseDto
   */
  isApproved: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PublicTherapistResponseDto
   */
  isTop: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PublicTherapistResponseDto
   */
  isChildTherapist: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PublicTherapistResponseDto
   */
  isAdult: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PublicTherapistResponseDto
   */
  isSupervisor: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PublicTherapistResponseDto
   */
  hasMedsiSessions: boolean;
  /**
   *
   * @type {TherapistPersonalInfoResponseDto}
   * @memberof PublicTherapistResponseDto
   */
  personalInfo: TherapistPersonalInfoResponseDto;
  /**
   *
   * @type {TherapistEducationInfoResponseDto}
   * @memberof PublicTherapistResponseDto
   */
  educationInfo: TherapistEducationInfoResponseDto;
  /**
   *
   * @type {TherapistWorkingInfoResponseDto}
   * @memberof PublicTherapistResponseDto
   */
  workingInfo: TherapistWorkingInfoResponseDto;
  /**
   *
   * @type {TherapistTherapiesInfoResponseDto}
   * @memberof PublicTherapistResponseDto
   */
  therapiesInfo: TherapistTherapiesInfoResponseDto;
  /**
   *
   * @type {TherapistSupervisionInfoResponseDto}
   * @memberof PublicTherapistResponseDto
   */
  supervisionInfo: TherapistSupervisionInfoResponseDto;
}
/**
 *
 * @export
 * @interface PublicTherapistsResponseDto
 */
export interface PublicTherapistsResponseDto {
  /**
   *
   * @type {Array<PublicTherapistResponseDto>}
   * @memberof PublicTherapistsResponseDto
   */
  therapists: Array<PublicTherapistResponseDto>;
}
/**
 *
 * @export
 * @interface PublicTherapistsReviewsResponseDto
 */
export interface PublicTherapistsReviewsResponseDto {
  /**
   *
   * @type {number}
   * @memberof PublicTherapistsReviewsResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof PublicTherapistsReviewsResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PublicTherapistsReviewsResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof PublicTherapistsReviewsResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<PublicTherapistResponseDto>}
   * @memberof PublicTherapistsResponseDto
   */
  data: Array<PublicTherapistReviewDto>;
}
/**
 *
 * @export
 * @interface PublicTherapistReviewReviewerDto
 */
export interface PublicTherapistReviewReviewerDto {
  /**
   *
   * @type {string | null}
   * @memberof PublicTherapistReviewDto
   */
  'avatar_key?': string | null;
  /**
   *
   * @type {string}
   * @memberof PublicTherapistReviewDto
   */
  name: string;
}
/**
 *
 * @export
 * @interface PublicTherapistReviewDto
 */
export interface PublicTherapistReviewDto {
  /**
   *
   * @type {string}
   * @memberof PublicTherapistReviewDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof PublicTherapistReviewDto
   */
  patientId: string;
  /**
   *
   * @type {string}
   * @memberof PublicTherapistReviewDto
   */
  therapistId: string;
  /**
   *
   * @type {string}
   * @memberof PublicTherapistReviewDto
   */
  review: string;
  /**
   *
   * @type {PublicTherapistReviewReviewerDto}
   * @memberof PublicTherapistReviewDto
   */
  reviewer: PublicTherapistReviewReviewerDto;
  /**
   *
   * @type {number}
   * @memberof PublicTherapistReviewDto
   */
  rating: number;
}
/**
 *
 * @export
 * @interface PusherAuthRequestDto
 */
export interface PusherAuthRequestDto {
  /**
   *
   * @type {string}
   * @memberof PusherAuthRequestDto
   */
  socket_id: string;
  /**
   *
   * @type {string}
   * @memberof PusherAuthRequestDto
   */
  channel_name: string;
}
/**
 *
 * @export
 * @interface PusherAuthResponseDto
 */
export interface PusherAuthResponseDto {
  /**
   *
   * @type {string}
   * @memberof PusherAuthResponseDto
   */
  auth: string;
  /**
   *
   * @type {string}
   * @memberof PusherAuthResponseDto
   */
  channel_data?: string;
  /**
   *
   * @type {string}
   * @memberof PusherAuthResponseDto
   */
  shared_secret?: string;
}
/**
 *
 * @export
 * @interface RejectPatientRequestDto
 */
export interface RejectPatientRequestDto {
  /**
   *
   * @type {string}
   * @memberof RejectPatientRequestDto
   */
  rejectReason: string;
}
/**
 *
 * @export
 * @interface RejectTherapistRequestDto
 */
export interface RejectTherapistRequestDto {
  /**
   *
   * @type {string}
   * @memberof RejectTherapistRequestDto
   */
  rejectReason: string;
}
/**
 *
 * @export
 * @interface ReligionsSearchStatisticsResponseDto
 */
export interface ReligionsSearchStatisticsResponseDto {
  /**
   *
   * @type {string}
   * @memberof ReligionsSearchStatisticsResponseDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ReligionsSearchStatisticsResponseDto
   */
  percentage: string;
}
/**
 *
 * @export
 * @interface RequestSessionRequestDto
 */
export interface RequestSessionRequestDto {
  /**
   *
   * @type {string}
   * @memberof RequestSessionRequestDto
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof RequestSessionRequestDto
   */
  name: string;
}
/**
 *
 * @export
 * @interface RequiredSupervisionInfoRequestDto
 */
export interface RequiredSupervisionInfoRequestDto {
  /**
   *
   * @type {number}
   * @memberof RequiredSupervisionInfoRequestDto
   */
  supervisionFrequency: number;
  /**
   *
   * @type {Array<string>}
   * @memberof RequiredSupervisionInfoRequestDto
   */
  supervisionType: Array<string>;
  /**
   *
   * @type {number}
   * @memberof RequiredSupervisionInfoRequestDto
   */
  supervisionVisitsDuration: number;
  /**
   *
   * @type {string}
   * @memberof RequiredSupervisionInfoRequestDto
   */
  supervisionExperienceDescription: string;
}
/**
 *
 * @export
 * @interface RequiredSupervisionInfoResponseDto
 */
export interface RequiredSupervisionInfoResponseDto {
  /**
   *
   * @type {number}
   * @memberof RequiredSupervisionInfoResponseDto
   */
  supervisionFrequency?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof RequiredSupervisionInfoResponseDto
   */
  supervisionType?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof RequiredSupervisionInfoResponseDto
   */
  supervisionVisitsDuration?: number;
  /**
   *
   * @type {string}
   * @memberof RequiredSupervisionInfoResponseDto
   */
  supervisionExperienceDescription?: string;
}
/**
 *
 * @export
 * @interface RestorePasswordByEmailRequestDto
 */
export interface RestorePasswordByEmailRequestDto {
  /**
   *
   * @type {string}
   * @memberof RestorePasswordByEmailRequestDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface RestorePasswordByPhoneRequestDto
 */
export interface RestorePasswordByPhoneRequestDto {
  /**
   *
   * @type {string}
   * @memberof RestorePasswordByPhoneRequestDto
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof RestorePasswordByPhoneRequestDto
   */
  code: string;
  /**
   *
   * @type {string}
   * @memberof RestorePasswordByPhoneRequestDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface SearchStatisticsResponseDto
 */
export interface SearchStatisticsResponseDto {
  /**
   *
   * @type {SessionPriceSearchStatisticsResponseDto}
   * @memberof SearchStatisticsResponseDto
   */
  price: SessionPriceSearchStatisticsResponseDto;
  /**
   *
   * @type {GendersSearchStatisticsResponseDto}
   * @memberof SearchStatisticsResponseDto
   */
  genders: GendersSearchStatisticsResponseDto;
  /**
   *
   * @type {Array<DiseasesSearchStatisticsResponseDto>}
   * @memberof SearchStatisticsResponseDto
   */
  diseases: Array<DiseasesSearchStatisticsResponseDto>;
  /**
   *
   * @type {Array<TherapyMethodsSearchStatisticsResponseDto>}
   * @memberof SearchStatisticsResponseDto
   */
  therapyMethods: Array<TherapyMethodsSearchStatisticsResponseDto>;
  /**
   *
   * @type {Array<ReligionsSearchStatisticsResponseDto>}
   * @memberof SearchStatisticsResponseDto
   */
  religions: Array<ReligionsSearchStatisticsResponseDto>;
  /**
   *
   * @type {DayLimitsSearchStatisticsResponseDto}
   * @memberof SearchStatisticsResponseDto
   */
  dayLimits: DayLimitsSearchStatisticsResponseDto;
  /**
   *
   * @type {string}
   * @memberof SearchStatisticsResponseDto
   */
  workWithLgbt: string;
  /**
   *
   * @type {string}
   * @memberof SearchStatisticsResponseDto
   */
  workWithEthnicGroups: string;
}
/**
 *
 * @export
 * @interface SendPhoneNumberVerificationDto
 */
export interface SendPhoneNumberVerificationDto {
  /**
   *
   * @type {string}
   * @memberof SendPhoneNumberVerificationDto
   */
  phone: string;
}
/**
 *
 * @export
 * @interface SendPromocodesRequestDto
 */
export interface SendPromocodesRequestDto {
  /**
   *
   * @type {Array<string>}
   * @memberof SendPromocodesRequestDto
   */
  emails: Array<string>;
  /**
   *
   * @type {number}
   * @memberof SendPromocodesRequestDto
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof SendPromocodesRequestDto
   */
  percentage?: number;
  /**
   *
   * @type {number}
   * @memberof SendPromocodesRequestDto
   */
  quantity: number;
  /**
   *
   * @type {string}
   * @memberof SendPromocodesRequestDto
   */
  expireDate: string;
}
/**
 *
 * @export
 * @interface SendUpdateEmailConfirmationRequestDto
 */
export interface SendUpdateEmailConfirmationRequestDto {
  /**
   *
   * @type {string}
   * @memberof SendUpdateEmailConfirmationRequestDto
   */
  email: string;
}
/**
 *
 * @export
 * @interface SendingStatusDto
 */
export interface SendingStatusDto {
  /**
   *
   * @type {boolean}
   * @memberof SendingStatusDto
   */
  send: boolean;
  /**
   *
   * @type {number}
   * @memberof SendingStatusDto
   */
  secondsBeforeNextAttempt: number;
}
/**
 *
 * @export
 * @interface SessionAvailabilityResponseDto
 */
export interface SessionAvailabilityResponseDto {
  /**
   *
   * @type {string}
   * @memberof SessionAvailabilityResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SessionAvailabilityResponseDto
   */
  startDate: string;
}
/**
 *
 * @export
 * @interface SessionBaseResponseDto
 */
export interface SessionBaseResponseDto {
  /**
   *
   * @type {string}
   * @memberof SessionBaseResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SessionBaseResponseDto
   */
  therapistId: string;
  /**
   *
   * @type {string}
   * @memberof SessionBaseResponseDto
   */
  availabilityId: string;
  /**
   *
   * @type {string}
   * @memberof SessionBaseResponseDto
   */
  status: SessionBaseResponseDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SessionBaseResponseDto
   */
  type: SessionBaseResponseDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SessionBaseResponseDto
   */
  cancelerId?: string;
  /**
   *
   * @type {string}
   * @memberof SessionBaseResponseDto
   */
  cancelReason?: string;
}

export const SessionBaseResponseDtoStatusEnum = {
  Awaiting: 'awaiting',
  Live: 'live',
  Complete: 'complete',
  Canceled: 'canceled',
} as const;

export type SessionBaseResponseDtoStatusEnum =
  typeof SessionBaseResponseDtoStatusEnum[keyof typeof SessionBaseResponseDtoStatusEnum];
export const SessionBaseResponseDtoTypeEnum = {
  Personal: 'personal',
  Group: 'group',
} as const;

export type SessionBaseResponseDtoTypeEnum =
  typeof SessionBaseResponseDtoTypeEnum[keyof typeof SessionBaseResponseDtoTypeEnum];

/**
 *
 * @export
 * @interface SessionCreateResponseDto
 */
export interface SessionCreateResponseDto {
  /**
   *
   * @type {string}
   * @memberof SessionCreateResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SessionCreateResponseDto
   */
  therapistId: string;
  /**
   *
   * @type {string}
   * @memberof SessionCreateResponseDto
   */
  availabilityId: string;
  /**
   *
   * @type {string}
   * @memberof SessionCreateResponseDto
   */
  status: SessionCreateResponseDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SessionCreateResponseDto
   */
  type: SessionCreateResponseDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SessionCreateResponseDto
   */
  cancelerId?: string;
  /**
   *
   * @type {string}
   * @memberof SessionCreateResponseDto
   */
  cancelReason?: string;
}

export const SessionCreateResponseDtoStatusEnum = {
  Awaiting: 'awaiting',
  Live: 'live',
  Complete: 'complete',
  Canceled: 'canceled',
} as const;

export type SessionCreateResponseDtoStatusEnum =
  typeof SessionCreateResponseDtoStatusEnum[keyof typeof SessionCreateResponseDtoStatusEnum];
export const SessionCreateResponseDtoTypeEnum = {
  Personal: 'personal',
  Group: 'group',
} as const;

export type SessionCreateResponseDtoTypeEnum =
  typeof SessionCreateResponseDtoTypeEnum[keyof typeof SessionCreateResponseDtoTypeEnum];

/**
 *
 * @export
 * @interface SessionNoteResponseDto
 */
export interface SessionNoteResponseDto {
  /**
   *
   * @type {string}
   * @memberof SessionNoteResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SessionNoteResponseDto
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof SessionNoteResponseDto
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof SessionNoteResponseDto
   */
  lastname: string;
  /**
   *
   * @type {string}
   * @memberof SessionNoteResponseDto
   */
  sessionId: string;
  /**
   *
   * @type {string}
   * @memberof SessionNoteResponseDto
   */
  comment: string;
  /**
   *
   * @type {string}
   * @memberof SessionNoteResponseDto
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface SessionNotesResponseDto
 */
export interface SessionNotesResponseDto {
  /**
   *
   * @type {Array<SessionNoteResponseDto>}
   * @memberof SessionNotesResponseDto
   */
  notes: Array<SessionNoteResponseDto>;
}
/**
 *
 * @export
 * @interface SessionOutcomeResponseDto
 */
export interface SessionOutcomeResponseDto {
  /**
   *
   * @type {string}
   * @memberof SessionOutcomeResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SessionOutcomeResponseDto
   */
  sessionParticipantId: string;
  /**
   *
   * @type {boolean}
   * @memberof SessionOutcomeResponseDto
   */
  liked: boolean;
  /**
   *
   * @type {string}
   * @memberof SessionOutcomeResponseDto
   */
  comment: string;
}
/**
 *
 * @export
 * @interface SessionPatientResponseDto
 */
export interface SessionPatientResponseDto {
  /**
   *
   * @type {string}
   * @memberof SessionPatientResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SessionPatientResponseDto
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof SessionPatientResponseDto
   */
  avatarKey: string;
}
/**
 *
 * @export
 * @interface SessionPriceSearchStatisticsResponseDto
 */
export interface SessionPriceSearchStatisticsResponseDto {
  /**
   *
   * @type {string}
   * @memberof SessionPriceSearchStatisticsResponseDto
   */
  lesser30k: string;
  /**
   *
   * @type {string}
   * @memberof SessionPriceSearchStatisticsResponseDto
   */
  lesser25k: string;
  /**
   *
   * @type {string}
   * @memberof SessionPriceSearchStatisticsResponseDto
   */
  lesser20k: string;
  /**
   *
   * @type {string}
   * @memberof SessionPriceSearchStatisticsResponseDto
   */
  lesser15k: string;
  /**
   *
   * @type {string}
   * @memberof SessionPriceSearchStatisticsResponseDto
   */
  lesser10k: string;
  /**
   *
   * @type {string}
   * @memberof SessionPriceSearchStatisticsResponseDto
   */
  lesser5k: string;
}
/**
 *
 * @export
 * @interface SessionRequestResponseDto
 */
export interface SessionRequestResponseDto {
  /**
   *
   * @type {string}
   * @memberof SessionRequestResponseDto
   */
  therapistId: string;
  /**
   *
   * @type {string}
   * @memberof SessionRequestResponseDto
   */
  availabilityId: string;
  /**
   *
   * @type {string}
   * @memberof SessionRequestResponseDto
   */
  type: SessionRequestResponseDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SessionRequestResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SessionRequestResponseDto
   */
  status: SessionRequestResponseDtoStatusEnum;
}

export const SessionRequestResponseDtoTypeEnum = {
  Personal: 'personal',
  Group: 'group',
} as const;

export type SessionRequestResponseDtoTypeEnum =
  typeof SessionRequestResponseDtoTypeEnum[keyof typeof SessionRequestResponseDtoTypeEnum];
export const SessionRequestResponseDtoStatusEnum = {
  Pending: 'pending',
  Accepted: 'accepted',
  Rejected: 'rejected',
  Canceled: 'canceled',
  Expired: 'expired',
} as const;

export type SessionRequestResponseDtoStatusEnum =
  typeof SessionRequestResponseDtoStatusEnum[keyof typeof SessionRequestResponseDtoStatusEnum];

/**
 *
 * @export
 * @interface SessionResponseDto
 */
export interface SessionResponseDto {
  /**
   *
   * @type {string}
   * @memberof SessionResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SessionResponseDto
   */
  therapistId: string;
  /**
   *
   * @type {string}
   * @memberof SessionResponseDto
   */
  availabilityId: string;
  /**
   *
   * @type {string}
   * @memberof SessionResponseDto
   */
  status: SessionResponseDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SessionResponseDto
   */
  type: SessionResponseDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SessionResponseDto
   */
  cancelerId?: string;
  /**
   *
   * @type {string}
   * @memberof SessionResponseDto
   */
  cancelReason?: string;
  /**
   *
   * @type {TherapistBaseResponseDto}
   * @memberof SessionResponseDto
   */
  therapist: TherapistBaseResponseDto;
  /**
   *
   * @type {AvailabilityResponseDto}
   * @memberof SessionResponseDto
   */
  availability: AvailabilityResponseDto;
  /**
   *
   * @type {Array<SessionNoteResponseDto>}
   * @memberof SessionResponseDto
   */
  notes: Array<SessionNoteResponseDto>;
  /**
   *
   * @type {Array<PartialPublicPatientProfileResponseDto>}
   * @memberof SessionResponseDto
   */
  patients: Array<PartialPublicPatientProfileResponseDto>;
}

export const SessionResponseDtoStatusEnum = {
  Awaiting: 'awaiting',
  Live: 'live',
  Complete: 'complete',
  Canceled: 'canceled',
} as const;

export type SessionResponseDtoStatusEnum =
  typeof SessionResponseDtoStatusEnum[keyof typeof SessionResponseDtoStatusEnum];
export const SessionResponseDtoTypeEnum = {
  Personal: 'personal',
  Group: 'group',
} as const;

export type SessionResponseDtoTypeEnum =
  typeof SessionResponseDtoTypeEnum[keyof typeof SessionResponseDtoTypeEnum];

/**
 *
 * @export
 * @interface SessionStatisticsResponseDto
 */
export interface SessionStatisticsResponseDto {
  /**
   *
   * @type {number}
   * @memberof SessionStatisticsResponseDto
   */
  completed: number;
  /**
   *
   * @type {number}
   * @memberof SessionStatisticsResponseDto
   */
  canceled: number;
  /**
   *
   * @type {number}
   * @memberof SessionStatisticsResponseDto
   */
  booked: number;
  /**
   *
   * @type {number}
   * @memberof SessionStatisticsResponseDto
   */
  planned: number;
}
/**
 *
 * @export
 * @interface SessionTherapistResponseDto
 */
export interface SessionTherapistResponseDto {
  /**
   *
   * @type {string}
   * @memberof SessionTherapistResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SessionTherapistResponseDto
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof SessionTherapistResponseDto
   */
  lastname: string;
  /**
   *
   * @type {string}
   * @memberof SessionTherapistResponseDto
   */
  avatarKey: string;
}
/**
 *
 * @export
 * @interface SessionWithStartDate
 */
export interface SessionWithStartDate {
  /**
   *
   * @type {string}
   * @memberof SessionWithStartDate
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SessionWithStartDate
   */
  therapistId: string;
  /**
   *
   * @type {string}
   * @memberof SessionWithStartDate
   */
  availabilityId: string;
  /**
   *
   * @type {string}
   * @memberof SessionWithStartDate
   */
  status: SessionWithStartDateStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SessionWithStartDate
   */
  type: SessionWithStartDateTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SessionWithStartDate
   */
  cancelerId?: string;
  /**
   *
   * @type {string}
   * @memberof SessionWithStartDate
   */
  cancelReason?: string;
  /**
   *
   * @type {string}
   * @memberof SessionWithStartDate
   */
  startDate: string;
  /**
   *
   * @type {number}
   * @memberof SessionWithStartDate
   */
  notificationBeforeTime: number;
}

export const SessionWithStartDateStatusEnum = {
  Awaiting: 'awaiting',
  Live: 'live',
  Complete: 'complete',
  Canceled: 'canceled',
} as const;

export type SessionWithStartDateStatusEnum =
  typeof SessionWithStartDateStatusEnum[keyof typeof SessionWithStartDateStatusEnum];
export const SessionWithStartDateTypeEnum = {
  Personal: 'personal',
  Group: 'group',
} as const;

export type SessionWithStartDateTypeEnum =
  typeof SessionWithStartDateTypeEnum[keyof typeof SessionWithStartDateTypeEnum];

/**
 *
 * @export
 * @interface SessionsResponseDto
 */
export interface SessionsResponseDto {
  /**
   *
   * @type {Array<SessionResponseDto>}
   * @memberof SessionsResponseDto
   */
  sessions: Array<SessionResponseDto>;
}
/**
 *
 * @export
 * @interface SessionsWithStartDate
 */
export interface SessionsWithStartDate {
  /**
   *
   * @type {Array<SessionWithStartDate>}
   * @memberof SessionsWithStartDate
   */
  sessions: Array<SessionWithStartDate>;
}
/**
 *
 * @export
 * @interface SetMiniAvatarKeyRequest
 */
export interface SetMiniAvatarKeyRequest {
  /**
   *
   * @type {string}
   * @memberof SetMiniAvatarKeyRequest
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof SetMiniAvatarKeyRequest
   */
  miniAvatarKey: string;
}
/**
 *
 * @export
 * @interface ShiftSessionTimeRequestDto
 */
export interface ShiftSessionTimeRequestDto {
  /**
   *
   * @type {string}
   * @memberof ShiftSessionTimeRequestDto
   */
  availabilityId: string;
}
/**
 *
 * @export
 * @interface ShiftSupervisionRequestDto
 */
export interface ShiftSupervisionRequestDto {
  /**
   *
   * @type {string}
   * @memberof ShiftSupervisionRequestDto
   */
  availabilityId: string;
}
/**
 *
 * @export
 * @interface ShortUserResponseDto
 */
export interface ShortUserResponseDto {
  /**
   *
   * @type {string}
   * @memberof ShortUserResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ShortUserResponseDto
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof ShortUserResponseDto
   */
  lastname?: string;
  /**
   *
   * @type {string}
   * @memberof ShortUserResponseDto
   */
  role: ShortUserResponseDtoRoleEnum;
  /**
   *
   * @type {string}
   * @memberof ShortUserResponseDto
   */
  avatarKey?: string;
}

export const ShortUserResponseDtoRoleEnum = {
  Patient: 'patient',
  Therapist: 'therapist',
  Company: 'company',
} as const;

export type ShortUserResponseDtoRoleEnum =
  typeof ShortUserResponseDtoRoleEnum[keyof typeof ShortUserResponseDtoRoleEnum];

/**
 *
 * @export
 * @interface StatisticsMonth
 */
export interface StatisticsMonth {
  /**
   *
   * @type {string}
   * @memberof StatisticsMonth
   */
  month: string;
  /**
   *
   * @type {number}
   * @memberof StatisticsMonth
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof StatisticsMonth
   */
  expire: number;
}
/**
 *
 * @export
 * @interface SupervisionBaseResponseDto
 */
export interface SupervisionBaseResponseDto {
  /**
   *
   * @type {string}
   * @memberof SupervisionBaseResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionBaseResponseDto
   */
  supervisorId: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionBaseResponseDto
   */
  availabilityId: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionBaseResponseDto
   */
  status: SupervisionBaseResponseDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SupervisionBaseResponseDto
   */
  type: SupervisionBaseResponseDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SupervisionBaseResponseDto
   */
  cancelerId?: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionBaseResponseDto
   */
  cancelReason?: string;
}

export const SupervisionBaseResponseDtoStatusEnum = {
  Awaiting: 'awaiting',
  Live: 'live',
  Complete: 'complete',
  Canceled: 'canceled',
} as const;

export type SupervisionBaseResponseDtoStatusEnum =
  typeof SupervisionBaseResponseDtoStatusEnum[keyof typeof SupervisionBaseResponseDtoStatusEnum];
export const SupervisionBaseResponseDtoTypeEnum = {
  Personal: 'personal',
  Group: 'group',
} as const;

export type SupervisionBaseResponseDtoTypeEnum =
  typeof SupervisionBaseResponseDtoTypeEnum[keyof typeof SupervisionBaseResponseDtoTypeEnum];

/**
 *
 * @export
 * @interface SupervisionCreateResponseDto
 */
export interface SupervisionCreateResponseDto {
  /**
   *
   * @type {string}
   * @memberof SupervisionCreateResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionCreateResponseDto
   */
  supervisorId: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionCreateResponseDto
   */
  availabilityId: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionCreateResponseDto
   */
  status: SupervisionCreateResponseDtoStatusEnum;
  /**
   *
   * @type {string}
   * @memberof SupervisionCreateResponseDto
   */
  type: SupervisionCreateResponseDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SupervisionCreateResponseDto
   */
  cancelerId?: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionCreateResponseDto
   */
  cancelReason?: string;
}

export const SupervisionCreateResponseDtoStatusEnum = {
  Awaiting: 'awaiting',
  Live: 'live',
  Complete: 'complete',
  Canceled: 'canceled',
} as const;

export type SupervisionCreateResponseDtoStatusEnum =
  typeof SupervisionCreateResponseDtoStatusEnum[keyof typeof SupervisionCreateResponseDtoStatusEnum];
export const SupervisionCreateResponseDtoTypeEnum = {
  Personal: 'personal',
  Group: 'group',
} as const;

export type SupervisionCreateResponseDtoTypeEnum =
  typeof SupervisionCreateResponseDtoTypeEnum[keyof typeof SupervisionCreateResponseDtoTypeEnum];

/**
 *
 * @export
 * @interface SupervisionNoteRequestDto
 */
export interface SupervisionNoteRequestDto {
  /**
   *
   * @type {string}
   * @memberof SupervisionNoteRequestDto
   */
  comment: string;
}
/**
 *
 * @export
 * @interface SupervisionNoteResponseDto
 */
export interface SupervisionNoteResponseDto {
  /**
   *
   * @type {string}
   * @memberof SupervisionNoteResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionNoteResponseDto
   */
  userId: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionNoteResponseDto
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionNoteResponseDto
   */
  lastname: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionNoteResponseDto
   */
  supervisionId: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionNoteResponseDto
   */
  comment: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionNoteResponseDto
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface SupervisionNotesResponseDto
 */
export interface SupervisionNotesResponseDto {
  /**
   *
   * @type {Array<SupervisionNoteResponseDto>}
   * @memberof SupervisionNotesResponseDto
   */
  notes: Array<SupervisionNoteResponseDto>;
}
/**
 *
 * @export
 * @interface SupervisionRequestResponseDto
 */
export interface SupervisionRequestResponseDto {
  /**
   *
   * @type {string}
   * @memberof SupervisionRequestResponseDto
   */
  supervisorId: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionRequestResponseDto
   */
  availabilityId: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionRequestResponseDto
   */
  type: SupervisionRequestResponseDtoTypeEnum;
  /**
   *
   * @type {string}
   * @memberof SupervisionRequestResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SupervisionRequestResponseDto
   */
  status: SupervisionRequestResponseDtoStatusEnum;
}

export const SupervisionRequestResponseDtoTypeEnum = {
  Personal: 'personal',
  Group: 'group',
} as const;

export type SupervisionRequestResponseDtoTypeEnum =
  typeof SupervisionRequestResponseDtoTypeEnum[keyof typeof SupervisionRequestResponseDtoTypeEnum];
export const SupervisionRequestResponseDtoStatusEnum = {
  Pending: 'pending',
  Accepted: 'accepted',
  Rejected: 'rejected',
  Canceled: 'canceled',
  Expired: 'expired',
} as const;

export type SupervisionRequestResponseDtoStatusEnum =
  typeof SupervisionRequestResponseDtoStatusEnum[keyof typeof SupervisionRequestResponseDtoStatusEnum];

/**
 *
 * @export
 * @interface SupervisionsBaseResponseDto
 */
export interface SupervisionsBaseResponseDto {
  /**
   *
   * @type {Array<SupervisionBaseResponseDto>}
   * @memberof SupervisionsBaseResponseDto
   */
  supervisions: Array<SupervisionBaseResponseDto>;
}
/**
 *
 * @export
 * @interface SupervisorTherapistResponseDto
 */
export interface SupervisorTherapistResponseDto {
  /**
   *
   * @type {string}
   * @memberof SupervisorTherapistResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SupervisorTherapistResponseDto
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof SupervisorTherapistResponseDto
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof SupervisorTherapistResponseDto
   */
  supervisionCount: string;
  /**
   *
   * @type {string}
   * @memberof SupervisorTherapistResponseDto
   */
  firstSupervisionDate: string;
  /**
   *
   * @type {string}
   * @memberof SupervisorTherapistResponseDto
   */
  lastSupervisionDate: string;
}
/**
 *
 * @export
 * @interface SupervisorTherapistsResponseDto
 */
export interface SupervisorTherapistsResponseDto {
  /**
   *
   * @type {number}
   * @memberof SupervisorTherapistsResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof SupervisorTherapistsResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof SupervisorTherapistsResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof SupervisorTherapistsResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<SupervisorTherapistResponseDto>}
   * @memberof SupervisorTherapistsResponseDto
   */
  data: Array<SupervisorTherapistResponseDto>;
}
/**
 *
 * @export
 * @interface TherapistAdditionalEducationRequestDto
 */
export interface TherapistAdditionalEducationRequestDto {
  /**
   *
   * @type {string}
   * @memberof TherapistAdditionalEducationRequestDto
   */
  course: string;
  /**
   *
   * @type {string}
   * @memberof TherapistAdditionalEducationRequestDto
   */
  organisation: string;
  /**
   *
   * @type {Array<UserFileRequestDto>}
   * @memberof TherapistAdditionalEducationRequestDto
   */
  files?: Array<UserFileRequestDto>;
}
/**
 *
 * @export
 * @interface TherapistAdditionalEducationResponseDto
 */
export interface TherapistAdditionalEducationResponseDto {
  /**
   *
   * @type {string}
   * @memberof TherapistAdditionalEducationResponseDto
   */
  course: string;
  /**
   *
   * @type {string}
   * @memberof TherapistAdditionalEducationResponseDto
   */
  organisation: string;
  /**
   *
   * @type {Array<UserFileResponseDto>}
   * @memberof TherapistAdditionalEducationResponseDto
   */
  files?: Array<UserFileResponseDto>;
}
/**
 *
 * @export
 * @interface TherapistBaseResponseDto
 */
export interface TherapistBaseResponseDto {
  /**
   *
   * @type {string}
   * @memberof TherapistBaseResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TherapistBaseResponseDto
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof TherapistBaseResponseDto
   */
  lastname: string;
  /**
   *
   * @type {string}
   * @memberof TherapistBaseResponseDto
   */
  avatarKey: string;
}
/**
 *
 * @export
 * @interface TherapistEducationInfoRequestDto
 */
export interface TherapistEducationInfoRequestDto {
  /**
   *
   * @type {Array<TherapistMainEducationRequestDto>}
   * @memberof TherapistEducationInfoRequestDto
   */
  education: Array<TherapistMainEducationRequestDto>;
  /**
   *
   * @type {Array<TherapistAdditionalEducationRequestDto>}
   * @memberof TherapistEducationInfoRequestDto
   */
  additionalEducation?: Array<TherapistAdditionalEducationRequestDto>;
}
/**
 *
 * @export
 * @interface TherapistEducationInfoResponseDto
 */
export interface TherapistEducationInfoResponseDto {
  /**
   *
   * @type {Array<TherapistMainEducationResponseDto>}
   * @memberof TherapistEducationInfoResponseDto
   */
  education: Array<TherapistMainEducationResponseDto>;
  /**
   *
   * @type {Array<TherapistAdditionalEducationResponseDto>}
   * @memberof TherapistEducationInfoResponseDto
   */
  additionalEducation?: Array<TherapistAdditionalEducationResponseDto> | null;
}
/**
 *
 * @export
 * @interface TherapistMainEducationRequestDto
 */
export interface TherapistMainEducationRequestDto {
  /**
   *
   * @type {string}
   * @memberof TherapistMainEducationRequestDto
   */
  university: string;
  /**
   *
   * @type {string}
   * @memberof TherapistMainEducationRequestDto
   */
  speciality: string;
  /**
   *
   * @type {Array<UserFileRequestDto>}
   * @memberof TherapistMainEducationRequestDto
   */
  files?: Array<UserFileRequestDto>;
}
/**
 *
 * @export
 * @interface TherapistMainEducationResponseDto
 */
export interface TherapistMainEducationResponseDto {
  /**
   *
   * @type {string}
   * @memberof TherapistMainEducationResponseDto
   */
  university: string;
  /**
   *
   * @type {string}
   * @memberof TherapistMainEducationResponseDto
   */
  speciality: string;
  /**
   *
   * @type {Array<UserFileResponseDto>}
   * @memberof TherapistMainEducationResponseDto
   */
  files?: Array<UserFileResponseDto>;
}
/**
 *
 * @export
 * @interface TherapistPaginationSearchResponseDto
 */
export interface TherapistPaginationSearchResponseDto {
  /**
   *
   * @type {number}
   * @memberof TherapistPaginationSearchResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof TherapistPaginationSearchResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof TherapistPaginationSearchResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof TherapistPaginationSearchResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<TherapistSearchResponseDto>}
   * @memberof TherapistPaginationSearchResponseDto
   */
  data: Array<TherapistSearchResponseDto>;
  /**
   *
   * @type {string}
   * @memberof TherapistPaginationSearchResponseDto
   */
  diseaseId?: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPaginationSearchResponseDto
   */
  diseaseName?: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPaginationSearchResponseDto
   */
  diseaseH1?: string;
}
/**
 *
 * @export
 * @interface TherapistPatientNoteRequestDto
 */
export interface TherapistPatientNoteRequestDto {
  /**
   *
   * @type {string}
   * @memberof TherapistPatientNoteRequestDto
   */
  comment: string;
}
/**
 *
 * @export
 * @interface TherapistPatientNoteResponseDto
 */
export interface TherapistPatientNoteResponseDto {
  /**
   *
   * @type {string}
   * @memberof TherapistPatientNoteResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPatientNoteResponseDto
   */
  comment: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPatientNoteResponseDto
   */
  createdAt: string;
}
/**
 *
 * @export
 * @interface TherapistPatientResponseDto
 */
export interface TherapistPatientResponseDto {
  /**
   *
   * @type {string}
   * @memberof TherapistPatientResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPatientResponseDto
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPatientResponseDto
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPatientResponseDto
   */
  totalSessions: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPatientResponseDto
   */
  firstSessionDate: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPatientResponseDto
   */
  lastSessionDate: string;
}
/**
 *
 * @export
 * @interface TherapistPatientsResponseDto
 */
export interface TherapistPatientsResponseDto {
  /**
   *
   * @type {number}
   * @memberof TherapistPatientsResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof TherapistPatientsResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof TherapistPatientsResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof TherapistPatientsResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<TherapistPatientResponseDto>}
   * @memberof TherapistPatientsResponseDto
   */
  data: Array<TherapistPatientResponseDto>;
}
/**
 *
 * @export
 * @interface TherapistPersonalInfoRequestDto
 */
export interface TherapistPersonalInfoRequestDto {
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoRequestDto
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoRequestDto
   */
  lastname: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoRequestDto
   */
  gender: TherapistPersonalInfoRequestDtoGenderEnum;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoRequestDto
   */
  country: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoRequestDto
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoRequestDto
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoRequestDto
   */
  shortAbout: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoRequestDto
   */
  about: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoRequestDto
   */
  videoFilekey?: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoRequestDto
   */
  avatarFilekey?: string;
}

export const TherapistPersonalInfoRequestDtoGenderEnum = {
  Male: 'male',
  Female: 'female',
  Transgender: 'transgender',
  Unknown: 'unknown',
} as const;

export type TherapistPersonalInfoRequestDtoGenderEnum =
  typeof TherapistPersonalInfoRequestDtoGenderEnum[keyof typeof TherapistPersonalInfoRequestDtoGenderEnum];

/**
 *
 * @export
 * @interface TherapistPersonalInfoResponseDto
 */
export interface TherapistPersonalInfoResponseDto {
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoResponseDto
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoResponseDto
   */
  lastname: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoResponseDto
   */
  gender: TherapistPersonalInfoResponseDtoGenderEnum;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoResponseDto
   */
  country: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoResponseDto
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoResponseDto
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoResponseDto
   */
  about?: string | null;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoResponseDto
   */
  shortAbout?: string | null;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoResponseDto
   */
  videoFilekey?: string | null;
  /**
   *
   * @type {string}
   * @memberof TherapistPersonalInfoResponseDto
   */
  avatarFilekey?: string | null;
}

export const TherapistPersonalInfoResponseDtoGenderEnum = {
  Male: 'male',
  Female: 'female',
  Transgender: 'transgender',
  Unknown: 'unknown',
} as const;

export type TherapistPersonalInfoResponseDtoGenderEnum =
  typeof TherapistPersonalInfoResponseDtoGenderEnum[keyof typeof TherapistPersonalInfoResponseDtoGenderEnum];

/**
 *
 * @export
 * @interface TherapistProfileUpdateResponseDto
 */
export interface TherapistProfileUpdateResponseDto {
  /**
   *
   * @type {boolean}
   * @memberof TherapistProfileUpdateResponseDto
   */
  isApproved: boolean;
  /**
   *
   * @type {TherapistPersonalInfoResponseDto}
   * @memberof TherapistProfileUpdateResponseDto
   */
  personalInfo: TherapistPersonalInfoResponseDto;
  /**
   *
   * @type {TherapistEducationInfoResponseDto}
   * @memberof TherapistProfileUpdateResponseDto
   */
  educationInfo: TherapistEducationInfoResponseDto;
  /**
   *
   * @type {TherapistWorkingInfoResponseDto}
   * @memberof TherapistProfileUpdateResponseDto
   */
  workingInfo: TherapistWorkingInfoResponseDto;
  /**
   *
   * @type {TherapistTherapiesInfoResponseDto}
   * @memberof TherapistProfileUpdateResponseDto
   */
  therapiesInfo: TherapistTherapiesInfoResponseDto;
  /**
   *
   * @type {TherapistSupervisionInfoResponseDto}
   * @memberof TherapistProfileUpdateResponseDto
   */
  supervisionInfo: TherapistSupervisionInfoResponseDto;
  /**
   *
   * @type {string}
   * @memberof TherapistProfileUpdateResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TherapistProfileUpdateResponseDto
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof TherapistProfileUpdateResponseDto
   */
  deletedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof TherapistProfileUpdateResponseDto
   */
  isRejected: boolean;
}
/**
 *
 * @export
 * @interface TherapistProfileUpdatesResponseDto
 */
export interface TherapistProfileUpdatesResponseDto {
  /**
   *
   * @type {Array<TherapistProfileUpdateResponseDto>}
   * @memberof TherapistProfileUpdatesResponseDto
   */
  updates: Array<TherapistProfileUpdateResponseDto>;
}
/**
 *
 * @export
 * @interface TherapistRecommendationInfoRequestDto
 */
export interface TherapistRecommendationInfoRequestDto {
  /**
   *
   * @type {string}
   * @memberof TherapistRecommendationInfoRequestDto
   */
  recommendationText?: string;
  /**
   *
   * @type {Array<UserFileRequestDto>}
   * @memberof TherapistRecommendationInfoRequestDto
   */
  recommendationFiles?: Array<UserFileRequestDto>;
}
/**
 *
 * @export
 * @interface TherapistScheduleResponseDto
 */
export interface TherapistScheduleResponseDto {
  /**
   *
   * @type {Array<SessionResponseDto>}
   * @memberof TherapistScheduleResponseDto
   */
  sessions: Array<SessionResponseDto>;
  /**
   *
   * @type {Array<AvailabilityResponseDto>}
   * @memberof TherapistScheduleResponseDto
   */
  availabilities: Array<AvailabilityResponseDto>;
  /**
   *
   * @type {Array<AvailabilityRecurringResponseDto>}
   * @memberof TherapistScheduleResponseDto
   */
  availabilityRecurrings: Array<AvailabilityRecurringResponseDto>;
}
/**
 *
 * @export
 * @interface TherapistSearchRequestDto
 */
export interface TherapistSearchRequestDto {
  /**
   *
   * @type {number}
   * @memberof TherapistSearchRequestDto
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof TherapistSearchRequestDto
   */
  page: number;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchRequestDto
   */
  order?: TherapistSearchRequestDtoOrderEnum;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchRequestDto
   */
  orderTerms?: TherapistSearchRequestDtoOrderTermsEnum;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchRequestDto
   */
  gender?: TherapistSearchRequestDtoGenderEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof TherapistSearchRequestDto
   */
  therapyMethods?: Array<string> | undefined;
  /**
   *
   * @type {Array<string>}
   * @memberof TherapistSearchRequestDto
   */
  diseases?: Array<string> | string | undefined;
  /**
   *
   * @type {number}
   * @memberof TherapistSearchRequestDto
   */
  dayLimit?: number;
  /**
   *
   * @type {boolean}
   * @memberof TherapistSearchRequestDto
   */
  workWithLgbt?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistSearchRequestDto
   */
  workWithEthnicGroups?: boolean;
  /**
   *
   * @type {number}
   * @memberof TherapistSearchRequestDto
   */
  workingExperience?: number;
  /**
   *
   * @type {boolean}
   * @memberof TherapistSearchRequestDto
   */
  isApproved?: boolean;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchRequestDto
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchRequestDto
   */
  lastname?: string;
  /**
   *
   * @type {number}
   * @memberof TherapistSearchRequestDto
   */
  priceMin?: number;
  /**
   *
   * @type {number}
   * @memberof TherapistSearchRequestDto
   */
  priceMax?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof TherapistSearchRequestDto
   */
  religions?: Array<TherapistSearchRequestDtoReligionsEnum>;
}

export const TherapistSearchRequestDtoOrderEnum = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const;

export type TherapistSearchRequestDtoOrderEnum =
  typeof TherapistSearchRequestDtoOrderEnum[keyof typeof TherapistSearchRequestDtoOrderEnum];
export const TherapistSearchRequestDtoOrderTermsEnum = {
  Price: 'price',
  Experience: 'experience',
  Availability: 'availability',
} as const;

export type TherapistSearchRequestDtoOrderTermsEnum =
  typeof TherapistSearchRequestDtoOrderTermsEnum[keyof typeof TherapistSearchRequestDtoOrderTermsEnum];
export const TherapistSearchRequestDtoGenderEnum = {
  Male: 'male',
  Female: 'female',
  Transgender: 'transgender',
  Unknown: 'unknown',
} as const;

export type TherapistSearchRequestDtoGenderEnum =
  typeof TherapistSearchRequestDtoGenderEnum[keyof typeof TherapistSearchRequestDtoGenderEnum];
export const TherapistSearchRequestDtoReligionsEnum = {
  Christianity: 'christianity',
  Islamism: 'islamism',
  Judaism: 'judaism',
  Buddhism: 'buddhism',
} as const;

export type TherapistSearchRequestDtoReligionsEnum =
  typeof TherapistSearchRequestDtoReligionsEnum[keyof typeof TherapistSearchRequestDtoReligionsEnum];

/**
 *
 * @export
 * @interface TherapistSearchResponseDto
 */
export interface TherapistSearchResponseDto {
  isMedsi: JSX.Element;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchResponseDto
   */
  isTop: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistSearchResponseDto
   */
  isAdult: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistSearchResponseDto
   */
  isChildTherapist: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistSearchResponseDto
   */
  isSupervisor: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistSearchResponseDto
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchResponseDto
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchResponseDto
   */
  lastname?: string;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchResponseDto
   */
  gender: TherapistSearchResponseDtoGenderEnum;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchResponseDto
   */
  avatarKey?: string;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchResponseDto
   */
  videoKey?: string;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchResponseDto
   */
  miniAvatarKey?: string;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchResponseDto
   */
  workingExperienceDescription: string;
  /**
   *
   * @type {number}
   * @memberof TherapistSearchResponseDto
   */
  workingExperience: number;
  /**
   *
   * @type {AvailabilityResponseDto}
   * @memberof TherapistSearchResponseDto
   */
  closestFreeAvailability: AvailabilityResponseDto;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchResponseDto
   */
  recommendationText: string;
  /**
   *
   * @type {string}
   * @memberof TherapistSearchResponseDto
   */
  about: string;
  /**
    /**
     *
     * @type {string}
     * @memberof TherapistSearchResponseDto
     */
  shortAbout: string;
  /**
  /**
    /**
     *
     * @type {string}
     * @memberof TherapistSearchResponseDto
     */
  dateOfBirth: string;
  /**
   *
   * @type {number}
   * @memberof TherapistSearchResponseDto
   */
  totalSessions: number;
  /**
   *
   * @type {Array<string>}
   * @memberof TherapistSearchResponseDto
   */
  specialisation: Array<TherapistSearchResponseDtoSpecialisationEnum>;
  /**
   *
   * @type {Array<TherapyMethodResponseDto>}
   * @memberof TherapistSearchResponseDto
   */
  therapyMethods: Array<TherapyMethodResponseDto>;
  /**
   *
   * @type {number}
   * @memberof TherapistSearchResponseDto
   */
  price: number;
  /**
   *
   * @type {boolean}
   * @memberof TherapistSearchResponseDto
   */
  isApproved: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistSearchResponseDto
   */
  hasMedsiSessions: boolean;
}

export const TherapistSearchResponseDtoGenderEnum = {
  Male: 'male',
  Female: 'female',
  Transgender: 'transgender',
  Unknown: 'unknown',
} as const;

export type TherapistSearchResponseDtoGenderEnum =
  typeof TherapistSearchResponseDtoGenderEnum[keyof typeof TherapistSearchResponseDtoGenderEnum];
export const TherapistSearchResponseDtoSpecialisationEnum = {
  Psychologist: 'psychologist',
  Psychotherapist: 'psychotherapist',
  Psychoanalyst: 'psychoanalyst',
  Coach: 'coach',
  Supervisor: 'supervisor',
  ClinicalPsychologist: 'clinical_psychologist',
} as const;

export type TherapistSearchResponseDtoSpecialisationEnum =
  typeof TherapistSearchResponseDtoSpecialisationEnum[keyof typeof TherapistSearchResponseDtoSpecialisationEnum];

/**
 *
 * @export
 * @interface TherapistSupervisionInfoRequestDto
 */
export interface TherapistSupervisionInfoRequestDto {
  /**
   *
   * @type {boolean}
   * @memberof TherapistSupervisionInfoRequestDto
   */
  isSupervisor: boolean;
  /**
   *
   * @type {number}
   * @memberof TherapistSupervisionInfoRequestDto
   */
  supervisionExperience?: number;
  /**
   *
   * @type {RequiredSupervisionInfoRequestDto}
   * @memberof TherapistSupervisionInfoRequestDto
   */
  therapistSupervisionInfo?: RequiredSupervisionInfoRequestDto;
}
/**
 *
 * @export
 * @interface TherapistSupervisionInfoResponseDto
 */
export interface TherapistSupervisionInfoResponseDto {
  /**
   *
   * @type {boolean}
   * @memberof TherapistSupervisionInfoResponseDto
   */
  isSupervisor: boolean;
  /**
   *
   * @type {number}
   * @memberof TherapistSupervisionInfoResponseDto
   */
  supervisionExperience: number;
  /**
   *
   * @type {RequiredSupervisionInfoResponseDto}
   * @memberof TherapistSupervisionInfoResponseDto
   */
  therapistSupervisionInfo: RequiredSupervisionInfoResponseDto;
}
/**
 *
 * @export
 * @interface TherapistSupervisorResponseDto
 */
export interface TherapistSupervisorResponseDto {
  /**
   *
   * @type {string}
   * @memberof TherapistSupervisorResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TherapistSupervisorResponseDto
   */
  avatarKey: string;
  /**
   *
   * @type {string}
   * @memberof TherapistSupervisorResponseDto
   */
  firstname: string;
  /**
   *
   * @type {string}
   * @memberof TherapistSupervisorResponseDto
   */
  lastname: string;
  /**
   *
   * @type {number}
   * @memberof TherapistSupervisorResponseDto
   */
  supervisionCount: number;
  /**
   *
   * @type {string}
   * @memberof TherapistSupervisorResponseDto
   */
  firstSupervisionDate: string;
  /**
   *
   * @type {string}
   * @memberof TherapistSupervisorResponseDto
   */
  lastSupervisionDate: string;
}
/**
 *
 * @export
 * @interface TherapistSupervisorsResponseDto
 */
export interface TherapistSupervisorsResponseDto {
  /**
   *
   * @type {number}
   * @memberof TherapistSupervisorsResponseDto
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof TherapistSupervisorsResponseDto
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof TherapistSupervisorsResponseDto
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof TherapistSupervisorsResponseDto
   */
  totalPage: number;
  /**
   *
   * @type {Array<TherapistSupervisorResponseDto>}
   * @memberof TherapistSupervisorsResponseDto
   */
  data: Array<TherapistSupervisorResponseDto>;
}
/**
 *
 * @export
 * @interface TherapistTherapiesInfoRequestDto
 */
export interface TherapistTherapiesInfoRequestDto {
  /**
   *
   * @type {Array<string>}
   * @memberof TherapistTherapiesInfoRequestDto
   */
  specialisation: Array<TherapistTherapiesInfoRequestDtoSpecialisationEnum>;
  /**
   * UUID of therapy methods
   * @type {Array<string>}
   * @memberof TherapistTherapiesInfoRequestDto
   */
  therapyMethodIds: Array<string>;
  /**
   * UUID of diseases
   * @type {Array<string>}
   * @memberof TherapistTherapiesInfoRequestDto
   */
  diseaseIds: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof TherapistTherapiesInfoRequestDto
   */
  excludedDiseases: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithCouples: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithFamilies: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithChildren: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithIndividual: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithClinical: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithCheckup: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithPsychiatristExperience: boolean;
  /**
   *
   * @type {string}
   * @memberof TherapistWorkingInfoResponseDto
   */
  psychiatristCollaboration: string;
}

export const TherapistTherapiesInfoRequestDtoSpecialisationEnum = {
  Psychologist: 'psychologist',
  Psychotherapist: 'psychotherapist',
  Psychoanalyst: 'psychoanalyst',
  Coach: 'coach',
  Supervisor: 'supervisor',
  ClinicalPsychologist: 'clinical_psychologist',
} as const;

export type TherapistTherapiesInfoRequestDtoSpecialisationEnum =
  typeof TherapistTherapiesInfoRequestDtoSpecialisationEnum[keyof typeof TherapistTherapiesInfoRequestDtoSpecialisationEnum];

/**
 *
 * @export
 * @interface TherapistTherapiesInfoResponseDto
 */
export interface TherapistTherapiesInfoResponseDto {
  /**
   *
   * @type {Array<string>}
   * @memberof TherapistTherapiesInfoResponseDto
   */
  specialisation: Array<TherapistTherapiesInfoResponseDtoSpecialisationEnum>;
  /**
   *
   * @type {Array<TherapyMethodResponseDto>}
   * @memberof TherapistTherapiesInfoResponseDto
   */
  therapyMethods: Array<TherapyMethodResponseDto>;
  /**
   *
   * @type {Array<DiseaseResponseDto>}
   * @memberof TherapistTherapiesInfoResponseDto
   */
  diseases: Array<DiseaseResponseDto>;
  /**
   *
   * @type {Array<string>}
   * @memberof TherapistTherapiesInfoResponseDto
   */
  excludedDiseases: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithCouples: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithFamilies: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithChildren: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithIndividual: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithClinical: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithCheckup: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithPsychiatristExperience: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  psychiatristCollaboration: boolean;
}

export const TherapistTherapiesInfoResponseDtoSpecialisationEnum = {
  Psychologist: 'psychologist',
  Psychotherapist: 'psychotherapist',
  Psychoanalyst: 'psychoanalyst',
  Coach: 'coach',
  Supervisor: 'supervisor',
  ClinicalPsychologist: 'clinical_psychologist',
} as const;

export type TherapistTherapiesInfoResponseDtoSpecialisationEnum =
  typeof TherapistTherapiesInfoResponseDtoSpecialisationEnum[keyof typeof TherapistTherapiesInfoResponseDtoSpecialisationEnum];

/**
 *
 * @export
 * @interface TherapistWorkingInfoRequestDto
 */
export interface TherapistWorkingInfoRequestDto {
  /**
   *
   * @type {number}
   * @memberof TherapistWorkingInfoRequestDto
   */
  workingExperience: number;
  /**
   *
   * @type {string}
   * @memberof TherapistWorkingInfoRequestDto
   */
  workingExperienceDescription?: string;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoRequestDto
   */
  workWithLgbt: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof TherapistWorkingInfoRequestDto
   */
  workWithReligions?: TherapistWorkingInfoRequestDtoWorkWithReligionsEnum;
  /**
   *
   * @type {string}
   * @memberof TherapistWorkingInfoRequestDto
   */
  ethnicExperienceDescription?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TherapistWorkingInfoRequestDto
   */
  languages: TherapistWorkingInfoRequestDtoLanguagesEnum;
  /**
   *
   * @type {number}
   * @memberof TherapistWorkingInfoRequestDto
   */
  numberOfPatients: number;
  /**
   *
   * @type {number}
   * @memberof TherapistWorkingInfoRequestDto
   */
  devoteHoursToPlatform: number;
  /**
   *
   * @type {string}
   * @memberof TherapistWorkingInfoRequestDto
   */
  workApproach1: string;
  /**
   *
   * @type {string}
   * @memberof TherapistWorkingInfoRequestDto
   */
  workApproach2: string;
  /**
   *
   * @type {string}
   * @memberof TherapistWorkingInfoRequestDto
   */
  workApproach3: string;
  /**
   * Флаг проведения очных сессий
   * @type {boolean}
   * @memberof TherapistWorkingInfoRequestDto
   */
  hasOfflineSessions?: boolean;
  /**
   * Общее количество сессий проведенных терапевтом за всю практику
   * @type {number}
   * @memberof TherapistWorkingInfoRequestDto
   */
  totalSessions?: number;
  /**
   *
   * @type {number}
   * @memberof TherapistWorkingInfoRequestDto
   */
  price: number;
}

export const TherapistWorkingInfoRequestDtoWorkWithReligionsEnum = {
  Christianity: 'christianity',
  Islamism: 'islamism',
  Judaism: 'judaism',
  Buddhism: 'buddhism',
} as const;

export type TherapistWorkingInfoRequestDtoWorkWithReligionsEnum =
  typeof TherapistWorkingInfoRequestDtoWorkWithReligionsEnum[keyof typeof TherapistWorkingInfoRequestDtoWorkWithReligionsEnum];
export const TherapistWorkingInfoRequestDtoLanguagesEnum = {
  English: 'english',
  Russian: 'russian',
  Kazakh: 'kazakh',
  German: 'german',
  Tatar: 'tatar',
  Armenian: 'armenian',
  French: 'french',
  Spanish: 'spanish',
  Italian: 'italian',
  Chinese: 'chinese',
  Arab: 'arab',
  Azerbaijani: 'azerbaijani',
  Georgian: 'georgian',
  Uzbek: 'uzbek',
} as const;

export type TherapistWorkingInfoRequestDtoLanguagesEnum =
  typeof TherapistWorkingInfoRequestDtoLanguagesEnum[keyof typeof TherapistWorkingInfoRequestDtoLanguagesEnum];

/**
 *
 * @export
 * @interface TherapistWorkingInfoResponseDto
 */
export interface TherapistWorkingInfoResponseDto {
  /**
   *
   * @type {number}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workingExperience: number;
  /**
   *
   * @type {string}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workingExperienceDescription: string;
  /**
   *
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithLgbt: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof TherapistWorkingInfoResponseDto
   */
  workWithReligions?: Array<TherapistWorkingInfoResponseDtoWorkWithReligionsEnum>;
  /**
   *
   * @type {string}
   * @memberof TherapistWorkingInfoResponseDto
   */
  ethnicExperienceDescription?: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof TherapistWorkingInfoResponseDto
   */
  languages: Array<TherapistWorkingInfoResponseDtoLanguagesEnum>;
  /**
   *
   * @type {number}
   * @memberof TherapistWorkingInfoResponseDto
   */
  numberOfPatients: number;
  /**
   *
   * @type {number}
   * @memberof TherapistWorkingInfoResponseDto
   */
  devoteHoursToPlatform: number;
  /**
   *
   * @type {string}
   * @memberof TherapistWorkingInfoRequestDto
   */
  workApproach1: string;
  /**
   *
   * @type {string}
   * @memberof TherapistWorkingInfoRequestDto
   */
  workApproach2: string;
  /**
   *
   * @type {string}
   * @memberof TherapistWorkingInfoRequestDto
   */
  workApproach3: string;
  /**
   *
   * @type {number}
   * @memberof TherapistWorkingInfoResponseDto
   */
  price: number;
  /**
   * Флаг проведения очных сессий
   * @type {boolean}
   * @memberof TherapistWorkingInfoResponseDto
   */
  hasOfflineSessions: boolean;
  /**
   * Общее количество сессий проведенных терапевтом за всю практику
   * @type {number}
   * @memberof TherapistWorkingInfoResponseDto
   */
  totalSessions?: number;
}

export const TherapistWorkingInfoResponseDtoWorkWithReligionsEnum = {
  Christianity: 'christianity',
  Islamism: 'islamism',
  Judaism: 'judaism',
  Buddhism: 'buddhism',
} as const;

export type TherapistWorkingInfoResponseDtoWorkWithReligionsEnum =
  typeof TherapistWorkingInfoResponseDtoWorkWithReligionsEnum[keyof typeof TherapistWorkingInfoResponseDtoWorkWithReligionsEnum];
export const TherapistWorkingInfoResponseDtoLanguagesEnum = {
  English: 'english',
  Russian: 'russian',
  Kazakh: 'kazakh',
  German: 'german',
  Tatar: 'tatar',
  Armenian: 'armenian',
  French: 'french',
  Spanish: 'spanish',
  Italian: 'italian',
  Chinese: 'chinese',
  Arab: 'arab',
  Azerbaijani: 'azerbaijani',
  Georgian: 'georgian',
  Uzbek: 'uzbek',
} as const;

export type TherapistWorkingInfoResponseDtoLanguagesEnum =
  typeof TherapistWorkingInfoResponseDtoLanguagesEnum[keyof typeof TherapistWorkingInfoResponseDtoLanguagesEnum];

/**
 *
 * @export
 * @interface TherapyMethodResponseDto
 */
export interface TherapyMethodResponseDto {
  /**
   *
   * @type {string}
   * @memberof TherapyMethodResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TherapyMethodResponseDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TherapyMethodResponseDto
   */
  diseaseName?: string | undefined;
}
/**
 *
 * @export
 * @interface TherapyMethodsResponseDto
 */
export interface TherapyMethodsResponseDto {
  /**
   *
   * @type {Array<TherapyMethodResponseDto>}
   * @memberof TherapyMethodsResponseDto
   */
  data: Array<TherapyMethodResponseDto>;
}
/**
 *
 * @export
 * @interface TherapyMethodsSearchStatisticsResponseDto
 */
export interface TherapyMethodsSearchStatisticsResponseDto {
  /**
   *
   * @type {string}
   * @memberof TherapyMethodsSearchStatisticsResponseDto
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TherapyMethodsSearchStatisticsResponseDto
   */
  percentage: string;
}
/**
 *
 * @export
 * @interface TherapistCollaborationInfoRequestDto
 */
export interface TherapistCollaborationInfoRequestDto {
  /**
   *
   * @type {boolean}
   * @memberof TherapistCollaborationInfoRequestDto
   */
  workshopExperience: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistCollaborationInfoRequestDto
   */
  workshopDesire: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistCollaborationInfoRequestDto
   */
  blogMaterials: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistCollaborationInfoRequestDto
   */
  specialProjects: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistCollaborationInfoRequestDto
   */
  promotionSupport: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TherapistCollaborationInfoRequestDto
   */
  socialsLinks: string[];
   /**
   *
   * @type {boolean}
   * @memberof TherapistCollaborationInfoRequestDto
   */
   materialsLinks: string[];
}
/**
 *
 * @export
 * @interface TherapistCollaborationInfoResponseDto
 */
export interface TherapistCollaborationInfoResponseDto {
 /**
   *
   * @type {boolean}
   * @memberof TherapistCollaborationInfoRequestDto
   */
 workshopExperience: boolean;
 /**
  *
  * @type {boolean}
  * @memberof TherapistCollaborationInfoRequestDto
  */
 workshopDesire: boolean;
 /**
  *
  * @type {boolean}
  * @memberof TherapistCollaborationInfoRequestDto
  */
 blogMaterials: boolean;
 /**
  *
  * @type {boolean}
  * @memberof TherapistCollaborationInfoRequestDto
  */
 specialProjects: boolean;
 /**
  *
  * @type {boolean}
  * @memberof TherapistCollaborationInfoRequestDto
  */
 promotionSupport: boolean;
 /**
   *
   * @type {boolean}
   * @memberof TherapistCollaborationInfoRequestDto
   */
 socialsLinks: string[];
 /**
 *
 * @type {boolean}
 * @memberof TherapistCollaborationInfoRequestDto
 */
 materialsLinks: string[];
}
/**
 *
 * @export
 * @interface UpcomingSessionPaymentInfoResponseDto
 */
export interface UpcomingSessionPaymentInfoResponseDto {
  /**
   *
   * @type {string}
   * @memberof UpcomingSessionPaymentInfoResponseDto
   */
  startDate: string;
  /**
   *
   * @type {string}
   * @memberof UpcomingSessionPaymentInfoResponseDto
   */
  therapistId: string;
  /**
   *
   * @type {string}
   * @memberof UpcomingSessionPaymentInfoResponseDto
   */
  therapistFirstname: string;
  /**
   *
   * @type {string}
   * @memberof UpcomingSessionPaymentInfoResponseDto
   */
  therapistLastname: string;
  /**
   *
   * @type {string}
   * @memberof UpcomingSessionPaymentInfoResponseDto
   */
  therapistAvatarKey: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UpcomingSessionPaymentInfoResponseDto
   */
  therapistSpecialization: Array<UpcomingSessionPaymentInfoResponseDtoTherapistSpecializationEnum>;
  /**
   *
   * @type {number}
   * @memberof UpcomingSessionPaymentInfoResponseDto
   */
  therapistWorkingExperience: number;
  /**
   *
   * @type {PaymentMethodResponseDto}
   * @memberof UpcomingSessionPaymentInfoResponseDto
   */
  paymentMethod?: PaymentMethodResponseDto;
  /**
   *
   * @type {number}
   * @memberof UpcomingSessionPaymentInfoResponseDto
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof UpcomingSessionPaymentInfoResponseDto
   */
  usdPrice?: number;
}

export const UpcomingSessionPaymentInfoResponseDtoTherapistSpecializationEnum =
  {
    Psychologist: 'psychologist',
    Psychotherapist: 'psychotherapist',
    Psychoanalyst: 'psychoanalyst',
    Coach: 'coach',
    Supervisor: 'supervisor',
    ClinicalPsychologist: 'clinical_psychologist',
  } as const;

export type UpcomingSessionPaymentInfoResponseDtoTherapistSpecializationEnum =
  typeof UpcomingSessionPaymentInfoResponseDtoTherapistSpecializationEnum[keyof typeof UpcomingSessionPaymentInfoResponseDtoTherapistSpecializationEnum];

/**
 *
 * @export
 * @interface UpdateAvailabilityRequestDto
 */
export interface UpdateAvailabilityRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdateAvailabilityRequestDto
   */
  startPeriodDate?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateAvailabilityRequestDto
   */
  startTime?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateAvailabilityRequestDto
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateAvailabilityRequestDto
   */
  type?: UpdateAvailabilityRequestDtoTypeEnum;
  /**
   *
   * @type {number}
   * @memberof UpdateAvailabilityRequestDto
   */
  offsetHours?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateAvailabilityRequestDto
   */
  changeMode?: UpdateAvailabilityRequestDtoChangeModeEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateAvailabilityRequestDto
   */
  changeDate?: string;
}

export const UpdateAvailabilityRequestDtoTypeEnum = {
  Day: 'every_day',
  Weekday: 'every_weekday',
  Monday: 'every_monday',
  Tuesday: 'every_tuesday',
  Wednesday: 'every_wednesday',
  Thursday: 'every_thursday',
  Friday: 'every_friday',
  Saturday: 'every_saturday',
  Sunday: 'every_sunday',
} as const;

export type UpdateAvailabilityRequestDtoTypeEnum =
  typeof UpdateAvailabilityRequestDtoTypeEnum[keyof typeof UpdateAvailabilityRequestDtoTypeEnum];
export const UpdateAvailabilityRequestDtoChangeModeEnum = {
  All: 'all',
  OnlyThis: 'only_this',
  ThisAndNext: 'this_and_next',
} as const;

export type UpdateAvailabilityRequestDtoChangeModeEnum =
  typeof UpdateAvailabilityRequestDtoChangeModeEnum[keyof typeof UpdateAvailabilityRequestDtoChangeModeEnum];

/**
 *
 * @export
 * @interface UpdateAvatarRequestDto
 */
export interface UpdateAvatarRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdateAvatarRequestDto
   */
  avatarKey: string;
}
/**
 *
 * @export
 * @interface UpdateCompanyAdminRequestDto
 */
export interface UpdateCompanyAdminRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyAdminRequestDto
   */
  adminId: string;
}
/**
 *
 * @export
 * @interface UpdateCompanyRequestDto
 */
export interface UpdateCompanyRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyRequestDto
   */
  avatarKey?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCompanyRequestDto
   */
  name?: string;
}
/**
 *
 * @export
 * @interface UpdateDiseaseRequestDto
 */
export interface UpdateDiseaseRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdateDiseaseRequestDto
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDiseaseRequestDto
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDiseaseRequestDto
   */
  diseasesGroupId?: string;
}
/**
 *
 * @export
 * @interface UpdateEmailWithoutConfirmationRequestDto
 */
export interface UpdateEmailWithoutConfirmationRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdateEmailWithoutConfirmationRequestDto
   */
  email: string;
}
/**
 *
 * @export
 * @interface UpdateFaqGroupRequestDto
 */
export interface UpdateFaqGroupRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdateFaqGroupRequestDto
   */
  title?: string;
}
/**
 *
 * @export
 * @interface UpdateFaqQuestionRequestDto
 */
export interface UpdateFaqQuestionRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdateFaqQuestionRequestDto
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateFaqQuestionRequestDto
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateFaqQuestionRequestDto
   */
  groupId?: string;
}
/**
 *
 * @export
 * @interface UpdateNotificationTimeRequestDto
 */
export interface UpdateNotificationTimeRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdateNotificationTimeRequestDto
   */
  timeInHours: UpdateNotificationTimeRequestDtoTimeInHoursEnum;
}

export const UpdateNotificationTimeRequestDtoTimeInHoursEnum = {
  _0: '0',
  _1: '1',
  _8: '8',
  _16: '16',
  _24: '24',
  _48: '48',
} as const;

export type UpdateNotificationTimeRequestDtoTimeInHoursEnum =
  typeof UpdateNotificationTimeRequestDtoTimeInHoursEnum[keyof typeof UpdateNotificationTimeRequestDtoTimeInHoursEnum];

/**
 *
 * @export
 * @interface UpdatePasswordRequestDto
 */
export interface UpdatePasswordRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdatePasswordRequestDto
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePasswordRequestDto
   */
  oldPassword: string;
}
/**
 *
 * @export
 * @interface UpdatePatientProfileRequestDto
 */
export interface UpdatePatientProfileRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdatePatientProfileRequestDto
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePatientProfileRequestDto
   */
  lastname?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePatientProfileRequestDto
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePatientProfileRequestDto
   */
  avatarKey?: string;
}
/**
 *
 * @export
 * @interface UpdatePhoneRequestDto
 */
export interface UpdatePhoneRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdatePhoneRequestDto
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePhoneRequestDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface UpdatePromocodeRequestDto
 */
export interface UpdatePromocodeRequestDto {
  /**
   *
   * @type {number}
   * @memberof UpdatePromocodeRequestDto
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof UpdatePromocodeRequestDto
   */
  percentage?: number;
  /**
   *
   * @type {string}
   * @memberof UpdatePromocodeRequestDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof UpdatePromocodeRequestDto
   */
  expireDate?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdatePromocodeRequestDto
   */
  firstUseOnly?: boolean;
}
/**
 *
 * @export
 * @interface UpdateSessionNoteRequestDto
 */
export interface UpdateSessionNoteRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdateSessionNoteRequestDto
   */
  comment: string;
}
/**
 *
 * @export
 * @interface UpdateTherapyMethodRequestDto
 */
export interface UpdateTherapyMethodRequestDto {
  /**
   *
   * @type {string}
   * @memberof UpdateTherapyMethodRequestDto
   */
  name: string;
}
/**
 *
 * @export
 * @interface UserEmailDto
 */
export interface UserEmailDto {
  /**
   *
   * @type {string}
   * @memberof UserEmailDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserEmailDto
   */
  redirectUrl?: string;
}
/**
 *
 * @export
 * @interface UserEmailSignUpRequestDto
 */
export interface UserEmailSignUpRequestDto {
  /**
   *
   * @type {string}
   * @memberof UserEmailSignUpRequestDto
   */
  redirectUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UserEmailSignUpRequestDto
   */
  role: UserEmailSignUpRequestDtoRoleEnum;
  /**
   *
   * @type {string}
   * @memberof UserEmailSignUpRequestDto
   */
  utmSource?: string;
  /**
   *
   * @type {string}
   * @memberof UserEmailSignUpRequestDto
   */
  utmMedium?: string;
  /**
   *
   * @type {string}
   * @memberof UserEmailSignUpRequestDto
   */
  utmCampaign?: string;
  /**
   *
   * @type {string}
   * @memberof UserEmailSignUpRequestDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserEmailSignUpRequestDto
   */
  password: string;
}

export const UserEmailSignUpRequestDtoRoleEnum = {
  Patient: 'patient',
  Therapist: 'therapist',
  Company: 'company',
} as const;

export type UserEmailSignUpRequestDtoRoleEnum =
  typeof UserEmailSignUpRequestDtoRoleEnum[keyof typeof UserEmailSignUpRequestDtoRoleEnum];

/**
 *
 * @export
 * @interface UserFileRequestDto
 */
export interface UserFileRequestDto {
  /**
   *
   * @type {string}
   * @memberof UserFileRequestDto
   */
  filename: string;
  /**
   *
   * @type {string}
   * @memberof UserFileRequestDto
   */
  key: string;
}
/**
 *
 * @export
 * @interface UserFileResponseDto
 */
export interface UserFileResponseDto {
  /**
   *
   * @type {string}
   * @memberof UserFileResponseDto
   */
  filename: string;
  /**
   *
   * @type {string}
   * @memberof UserFileResponseDto
   */
  key: string;
}
/**
 *
 * @export
 * @interface UserMetadataResponseDto
 */
export interface UserMetadataResponseDto {
  /**
   *
   * @type {string}
   * @memberof UserMetadataResponseDto
   */
  therapistStatus?: UserMetadataResponseDtoTherapistStatusEnum;
  /**
   *
   * @type {string}
   * @memberof UserMetadataResponseDto
   */
  patientStatus?: UserMetadataResponseDtoPatientStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof UserMetadataResponseDto
   */
  allowedRecommendedSearch?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserMetadataResponseDto
   */
  integration?: UserMetadataResponseDtoIntegrationEnum;
  /**
   *
   * @type {string}
   * @memberof UserMetadataResponseDto
   */
  hasPassword?: UserMetadataResponseDtoHasPasswordEnum;
  /**
   *
   * @type {boolean}
   * @memberof UserMetadataResponseDto
   */
  isVideoUploaded?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserMetadataResponseDto
   */
  hasSessions?: boolean;
}

export const UserMetadataResponseDtoTherapistStatusEnum = {
  NotExists: 'not-exists',
  NotVerified: 'not-verified',
  Verified: 'verified',
} as const;

export type UserMetadataResponseDtoTherapistStatusEnum =
  typeof UserMetadataResponseDtoTherapistStatusEnum[keyof typeof UserMetadataResponseDtoTherapistStatusEnum];
export const UserMetadataResponseDtoPatientStatusEnum = {
  NotVerified: 'not-verified',
  Verified: 'verified',
} as const;

export type UserMetadataResponseDtoPatientStatusEnum =
  typeof UserMetadataResponseDtoPatientStatusEnum[keyof typeof UserMetadataResponseDtoPatientStatusEnum];
export const UserMetadataResponseDtoIntegrationEnum = {
  Medsi: 'medsi',
} as const;

export type UserMetadataResponseDtoIntegrationEnum =
  typeof UserMetadataResponseDtoIntegrationEnum[keyof typeof UserMetadataResponseDtoIntegrationEnum];
export const UserMetadataResponseDtoHasPasswordEnum = {
  Medsi: 'medsi',
} as const;

export type UserMetadataResponseDtoHasPasswordEnum =
  typeof UserMetadataResponseDtoHasPasswordEnum[keyof typeof UserMetadataResponseDtoHasPasswordEnum];

/**
 *
 * @export
 * @interface UserPhoneSignInRequestDto
 */
export interface UserPhoneSignInRequestDto {
  /**
   *
   * @type {string}
   * @memberof UserPhoneSignInRequestDto
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof UserPhoneSignInRequestDto
   */
  code: string;
}
/**
 *
 * @export
 * @interface UserPhoneSignUpRequestDto
 */
export interface UserPhoneSignUpRequestDto {
  /**
   *
   * @type {string}
   * @memberof UserPhoneSignUpRequestDto
   */
  redirectUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UserPhoneSignUpRequestDto
   */
  role: UserPhoneSignUpRequestDtoRoleEnum;
  /**
   *
   * @type {string}
   * @memberof UserPhoneSignUpRequestDto
   */
  utmSource?: string;
  /**
   *
   * @type {string}
   * @memberof UserPhoneSignUpRequestDto
   */
  utmMedium?: string;
  /**
   *
   * @type {string}
   * @memberof UserPhoneSignUpRequestDto
   */
  utmCampaign?: string;
  /**
   *
   * @type {string}
   * @memberof UserPhoneSignUpRequestDto
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof UserPhoneSignUpRequestDto
   */
  code: string;
}

export const UserPhoneSignUpRequestDtoRoleEnum = {
  Patient: 'patient',
  Therapist: 'therapist',
  Company: 'company',
} as const;

export type UserPhoneSignUpRequestDtoRoleEnum =
  typeof UserPhoneSignUpRequestDtoRoleEnum[keyof typeof UserPhoneSignUpRequestDtoRoleEnum];

/**
 *
 * @export
 * @interface UserResponseDto
 */
export interface UserResponseDto {
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  firstname?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  lastname?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  role: UserResponseDtoRoleEnum;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  gender: UserResponseDtoGenderEnum;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  avatarKey?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  miniAvatarKey?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserResponseDto
   */
  isEmailConfirmed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserResponseDto
   */
  isPhoneConfirmed: boolean;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  notificationBeforeTime?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  paymentMethodId?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  utmSource?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  utmMedium?: string;
  /**
   *
   * @type {string}
   * @memberof UserResponseDto
   */
  utmCampaign?: string;
}

export const UserResponseDtoRoleEnum = {
  Patient: 'patient',
  Therapist: 'therapist',
  Company: 'company',
} as const;

export type UserResponseDtoRoleEnum =
  typeof UserResponseDtoRoleEnum[keyof typeof UserResponseDtoRoleEnum];
export const UserResponseDtoGenderEnum = {
  Male: 'male',
  Female: 'female',
  Transgender: 'transgender',
  Unknown: 'unknown',
} as const;

export type UserResponseDtoGenderEnum =
  typeof UserResponseDtoGenderEnum[keyof typeof UserResponseDtoGenderEnum];

/**
 *
 * @export
 * @interface UserSignInDto
 */
export interface UserSignInDto {
  /**
   *
   * @type {string}
   * @memberof UserSignInDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserSignInDto
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof UserSignInDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface UserSignUpDto
 */
export interface UserSignUpDto {
  /**
   *
   * @type {string}
   * @memberof UserSignUpDto
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof UserSignUpDto
   */
  phone?: string;
  /**
   *
   * @type {string}
   * @memberof UserSignUpDto
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserSignUpDto
   */
  redirectUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UserSignUpDto
   */
  role: UserSignUpDtoRoleEnum;
  /**
   *
   * @type {string}
   * @memberof UserSignUpDto
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof UserSignUpDto
   */
  utmSource?: string;
  /**
   *
   * @type {string}
   * @memberof UserSignUpDto
   */
  utmMedium?: string;
  /**
   *
   * @type {string}
   * @memberof UserSignUpDto
   */
  utmCampaign?: string;
}

export const UserSignUpDtoRoleEnum = {
  Patient: 'patient',
  Therapist: 'therapist',
  Company: 'company',
} as const;

export type UserSignUpDtoRoleEnum =
  typeof UserSignUpDtoRoleEnum[keyof typeof UserSignUpDtoRoleEnum];

/**
 *
 * @export
 * @interface UserWithMetadataResponseDto
 */
export interface UserWithMetadataResponseDto {
  /**
   *
   * @type {UserResponseDto}
   * @memberof UserWithMetadataResponseDto
   */
  user: UserResponseDto;
  /**
   *
   * @type {UserMetadataResponseDto}
   * @memberof UserWithMetadataResponseDto
   */
  metadata: UserMetadataResponseDto;
}
/**
 *
 * @export
 * @interface UserWithTokenAndMetadataResponseDto
 */
export interface UserWithTokenAndMetadataResponseDto {
  /**
   *
   * @type {UserResponseDto}
   * @memberof UserWithTokenAndMetadataResponseDto
   */
  user: UserResponseDto;
  /**
   *
   * @type {string}
   * @memberof UserWithTokenAndMetadataResponseDto
   */
  accessToken: string;
  /**
   *
   * @type {UserMetadataResponseDto}
   * @memberof UserWithTokenAndMetadataResponseDto
   */
  metadata: UserMetadataResponseDto;
}
/**
 *
 * @export
 * @interface UserWithTokenResponseDto
 */
export interface UserWithTokenResponseDto {
  /**
   *
   * @type {UserResponseDto}
   * @memberof UserWithTokenResponseDto
   */
  user: UserResponseDto;
  /**
   *
   * @type {string}
   * @memberof UserWithTokenResponseDto
   */
  accessToken: string;
}
/**
 *
 * @export
 * @interface ValidateMedsiTokenResponse
 */
export interface ValidateMedsiTokenResponse {
  /**
   *
   * @type {string}
   * @memberof ValidateMedsiTokenResponse
   */
  action: ValidateMedsiTokenResponseActionEnum;
  /**
   *
   * @type {boolean}
   * @memberof ValidateMedsiTokenResponse
   */
  isPhoneTaken: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ValidateMedsiTokenResponse
   */
  isIntegrationExists: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ValidateMedsiTokenResponse
   */
  isRequiredRecover: boolean;
}

export const ValidateMedsiTokenResponseActionEnum = {
  SignUp: 'sign_up',
  SignIn: 'sign_in',
  Recover: 'recover',
  Skip: 'skip',
} as const;

export type ValidateMedsiTokenResponseActionEnum =
  typeof ValidateMedsiTokenResponseActionEnum[keyof typeof ValidateMedsiTokenResponseActionEnum];

/**
 * AdminB2BApi - axios parameter creator
 * @export
 */
export const AdminB2BApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add promocodes to company
     * @param {string} companyId
     * @param {AddPromocodesToCompany} addPromocodesToCompany
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerAddPromocodesToCompany: async (
      companyId: string,
      addPromocodesToCompany: AddPromocodesToCompany,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'adminB2BControllerAddPromocodesToCompany',
        'companyId',
        companyId,
      );
      // verify required parameter 'addPromocodesToCompany' is not null or undefined
      assertParamExists(
        'adminB2BControllerAddPromocodesToCompany',
        'addPromocodesToCompany',
        addPromocodesToCompany,
      );
      const localVarPath =
        `/api/v1/admin/b2b/companies/{companyId}/promocodes`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addPromocodesToCompany,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Archive company (superadmin and attached admin only)
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerArchiveCompany: async (
      companyId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'adminB2BControllerArchiveCompany',
        'companyId',
        companyId,
      );
      const localVarPath =
        `/api/v1/admin/b2b/companies/{companyId}/archive`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create company (superadmin only)
     * @param {CreateCompanyRequestDto} createCompanyRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerCreateCompany: async (
      createCompanyRequestDto: CreateCompanyRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createCompanyRequestDto' is not null or undefined
      assertParamExists(
        'adminB2BControllerCreateCompany',
        'createCompanyRequestDto',
        createCompanyRequestDto,
      );
      const localVarPath = `/api/v1/admin/b2b/companies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCompanyRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get companies
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'createdAt' | 'name'} [orderTerms]
     * @param {boolean} [isArchived]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerGetCompanies: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'createdAt' | 'name',
      isArchived?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('adminB2BControllerGetCompanies', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('adminB2BControllerGetCompanies', 'page', page);
      const localVarPath = `/api/v1/admin/b2b/companies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (orderTerms !== undefined) {
        localVarQueryParameter['orderTerms'] = orderTerms;
      }

      if (isArchived !== undefined) {
        localVarQueryParameter['isArchived'] = isArchived;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get company by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerGetCompanyById: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminB2BControllerGetCompanyById', 'id', id);
      const localVarPath = `/api/v1/admin/b2b/companies/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get company create requests (superadmin only)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerGetCompanyCreateRequests: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists(
        'adminB2BControllerGetCompanyCreateRequests',
        'limit',
        limit,
      );
      // verify required parameter 'page' is not null or undefined
      assertParamExists(
        'adminB2BControllerGetCompanyCreateRequests',
        'page',
        page,
      );
      const localVarPath = `/api/v1/admin/b2b/requests`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get company\'s promocodes
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerGetCompanyPromocodes: async (
      companyId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'adminB2BControllerGetCompanyPromocodes',
        'companyId',
        companyId,
      );
      const localVarPath =
        `/api/v1/admin/b2b/companies/{companyId}/promocodes`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get company\'s statistics
     * @param {string} companyId
     * @param {string} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerGetCompanyStatistics: async (
      companyId: string,
      year: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'adminB2BControllerGetCompanyStatistics',
        'companyId',
        companyId,
      );
      // verify required parameter 'year' is not null or undefined
      assertParamExists('adminB2BControllerGetCompanyStatistics', 'year', year);
      const localVarPath =
        `/api/v1/admin/b2b/companies/{companyId}/statistics`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unarchive company (superadmin and attached admin only)
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerUnarchiveCompany: async (
      companyId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'adminB2BControllerUnarchiveCompany',
        'companyId',
        companyId,
      );
      const localVarPath =
        `/api/v1/admin/b2b/companies/{companyId}/unarchive`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update company (superadmin and attached admin only)
     * @param {string} companyId
     * @param {UpdateCompanyRequestDto} updateCompanyRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerUpdateCompany: async (
      companyId: string,
      updateCompanyRequestDto: UpdateCompanyRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'adminB2BControllerUpdateCompany',
        'companyId',
        companyId,
      );
      // verify required parameter 'updateCompanyRequestDto' is not null or undefined
      assertParamExists(
        'adminB2BControllerUpdateCompany',
        'updateCompanyRequestDto',
        updateCompanyRequestDto,
      );
      const localVarPath = `/api/v1/admin/b2b/companies/{companyId}`.replace(
        `{${'companyId'}}`,
        encodeURIComponent(String(companyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCompanyRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update company\'s admin (superadmin only)
     * @param {string} companyId
     * @param {UpdateCompanyAdminRequestDto} updateCompanyAdminRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerUpdateCompanyAdmin: async (
      companyId: string,
      updateCompanyAdminRequestDto: UpdateCompanyAdminRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'companyId' is not null or undefined
      assertParamExists(
        'adminB2BControllerUpdateCompanyAdmin',
        'companyId',
        companyId,
      );
      // verify required parameter 'updateCompanyAdminRequestDto' is not null or undefined
      assertParamExists(
        'adminB2BControllerUpdateCompanyAdmin',
        'updateCompanyAdminRequestDto',
        updateCompanyAdminRequestDto,
      );
      const localVarPath =
        `/api/v1/admin/b2b/companies/{companyId}/admin`.replace(
          `{${'companyId'}}`,
          encodeURIComponent(String(companyId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateCompanyAdminRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminB2BApi - functional programming interface
 * @export
 */
export const AdminB2BApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminB2BApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add promocodes to company
     * @param {string} companyId
     * @param {AddPromocodesToCompany} addPromocodesToCompany
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminB2BControllerAddPromocodesToCompany(
      companyId: string,
      addPromocodesToCompany: AddPromocodesToCompany,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminB2BControllerAddPromocodesToCompany(
          companyId,
          addPromocodesToCompany,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Archive company (superadmin and attached admin only)
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminB2BControllerArchiveCompany(
      companyId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminB2BControllerArchiveCompany(
          companyId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create company (superadmin only)
     * @param {CreateCompanyRequestDto} createCompanyRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminB2BControllerCreateCompany(
      createCompanyRequestDto: CreateCompanyRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminB2BControllerCreateCompany(
          createCompanyRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get companies
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'createdAt' | 'name'} [orderTerms]
     * @param {boolean} [isArchived]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminB2BControllerGetCompanies(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'createdAt' | 'name',
      isArchived?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminPaginatedCompaniesResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminB2BControllerGetCompanies(
          limit,
          page,
          order,
          orderTerms,
          isArchived,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get company by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminB2BControllerGetCompanyById(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminPaginatedCompaniesResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminB2BControllerGetCompanyById(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get company create requests (superadmin only)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminB2BControllerGetCompanyCreateRequests(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminPaginatedCompanyCreateRequestResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminB2BControllerGetCompanyCreateRequests(
          limit,
          page,
          order,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get company\'s promocodes
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminB2BControllerGetCompanyPromocodes(
      companyId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetPromocodesResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminB2BControllerGetCompanyPromocodes(
          companyId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get company\'s statistics
     * @param {string} companyId
     * @param {string} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminB2BControllerGetCompanyStatistics(
      companyId: string,
      year: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetStatisticsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminB2BControllerGetCompanyStatistics(
          companyId,
          year,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Unarchive company (superadmin and attached admin only)
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminB2BControllerUnarchiveCompany(
      companyId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminB2BControllerUnarchiveCompany(
          companyId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update company (superadmin and attached admin only)
     * @param {string} companyId
     * @param {UpdateCompanyRequestDto} updateCompanyRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminB2BControllerUpdateCompany(
      companyId: string,
      updateCompanyRequestDto: UpdateCompanyRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminB2BControllerUpdateCompany(
          companyId,
          updateCompanyRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update company\'s admin (superadmin only)
     * @param {string} companyId
     * @param {UpdateCompanyAdminRequestDto} updateCompanyAdminRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminB2BControllerUpdateCompanyAdmin(
      companyId: string,
      updateCompanyAdminRequestDto: UpdateCompanyAdminRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminB2BControllerUpdateCompanyAdmin(
          companyId,
          updateCompanyAdminRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminB2BApi - factory interface
 * @export
 */
export const AdminB2BApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminB2BApiFp(configuration);
  return {
    /**
     *
     * @summary Add promocodes to company
     * @param {string} companyId
     * @param {AddPromocodesToCompany} addPromocodesToCompany
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerAddPromocodesToCompany(
      companyId: string,
      addPromocodesToCompany: AddPromocodesToCompany,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminB2BControllerAddPromocodesToCompany(
          companyId,
          addPromocodesToCompany,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Archive company (superadmin and attached admin only)
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerArchiveCompany(
      companyId: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminB2BControllerArchiveCompany(companyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create company (superadmin only)
     * @param {CreateCompanyRequestDto} createCompanyRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerCreateCompany(
      createCompanyRequestDto: CreateCompanyRequestDto,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminB2BControllerCreateCompany(createCompanyRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get companies
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'createdAt' | 'name'} [orderTerms]
     * @param {boolean} [isArchived]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerGetCompanies(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'createdAt' | 'name',
      isArchived?: boolean,
      options?: any,
    ): AxiosPromise<AdminPaginatedCompaniesResponseDto> {
      return localVarFp
        .adminB2BControllerGetCompanies(
          limit,
          page,
          order,
          orderTerms,
          isArchived,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get company by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerGetCompanyById(
      id: string,
      options?: any,
    ): AxiosPromise<AdminPaginatedCompaniesResponseDto> {
      return localVarFp
        .adminB2BControllerGetCompanyById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get company create requests (superadmin only)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerGetCompanyCreateRequests(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      options?: any,
    ): AxiosPromise<AdminPaginatedCompanyCreateRequestResponseDto> {
      return localVarFp
        .adminB2BControllerGetCompanyCreateRequests(limit, page, order, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get company\'s promocodes
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerGetCompanyPromocodes(
      companyId: string,
      options?: any,
    ): AxiosPromise<GetPromocodesResponseDto> {
      return localVarFp
        .adminB2BControllerGetCompanyPromocodes(companyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get company\'s statistics
     * @param {string} companyId
     * @param {string} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerGetCompanyStatistics(
      companyId: string,
      year: string,
      options?: any,
    ): AxiosPromise<GetStatisticsResponseDto> {
      return localVarFp
        .adminB2BControllerGetCompanyStatistics(companyId, year, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Unarchive company (superadmin and attached admin only)
     * @param {string} companyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerUnarchiveCompany(
      companyId: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminB2BControllerUnarchiveCompany(companyId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update company (superadmin and attached admin only)
     * @param {string} companyId
     * @param {UpdateCompanyRequestDto} updateCompanyRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerUpdateCompany(
      companyId: string,
      updateCompanyRequestDto: UpdateCompanyRequestDto,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminB2BControllerUpdateCompany(
          companyId,
          updateCompanyRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update company\'s admin (superadmin only)
     * @param {string} companyId
     * @param {UpdateCompanyAdminRequestDto} updateCompanyAdminRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminB2BControllerUpdateCompanyAdmin(
      companyId: string,
      updateCompanyAdminRequestDto: UpdateCompanyAdminRequestDto,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminB2BControllerUpdateCompanyAdmin(
          companyId,
          updateCompanyAdminRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminB2BApi - object-oriented interface
 * @export
 * @class AdminB2BApi
 * @extends {BaseAPI}
 */
export class AdminB2BApi extends BaseAPI {
  /**
   *
   * @summary Add promocodes to company
   * @param {string} companyId
   * @param {AddPromocodesToCompany} addPromocodesToCompany
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminB2BApi
   */
  public adminB2BControllerAddPromocodesToCompany(
    companyId: string,
    addPromocodesToCompany: AddPromocodesToCompany,
    options?: AxiosRequestConfig,
  ) {
    return AdminB2BApiFp(this.configuration)
      .adminB2BControllerAddPromocodesToCompany(
        companyId,
        addPromocodesToCompany,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Archive company (superadmin and attached admin only)
   * @param {string} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminB2BApi
   */
  public adminB2BControllerArchiveCompany(
    companyId: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminB2BApiFp(this.configuration)
      .adminB2BControllerArchiveCompany(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create company (superadmin only)
   * @param {CreateCompanyRequestDto} createCompanyRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminB2BApi
   */
  public adminB2BControllerCreateCompany(
    createCompanyRequestDto: CreateCompanyRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminB2BApiFp(this.configuration)
      .adminB2BControllerCreateCompany(createCompanyRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get companies
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {'createdAt' | 'name'} [orderTerms]
   * @param {boolean} [isArchived]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminB2BApi
   */
  public adminB2BControllerGetCompanies(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    orderTerms?: 'createdAt' | 'name',
    isArchived?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return AdminB2BApiFp(this.configuration)
      .adminB2BControllerGetCompanies(
        limit,
        page,
        order,
        orderTerms,
        isArchived,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get company by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminB2BApi
   */
  public adminB2BControllerGetCompanyById(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminB2BApiFp(this.configuration)
      .adminB2BControllerGetCompanyById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get company create requests (superadmin only)
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminB2BApi
   */
  public adminB2BControllerGetCompanyCreateRequests(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    options?: AxiosRequestConfig,
  ) {
    return AdminB2BApiFp(this.configuration)
      .adminB2BControllerGetCompanyCreateRequests(limit, page, order, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get company\'s promocodes
   * @param {string} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminB2BApi
   */
  public adminB2BControllerGetCompanyPromocodes(
    companyId: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminB2BApiFp(this.configuration)
      .adminB2BControllerGetCompanyPromocodes(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get company\'s statistics
   * @param {string} companyId
   * @param {string} year
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminB2BApi
   */
  public adminB2BControllerGetCompanyStatistics(
    companyId: string,
    year: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminB2BApiFp(this.configuration)
      .adminB2BControllerGetCompanyStatistics(companyId, year, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Unarchive company (superadmin and attached admin only)
   * @param {string} companyId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminB2BApi
   */
  public adminB2BControllerUnarchiveCompany(
    companyId: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminB2BApiFp(this.configuration)
      .adminB2BControllerUnarchiveCompany(companyId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update company (superadmin and attached admin only)
   * @param {string} companyId
   * @param {UpdateCompanyRequestDto} updateCompanyRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminB2BApi
   */
  public adminB2BControllerUpdateCompany(
    companyId: string,
    updateCompanyRequestDto: UpdateCompanyRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminB2BApiFp(this.configuration)
      .adminB2BControllerUpdateCompany(
        companyId,
        updateCompanyRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update company\'s admin (superadmin only)
   * @param {string} companyId
   * @param {UpdateCompanyAdminRequestDto} updateCompanyAdminRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminB2BApi
   */
  public adminB2BControllerUpdateCompanyAdmin(
    companyId: string,
    updateCompanyAdminRequestDto: UpdateCompanyAdminRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminB2BApiFp(this.configuration)
      .adminB2BControllerUpdateCompanyAdmin(
        companyId,
        updateCompanyAdminRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminDiseasesAndTherapyMethodsApi - axios parameter creator
 * @export
 */
export const AdminDiseasesAndTherapyMethodsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Add new disease
     * @summary Add new disease
     * @param {AddDiseaseRequestDto} addDiseaseRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerAddDisease: async (
      addDiseaseRequestDto: AddDiseaseRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addDiseaseRequestDto' is not null or undefined
      assertParamExists(
        'adminDiseasesControllerAddDisease',
        'addDiseaseRequestDto',
        addDiseaseRequestDto,
      );
      const localVarPath = `/api/v1/admin/diseases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addDiseaseRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add new disease group
     * @summary Add new disease group
     * @param {AddDiseaseGroupRequestDto} addDiseaseGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerAddDiseaseGroup: async (
      addDiseaseGroupRequestDto: AddDiseaseGroupRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addDiseaseGroupRequestDto' is not null or undefined
      assertParamExists(
        'adminDiseasesControllerAddDiseaseGroup',
        'addDiseaseGroupRequestDto',
        addDiseaseGroupRequestDto,
      );
      const localVarPath = `/api/v1/admin/diseases/group`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addDiseaseGroupRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete disease by id
     * @summary Delete disease by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerDeleteDisease: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminDiseasesControllerDeleteDisease', 'id', id);
      const localVarPath = `/api/v1/admin/diseases/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete disease group with diseases by id
     * @summary Delete disease group with diseases by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerDeleteDiseaseGroup: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminDiseasesControllerDeleteDiseaseGroup', 'id', id);
      const localVarPath = `/api/v1/admin/diseases/group/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get disease by id
     * @summary Get disease by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerGetDiseaseById: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminDiseasesControllerGetDiseaseById', 'id', id);
      const localVarPath = `/api/v1/admin/diseases/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get disease group by id
     * @summary Get disease group by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerGetDiseaseGroupById: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminDiseasesControllerGetDiseaseGroupById', 'id', id);
      const localVarPath = `/api/v1/admin/diseases/group/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get disease groups with diseases
     * @summary Get disease groups with diseases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerGetDiseaseGroupsWithDiseases: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/diseases/group`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get diseases
     * @summary Get diseases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerGetDiseases: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/diseases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update disease by id
     * @summary Update disease by id
     * @param {string} id
     * @param {UpdateDiseaseRequestDto} updateDiseaseRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerUpdateDisease: async (
      id: string,
      updateDiseaseRequestDto: UpdateDiseaseRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminDiseasesControllerUpdateDisease', 'id', id);
      // verify required parameter 'updateDiseaseRequestDto' is not null or undefined
      assertParamExists(
        'adminDiseasesControllerUpdateDisease',
        'updateDiseaseRequestDto',
        updateDiseaseRequestDto,
      );
      const localVarPath = `/api/v1/admin/diseases/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDiseaseRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update disease group by id
     * @summary Update disease group by id
     * @param {string} id
     * @param {AddDiseaseGroupRequestDto} addDiseaseGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerUpdateDiseaseGroupById: async (
      id: string,
      addDiseaseGroupRequestDto: AddDiseaseGroupRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'adminDiseasesControllerUpdateDiseaseGroupById',
        'id',
        id,
      );
      // verify required parameter 'addDiseaseGroupRequestDto' is not null or undefined
      assertParamExists(
        'adminDiseasesControllerUpdateDiseaseGroupById',
        'addDiseaseGroupRequestDto',
        addDiseaseGroupRequestDto,
      );
      const localVarPath = `/api/v1/admin/diseases/group/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addDiseaseGroupRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add new therapy method
     * @summary Add new therapy method
     * @param {AddTherapyMethodRequestDto} addTherapyMethodRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapyMethodsControllerAddTherapyMethod: async (
      addTherapyMethodRequestDto: AddTherapyMethodRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addTherapyMethodRequestDto' is not null or undefined
      assertParamExists(
        'adminTherapyMethodsControllerAddTherapyMethod',
        'addTherapyMethodRequestDto',
        addTherapyMethodRequestDto,
      );
      const localVarPath = `/api/v1/admin/therapy_methods`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addTherapyMethodRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete therapy method by id
     * @summary Delete therapy method by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapyMethodsControllerDeleteTherapyMethod: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'adminTherapyMethodsControllerDeleteTherapyMethod',
        'id',
        id,
      );
      const localVarPath = `/api/v1/admin/therapy_methods/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get therapy methods
     * @summary Get therapy methods
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapyMethodsControllerGetTherapyMethods: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/therapy_methods`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get therapy methods
     * @summary Get therapy methods
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapyMethodsControllerGetTherapyMethodsById: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'adminTherapyMethodsControllerGetTherapyMethodsById',
        'id',
        id,
      );
      const localVarPath = `/api/v1/admin/therapy_methods/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update therapy method by id
     * @summary Update therapy method by id
     * @param {string} id
     * @param {UpdateTherapyMethodRequestDto} updateTherapyMethodRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapyMethodsControllerUpdateTherapyMethod: async (
      id: string,
      updateTherapyMethodRequestDto: UpdateTherapyMethodRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'adminTherapyMethodsControllerUpdateTherapyMethod',
        'id',
        id,
      );
      // verify required parameter 'updateTherapyMethodRequestDto' is not null or undefined
      assertParamExists(
        'adminTherapyMethodsControllerUpdateTherapyMethod',
        'updateTherapyMethodRequestDto',
        updateTherapyMethodRequestDto,
      );
      const localVarPath = `/api/v1/admin/therapy_methods/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTherapyMethodRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminDiseasesAndTherapyMethodsApi - functional programming interface
 * @export
 */
export const AdminDiseasesAndTherapyMethodsApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    AdminDiseasesAndTherapyMethodsApiAxiosParamCreator(configuration);
  return {
    /**
     * Add new disease
     * @summary Add new disease
     * @param {AddDiseaseRequestDto} addDiseaseRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDiseasesControllerAddDisease(
      addDiseaseRequestDto: AddDiseaseRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DiseaseResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminDiseasesControllerAddDisease(
          addDiseaseRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Add new disease group
     * @summary Add new disease group
     * @param {AddDiseaseGroupRequestDto} addDiseaseGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDiseasesControllerAddDiseaseGroup(
      addDiseaseGroupRequestDto: AddDiseaseGroupRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminDiseaseGroupResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminDiseasesControllerAddDiseaseGroup(
          addDiseaseGroupRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Delete disease by id
     * @summary Delete disease by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDiseasesControllerDeleteDisease(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminDiseasesControllerDeleteDisease(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Delete disease group with diseases by id
     * @summary Delete disease group with diseases by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDiseasesControllerDeleteDiseaseGroup(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminDiseasesControllerDeleteDiseaseGroup(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get disease by id
     * @summary Get disease by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDiseasesControllerGetDiseaseById(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DiseaseResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminDiseasesControllerGetDiseaseById(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get disease group by id
     * @summary Get disease group by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDiseasesControllerGetDiseaseGroupById(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminDiseaseGroupResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminDiseasesControllerGetDiseaseGroupById(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get disease groups with diseases
     * @summary Get disease groups with diseases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDiseasesControllerGetDiseaseGroupsWithDiseases(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminDiseaseGroupsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminDiseasesControllerGetDiseaseGroupsWithDiseases(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get diseases
     * @summary Get diseases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDiseasesControllerGetDiseases(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminDiseasesResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminDiseasesControllerGetDiseases(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update disease by id
     * @summary Update disease by id
     * @param {string} id
     * @param {UpdateDiseaseRequestDto} updateDiseaseRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDiseasesControllerUpdateDisease(
      id: string,
      updateDiseaseRequestDto: UpdateDiseaseRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DiseaseResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminDiseasesControllerUpdateDisease(
          id,
          updateDiseaseRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update disease group by id
     * @summary Update disease group by id
     * @param {string} id
     * @param {AddDiseaseGroupRequestDto} addDiseaseGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminDiseasesControllerUpdateDiseaseGroupById(
      id: string,
      addDiseaseGroupRequestDto: AddDiseaseGroupRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DiseaseResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminDiseasesControllerUpdateDiseaseGroupById(
          id,
          addDiseaseGroupRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Add new therapy method
     * @summary Add new therapy method
     * @param {AddTherapyMethodRequestDto} addTherapyMethodRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapyMethodsControllerAddTherapyMethod(
      addTherapyMethodRequestDto: AddTherapyMethodRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapyMethodResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapyMethodsControllerAddTherapyMethod(
          addTherapyMethodRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Delete therapy method by id
     * @summary Delete therapy method by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapyMethodsControllerDeleteTherapyMethod(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapyMethodsControllerDeleteTherapyMethod(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get therapy methods
     * @summary Get therapy methods
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapyMethodsControllerGetTherapyMethods(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapyMethodsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapyMethodsControllerGetTherapyMethods(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get therapy methods
     * @summary Get therapy methods
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapyMethodsControllerGetTherapyMethodsById(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapyMethodsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapyMethodsControllerGetTherapyMethodsById(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update therapy method by id
     * @summary Update therapy method by id
     * @param {string} id
     * @param {UpdateTherapyMethodRequestDto} updateTherapyMethodRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapyMethodsControllerUpdateTherapyMethod(
      id: string,
      updateTherapyMethodRequestDto: UpdateTherapyMethodRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapyMethodResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapyMethodsControllerUpdateTherapyMethod(
          id,
          updateTherapyMethodRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminDiseasesAndTherapyMethodsApi - factory interface
 * @export
 */
export const AdminDiseasesAndTherapyMethodsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminDiseasesAndTherapyMethodsApiFp(configuration);
  return {
    /**
     * Add new disease
     * @summary Add new disease
     * @param {AddDiseaseRequestDto} addDiseaseRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerAddDisease(
      addDiseaseRequestDto: AddDiseaseRequestDto,
      options?: any,
    ): AxiosPromise<DiseaseResponseDto> {
      return localVarFp
        .adminDiseasesControllerAddDisease(addDiseaseRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Add new disease group
     * @summary Add new disease group
     * @param {AddDiseaseGroupRequestDto} addDiseaseGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerAddDiseaseGroup(
      addDiseaseGroupRequestDto: AddDiseaseGroupRequestDto,
      options?: any,
    ): AxiosPromise<AdminDiseaseGroupResponseDto> {
      return localVarFp
        .adminDiseasesControllerAddDiseaseGroup(
          addDiseaseGroupRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete disease by id
     * @summary Delete disease by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerDeleteDisease(
      id: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminDiseasesControllerDeleteDisease(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete disease group with diseases by id
     * @summary Delete disease group with diseases by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerDeleteDiseaseGroup(
      id: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminDiseasesControllerDeleteDiseaseGroup(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get disease by id
     * @summary Get disease by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerGetDiseaseById(
      id: string,
      options?: any,
    ): AxiosPromise<DiseaseResponseDto> {
      return localVarFp
        .adminDiseasesControllerGetDiseaseById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get disease group by id
     * @summary Get disease group by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerGetDiseaseGroupById(
      id: string,
      options?: any,
    ): AxiosPromise<AdminDiseaseGroupResponseDto> {
      return localVarFp
        .adminDiseasesControllerGetDiseaseGroupById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get disease groups with diseases
     * @summary Get disease groups with diseases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerGetDiseaseGroupsWithDiseases(
      options?: any,
    ): AxiosPromise<AdminDiseaseGroupsResponseDto> {
      return localVarFp
        .adminDiseasesControllerGetDiseaseGroupsWithDiseases(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get diseases
     * @summary Get diseases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerGetDiseases(
      options?: any,
    ): AxiosPromise<AdminDiseasesResponseDto> {
      return localVarFp
        .adminDiseasesControllerGetDiseases(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update disease by id
     * @summary Update disease by id
     * @param {string} id
     * @param {UpdateDiseaseRequestDto} updateDiseaseRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerUpdateDisease(
      id: string,
      updateDiseaseRequestDto: UpdateDiseaseRequestDto,
      options?: any,
    ): AxiosPromise<DiseaseResponseDto> {
      return localVarFp
        .adminDiseasesControllerUpdateDisease(
          id,
          updateDiseaseRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update disease group by id
     * @summary Update disease group by id
     * @param {string} id
     * @param {AddDiseaseGroupRequestDto} addDiseaseGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminDiseasesControllerUpdateDiseaseGroupById(
      id: string,
      addDiseaseGroupRequestDto: AddDiseaseGroupRequestDto,
      options?: any,
    ): AxiosPromise<DiseaseResponseDto> {
      return localVarFp
        .adminDiseasesControllerUpdateDiseaseGroupById(
          id,
          addDiseaseGroupRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Add new therapy method
     * @summary Add new therapy method
     * @param {AddTherapyMethodRequestDto} addTherapyMethodRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapyMethodsControllerAddTherapyMethod(
      addTherapyMethodRequestDto: AddTherapyMethodRequestDto,
      options?: any,
    ): AxiosPromise<TherapyMethodResponseDto> {
      return localVarFp
        .adminTherapyMethodsControllerAddTherapyMethod(
          addTherapyMethodRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete therapy method by id
     * @summary Delete therapy method by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapyMethodsControllerDeleteTherapyMethod(
      id: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminTherapyMethodsControllerDeleteTherapyMethod(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get therapy methods
     * @summary Get therapy methods
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapyMethodsControllerGetTherapyMethods(
      options?: any,
    ): AxiosPromise<TherapyMethodsResponseDto> {
      return localVarFp
        .adminTherapyMethodsControllerGetTherapyMethods(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get therapy methods
     * @summary Get therapy methods
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapyMethodsControllerGetTherapyMethodsById(
      id: string,
      options?: any,
    ): AxiosPromise<TherapyMethodsResponseDto> {
      return localVarFp
        .adminTherapyMethodsControllerGetTherapyMethodsById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update therapy method by id
     * @summary Update therapy method by id
     * @param {string} id
     * @param {UpdateTherapyMethodRequestDto} updateTherapyMethodRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapyMethodsControllerUpdateTherapyMethod(
      id: string,
      updateTherapyMethodRequestDto: UpdateTherapyMethodRequestDto,
      options?: any,
    ): AxiosPromise<TherapyMethodResponseDto> {
      return localVarFp
        .adminTherapyMethodsControllerUpdateTherapyMethod(
          id,
          updateTherapyMethodRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminDiseasesAndTherapyMethodsApi - object-oriented interface
 * @export
 * @class AdminDiseasesAndTherapyMethodsApi
 * @extends {BaseAPI}
 */
export class AdminDiseasesAndTherapyMethodsApi extends BaseAPI {
  /**
   * Add new disease
   * @summary Add new disease
   * @param {AddDiseaseRequestDto} addDiseaseRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminDiseasesControllerAddDisease(
    addDiseaseRequestDto: AddDiseaseRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminDiseasesControllerAddDisease(addDiseaseRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add new disease group
   * @summary Add new disease group
   * @param {AddDiseaseGroupRequestDto} addDiseaseGroupRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminDiseasesControllerAddDiseaseGroup(
    addDiseaseGroupRequestDto: AddDiseaseGroupRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminDiseasesControllerAddDiseaseGroup(
        addDiseaseGroupRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete disease by id
   * @summary Delete disease by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminDiseasesControllerDeleteDisease(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminDiseasesControllerDeleteDisease(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete disease group with diseases by id
   * @summary Delete disease group with diseases by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminDiseasesControllerDeleteDiseaseGroup(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminDiseasesControllerDeleteDiseaseGroup(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get disease by id
   * @summary Get disease by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminDiseasesControllerGetDiseaseById(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminDiseasesControllerGetDiseaseById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get disease group by id
   * @summary Get disease group by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminDiseasesControllerGetDiseaseGroupById(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminDiseasesControllerGetDiseaseGroupById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get disease groups with diseases
   * @summary Get disease groups with diseases
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminDiseasesControllerGetDiseaseGroupsWithDiseases(
    options?: AxiosRequestConfig,
  ) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminDiseasesControllerGetDiseaseGroupsWithDiseases(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get diseases
   * @summary Get diseases
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminDiseasesControllerGetDiseases(options?: AxiosRequestConfig) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminDiseasesControllerGetDiseases(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update disease by id
   * @summary Update disease by id
   * @param {string} id
   * @param {UpdateDiseaseRequestDto} updateDiseaseRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminDiseasesControllerUpdateDisease(
    id: string,
    updateDiseaseRequestDto: UpdateDiseaseRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminDiseasesControllerUpdateDisease(
        id,
        updateDiseaseRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update disease group by id
   * @summary Update disease group by id
   * @param {string} id
   * @param {AddDiseaseGroupRequestDto} addDiseaseGroupRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminDiseasesControllerUpdateDiseaseGroupById(
    id: string,
    addDiseaseGroupRequestDto: AddDiseaseGroupRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminDiseasesControllerUpdateDiseaseGroupById(
        id,
        addDiseaseGroupRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add new therapy method
   * @summary Add new therapy method
   * @param {AddTherapyMethodRequestDto} addTherapyMethodRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminTherapyMethodsControllerAddTherapyMethod(
    addTherapyMethodRequestDto: AddTherapyMethodRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminTherapyMethodsControllerAddTherapyMethod(
        addTherapyMethodRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete therapy method by id
   * @summary Delete therapy method by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminTherapyMethodsControllerDeleteTherapyMethod(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminTherapyMethodsControllerDeleteTherapyMethod(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get therapy methods
   * @summary Get therapy methods
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminTherapyMethodsControllerGetTherapyMethods(
    options?: AxiosRequestConfig,
  ) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminTherapyMethodsControllerGetTherapyMethods(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get therapy methods
   * @summary Get therapy methods
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminTherapyMethodsControllerGetTherapyMethodsById(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminTherapyMethodsControllerGetTherapyMethodsById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update therapy method by id
   * @summary Update therapy method by id
   * @param {string} id
   * @param {UpdateTherapyMethodRequestDto} updateTherapyMethodRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminDiseasesAndTherapyMethodsApi
   */
  public adminTherapyMethodsControllerUpdateTherapyMethod(
    id: string,
    updateTherapyMethodRequestDto: UpdateTherapyMethodRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminDiseasesAndTherapyMethodsApiFp(this.configuration)
      .adminTherapyMethodsControllerUpdateTherapyMethod(
        id,
        updateTherapyMethodRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminFAQApi - axios parameter creator
 * @export
 */
export const AdminFAQApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add FAQ group
     * @param {AdminAddFaqGroupRequestDto} adminAddFaqGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerAddFaqGroup: async (
      adminAddFaqGroupRequestDto: AdminAddFaqGroupRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminAddFaqGroupRequestDto' is not null or undefined
      assertParamExists(
        'adminFaqControllerAddFaqGroup',
        'adminAddFaqGroupRequestDto',
        adminAddFaqGroupRequestDto,
      );
      const localVarPath = `/api/v1/admin/faq/group`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminAddFaqGroupRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add FAQ question
     * @param {AdminAddFaqQuestionRequestDto} adminAddFaqQuestionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerAddFaqQuestion: async (
      adminAddFaqQuestionRequestDto: AdminAddFaqQuestionRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminAddFaqQuestionRequestDto' is not null or undefined
      assertParamExists(
        'adminFaqControllerAddFaqQuestion',
        'adminAddFaqQuestionRequestDto',
        adminAddFaqQuestionRequestDto,
      );
      const localVarPath = `/api/v1/admin/faq/question`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminAddFaqQuestionRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete FAQ group
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerDeleteFaqGroup: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminFaqControllerDeleteFaqGroup', 'id', id);
      const localVarPath = `/api/v1/admin/faq/group/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete FAQ question
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerDeleteFaqQuestion: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminFaqControllerDeleteFaqQuestion', 'id', id);
      const localVarPath = `/api/v1/admin/faq/question/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all FAQ groups with questions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerGetFaqGroupsWithQuestions: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/faq/group`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all FAQ questions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerGetFaqQuestions: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/faq/question`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get one FAQ group
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerGetOneFaqGroup: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminFaqControllerGetOneFaqGroup', 'id', id);
      const localVarPath = `/api/v1/admin/faq/group/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get one FAQ question
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerGetOneFaqQuestion: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminFaqControllerGetOneFaqQuestion', 'id', id);
      const localVarPath = `/api/v1/admin/faq/question/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update FAQ group
     * @param {string} id
     * @param {AdminUpdateFaqGroupRequestDto} adminUpdateFaqGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerUpdateFaqGroup: async (
      id: string,
      adminUpdateFaqGroupRequestDto: AdminUpdateFaqGroupRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminFaqControllerUpdateFaqGroup', 'id', id);
      // verify required parameter 'adminUpdateFaqGroupRequestDto' is not null or undefined
      assertParamExists(
        'adminFaqControllerUpdateFaqGroup',
        'adminUpdateFaqGroupRequestDto',
        adminUpdateFaqGroupRequestDto,
      );
      const localVarPath = `/api/v1/admin/faq/group/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminUpdateFaqGroupRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update FAQ question
     * @param {string} id
     * @param {AdminUpdateFaqQuestionRequestDto} adminUpdateFaqQuestionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerUpdateFaqQuestion: async (
      id: string,
      adminUpdateFaqQuestionRequestDto: AdminUpdateFaqQuestionRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminFaqControllerUpdateFaqQuestion', 'id', id);
      // verify required parameter 'adminUpdateFaqQuestionRequestDto' is not null or undefined
      assertParamExists(
        'adminFaqControllerUpdateFaqQuestion',
        'adminUpdateFaqQuestionRequestDto',
        adminUpdateFaqQuestionRequestDto,
      );
      const localVarPath = `/api/v1/admin/faq/question/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminUpdateFaqQuestionRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminFAQApi - functional programming interface
 * @export
 */
export const AdminFAQApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminFAQApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add FAQ group
     * @param {AdminAddFaqGroupRequestDto} adminAddFaqGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFaqControllerAddFaqGroup(
      adminAddFaqGroupRequestDto: AdminAddFaqGroupRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminFaqGroupWithQuestionsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminFaqControllerAddFaqGroup(
          adminAddFaqGroupRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Add FAQ question
     * @param {AdminAddFaqQuestionRequestDto} adminAddFaqQuestionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFaqControllerAddFaqQuestion(
      adminAddFaqQuestionRequestDto: AdminAddFaqQuestionRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminFaqQuestionResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminFaqControllerAddFaqQuestion(
          adminAddFaqQuestionRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete FAQ group
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFaqControllerDeleteFaqGroup(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminFaqControllerDeleteFaqGroup(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete FAQ question
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFaqControllerDeleteFaqQuestion(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminFaqControllerDeleteFaqQuestion(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all FAQ groups with questions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFaqControllerGetFaqGroupsWithQuestions(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminFaqGroupsWithQuestionsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminFaqControllerGetFaqGroupsWithQuestions(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all FAQ questions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFaqControllerGetFaqQuestions(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminFaqQuestionsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminFaqControllerGetFaqQuestions(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get one FAQ group
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFaqControllerGetOneFaqGroup(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminFaqGroupWithQuestionsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminFaqControllerGetOneFaqGroup(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get one FAQ question
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFaqControllerGetOneFaqQuestion(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminFaqQuestionResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminFaqControllerGetOneFaqQuestion(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update FAQ group
     * @param {string} id
     * @param {AdminUpdateFaqGroupRequestDto} adminUpdateFaqGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFaqControllerUpdateFaqGroup(
      id: string,
      adminUpdateFaqGroupRequestDto: AdminUpdateFaqGroupRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminFaqGroupWithQuestionsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminFaqControllerUpdateFaqGroup(
          id,
          adminUpdateFaqGroupRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update FAQ question
     * @param {string} id
     * @param {AdminUpdateFaqQuestionRequestDto} adminUpdateFaqQuestionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminFaqControllerUpdateFaqQuestion(
      id: string,
      adminUpdateFaqQuestionRequestDto: AdminUpdateFaqQuestionRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminFaqQuestionResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminFaqControllerUpdateFaqQuestion(
          id,
          adminUpdateFaqQuestionRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminFAQApi - factory interface
 * @export
 */
export const AdminFAQApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminFAQApiFp(configuration);
  return {
    /**
     *
     * @summary Add FAQ group
     * @param {AdminAddFaqGroupRequestDto} adminAddFaqGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerAddFaqGroup(
      adminAddFaqGroupRequestDto: AdminAddFaqGroupRequestDto,
      options?: any,
    ): AxiosPromise<AdminFaqGroupWithQuestionsResponseDto> {
      return localVarFp
        .adminFaqControllerAddFaqGroup(adminAddFaqGroupRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add FAQ question
     * @param {AdminAddFaqQuestionRequestDto} adminAddFaqQuestionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerAddFaqQuestion(
      adminAddFaqQuestionRequestDto: AdminAddFaqQuestionRequestDto,
      options?: any,
    ): AxiosPromise<AdminFaqQuestionResponseDto> {
      return localVarFp
        .adminFaqControllerAddFaqQuestion(
          adminAddFaqQuestionRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete FAQ group
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerDeleteFaqGroup(
      id: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminFaqControllerDeleteFaqGroup(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete FAQ question
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerDeleteFaqQuestion(
      id: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminFaqControllerDeleteFaqQuestion(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all FAQ groups with questions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerGetFaqGroupsWithQuestions(
      options?: any,
    ): AxiosPromise<AdminFaqGroupsWithQuestionsResponseDto> {
      return localVarFp
        .adminFaqControllerGetFaqGroupsWithQuestions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all FAQ questions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerGetFaqQuestions(
      options?: any,
    ): AxiosPromise<AdminFaqQuestionsResponseDto> {
      return localVarFp
        .adminFaqControllerGetFaqQuestions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get one FAQ group
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerGetOneFaqGroup(
      id: string,
      options?: any,
    ): AxiosPromise<AdminFaqGroupWithQuestionsResponseDto> {
      return localVarFp
        .adminFaqControllerGetOneFaqGroup(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get one FAQ question
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerGetOneFaqQuestion(
      id: string,
      options?: any,
    ): AxiosPromise<AdminFaqQuestionResponseDto> {
      return localVarFp
        .adminFaqControllerGetOneFaqQuestion(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update FAQ group
     * @param {string} id
     * @param {AdminUpdateFaqGroupRequestDto} adminUpdateFaqGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerUpdateFaqGroup(
      id: string,
      adminUpdateFaqGroupRequestDto: AdminUpdateFaqGroupRequestDto,
      options?: any,
    ): AxiosPromise<AdminFaqGroupWithQuestionsResponseDto> {
      return localVarFp
        .adminFaqControllerUpdateFaqGroup(
          id,
          adminUpdateFaqGroupRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update FAQ question
     * @param {string} id
     * @param {AdminUpdateFaqQuestionRequestDto} adminUpdateFaqQuestionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminFaqControllerUpdateFaqQuestion(
      id: string,
      adminUpdateFaqQuestionRequestDto: AdminUpdateFaqQuestionRequestDto,
      options?: any,
    ): AxiosPromise<AdminFaqQuestionResponseDto> {
      return localVarFp
        .adminFaqControllerUpdateFaqQuestion(
          id,
          adminUpdateFaqQuestionRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminFAQApi - object-oriented interface
 * @export
 * @class AdminFAQApi
 * @extends {BaseAPI}
 */
export class AdminFAQApi extends BaseAPI {
  /**
   *
   * @summary Add FAQ group
   * @param {AdminAddFaqGroupRequestDto} adminAddFaqGroupRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFAQApi
   */
  public adminFaqControllerAddFaqGroup(
    adminAddFaqGroupRequestDto: AdminAddFaqGroupRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminFAQApiFp(this.configuration)
      .adminFaqControllerAddFaqGroup(adminAddFaqGroupRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add FAQ question
   * @param {AdminAddFaqQuestionRequestDto} adminAddFaqQuestionRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFAQApi
   */
  public adminFaqControllerAddFaqQuestion(
    adminAddFaqQuestionRequestDto: AdminAddFaqQuestionRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminFAQApiFp(this.configuration)
      .adminFaqControllerAddFaqQuestion(adminAddFaqQuestionRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete FAQ group
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFAQApi
   */
  public adminFaqControllerDeleteFaqGroup(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminFAQApiFp(this.configuration)
      .adminFaqControllerDeleteFaqGroup(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete FAQ question
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFAQApi
   */
  public adminFaqControllerDeleteFaqQuestion(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminFAQApiFp(this.configuration)
      .adminFaqControllerDeleteFaqQuestion(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all FAQ groups with questions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFAQApi
   */
  public adminFaqControllerGetFaqGroupsWithQuestions(
    options?: AxiosRequestConfig,
  ) {
    return AdminFAQApiFp(this.configuration)
      .adminFaqControllerGetFaqGroupsWithQuestions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all FAQ questions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFAQApi
   */
  public adminFaqControllerGetFaqQuestions(options?: AxiosRequestConfig) {
    return AdminFAQApiFp(this.configuration)
      .adminFaqControllerGetFaqQuestions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get one FAQ group
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFAQApi
   */
  public adminFaqControllerGetOneFaqGroup(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminFAQApiFp(this.configuration)
      .adminFaqControllerGetOneFaqGroup(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get one FAQ question
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFAQApi
   */
  public adminFaqControllerGetOneFaqQuestion(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminFAQApiFp(this.configuration)
      .adminFaqControllerGetOneFaqQuestion(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update FAQ group
   * @param {string} id
   * @param {AdminUpdateFaqGroupRequestDto} adminUpdateFaqGroupRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFAQApi
   */
  public adminFaqControllerUpdateFaqGroup(
    id: string,
    adminUpdateFaqGroupRequestDto: AdminUpdateFaqGroupRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminFAQApiFp(this.configuration)
      .adminFaqControllerUpdateFaqGroup(
        id,
        adminUpdateFaqGroupRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update FAQ question
   * @param {string} id
   * @param {AdminUpdateFaqQuestionRequestDto} adminUpdateFaqQuestionRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminFAQApi
   */
  public adminFaqControllerUpdateFaqQuestion(
    id: string,
    adminUpdateFaqQuestionRequestDto: AdminUpdateFaqQuestionRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminFAQApiFp(this.configuration)
      .adminFaqControllerUpdateFaqQuestion(
        id,
        adminUpdateFaqQuestionRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminPatientsApi - axios parameter creator
 * @export
 */
export const AdminPatientsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Ban patient by id
     * @summary Ban patient by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPatientsControllerBanPatient: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminPatientsControllerBanPatient', 'id', id);
      const localVarPath = `/api/v1/admin/patients/ban/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get patient by id
     * @summary Get patient by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPatientsControllerGetPatientById: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminPatientsControllerGetPatientById', 'id', id);
      const localVarPath = `/api/v1/admin/patients/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get patients
     * @summary Get patients
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'createdAt' | 'lastname' | 'sessionsCompleted' | 'sessionsAwaiting'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPatientsControllerGetPatients: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'createdAt'
        | 'lastname'
        | 'sessionsCompleted'
        | 'sessionsAwaiting',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('adminPatientsControllerGetPatients', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('adminPatientsControllerGetPatients', 'page', page);
      const localVarPath = `/api/v1/admin/patients`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (orderTerms !== undefined) {
        localVarQueryParameter['orderTerms'] = orderTerms;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Unban patient by id
     * @summary Unban patient by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPatientsControllerUnbanPatient: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminPatientsControllerUnbanPatient', 'id', id);
      const localVarPath = `/api/v1/admin/patients/unban/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminPatientsApi - functional programming interface
 * @export
 */
export const AdminPatientsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AdminPatientsApiAxiosParamCreator(configuration);
  return {
    /**
     * Ban patient by id
     * @summary Ban patient by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPatientsControllerBanPatient(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminPatientsControllerBanPatient(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get patient by id
     * @summary Get patient by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPatientsControllerGetPatientById(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminPatientResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminPatientsControllerGetPatientById(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get patients
     * @summary Get patients
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'createdAt' | 'lastname' | 'sessionsCompleted' | 'sessionsAwaiting'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPatientsControllerGetPatients(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'createdAt'
        | 'lastname'
        | 'sessionsCompleted'
        | 'sessionsAwaiting',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminPaginatedPatientsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminPatientsControllerGetPatients(
          limit,
          page,
          order,
          orderTerms,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Unban patient by id
     * @summary Unban patient by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPatientsControllerUnbanPatient(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminPatientsControllerUnbanPatient(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminPatientsApi - factory interface
 * @export
 */
export const AdminPatientsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminPatientsApiFp(configuration);
  return {
    /**
     * Ban patient by id
     * @summary Ban patient by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPatientsControllerBanPatient(
      id: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminPatientsControllerBanPatient(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get patient by id
     * @summary Get patient by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPatientsControllerGetPatientById(
      id: string,
      options?: any,
    ): AxiosPromise<AdminPatientResponseDto> {
      return localVarFp
        .adminPatientsControllerGetPatientById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get patients
     * @summary Get patients
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'createdAt' | 'lastname' | 'sessionsCompleted' | 'sessionsAwaiting'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPatientsControllerGetPatients(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'createdAt'
        | 'lastname'
        | 'sessionsCompleted'
        | 'sessionsAwaiting',
      options?: any,
    ): AxiosPromise<AdminPaginatedPatientsResponseDto> {
      return localVarFp
        .adminPatientsControllerGetPatients(
          limit,
          page,
          order,
          orderTerms,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Unban patient by id
     * @summary Unban patient by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPatientsControllerUnbanPatient(
      id: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminPatientsControllerUnbanPatient(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminPatientsApi - object-oriented interface
 * @export
 * @class AdminPatientsApi
 * @extends {BaseAPI}
 */
export class AdminPatientsApi extends BaseAPI {
  /**
   * Ban patient by id
   * @summary Ban patient by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminPatientsApi
   */
  public adminPatientsControllerBanPatient(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminPatientsApiFp(this.configuration)
      .adminPatientsControllerBanPatient(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get patient by id
   * @summary Get patient by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminPatientsApi
   */
  public adminPatientsControllerGetPatientById(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminPatientsApiFp(this.configuration)
      .adminPatientsControllerGetPatientById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get patients
   * @summary Get patients
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {'createdAt' | 'lastname' | 'sessionsCompleted' | 'sessionsAwaiting'} [orderTerms]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminPatientsApi
   */
  public adminPatientsControllerGetPatients(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    orderTerms?:
      | 'createdAt'
      | 'lastname'
      | 'sessionsCompleted'
      | 'sessionsAwaiting',
    options?: AxiosRequestConfig,
  ) {
    return AdminPatientsApiFp(this.configuration)
      .adminPatientsControllerGetPatients(
        limit,
        page,
        order,
        orderTerms,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Unban patient by id
   * @summary Unban patient by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminPatientsApi
   */
  public adminPatientsControllerUnbanPatient(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminPatientsApiFp(this.configuration)
      .adminPatientsControllerUnbanPatient(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminPromocodesReusableApi - axios parameter creator
 * @export
 */
export const AdminPromocodesReusableApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Create promocode
     * @summary Create promocode
     * @param {CreatePromocodeRequestDto} createPromocodeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPromocodesControllerCreatePromocode: async (
      createPromocodeRequestDto: CreatePromocodeRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPromocodeRequestDto' is not null or undefined
      assertParamExists(
        'adminPromocodesControllerCreatePromocode',
        'createPromocodeRequestDto',
        createPromocodeRequestDto,
      );
      const localVarPath = `/api/v1/admin/promocodes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPromocodeRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete promocode by id
     * @summary Delete promocode by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPromocodesControllerDeletePromocodeById: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'adminPromocodesControllerDeletePromocodeById',
        'id',
        id,
      );
      const localVarPath = `/api/v1/admin/promocodes/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get promocode by id
     * @summary Get promocode by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPromocodesControllerGetPromocodeById: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminPromocodesControllerGetPromocodeById', 'id', id);
      const localVarPath = `/api/v1/admin/promocodes/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get paginated promocodes
     * @summary Get paginated promocodes
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'amount' | 'percentage' | 'createdAt' | 'expireDate' | 'code' | 'timesUsed'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPromocodesControllerGetPromocodes: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'amount'
        | 'percentage'
        | 'createdAt'
        | 'expireDate'
        | 'code'
        | 'timesUsed',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists(
        'adminPromocodesControllerGetPromocodes',
        'limit',
        limit,
      );
      // verify required parameter 'page' is not null or undefined
      assertParamExists('adminPromocodesControllerGetPromocodes', 'page', page);
      const localVarPath = `/api/v1/admin/promocodes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (orderTerms !== undefined) {
        localVarQueryParameter['orderTerms'] = orderTerms;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update promocode by id
     * @summary Update promocode by id
     * @param {string} id
     * @param {UpdatePromocodeRequestDto} updatePromocodeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPromocodesControllerUpdatePromocodeById: async (
      id: string,
      updatePromocodeRequestDto: UpdatePromocodeRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'adminPromocodesControllerUpdatePromocodeById',
        'id',
        id,
      );
      // verify required parameter 'updatePromocodeRequestDto' is not null or undefined
      assertParamExists(
        'adminPromocodesControllerUpdatePromocodeById',
        'updatePromocodeRequestDto',
        updatePromocodeRequestDto,
      );
      const localVarPath = `/api/v1/admin/promocodes/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePromocodeRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminPromocodesReusableApi - functional programming interface
 * @export
 */
export const AdminPromocodesReusableApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    AdminPromocodesReusableApiAxiosParamCreator(configuration);
  return {
    /**
     * Create promocode
     * @summary Create promocode
     * @param {CreatePromocodeRequestDto} createPromocodeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPromocodesControllerCreatePromocode(
      createPromocodeRequestDto: CreatePromocodeRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminPromocodeResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminPromocodesControllerCreatePromocode(
          createPromocodeRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Delete promocode by id
     * @summary Delete promocode by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPromocodesControllerDeletePromocodeById(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminPromocodesControllerDeletePromocodeById(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get promocode by id
     * @summary Get promocode by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPromocodesControllerGetPromocodeById(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminPromocodeResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminPromocodesControllerGetPromocodeById(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get paginated promocodes
     * @summary Get paginated promocodes
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'amount' | 'percentage' | 'createdAt' | 'expireDate' | 'code' | 'timesUsed'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPromocodesControllerGetPromocodes(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'amount'
        | 'percentage'
        | 'createdAt'
        | 'expireDate'
        | 'code'
        | 'timesUsed',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminPromocodesResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminPromocodesControllerGetPromocodes(
          limit,
          page,
          order,
          orderTerms,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update promocode by id
     * @summary Update promocode by id
     * @param {string} id
     * @param {UpdatePromocodeRequestDto} updatePromocodeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPromocodesControllerUpdatePromocodeById(
      id: string,
      updatePromocodeRequestDto: UpdatePromocodeRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminPromocodeResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminPromocodesControllerUpdatePromocodeById(
          id,
          updatePromocodeRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminPromocodesReusableApi - factory interface
 * @export
 */
export const AdminPromocodesReusableApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminPromocodesReusableApiFp(configuration);
  return {
    /**
     * Create promocode
     * @summary Create promocode
     * @param {CreatePromocodeRequestDto} createPromocodeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPromocodesControllerCreatePromocode(
      createPromocodeRequestDto: CreatePromocodeRequestDto,
      options?: any,
    ): AxiosPromise<AdminPromocodeResponseDto> {
      return localVarFp
        .adminPromocodesControllerCreatePromocode(
          createPromocodeRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete promocode by id
     * @summary Delete promocode by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPromocodesControllerDeletePromocodeById(
      id: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminPromocodesControllerDeletePromocodeById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get promocode by id
     * @summary Get promocode by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPromocodesControllerGetPromocodeById(
      id: string,
      options?: any,
    ): AxiosPromise<AdminPromocodeResponseDto> {
      return localVarFp
        .adminPromocodesControllerGetPromocodeById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get paginated promocodes
     * @summary Get paginated promocodes
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'amount' | 'percentage' | 'createdAt' | 'expireDate' | 'code' | 'timesUsed'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPromocodesControllerGetPromocodes(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'amount'
        | 'percentage'
        | 'createdAt'
        | 'expireDate'
        | 'code'
        | 'timesUsed',
      options?: any,
    ): AxiosPromise<AdminPromocodesResponseDto> {
      return localVarFp
        .adminPromocodesControllerGetPromocodes(
          limit,
          page,
          order,
          orderTerms,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update promocode by id
     * @summary Update promocode by id
     * @param {string} id
     * @param {UpdatePromocodeRequestDto} updatePromocodeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPromocodesControllerUpdatePromocodeById(
      id: string,
      updatePromocodeRequestDto: UpdatePromocodeRequestDto,
      options?: any,
    ): AxiosPromise<AdminPromocodeResponseDto> {
      return localVarFp
        .adminPromocodesControllerUpdatePromocodeById(
          id,
          updatePromocodeRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminPromocodesReusableApi - object-oriented interface
 * @export
 * @class AdminPromocodesReusableApi
 * @extends {BaseAPI}
 */
export class AdminPromocodesReusableApi extends BaseAPI {
  /**
   * Create promocode
   * @summary Create promocode
   * @param {CreatePromocodeRequestDto} createPromocodeRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminPromocodesReusableApi
   */
  public adminPromocodesControllerCreatePromocode(
    createPromocodeRequestDto: CreatePromocodeRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminPromocodesReusableApiFp(this.configuration)
      .adminPromocodesControllerCreatePromocode(
        createPromocodeRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete promocode by id
   * @summary Delete promocode by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminPromocodesReusableApi
   */
  public adminPromocodesControllerDeletePromocodeById(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminPromocodesReusableApiFp(this.configuration)
      .adminPromocodesControllerDeletePromocodeById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get promocode by id
   * @summary Get promocode by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminPromocodesReusableApi
   */
  public adminPromocodesControllerGetPromocodeById(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminPromocodesReusableApiFp(this.configuration)
      .adminPromocodesControllerGetPromocodeById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get paginated promocodes
   * @summary Get paginated promocodes
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {'amount' | 'percentage' | 'createdAt' | 'expireDate' | 'code' | 'timesUsed'} [orderTerms]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminPromocodesReusableApi
   */
  public adminPromocodesControllerGetPromocodes(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    orderTerms?:
      | 'amount'
      | 'percentage'
      | 'createdAt'
      | 'expireDate'
      | 'code'
      | 'timesUsed',
    options?: AxiosRequestConfig,
  ) {
    return AdminPromocodesReusableApiFp(this.configuration)
      .adminPromocodesControllerGetPromocodes(
        limit,
        page,
        order,
        orderTerms,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update promocode by id
   * @summary Update promocode by id
   * @param {string} id
   * @param {UpdatePromocodeRequestDto} updatePromocodeRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminPromocodesReusableApi
   */
  public adminPromocodesControllerUpdatePromocodeById(
    id: string,
    updatePromocodeRequestDto: UpdatePromocodeRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminPromocodesReusableApiFp(this.configuration)
      .adminPromocodesControllerUpdatePromocodeById(
        id,
        updatePromocodeRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminPromotedCompaniesApi - axios parameter creator
 * @export
 */
export const AdminPromotedCompaniesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get session statistics
     * @param {string} [promoted]
     * @param {boolean} [isPromoted]
     * @param {boolean} [isHavePromoCode]
     * @param {Array<'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'>} [payoutStatuses]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPromoteControllerGetSessionStatistics: async (
      promoted?: string,
      isPromoted?: boolean,
      isHavePromoCode?: boolean,
      payoutStatuses?: Array<
        'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'
      >,
      startDate?: string,
      endDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/promote`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (promoted !== undefined) {
        localVarQueryParameter['promoted'] = promoted;
      }

      if (isPromoted !== undefined) {
        localVarQueryParameter['isPromoted'] = isPromoted;
      }

      if (isHavePromoCode !== undefined) {
        localVarQueryParameter['isHavePromoCode'] = isHavePromoCode;
      }

      if (payoutStatuses) {
        localVarQueryParameter['payoutStatuses'] = payoutStatuses;
      }

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString()
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString()
            : endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminPromotedCompaniesApi - functional programming interface
 * @export
 */
export const AdminPromotedCompaniesApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    AdminPromotedCompaniesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get session statistics
     * @param {string} [promoted]
     * @param {boolean} [isPromoted]
     * @param {boolean} [isHavePromoCode]
     * @param {Array<'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'>} [payoutStatuses]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminPromoteControllerGetSessionStatistics(
      promoted?: string,
      isPromoted?: boolean,
      isHavePromoCode?: boolean,
      payoutStatuses?: Array<
        'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'
      >,
      startDate?: string,
      endDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminPromoteControllerGetSessionStatistics(
          promoted,
          isPromoted,
          isHavePromoCode,
          payoutStatuses,
          startDate,
          endDate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminPromotedCompaniesApi - factory interface
 * @export
 */
export const AdminPromotedCompaniesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminPromotedCompaniesApiFp(configuration);
  return {
    /**
     *
     * @summary Get session statistics
     * @param {string} [promoted]
     * @param {boolean} [isPromoted]
     * @param {boolean} [isHavePromoCode]
     * @param {Array<'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'>} [payoutStatuses]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminPromoteControllerGetSessionStatistics(
      promoted?: string,
      isPromoted?: boolean,
      isHavePromoCode?: boolean,
      payoutStatuses?: Array<
        'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'
      >,
      startDate?: string,
      endDate?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .adminPromoteControllerGetSessionStatistics(
          promoted,
          isPromoted,
          isHavePromoCode,
          payoutStatuses,
          startDate,
          endDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminPromotedCompaniesApi - object-oriented interface
 * @export
 * @class AdminPromotedCompaniesApi
 * @extends {BaseAPI}
 */
export class AdminPromotedCompaniesApi extends BaseAPI {
  /**
   *
   * @summary Get session statistics
   * @param {string} [promoted]
   * @param {boolean} [isPromoted]
   * @param {boolean} [isHavePromoCode]
   * @param {Array<'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'>} [payoutStatuses]
   * @param {string} [startDate]
   * @param {string} [endDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminPromotedCompaniesApi
   */
  public adminPromoteControllerGetSessionStatistics(
    promoted?: string,
    isPromoted?: boolean,
    isHavePromoCode?: boolean,
    payoutStatuses?: Array<
      'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'
    >,
    startDate?: string,
    endDate?: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminPromotedCompaniesApiFp(this.configuration)
      .adminPromoteControllerGetSessionStatistics(
        promoted,
        isPromoted,
        isHavePromoCode,
        payoutStatuses,
        startDate,
        endDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminSearchStatisticsApi - axios parameter creator
 * @export
 */
export const AdminSearchStatisticsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Resolve search log statistics
     * @param {GetSearchStatisticsRequestDto} getSearchStatisticsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSearchStatisticsControllerGetSearchStatistics: async (
      getSearchStatisticsRequestDto: GetSearchStatisticsRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getSearchStatisticsRequestDto' is not null or undefined
      assertParamExists(
        'adminSearchStatisticsControllerGetSearchStatistics',
        'getSearchStatisticsRequestDto',
        getSearchStatisticsRequestDto,
      );
      const localVarPath = `/api/v1/admin/search-statistics`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getSearchStatisticsRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminSearchStatisticsApi - functional programming interface
 * @export
 */
export const AdminSearchStatisticsApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    AdminSearchStatisticsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Resolve search log statistics
     * @param {GetSearchStatisticsRequestDto} getSearchStatisticsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSearchStatisticsControllerGetSearchStatistics(
      getSearchStatisticsRequestDto: GetSearchStatisticsRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SearchStatisticsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminSearchStatisticsControllerGetSearchStatistics(
          getSearchStatisticsRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminSearchStatisticsApi - factory interface
 * @export
 */
export const AdminSearchStatisticsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminSearchStatisticsApiFp(configuration);
  return {
    /**
     *
     * @summary Resolve search log statistics
     * @param {GetSearchStatisticsRequestDto} getSearchStatisticsRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSearchStatisticsControllerGetSearchStatistics(
      getSearchStatisticsRequestDto: GetSearchStatisticsRequestDto,
      options?: any,
    ): AxiosPromise<SearchStatisticsResponseDto> {
      return localVarFp
        .adminSearchStatisticsControllerGetSearchStatistics(
          getSearchStatisticsRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminSearchStatisticsApi - object-oriented interface
 * @export
 * @class AdminSearchStatisticsApi
 * @extends {BaseAPI}
 */
export class AdminSearchStatisticsApi extends BaseAPI {
  /**
   *
   * @summary Resolve search log statistics
   * @param {GetSearchStatisticsRequestDto} getSearchStatisticsRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSearchStatisticsApi
   */
  public adminSearchStatisticsControllerGetSearchStatistics(
    getSearchStatisticsRequestDto: GetSearchStatisticsRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminSearchStatisticsApiFp(this.configuration)
      .adminSearchStatisticsControllerGetSearchStatistics(
        getSearchStatisticsRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminSessionsApi - axios parameter creator
 * @export
 */
export const AdminSessionsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Cancel session payment
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSessionsControllerCancelSessionPayment: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'adminSessionsControllerCancelSessionPayment',
        'id',
        id,
      );
      const localVarPath = `/api/v1/admin/sessions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get therapist session by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSessionsControllerGetSessionById: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminSessionsControllerGetSessionById', 'id', id);
      const localVarPath = `/api/v1/admin/sessions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get therapist sessions, order by startDate
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [therapistId]
     * @param {'awaiting' | 'live' | 'complete' | 'canceled'} [status]
     * @param {string} [patientId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSessionsControllerGetSessions: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      therapistId?: string,
      status?: 'awaiting' | 'live' | 'complete' | 'canceled',
      patientId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('adminSessionsControllerGetSessions', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('adminSessionsControllerGetSessions', 'page', page);
      const localVarPath = `/api/v1/admin/sessions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (therapistId !== undefined) {
        localVarQueryParameter['therapistId'] = therapistId;
      }

      if (status !== undefined) {
        localVarQueryParameter['status'] = status;
      }

      if (patientId !== undefined) {
        localVarQueryParameter['patientId'] = patientId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Set session payment as paid for promocode
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSessionsControllerSetPaidForPromocode: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminSessionsControllerSetPaidForPromocode', 'id', id);
      const localVarPath = `/api/v1/admin/sessions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminSessionsApi - functional programming interface
 * @export
 */
export const AdminSessionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AdminSessionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Cancel session payment
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSessionsControllerCancelSessionPayment(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaymentResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminSessionsControllerCancelSessionPayment(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get therapist session by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSessionsControllerGetSessionById(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminSessionResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminSessionsControllerGetSessionById(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get therapist sessions, order by startDate
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [therapistId]
     * @param {'awaiting' | 'live' | 'complete' | 'canceled'} [status]
     * @param {string} [patientId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSessionsControllerGetSessions(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      therapistId?: string,
      status?: 'awaiting' | 'live' | 'complete' | 'canceled',
      patientId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminSessionsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminSessionsControllerGetSessions(
          limit,
          page,
          order,
          therapistId,
          status,
          patientId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Set session payment as paid for promocode
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminSessionsControllerSetPaidForPromocode(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaymentResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminSessionsControllerSetPaidForPromocode(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminSessionsApi - factory interface
 * @export
 */
export const AdminSessionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminSessionsApiFp(configuration);
  return {
    /**
     *
     * @summary Cancel session payment
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSessionsControllerCancelSessionPayment(
      id: string,
      options?: any,
    ): AxiosPromise<PaymentResponseDto> {
      return localVarFp
        .adminSessionsControllerCancelSessionPayment(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get therapist session by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSessionsControllerGetSessionById(
      id: string,
      options?: any,
    ): AxiosPromise<AdminSessionResponseDto> {
      return localVarFp
        .adminSessionsControllerGetSessionById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get therapist sessions, order by startDate
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [therapistId]
     * @param {'awaiting' | 'live' | 'complete' | 'canceled'} [status]
     * @param {string} [patientId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSessionsControllerGetSessions(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      therapistId?: string,
      status?: 'awaiting' | 'live' | 'complete' | 'canceled',
      patientId?: string,
      options?: any,
    ): AxiosPromise<AdminSessionsResponseDto> {
      return localVarFp
        .adminSessionsControllerGetSessions(
          limit,
          page,
          order,
          therapistId,
          status,
          patientId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Set session payment as paid for promocode
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminSessionsControllerSetPaidForPromocode(
      id: string,
      options?: any,
    ): AxiosPromise<PaymentResponseDto> {
      return localVarFp
        .adminSessionsControllerSetPaidForPromocode(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminSessionsApi - object-oriented interface
 * @export
 * @class AdminSessionsApi
 * @extends {BaseAPI}
 */
export class AdminSessionsApi extends BaseAPI {
  /**
   *
   * @summary Cancel session payment
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSessionsApi
   */
  public adminSessionsControllerCancelSessionPayment(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminSessionsApiFp(this.configuration)
      .adminSessionsControllerCancelSessionPayment(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get therapist session by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSessionsApi
   */
  public adminSessionsControllerGetSessionById(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminSessionsApiFp(this.configuration)
      .adminSessionsControllerGetSessionById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get therapist sessions, order by startDate
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {string} [therapistId]
   * @param {'awaiting' | 'live' | 'complete' | 'canceled'} [status]
   * @param {string} [patientId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSessionsApi
   */
  public adminSessionsControllerGetSessions(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    therapistId?: string,
    status?: 'awaiting' | 'live' | 'complete' | 'canceled',
    patientId?: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminSessionsApiFp(this.configuration)
      .adminSessionsControllerGetSessions(
        limit,
        page,
        order,
        therapistId,
        status,
        patientId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Set session payment as paid for promocode
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminSessionsApi
   */
  public adminSessionsControllerSetPaidForPromocode(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminSessionsApiFp(this.configuration)
      .adminSessionsControllerSetPaidForPromocode(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminStatisticsApi - axios parameter creator
 * @export
 */
export const AdminStatisticsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get session statistics for one patient by id
     * @param {string} patientId
     * @param {string} startDate
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStatisticsControllerGetPatientSessionStatistics: async (
      patientId: string,
      startDate: string,
      endDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists(
        'adminStatisticsControllerGetPatientSessionStatistics',
        'patientId',
        patientId,
      );
      // verify required parameter 'startDate' is not null or undefined
      assertParamExists(
        'adminStatisticsControllerGetPatientSessionStatistics',
        'startDate',
        startDate,
      );
      const localVarPath =
        `/api/v1/admin/statistics/sessions/patient/{patientId}`.replace(
          `{${'patientId'}}`,
          encodeURIComponent(String(patientId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString()
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString()
            : endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get session statistics
     * @param {string} startDate
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStatisticsControllerGetSessionStatistics: async (
      startDate: string,
      endDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      assertParamExists(
        'adminStatisticsControllerGetSessionStatistics',
        'startDate',
        startDate,
      );
      const localVarPath = `/api/v1/admin/statistics/sessions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString()
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString()
            : endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get session statistics for one patient by id
     * @param {string} startDate
     * @param {string} [endDate]
     * @param {Array<string>} [therapistIds]
     * @param {Array<'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'>} [payoutStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStatisticsControllerGetTherapistPromocodeStatistics: async (
      startDate: string,
      endDate?: string,
      therapistIds?: Array<string>,
      payoutStatuses?: Array<
        'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'
      >,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'startDate' is not null or undefined
      assertParamExists(
        'adminStatisticsControllerGetTherapistPromocodeStatistics',
        'startDate',
        startDate,
      );
      const localVarPath = `/api/v1/admin/statistics/therapists/promocodes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString()
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString()
            : endDate;
      }

      if (therapistIds) {
        localVarQueryParameter['therapistIds'] = therapistIds;
      }

      if (payoutStatuses) {
        localVarQueryParameter['payoutStatuses'] = payoutStatuses;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get session statistics for one therapist by id
     * @param {string} therapistId
     * @param {string} startDate
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStatisticsControllerGetTherapistSessionStatistics: async (
      therapistId: string,
      startDate: string,
      endDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'therapistId' is not null or undefined
      assertParamExists(
        'adminStatisticsControllerGetTherapistSessionStatistics',
        'therapistId',
        therapistId,
      );
      // verify required parameter 'startDate' is not null or undefined
      assertParamExists(
        'adminStatisticsControllerGetTherapistSessionStatistics',
        'startDate',
        startDate,
      );
      const localVarPath =
        `/api/v1/admin/statistics/sessions/therapist/{therapistId}`.replace(
          `{${'therapistId'}}`,
          encodeURIComponent(String(therapistId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (startDate !== undefined) {
        localVarQueryParameter['startDate'] =
          (startDate as any) instanceof Date
            ? (startDate as any).toISOString()
            : startDate;
      }

      if (endDate !== undefined) {
        localVarQueryParameter['endDate'] =
          (endDate as any) instanceof Date
            ? (endDate as any).toISOString()
            : endDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminStatisticsApi - functional programming interface
 * @export
 */
export const AdminStatisticsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AdminStatisticsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get session statistics for one patient by id
     * @param {string} patientId
     * @param {string} startDate
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminStatisticsControllerGetPatientSessionStatistics(
      patientId: string,
      startDate: string,
      endDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionStatisticsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminStatisticsControllerGetPatientSessionStatistics(
          patientId,
          startDate,
          endDate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get session statistics
     * @param {string} startDate
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminStatisticsControllerGetSessionStatistics(
      startDate: string,
      endDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionStatisticsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminStatisticsControllerGetSessionStatistics(
          startDate,
          endDate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get session statistics for one patient by id
     * @param {string} startDate
     * @param {string} [endDate]
     * @param {Array<string>} [therapistIds]
     * @param {Array<'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'>} [payoutStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminStatisticsControllerGetTherapistPromocodeStatistics(
      startDate: string,
      endDate?: string,
      therapistIds?: Array<string>,
      payoutStatuses?: Array<
        'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'
      >,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminStatisticsControllerGetTherapistPromocodeStatistics(
          startDate,
          endDate,
          therapistIds,
          payoutStatuses,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get session statistics for one therapist by id
     * @param {string} therapistId
     * @param {string} startDate
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminStatisticsControllerGetTherapistSessionStatistics(
      therapistId: string,
      startDate: string,
      endDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionStatisticsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminStatisticsControllerGetTherapistSessionStatistics(
          therapistId,
          startDate,
          endDate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminStatisticsApi - factory interface
 * @export
 */
export const AdminStatisticsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminStatisticsApiFp(configuration);
  return {
    /**
     *
     * @summary Get session statistics for one patient by id
     * @param {string} patientId
     * @param {string} startDate
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStatisticsControllerGetPatientSessionStatistics(
      patientId: string,
      startDate: string,
      endDate?: string,
      options?: any,
    ): AxiosPromise<SessionStatisticsResponseDto> {
      return localVarFp
        .adminStatisticsControllerGetPatientSessionStatistics(
          patientId,
          startDate,
          endDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get session statistics
     * @param {string} startDate
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStatisticsControllerGetSessionStatistics(
      startDate: string,
      endDate?: string,
      options?: any,
    ): AxiosPromise<SessionStatisticsResponseDto> {
      return localVarFp
        .adminStatisticsControllerGetSessionStatistics(
          startDate,
          endDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get session statistics for one patient by id
     * @param {string} startDate
     * @param {string} [endDate]
     * @param {Array<string>} [therapistIds]
     * @param {Array<'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'>} [payoutStatuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStatisticsControllerGetTherapistPromocodeStatistics(
      startDate: string,
      endDate?: string,
      therapistIds?: Array<string>,
      payoutStatuses?: Array<
        'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'
      >,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .adminStatisticsControllerGetTherapistPromocodeStatistics(
          startDate,
          endDate,
          therapistIds,
          payoutStatuses,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get session statistics for one therapist by id
     * @param {string} therapistId
     * @param {string} startDate
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminStatisticsControllerGetTherapistSessionStatistics(
      therapistId: string,
      startDate: string,
      endDate?: string,
      options?: any,
    ): AxiosPromise<SessionStatisticsResponseDto> {
      return localVarFp
        .adminStatisticsControllerGetTherapistSessionStatistics(
          therapistId,
          startDate,
          endDate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminStatisticsApi - object-oriented interface
 * @export
 * @class AdminStatisticsApi
 * @extends {BaseAPI}
 */
export class AdminStatisticsApi extends BaseAPI {
  /**
   *
   * @summary Get session statistics for one patient by id
   * @param {string} patientId
   * @param {string} startDate
   * @param {string} [endDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStatisticsApi
   */
  public adminStatisticsControllerGetPatientSessionStatistics(
    patientId: string,
    startDate: string,
    endDate?: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminStatisticsApiFp(this.configuration)
      .adminStatisticsControllerGetPatientSessionStatistics(
        patientId,
        startDate,
        endDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get session statistics
   * @param {string} startDate
   * @param {string} [endDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStatisticsApi
   */
  public adminStatisticsControllerGetSessionStatistics(
    startDate: string,
    endDate?: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminStatisticsApiFp(this.configuration)
      .adminStatisticsControllerGetSessionStatistics(
        startDate,
        endDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get session statistics for one patient by id
   * @param {string} startDate
   * @param {string} [endDate]
   * @param {Array<string>} [therapistIds]
   * @param {Array<'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'>} [payoutStatuses]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStatisticsApi
   */
  public adminStatisticsControllerGetTherapistPromocodeStatistics(
    startDate: string,
    endDate?: string,
    therapistIds?: Array<string>,
    payoutStatuses?: Array<
      'awaiting' | 'pending' | 'awaiting_promocode' | 'succeeded' | 'canceled'
    >,
    options?: AxiosRequestConfig,
  ) {
    return AdminStatisticsApiFp(this.configuration)
      .adminStatisticsControllerGetTherapistPromocodeStatistics(
        startDate,
        endDate,
        therapistIds,
        payoutStatuses,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get session statistics for one therapist by id
   * @param {string} therapistId
   * @param {string} startDate
   * @param {string} [endDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminStatisticsApi
   */
  public adminStatisticsControllerGetTherapistSessionStatistics(
    therapistId: string,
    startDate: string,
    endDate?: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminStatisticsApiFp(this.configuration)
      .adminStatisticsControllerGetTherapistSessionStatistics(
        therapistId,
        startDate,
        endDate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminTherapistsApi - axios parameter creator
 * @export
 */
export const AdminTherapistsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Accept profile update
     * @summary Accept profile update
     * @param {string} therapistProfileUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerAcceptProfileUpdate: async (
      therapistProfileUpdateId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'therapistProfileUpdateId' is not null or undefined
      assertParamExists(
        'adminTherapistsControllerAcceptProfileUpdate',
        'therapistProfileUpdateId',
        therapistProfileUpdateId,
      );
      const localVarPath =
        `/api/v1/admin/therapists/profile-updates/{therapistProfileUpdateId}/accept`.replace(
          `{${'therapistProfileUpdateId'}}`,
          encodeURIComponent(String(therapistProfileUpdateId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Approve therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerApproveTherapist: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminTherapistsControllerApproveTherapist', 'id', id);
      const localVarPath = `/api/v1/admin/therapists/approve/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Ban therapist by id
     * @summary Ban therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerBanTherapist: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminTherapistsControllerBanTherapist', 'id', id);
      const localVarPath = `/api/v1/admin/therapists/ban/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get profile update by id
     * @summary Get profile update by id
     * @param {string} therapistProfileUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerGetProfileUpdateById: async (
      therapistProfileUpdateId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'therapistProfileUpdateId' is not null or undefined
      assertParamExists(
        'adminTherapistsControllerGetProfileUpdateById',
        'therapistProfileUpdateId',
        therapistProfileUpdateId,
      );
      const localVarPath =
        `/api/v1/admin/therapists/profile-updates/{therapistProfileUpdateId}`.replace(
          `{${'therapistProfileUpdateId'}}`,
          encodeURIComponent(String(therapistProfileUpdateId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get profile updates, sorting by update\'s createdAt
     * @summary Get profile updates, sorting by update\'s createdAt
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerGetProfileUpdates: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists(
        'adminTherapistsControllerGetProfileUpdates',
        'limit',
        limit,
      );
      // verify required parameter 'page' is not null or undefined
      assertParamExists(
        'adminTherapistsControllerGetProfileUpdates',
        'page',
        page,
      );
      const localVarPath = `/api/v1/admin/therapists/profile-updates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get therapist by id
     * @summary Get therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerGetTherapistById: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminTherapistsControllerGetTherapistById', 'id', id);
      const localVarPath = `/api/v1/admin/therapists/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get therapist request by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerGetTherapistRequestByUserId: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'adminTherapistsControllerGetTherapistRequestByUserId',
        'id',
        id,
      );
      const localVarPath = `/api/v1/admin/therapists/requests/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get therapist requests, sorting by createdAt
     * @summary Get therapists
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerGetTherapistRequests: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists(
        'adminTherapistsControllerGetTherapistRequests',
        'limit',
        limit,
      );
      // verify required parameter 'page' is not null or undefined
      assertParamExists(
        'adminTherapistsControllerGetTherapistRequests',
        'page',
        page,
      );
      const localVarPath = `/api/v1/admin/therapists/requests`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get therapists
     * @summary Get therapists
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'createdAt' | 'lastname'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerGetTherapists: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'createdAt' | 'lastname',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists(
        'adminTherapistsControllerGetTherapists',
        'limit',
        limit,
      );
      // verify required parameter 'page' is not null or undefined
      assertParamExists('adminTherapistsControllerGetTherapists', 'page', page);
      const localVarPath = `/api/v1/admin/therapists`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (orderTerms !== undefined) {
        localVarQueryParameter['orderTerms'] = orderTerms;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reject therapist profile update
     * @param {string} therapistProfileUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerRejectProfileUpdate: async (
      therapistProfileUpdateId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'therapistProfileUpdateId' is not null or undefined
      assertParamExists(
        'adminTherapistsControllerRejectProfileUpdate',
        'therapistProfileUpdateId',
        therapistProfileUpdateId,
      );
      const localVarPath =
        `/api/v1/admin/therapists/profile-updates/{therapistProfileUpdateId}/reject`.replace(
          `{${'therapistProfileUpdateId'}}`,
          encodeURIComponent(String(therapistProfileUpdateId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Verify therapist
     * @param {string} id
     * @param {AdminRejectTherapistRequestDto} adminRejectTherapistRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerRejectTherapist: async (
      id: string,
      adminRejectTherapistRequestDto: AdminRejectTherapistRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminTherapistsControllerRejectTherapist', 'id', id);
      // verify required parameter 'adminRejectTherapistRequestDto' is not null or undefined
      assertParamExists(
        'adminTherapistsControllerRejectTherapist',
        'adminRejectTherapistRequestDto',
        adminRejectTherapistRequestDto,
      );
      const localVarPath =
        `/api/v1/admin/therapists/requests/{id}/reject`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminRejectTherapistRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Unapprove therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerUnapproveTherapist: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'adminTherapistsControllerUnapproveTherapist',
        'id',
        id,
      );
      const localVarPath = `/api/v1/admin/therapists/unapprove/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Unban therapist by id
     * @summary Unban therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerUnbanTherapist: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminTherapistsControllerUnbanTherapist', 'id', id);
      const localVarPath = `/api/v1/admin/therapists/unban/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Verify therapist by request id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerVerifyTherapist: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminTherapistsControllerVerifyTherapist', 'id', id);
      const localVarPath =
        `/api/v1/admin/therapists/requests/{id}/verify`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminTherapistsApi - functional programming interface
 * @export
 */
export const AdminTherapistsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    AdminTherapistsApiAxiosParamCreator(configuration);
  return {
    /**
     * Accept profile update
     * @summary Accept profile update
     * @param {string} therapistProfileUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapistsControllerAcceptProfileUpdate(
      therapistProfileUpdateId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapistProfileUpdateResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapistsControllerAcceptProfileUpdate(
          therapistProfileUpdateId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Approve therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapistsControllerApproveTherapist(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapistsControllerApproveTherapist(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Ban therapist by id
     * @summary Ban therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapistsControllerBanTherapist(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapistsControllerBanTherapist(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get profile update by id
     * @summary Get profile update by id
     * @param {string} therapistProfileUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapistsControllerGetProfileUpdateById(
      therapistProfileUpdateId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminTherapistProfileUpdateResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapistsControllerGetProfileUpdateById(
          therapistProfileUpdateId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get profile updates, sorting by update\'s createdAt
     * @summary Get profile updates, sorting by update\'s createdAt
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapistsControllerGetProfileUpdates(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminTherapistProfileUpdatesResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapistsControllerGetProfileUpdates(
          limit,
          page,
          order,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get therapist by id
     * @summary Get therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapistsControllerGetTherapistById(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminTherapistResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapistsControllerGetTherapistById(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get therapist request by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapistsControllerGetTherapistRequestByUserId(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminTherapistRequestResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapistsControllerGetTherapistRequestByUserId(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get therapist requests, sorting by createdAt
     * @summary Get therapists
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapistsControllerGetTherapistRequests(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminTherapistRequestsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapistsControllerGetTherapistRequests(
          limit,
          page,
          order,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get therapists
     * @summary Get therapists
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'createdAt' | 'lastname'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapistsControllerGetTherapists(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'createdAt' | 'lastname',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminTherapistsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapistsControllerGetTherapists(
          limit,
          page,
          order,
          orderTerms,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Reject therapist profile update
     * @param {string} therapistProfileUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapistsControllerRejectProfileUpdate(
      therapistProfileUpdateId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapistProfileUpdateResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapistsControllerRejectProfileUpdate(
          therapistProfileUpdateId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Verify therapist
     * @param {string} id
     * @param {AdminRejectTherapistRequestDto} adminRejectTherapistRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapistsControllerRejectTherapist(
      id: string,
      adminRejectTherapistRequestDto: AdminRejectTherapistRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapistsControllerRejectTherapist(
          id,
          adminRejectTherapistRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Unapprove therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapistsControllerUnapproveTherapist(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapistsControllerUnapproveTherapist(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Unban therapist by id
     * @summary Unban therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapistsControllerUnbanTherapist(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapistsControllerUnbanTherapist(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Verify therapist by request id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminTherapistsControllerVerifyTherapist(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminTherapistsControllerVerifyTherapist(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminTherapistsApi - factory interface
 * @export
 */
export const AdminTherapistsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminTherapistsApiFp(configuration);
  return {
    /**
     * Accept profile update
     * @summary Accept profile update
     * @param {string} therapistProfileUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerAcceptProfileUpdate(
      therapistProfileUpdateId: string,
      options?: any,
    ): AxiosPromise<TherapistProfileUpdateResponseDto> {
      return localVarFp
        .adminTherapistsControllerAcceptProfileUpdate(
          therapistProfileUpdateId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Approve therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerApproveTherapist(
      id: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .adminTherapistsControllerApproveTherapist(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Ban therapist by id
     * @summary Ban therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerBanTherapist(
      id: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminTherapistsControllerBanTherapist(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get profile update by id
     * @summary Get profile update by id
     * @param {string} therapistProfileUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerGetProfileUpdateById(
      therapistProfileUpdateId: string,
      options?: any,
    ): AxiosPromise<AdminTherapistProfileUpdateResponseDto> {
      return localVarFp
        .adminTherapistsControllerGetProfileUpdateById(
          therapistProfileUpdateId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get profile updates, sorting by update\'s createdAt
     * @summary Get profile updates, sorting by update\'s createdAt
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerGetProfileUpdates(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      options?: any,
    ): AxiosPromise<AdminTherapistProfileUpdatesResponseDto> {
      return localVarFp
        .adminTherapistsControllerGetProfileUpdates(limit, page, order, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get therapist by id
     * @summary Get therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerGetTherapistById(
      id: string,
      options?: any,
    ): AxiosPromise<AdminTherapistResponseDto> {
      return localVarFp
        .adminTherapistsControllerGetTherapistById(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get therapist request by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerGetTherapistRequestByUserId(
      id: string,
      options?: any,
    ): AxiosPromise<AdminTherapistRequestResponseDto> {
      return localVarFp
        .adminTherapistsControllerGetTherapistRequestByUserId(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get therapist requests, sorting by createdAt
     * @summary Get therapists
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerGetTherapistRequests(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      options?: any,
    ): AxiosPromise<AdminTherapistRequestsResponseDto> {
      return localVarFp
        .adminTherapistsControllerGetTherapistRequests(
          limit,
          page,
          order,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get therapists
     * @summary Get therapists
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'createdAt' | 'lastname'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerGetTherapists(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'createdAt' | 'lastname',
      options?: any,
    ): AxiosPromise<AdminTherapistsResponseDto> {
      return localVarFp
        .adminTherapistsControllerGetTherapists(
          limit,
          page,
          order,
          orderTerms,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reject therapist profile update
     * @param {string} therapistProfileUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerRejectProfileUpdate(
      therapistProfileUpdateId: string,
      options?: any,
    ): AxiosPromise<TherapistProfileUpdateResponseDto> {
      return localVarFp
        .adminTherapistsControllerRejectProfileUpdate(
          therapistProfileUpdateId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Verify therapist
     * @param {string} id
     * @param {AdminRejectTherapistRequestDto} adminRejectTherapistRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerRejectTherapist(
      id: string,
      adminRejectTherapistRequestDto: AdminRejectTherapistRequestDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .adminTherapistsControllerRejectTherapist(
          id,
          adminRejectTherapistRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Unapprove therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerUnapproveTherapist(
      id: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .adminTherapistsControllerUnapproveTherapist(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Unban therapist by id
     * @summary Unban therapist by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerUnbanTherapist(
      id: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminTherapistsControllerUnbanTherapist(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Verify therapist by request id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminTherapistsControllerVerifyTherapist(
      id: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .adminTherapistsControllerVerifyTherapist(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminTherapistsApi - object-oriented interface
 * @export
 * @class AdminTherapistsApi
 * @extends {BaseAPI}
 */
export class AdminTherapistsApi extends BaseAPI {
  /**
   * Accept profile update
   * @summary Accept profile update
   * @param {string} therapistProfileUpdateId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminTherapistsApi
   */
  public adminTherapistsControllerAcceptProfileUpdate(
    therapistProfileUpdateId: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminTherapistsApiFp(this.configuration)
      .adminTherapistsControllerAcceptProfileUpdate(
        therapistProfileUpdateId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Approve therapist by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminTherapistsApi
   */
  public adminTherapistsControllerApproveTherapist(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminTherapistsApiFp(this.configuration)
      .adminTherapistsControllerApproveTherapist(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Ban therapist by id
   * @summary Ban therapist by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminTherapistsApi
   */
  public adminTherapistsControllerBanTherapist(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminTherapistsApiFp(this.configuration)
      .adminTherapistsControllerBanTherapist(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get profile update by id
   * @summary Get profile update by id
   * @param {string} therapistProfileUpdateId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminTherapistsApi
   */
  public adminTherapistsControllerGetProfileUpdateById(
    therapistProfileUpdateId: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminTherapistsApiFp(this.configuration)
      .adminTherapistsControllerGetProfileUpdateById(
        therapistProfileUpdateId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get profile updates, sorting by update\'s createdAt
   * @summary Get profile updates, sorting by update\'s createdAt
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminTherapistsApi
   */
  public adminTherapistsControllerGetProfileUpdates(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    options?: AxiosRequestConfig,
  ) {
    return AdminTherapistsApiFp(this.configuration)
      .adminTherapistsControllerGetProfileUpdates(limit, page, order, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get therapist by id
   * @summary Get therapist by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminTherapistsApi
   */
  public adminTherapistsControllerGetTherapistById(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminTherapistsApiFp(this.configuration)
      .adminTherapistsControllerGetTherapistById(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get therapist request by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminTherapistsApi
   */
  public adminTherapistsControllerGetTherapistRequestByUserId(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminTherapistsApiFp(this.configuration)
      .adminTherapistsControllerGetTherapistRequestByUserId(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get therapist requests, sorting by createdAt
   * @summary Get therapists
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminTherapistsApi
   */
  public adminTherapistsControllerGetTherapistRequests(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    options?: AxiosRequestConfig,
  ) {
    return AdminTherapistsApiFp(this.configuration)
      .adminTherapistsControllerGetTherapistRequests(
        limit,
        page,
        order,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get therapists
   * @summary Get therapists
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {'createdAt' | 'lastname'} [orderTerms]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminTherapistsApi
   */
  public adminTherapistsControllerGetTherapists(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    orderTerms?: 'createdAt' | 'lastname',
    options?: AxiosRequestConfig,
  ) {
    return AdminTherapistsApiFp(this.configuration)
      .adminTherapistsControllerGetTherapists(
        limit,
        page,
        order,
        orderTerms,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reject therapist profile update
   * @param {string} therapistProfileUpdateId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminTherapistsApi
   */
  public adminTherapistsControllerRejectProfileUpdate(
    therapistProfileUpdateId: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminTherapistsApiFp(this.configuration)
      .adminTherapistsControllerRejectProfileUpdate(
        therapistProfileUpdateId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Verify therapist
   * @param {string} id
   * @param {AdminRejectTherapistRequestDto} adminRejectTherapistRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminTherapistsApi
   */
  public adminTherapistsControllerRejectTherapist(
    id: string,
    adminRejectTherapistRequestDto: AdminRejectTherapistRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminTherapistsApiFp(this.configuration)
      .adminTherapistsControllerRejectTherapist(
        id,
        adminRejectTherapistRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Unapprove therapist by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminTherapistsApi
   */
  public adminTherapistsControllerUnapproveTherapist(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminTherapistsApiFp(this.configuration)
      .adminTherapistsControllerUnapproveTherapist(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Unban therapist by id
   * @summary Unban therapist by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminTherapistsApi
   */
  public adminTherapistsControllerUnbanTherapist(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminTherapistsApiFp(this.configuration)
      .adminTherapistsControllerUnbanTherapist(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Verify therapist by request id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminTherapistsApi
   */
  public adminTherapistsControllerVerifyTherapist(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return AdminTherapistsApiFp(this.configuration)
      .adminTherapistsControllerVerifyTherapist(id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AdminsApi - axios parameter creator
 * @export
 */
export const AdminsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateAdminRequestDto} createAdminRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCreateAdmin: async (
      createAdminRequestDto: CreateAdminRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createAdminRequestDto' is not null or undefined
      assertParamExists(
        'adminControllerCreateAdmin',
        'createAdminRequestDto',
        createAdminRequestDto,
      );
      const localVarPath = `/api/v1/admin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAdminRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerDeleteAdmin: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('adminControllerDeleteAdmin', 'id', id);
      const localVarPath = `/api/v1/admin/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetAdmins: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get api key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiKeysControllerGetApiKey: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/api-keys`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update api key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiKeysControllerUpdateApiKey: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/admin/api-keys`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminsApi - functional programming interface
 * @export
 */
export const AdminsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateAdminRequestDto} createAdminRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerCreateAdmin(
      createAdminRequestDto: CreateAdminRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerCreateAdmin(
          createAdminRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerDeleteAdmin(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerDeleteAdmin(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adminControllerGetAdmins(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.adminControllerGetAdmins(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get api key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiKeysControllerGetApiKey(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetApiKeyResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiKeysControllerGetApiKey(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update api key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async apiKeysControllerUpdateApiKey(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.apiKeysControllerUpdateApiKey(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AdminsApi - factory interface
 * @export
 */
export const AdminsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdminsApiFp(configuration);
  return {
    /**
     *
     * @param {CreateAdminRequestDto} createAdminRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerCreateAdmin(
      createAdminRequestDto: CreateAdminRequestDto,
      options?: any,
    ): AxiosPromise<AdminResponseDto> {
      return localVarFp
        .adminControllerCreateAdmin(createAdminRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerDeleteAdmin(
      id: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .adminControllerDeleteAdmin(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGetAdmins(options?: any): AxiosPromise<AdminsResponseDto> {
      return localVarFp
        .adminControllerGetAdmins(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get api key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiKeysControllerGetApiKey(
      options?: any,
    ): AxiosPromise<GetApiKeyResponseDto> {
      return localVarFp
        .apiKeysControllerGetApiKey(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update api key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    apiKeysControllerUpdateApiKey(
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .apiKeysControllerUpdateApiKey(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdminsApi - object-oriented interface
 * @export
 * @class AdminsApi
 * @extends {BaseAPI}
 */
export class AdminsApi extends BaseAPI {
  /**
   *
   * @param {CreateAdminRequestDto} createAdminRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminsApi
   */
  public adminControllerCreateAdmin(
    createAdminRequestDto: CreateAdminRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AdminsApiFp(this.configuration)
      .adminControllerCreateAdmin(createAdminRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminsApi
   */
  public adminControllerDeleteAdmin(id: string, options?: AxiosRequestConfig) {
    return AdminsApiFp(this.configuration)
      .adminControllerDeleteAdmin(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminsApi
   */
  public adminControllerGetAdmins(options?: AxiosRequestConfig) {
    return AdminsApiFp(this.configuration)
      .adminControllerGetAdmins(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get api key
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminsApi
   */
  public apiKeysControllerGetApiKey(options?: AxiosRequestConfig) {
    return AdminsApiFp(this.configuration)
      .apiKeysControllerGetApiKey(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update api key
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminsApi
   */
  public apiKeysControllerUpdateApiKey(options?: AxiosRequestConfig) {
    return AdminsApiFp(this.configuration)
      .apiKeysControllerUpdateApiKey(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {AdminSignInDto} adminSignInDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerAdminSignIn: async (
      adminSignInDto: AdminSignInDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'adminSignInDto' is not null or undefined
      assertParamExists(
        'authControllerAdminSignIn',
        'adminSignInDto',
        adminSignInDto,
      );
      const localVarPath = `/api/v1/auth/admins/sign-in`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        adminSignInDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check if email is available
     * @param {CheckEmailRequestDto} checkEmailRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerCheckEmail: async (
      checkEmailRequestDto: CheckEmailRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'checkEmailRequestDto' is not null or undefined
      assertParamExists(
        'authControllerCheckEmail',
        'checkEmailRequestDto',
        checkEmailRequestDto,
      );
      const localVarPath = `/api/v1/auth/check/email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkEmailRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check if password is valid
     * @param {CheckPasswordRequestDto} checkPasswordRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerCheckPassword: async (
      checkPasswordRequestDto: CheckPasswordRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'checkPasswordRequestDto' is not null or undefined
      assertParamExists(
        'authControllerCheckPassword',
        'checkPasswordRequestDto',
        checkPasswordRequestDto,
      );
      const localVarPath = `/api/v1/auth/check/password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkPasswordRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check if phone is available
     * @param {CheckPhoneRequestDto} checkPhoneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerCheckPhone: async (
      checkPhoneRequestDto: CheckPhoneRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'checkPhoneRequestDto' is not null or undefined
      assertParamExists(
        'authControllerCheckPhone',
        'checkPhoneRequestDto',
        checkPhoneRequestDto,
      );
      const localVarPath = `/api/v1/auth/check/phone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkPhoneRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CheckPhoneNumberVerificationDto} checkPhoneNumberVerificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerCheckPhoneNumberVerification: async (
      checkPhoneNumberVerificationDto: CheckPhoneNumberVerificationDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'checkPhoneNumberVerificationDto' is not null or undefined
      assertParamExists(
        'authControllerCheckPhoneNumberVerification',
        'checkPhoneNumberVerificationDto',
        checkPhoneNumberVerificationDto,
      );
      const localVarPath = `/api/v1/auth/phone_number/check_verification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkPhoneNumberVerificationDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Confirm user email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerConfirmUserEmail: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/confirm_email/confirm`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerGetEmailConfirmationToken: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/confirm-email-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Change forgotten password
     * @param {RestorePasswordByEmailRequestDto} restorePasswordByEmailRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerRestorePasswordByJwtToken: async (
      restorePasswordByEmailRequestDto: RestorePasswordByEmailRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'restorePasswordByEmailRequestDto' is not null or undefined
      assertParamExists(
        'authControllerRestorePasswordByJwtToken',
        'restorePasswordByEmailRequestDto',
        restorePasswordByEmailRequestDto,
      );
      const localVarPath = `/api/v1/auth/forget_password/email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        restorePasswordByEmailRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Change forgotten password
     * @param {RestorePasswordByPhoneRequestDto} restorePasswordByPhoneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerRestorePasswordByPhone: async (
      restorePasswordByPhoneRequestDto: RestorePasswordByPhoneRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'restorePasswordByPhoneRequestDto' is not null or undefined
      assertParamExists(
        'authControllerRestorePasswordByPhone',
        'restorePasswordByPhoneRequestDto',
        restorePasswordByPhoneRequestDto,
      );
      const localVarPath = `/api/v1/auth/forget_password/phone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        restorePasswordByPhoneRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send url for change forgotten password
     * @param {SendPhoneNumberVerificationDto} sendPhoneNumberVerificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendChangeForgetPasswordCode: async (
      sendPhoneNumberVerificationDto: SendPhoneNumberVerificationDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendPhoneNumberVerificationDto' is not null or undefined
      assertParamExists(
        'authControllerSendChangeForgetPasswordCode',
        'sendPhoneNumberVerificationDto',
        sendPhoneNumberVerificationDto,
      );
      const localVarPath = `/api/v1/auth/forget_password/phone/send`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendPhoneNumberVerificationDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send url for change forgotten password
     * @param {UserEmailDto} userEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendChangeForgetPasswordUrl: async (
      userEmailDto: UserEmailDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userEmailDto' is not null or undefined
      assertParamExists(
        'authControllerSendChangeForgetPasswordUrl',
        'userEmailDto',
        userEmailDto,
      );
      const localVarPath = `/api/v1/auth/forget_password/email/send`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userEmailDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SendPhoneNumberVerificationDto} sendPhoneNumberVerificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendPhoneNumberVerification: async (
      sendPhoneNumberVerificationDto: SendPhoneNumberVerificationDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendPhoneNumberVerificationDto' is not null or undefined
      assertParamExists(
        'authControllerSendPhoneNumberVerification',
        'sendPhoneNumberVerificationDto',
        sendPhoneNumberVerificationDto,
      );
      const localVarPath = `/api/v1/auth/phone_number/send_verification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendPhoneNumberVerificationDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SendPhoneNumberVerificationDto} sendPhoneNumberVerificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendPhoneNumberVerificationForSignIn: async (
      sendPhoneNumberVerificationDto: SendPhoneNumberVerificationDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendPhoneNumberVerificationDto' is not null or undefined
      assertParamExists(
        'authControllerSendPhoneNumberVerificationForSignIn',
        'sendPhoneNumberVerificationDto',
        sendPhoneNumberVerificationDto,
      );
      const localVarPath = `/api/v1/auth/sign-in/phone/send_verification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendPhoneNumberVerificationDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send update email confirmation link
     * @param {SendUpdateEmailConfirmationRequestDto} sendUpdateEmailConfirmationRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendUpdateEmailConfirmation: async (
      sendUpdateEmailConfirmationRequestDto: SendUpdateEmailConfirmationRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendUpdateEmailConfirmationRequestDto' is not null or undefined
      assertParamExists(
        'authControllerSendUpdateEmailConfirmation',
        'sendUpdateEmailConfirmationRequestDto',
        sendUpdateEmailConfirmationRequestDto,
      );
      const localVarPath = `/api/v1/auth/email/send`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendUpdateEmailConfirmationRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send email confirmation
     * @param {UserEmailDto} userEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendUserConfirmationEmail: async (
      userEmailDto: UserEmailDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userEmailDto' is not null or undefined
      assertParamExists(
        'authControllerSendUserConfirmationEmail',
        'userEmailDto',
        userEmailDto,
      );
      const localVarPath = `/api/v1/auth/confirm_email/send`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userEmailDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserSignInDto} userSignInDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSignIn: async (
      userSignInDto: UserSignInDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userSignInDto' is not null or undefined
      assertParamExists('authControllerSignIn', 'userSignInDto', userSignInDto);
      const localVarPath = `/api/v1/auth/sign-in`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userSignInDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserPhoneSignInRequestDto} userPhoneSignInRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSignInByPhone: async (
      userPhoneSignInRequestDto: UserPhoneSignInRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userPhoneSignInRequestDto' is not null or undefined
      assertParamExists(
        'authControllerSignInByPhone',
        'userPhoneSignInRequestDto',
        userPhoneSignInRequestDto,
      );
      const localVarPath = `/api/v1/auth/sign-in/phone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userPhoneSignInRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserSignUpDto} userSignUpDto
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    authControllerSignUp: async (
      userSignUpDto: UserSignUpDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userSignUpDto' is not null or undefined
      assertParamExists('authControllerSignUp', 'userSignUpDto', userSignUpDto);
      const localVarPath = `/api/v1/auth/sign-up`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userSignUpDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserEmailSignUpRequestDto} userEmailSignUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSignUpWithEmail: async (
      userEmailSignUpRequestDto: UserEmailSignUpRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userEmailSignUpRequestDto' is not null or undefined
      assertParamExists(
        'authControllerSignUpWithEmail',
        'userEmailSignUpRequestDto',
        userEmailSignUpRequestDto,
      );
      const localVarPath = `/api/v1/auth/sign-up/email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userEmailSignUpRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserPhoneSignUpRequestDto} userPhoneSignUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSignUpWithPhone: async (
      userPhoneSignUpRequestDto: UserPhoneSignUpRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userPhoneSignUpRequestDto' is not null or undefined
      assertParamExists(
        'authControllerSignUpWithPhone',
        'userPhoneSignUpRequestDto',
        userPhoneSignUpRequestDto,
      );
      const localVarPath = `/api/v1/auth/sign-up/phone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userPhoneSignUpRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update email (require confirmation token from mail)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerUpdateEmail: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/auth/email`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * temporary
     * @summary Update email (only for user without email and password)
     * @param {UpdateEmailWithoutConfirmationRequestDto} updateEmailWithoutConfirmationRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerUpdateEmailWithNoConfirmation: async (
      updateEmailWithoutConfirmationRequestDto: UpdateEmailWithoutConfirmationRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateEmailWithoutConfirmationRequestDto' is not null or undefined
      assertParamExists(
        'authControllerUpdateEmailWithNoConfirmation',
        'updateEmailWithoutConfirmationRequestDto',
        updateEmailWithoutConfirmationRequestDto,
      );
      const localVarPath = `/api/v1/auth/email/with_no_confirmation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateEmailWithoutConfirmationRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update password
     * @param {UpdatePasswordRequestDto} updatePasswordRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerUpdatePassword: async (
      updatePasswordRequestDto: UpdatePasswordRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updatePasswordRequestDto' is not null or undefined
      assertParamExists(
        'authControllerUpdatePassword',
        'updatePasswordRequestDto',
        updatePasswordRequestDto,
      );
      const localVarPath = `/api/v1/auth/password`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePasswordRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update phone
     * @param {UpdatePhoneRequestDto} updatePhoneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerUpdatePhone: async (
      updatePhoneRequestDto: UpdatePhoneRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updatePhoneRequestDto' is not null or undefined
      assertParamExists(
        'authControllerUpdatePhone',
        'updatePhoneRequestDto',
        updatePhoneRequestDto,
      );
      const localVarPath = `/api/v1/auth/phone`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePhoneRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {AdminSignInDto} adminSignInDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerAdminSignIn(
      adminSignInDto: AdminSignInDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AdminWithTokenResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerAdminSignIn(
          adminSignInDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Check if email is available
     * @param {CheckEmailRequestDto} checkEmailRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerCheckEmail(
      checkEmailRequestDto: CheckEmailRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CheckAuthTermResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerCheckEmail(
          checkEmailRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Check if password is valid
     * @param {CheckPasswordRequestDto} checkPasswordRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerCheckPassword(
      checkPasswordRequestDto: CheckPasswordRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CheckAuthTermResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerCheckPassword(
          checkPasswordRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Check if phone is available
     * @param {CheckPhoneRequestDto} checkPhoneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerCheckPhone(
      checkPhoneRequestDto: CheckPhoneRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CheckAuthTermResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerCheckPhone(
          checkPhoneRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CheckPhoneNumberVerificationDto} checkPhoneNumberVerificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerCheckPhoneNumberVerification(
      checkPhoneNumberVerificationDto: CheckPhoneNumberVerificationDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CheckVerificationCodeDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerCheckPhoneNumberVerification(
          checkPhoneNumberVerificationDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Confirm user email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerConfirmUserEmail(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenAndMetadataResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerConfirmUserEmail(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerGetEmailConfirmationToken(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerGetEmailConfirmationToken(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Change forgotten password
     * @param {RestorePasswordByEmailRequestDto} restorePasswordByEmailRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerRestorePasswordByJwtToken(
      restorePasswordByEmailRequestDto: RestorePasswordByEmailRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerRestorePasswordByJwtToken(
          restorePasswordByEmailRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Change forgotten password
     * @param {RestorePasswordByPhoneRequestDto} restorePasswordByPhoneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerRestorePasswordByPhone(
      restorePasswordByPhoneRequestDto: RestorePasswordByPhoneRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerRestorePasswordByPhone(
          restorePasswordByPhoneRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Send url for change forgotten password
     * @param {SendPhoneNumberVerificationDto} sendPhoneNumberVerificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerSendChangeForgetPasswordCode(
      sendPhoneNumberVerificationDto: SendPhoneNumberVerificationDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SendingStatusDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerSendChangeForgetPasswordCode(
          sendPhoneNumberVerificationDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Send url for change forgotten password
     * @param {UserEmailDto} userEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerSendChangeForgetPasswordUrl(
      userEmailDto: UserEmailDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SendingStatusDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerSendChangeForgetPasswordUrl(
          userEmailDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {SendPhoneNumberVerificationDto} sendPhoneNumberVerificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerSendPhoneNumberVerification(
      sendPhoneNumberVerificationDto: SendPhoneNumberVerificationDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SendingStatusDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerSendPhoneNumberVerification(
          sendPhoneNumberVerificationDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {SendPhoneNumberVerificationDto} sendPhoneNumberVerificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerSendPhoneNumberVerificationForSignIn(
      sendPhoneNumberVerificationDto: SendPhoneNumberVerificationDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SendingStatusDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerSendPhoneNumberVerificationForSignIn(
          sendPhoneNumberVerificationDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Send update email confirmation link
     * @param {SendUpdateEmailConfirmationRequestDto} sendUpdateEmailConfirmationRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerSendUpdateEmailConfirmation(
      sendUpdateEmailConfirmationRequestDto: SendUpdateEmailConfirmationRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SendingStatusDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerSendUpdateEmailConfirmation(
          sendUpdateEmailConfirmationRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Send email confirmation
     * @param {UserEmailDto} userEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerSendUserConfirmationEmail(
      userEmailDto: UserEmailDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SendingStatusDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerSendUserConfirmationEmail(
          userEmailDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UserSignInDto} userSignInDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerSignIn(
      userSignInDto: UserSignInDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenAndMetadataResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerSignIn(
          userSignInDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UserPhoneSignInRequestDto} userPhoneSignInRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerSignInByPhone(
      userPhoneSignInRequestDto: UserPhoneSignInRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenAndMetadataResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerSignInByPhone(
          userPhoneSignInRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UserSignUpDto} userSignUpDto
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async authControllerSignUp(
      userSignUpDto: UserSignUpDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenAndMetadataResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerSignUp(
          userSignUpDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UserEmailSignUpRequestDto} userEmailSignUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerSignUpWithEmail(
      userEmailSignUpRequestDto: UserEmailSignUpRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenAndMetadataResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerSignUpWithEmail(
          userEmailSignUpRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UserPhoneSignUpRequestDto} userPhoneSignUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerSignUpWithPhone(
      userPhoneSignUpRequestDto: UserPhoneSignUpRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenAndMetadataResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerSignUpWithPhone(
          userPhoneSignUpRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update email (require confirmation token from mail)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerUpdateEmail(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenAndMetadataResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerUpdateEmail(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * temporary
     * @summary Update email (only for user without email and password)
     * @param {UpdateEmailWithoutConfirmationRequestDto} updateEmailWithoutConfirmationRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerUpdateEmailWithNoConfirmation(
      updateEmailWithoutConfirmationRequestDto: UpdateEmailWithoutConfirmationRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenAndMetadataResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerUpdateEmailWithNoConfirmation(
          updateEmailWithoutConfirmationRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update password
     * @param {UpdatePasswordRequestDto} updatePasswordRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerUpdatePassword(
      updatePasswordRequestDto: UpdatePasswordRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenAndMetadataResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerUpdatePassword(
          updatePasswordRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update phone
     * @param {UpdatePhoneRequestDto} updatePhoneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authControllerUpdatePhone(
      updatePhoneRequestDto: UpdatePhoneRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenAndMetadataResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authControllerUpdatePhone(
          updatePhoneRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     *
     * @param {AdminSignInDto} adminSignInDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerAdminSignIn(
      adminSignInDto: AdminSignInDto,
      options?: any,
    ): AxiosPromise<AdminWithTokenResponseDto> {
      return localVarFp
        .authControllerAdminSignIn(adminSignInDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Check if email is available
     * @param {CheckEmailRequestDto} checkEmailRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerCheckEmail(
      checkEmailRequestDto: CheckEmailRequestDto,
      options?: any,
    ): AxiosPromise<CheckAuthTermResponseDto> {
      return localVarFp
        .authControllerCheckEmail(checkEmailRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Check if password is valid
     * @param {CheckPasswordRequestDto} checkPasswordRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerCheckPassword(
      checkPasswordRequestDto: CheckPasswordRequestDto,
      options?: any,
    ): AxiosPromise<CheckAuthTermResponseDto> {
      return localVarFp
        .authControllerCheckPassword(checkPasswordRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Check if phone is available
     * @param {CheckPhoneRequestDto} checkPhoneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerCheckPhone(
      checkPhoneRequestDto: CheckPhoneRequestDto,
      options?: any,
    ): AxiosPromise<CheckAuthTermResponseDto> {
      return localVarFp
        .authControllerCheckPhone(checkPhoneRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CheckPhoneNumberVerificationDto} checkPhoneNumberVerificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerCheckPhoneNumberVerification(
      checkPhoneNumberVerificationDto: CheckPhoneNumberVerificationDto,
      options?: any,
    ): AxiosPromise<CheckVerificationCodeDto> {
      return localVarFp
        .authControllerCheckPhoneNumberVerification(
          checkPhoneNumberVerificationDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Confirm user email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerConfirmUserEmail(
      options?: any,
    ): AxiosPromise<UserWithTokenAndMetadataResponseDto> {
      return localVarFp
        .authControllerConfirmUserEmail(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerGetEmailConfirmationToken(options?: any): AxiosPromise<void> {
      return localVarFp
        .authControllerGetEmailConfirmationToken(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Change forgotten password
     * @param {RestorePasswordByEmailRequestDto} restorePasswordByEmailRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerRestorePasswordByJwtToken(
      restorePasswordByEmailRequestDto: RestorePasswordByEmailRequestDto,
      options?: any,
    ): AxiosPromise<UserWithTokenResponseDto> {
      return localVarFp
        .authControllerRestorePasswordByJwtToken(
          restorePasswordByEmailRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Change forgotten password
     * @param {RestorePasswordByPhoneRequestDto} restorePasswordByPhoneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerRestorePasswordByPhone(
      restorePasswordByPhoneRequestDto: RestorePasswordByPhoneRequestDto,
      options?: any,
    ): AxiosPromise<UserWithTokenResponseDto> {
      return localVarFp
        .authControllerRestorePasswordByPhone(
          restorePasswordByPhoneRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send url for change forgotten password
     * @param {SendPhoneNumberVerificationDto} sendPhoneNumberVerificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendChangeForgetPasswordCode(
      sendPhoneNumberVerificationDto: SendPhoneNumberVerificationDto,
      options?: any,
    ): AxiosPromise<SendingStatusDto> {
      return localVarFp
        .authControllerSendChangeForgetPasswordCode(
          sendPhoneNumberVerificationDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send url for change forgotten password
     * @param {UserEmailDto} userEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendChangeForgetPasswordUrl(
      userEmailDto: UserEmailDto,
      options?: any,
    ): AxiosPromise<SendingStatusDto> {
      return localVarFp
        .authControllerSendChangeForgetPasswordUrl(userEmailDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SendPhoneNumberVerificationDto} sendPhoneNumberVerificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendPhoneNumberVerification(
      sendPhoneNumberVerificationDto: SendPhoneNumberVerificationDto,
      options?: any,
    ): AxiosPromise<SendingStatusDto> {
      return localVarFp
        .authControllerSendPhoneNumberVerification(
          sendPhoneNumberVerificationDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {SendPhoneNumberVerificationDto} sendPhoneNumberVerificationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendPhoneNumberVerificationForSignIn(
      sendPhoneNumberVerificationDto: SendPhoneNumberVerificationDto,
      options?: any,
    ): AxiosPromise<SendingStatusDto> {
      return localVarFp
        .authControllerSendPhoneNumberVerificationForSignIn(
          sendPhoneNumberVerificationDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send update email confirmation link
     * @param {SendUpdateEmailConfirmationRequestDto} sendUpdateEmailConfirmationRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendUpdateEmailConfirmation(
      sendUpdateEmailConfirmationRequestDto: SendUpdateEmailConfirmationRequestDto,
      options?: any,
    ): AxiosPromise<SendingStatusDto> {
      return localVarFp
        .authControllerSendUpdateEmailConfirmation(
          sendUpdateEmailConfirmationRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send email confirmation
     * @param {UserEmailDto} userEmailDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSendUserConfirmationEmail(
      userEmailDto: UserEmailDto,
      options?: any,
    ): AxiosPromise<SendingStatusDto> {
      return localVarFp
        .authControllerSendUserConfirmationEmail(userEmailDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UserSignInDto} userSignInDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSignIn(
      userSignInDto: UserSignInDto,
      options?: any,
    ): AxiosPromise<UserWithTokenAndMetadataResponseDto> {
      return localVarFp
        .authControllerSignIn(userSignInDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UserPhoneSignInRequestDto} userPhoneSignInRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSignInByPhone(
      userPhoneSignInRequestDto: UserPhoneSignInRequestDto,
      options?: any,
    ): AxiosPromise<UserWithTokenAndMetadataResponseDto> {
      return localVarFp
        .authControllerSignInByPhone(userPhoneSignInRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UserSignUpDto} userSignUpDto
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    authControllerSignUp(
      userSignUpDto: UserSignUpDto,
      options?: any,
    ): AxiosPromise<UserWithTokenAndMetadataResponseDto> {
      return localVarFp
        .authControllerSignUp(userSignUpDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UserEmailSignUpRequestDto} userEmailSignUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSignUpWithEmail(
      userEmailSignUpRequestDto: UserEmailSignUpRequestDto,
      options?: any,
    ): AxiosPromise<UserWithTokenAndMetadataResponseDto> {
      return localVarFp
        .authControllerSignUpWithEmail(userEmailSignUpRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UserPhoneSignUpRequestDto} userPhoneSignUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerSignUpWithPhone(
      userPhoneSignUpRequestDto: UserPhoneSignUpRequestDto,
      options?: any,
    ): AxiosPromise<UserWithTokenAndMetadataResponseDto> {
      return localVarFp
        .authControllerSignUpWithPhone(userPhoneSignUpRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update email (require confirmation token from mail)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerUpdateEmail(
      options?: any,
    ): AxiosPromise<UserWithTokenAndMetadataResponseDto> {
      return localVarFp
        .authControllerUpdateEmail(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * temporary
     * @summary Update email (only for user without email and password)
     * @param {UpdateEmailWithoutConfirmationRequestDto} updateEmailWithoutConfirmationRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerUpdateEmailWithNoConfirmation(
      updateEmailWithoutConfirmationRequestDto: UpdateEmailWithoutConfirmationRequestDto,
      options?: any,
    ): AxiosPromise<UserWithTokenAndMetadataResponseDto> {
      return localVarFp
        .authControllerUpdateEmailWithNoConfirmation(
          updateEmailWithoutConfirmationRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update password
     * @param {UpdatePasswordRequestDto} updatePasswordRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerUpdatePassword(
      updatePasswordRequestDto: UpdatePasswordRequestDto,
      options?: any,
    ): AxiosPromise<UserWithTokenAndMetadataResponseDto> {
      return localVarFp
        .authControllerUpdatePassword(updatePasswordRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update phone
     * @param {UpdatePhoneRequestDto} updatePhoneRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authControllerUpdatePhone(
      updatePhoneRequestDto: UpdatePhoneRequestDto,
      options?: any,
    ): AxiosPromise<UserWithTokenAndMetadataResponseDto> {
      return localVarFp
        .authControllerUpdatePhone(updatePhoneRequestDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   *
   * @param {AdminSignInDto} adminSignInDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerAdminSignIn(
    adminSignInDto: AdminSignInDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerAdminSignIn(adminSignInDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Check if email is available
   * @param {CheckEmailRequestDto} checkEmailRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerCheckEmail(
    checkEmailRequestDto: CheckEmailRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerCheckEmail(checkEmailRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Check if password is valid
   * @param {CheckPasswordRequestDto} checkPasswordRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerCheckPassword(
    checkPasswordRequestDto: CheckPasswordRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerCheckPassword(checkPasswordRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Check if phone is available
   * @param {CheckPhoneRequestDto} checkPhoneRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerCheckPhone(
    checkPhoneRequestDto: CheckPhoneRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerCheckPhone(checkPhoneRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CheckPhoneNumberVerificationDto} checkPhoneNumberVerificationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerCheckPhoneNumberVerification(
    checkPhoneNumberVerificationDto: CheckPhoneNumberVerificationDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerCheckPhoneNumberVerification(
        checkPhoneNumberVerificationDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Confirm user email
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerConfirmUserEmail(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authControllerConfirmUserEmail(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerGetEmailConfirmationToken(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authControllerGetEmailConfirmationToken(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Change forgotten password
   * @param {RestorePasswordByEmailRequestDto} restorePasswordByEmailRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerRestorePasswordByJwtToken(
    restorePasswordByEmailRequestDto: RestorePasswordByEmailRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerRestorePasswordByJwtToken(
        restorePasswordByEmailRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Change forgotten password
   * @param {RestorePasswordByPhoneRequestDto} restorePasswordByPhoneRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerRestorePasswordByPhone(
    restorePasswordByPhoneRequestDto: RestorePasswordByPhoneRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerRestorePasswordByPhone(
        restorePasswordByPhoneRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send url for change forgotten password
   * @param {SendPhoneNumberVerificationDto} sendPhoneNumberVerificationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerSendChangeForgetPasswordCode(
    sendPhoneNumberVerificationDto: SendPhoneNumberVerificationDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerSendChangeForgetPasswordCode(
        sendPhoneNumberVerificationDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send url for change forgotten password
   * @param {UserEmailDto} userEmailDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerSendChangeForgetPasswordUrl(
    userEmailDto: UserEmailDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerSendChangeForgetPasswordUrl(userEmailDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SendPhoneNumberVerificationDto} sendPhoneNumberVerificationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerSendPhoneNumberVerification(
    sendPhoneNumberVerificationDto: SendPhoneNumberVerificationDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerSendPhoneNumberVerification(
        sendPhoneNumberVerificationDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SendPhoneNumberVerificationDto} sendPhoneNumberVerificationDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerSendPhoneNumberVerificationForSignIn(
    sendPhoneNumberVerificationDto: SendPhoneNumberVerificationDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerSendPhoneNumberVerificationForSignIn(
        sendPhoneNumberVerificationDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send update email confirmation link
   * @param {SendUpdateEmailConfirmationRequestDto} sendUpdateEmailConfirmationRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerSendUpdateEmailConfirmation(
    sendUpdateEmailConfirmationRequestDto: SendUpdateEmailConfirmationRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerSendUpdateEmailConfirmation(
        sendUpdateEmailConfirmationRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send email confirmation
   * @param {UserEmailDto} userEmailDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerSendUserConfirmationEmail(
    userEmailDto: UserEmailDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerSendUserConfirmationEmail(userEmailDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserSignInDto} userSignInDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerSignIn(
    userSignInDto: UserSignInDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerSignIn(userSignInDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserPhoneSignInRequestDto} userPhoneSignInRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerSignInByPhone(
    userPhoneSignInRequestDto: UserPhoneSignInRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerSignInByPhone(userPhoneSignInRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserSignUpDto} userSignUpDto
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerSignUp(
    userSignUpDto: UserSignUpDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerSignUp(userSignUpDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserEmailSignUpRequestDto} userEmailSignUpRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerSignUpWithEmail(
    userEmailSignUpRequestDto: UserEmailSignUpRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerSignUpWithEmail(userEmailSignUpRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserPhoneSignUpRequestDto} userPhoneSignUpRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerSignUpWithPhone(
    userPhoneSignUpRequestDto: UserPhoneSignUpRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerSignUpWithPhone(userPhoneSignUpRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update email (require confirmation token from mail)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerUpdateEmail(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authControllerUpdateEmail(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * temporary
   * @summary Update email (only for user without email and password)
   * @param {UpdateEmailWithoutConfirmationRequestDto} updateEmailWithoutConfirmationRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerUpdateEmailWithNoConfirmation(
    updateEmailWithoutConfirmationRequestDto: UpdateEmailWithoutConfirmationRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerUpdateEmailWithNoConfirmation(
        updateEmailWithoutConfirmationRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update password
   * @param {UpdatePasswordRequestDto} updatePasswordRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerUpdatePassword(
    updatePasswordRequestDto: UpdatePasswordRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerUpdatePassword(updatePasswordRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update phone
   * @param {UpdatePhoneRequestDto} updatePhoneRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authControllerUpdatePhone(
    updatePhoneRequestDto: UpdatePhoneRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authControllerUpdatePhone(updatePhoneRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get all diseases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonControllerGetDiseases: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/common/diseases`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all diseases groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonControllerGetDiseasesGroups: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/common/diseases_groups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get FAQ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonControllerGetFaqList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/common/faq`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all therapy methods
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonControllerGetTherapyMethods: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/common/therapy_methods`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get all diseases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commonControllerGetDiseases(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DiseaseResponseDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commonControllerGetDiseases(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all diseases groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commonControllerGetDiseasesGroups(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DiseaseGroupResponseDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commonControllerGetDiseasesGroups(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get FAQ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commonControllerGetFaqList(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<FaqResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commonControllerGetFaqList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all therapy methods
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async commonControllerGetTherapyMethods(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TherapyMethodResponseDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.commonControllerGetTherapyMethods(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CommonApiFp(configuration);
  return {
    /**
     *
     * @summary Get all diseases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonControllerGetDiseases(
      options?: any,
    ): AxiosPromise<Array<DiseaseResponseDto>> {
      return localVarFp
        .commonControllerGetDiseases(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all diseases groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonControllerGetDiseasesGroups(
      options?: any,
    ): AxiosPromise<Array<DiseaseGroupResponseDto>> {
      return localVarFp
        .commonControllerGetDiseasesGroups(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get FAQ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonControllerGetFaqList(options?: any): AxiosPromise<FaqResponseDto> {
      return localVarFp
        .commonControllerGetFaqList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all therapy methods
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    commonControllerGetTherapyMethods(
      options?: any,
    ): AxiosPromise<Array<TherapyMethodResponseDto>> {
      return localVarFp
        .commonControllerGetTherapyMethods(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
  /**
   *
   * @summary Get all diseases
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonControllerGetDiseases(options?: AxiosRequestConfig) {
    return CommonApiFp(this.configuration)
      .commonControllerGetDiseases(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all diseases groups
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonControllerGetDiseasesGroups(options?: AxiosRequestConfig) {
    return CommonApiFp(this.configuration)
      .commonControllerGetDiseasesGroups(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get FAQ
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonControllerGetFaqList(options?: AxiosRequestConfig) {
    return CommonApiFp(this.configuration)
      .commonControllerGetFaqList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all therapy methods
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommonApi
   */
  public commonControllerGetTherapyMethods(options?: AxiosRequestConfig) {
    return CommonApiFp(this.configuration)
      .commonControllerGetTherapyMethods(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create company create request
     * @param {CreateCompanyCreateRequestRequestDto} createCompanyCreateRequestRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesControllerCreateCompanyCreateRequest: async (
      createCompanyCreateRequestRequestDto: CreateCompanyCreateRequestRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createCompanyCreateRequestRequestDto' is not null or undefined
      assertParamExists(
        'companiesControllerCreateCompanyCreateRequest',
        'createCompanyCreateRequestRequestDto',
        createCompanyCreateRequestRequestDto,
      );
      const localVarPath = `/api/v1/companies`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCompanyCreateRequestRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send promocode request
     * @param {PromocodeRequestRequestDto} promocodeRequestRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesControllerCreatePromocodeRequest: async (
      promocodeRequestRequestDto: PromocodeRequestRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'promocodeRequestRequestDto' is not null or undefined
      assertParamExists(
        'companiesControllerCreatePromocodeRequest',
        'promocodeRequestRequestDto',
        promocodeRequestRequestDto,
      );
      const localVarPath = `/api/v1/companies/promocodes-request`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        promocodeRequestRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get promocodes
     * @param {boolean} isExpired
     * @param {Array<string>} [amountFilter]
     * @param {Array<string>} [percentageFilter]
     * @param {'expired' | 'used'} [statusFilter]
     * @param {'expireDate' | 'amount'} [sortTerm]
     * @param {'ASC' | 'DESC'} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesControllerGetPromocodes: async (
      isExpired: boolean,
      amountFilter?: Array<string>,
      percentageFilter?: Array<string>,
      statusFilter?: 'expired' | 'used',
      sortTerm?: 'expireDate' | 'amount',
      sortOrder?: 'ASC' | 'DESC',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'isExpired' is not null or undefined
      assertParamExists(
        'companiesControllerGetPromocodes',
        'isExpired',
        isExpired,
      );
      const localVarPath = `/api/v1/companies/promocodes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (isExpired !== undefined) {
        localVarQueryParameter['isExpired'] = isExpired;
      }

      if (amountFilter) {
        localVarQueryParameter['amountFilter'] = amountFilter;
      }

      if (percentageFilter) {
        localVarQueryParameter['percentageFilter'] = percentageFilter;
      }

      if (statusFilter !== undefined) {
        localVarQueryParameter['statusFilter'] = statusFilter;
      }

      if (sortTerm !== undefined) {
        localVarQueryParameter['sortTerm'] = sortTerm;
      }

      if (sortOrder !== undefined) {
        localVarQueryParameter['sortOrder'] = sortOrder;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get company\'s statistics
     * @param {string} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesControllerGetStatistics: async (
      year: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      assertParamExists('companiesControllerGetStatistics', 'year', year);
      const localVarPath = `/api/v1/companies/statistics`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get company\'s statistics in xls format
     * @param {string} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesControllerGetXlsStatistics: async (
      year: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      assertParamExists('companiesControllerGetXlsStatistics', 'year', year);
      const localVarPath = `/api/v1/companies/statistics/xls`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Send promocodes
     * @param {SendPromocodesRequestDto} sendPromocodesRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesControllerSendPromocodes: async (
      sendPromocodesRequestDto: SendPromocodesRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sendPromocodesRequestDto' is not null or undefined
      assertParamExists(
        'companiesControllerSendPromocodes',
        'sendPromocodesRequestDto',
        sendPromocodesRequestDto,
      );
      const localVarPath = `/api/v1/companies/promocodes/send`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        sendPromocodesRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create company create request
     * @param {CreateCompanyCreateRequestRequestDto} createCompanyCreateRequestRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companiesControllerCreateCompanyCreateRequest(
      createCompanyCreateRequestRequestDto: CreateCompanyCreateRequestRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.companiesControllerCreateCompanyCreateRequest(
          createCompanyCreateRequestRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Send promocode request
     * @param {PromocodeRequestRequestDto} promocodeRequestRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companiesControllerCreatePromocodeRequest(
      promocodeRequestRequestDto: PromocodeRequestRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.companiesControllerCreatePromocodeRequest(
          promocodeRequestRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get promocodes
     * @param {boolean} isExpired
     * @param {Array<string>} [amountFilter]
     * @param {Array<string>} [percentageFilter]
     * @param {'expired' | 'used'} [statusFilter]
     * @param {'expireDate' | 'amount'} [sortTerm]
     * @param {'ASC' | 'DESC'} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companiesControllerGetPromocodes(
      isExpired: boolean,
      amountFilter?: Array<string>,
      percentageFilter?: Array<string>,
      statusFilter?: 'expired' | 'used',
      sortTerm?: 'expireDate' | 'amount',
      sortOrder?: 'ASC' | 'DESC',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetPromocodesResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.companiesControllerGetPromocodes(
          isExpired,
          amountFilter,
          percentageFilter,
          statusFilter,
          sortTerm,
          sortOrder,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get company\'s statistics
     * @param {string} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companiesControllerGetStatistics(
      year: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetStatisticsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.companiesControllerGetStatistics(
          year,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get company\'s statistics in xls format
     * @param {string} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companiesControllerGetXlsStatistics(
      year: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.companiesControllerGetXlsStatistics(
          year,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Send promocodes
     * @param {SendPromocodesRequestDto} sendPromocodesRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async companiesControllerSendPromocodes(
      sendPromocodesRequestDto: SendPromocodesRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.companiesControllerSendPromocodes(
          sendPromocodesRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CompanyApiFp(configuration);
  return {
    /**
     *
     * @summary Create company create request
     * @param {CreateCompanyCreateRequestRequestDto} createCompanyCreateRequestRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesControllerCreateCompanyCreateRequest(
      createCompanyCreateRequestRequestDto: CreateCompanyCreateRequestRequestDto,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .companiesControllerCreateCompanyCreateRequest(
          createCompanyCreateRequestRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send promocode request
     * @param {PromocodeRequestRequestDto} promocodeRequestRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesControllerCreatePromocodeRequest(
      promocodeRequestRequestDto: PromocodeRequestRequestDto,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .companiesControllerCreatePromocodeRequest(
          promocodeRequestRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get promocodes
     * @param {boolean} isExpired
     * @param {Array<string>} [amountFilter]
     * @param {Array<string>} [percentageFilter]
     * @param {'expired' | 'used'} [statusFilter]
     * @param {'expireDate' | 'amount'} [sortTerm]
     * @param {'ASC' | 'DESC'} [sortOrder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesControllerGetPromocodes(
      isExpired: boolean,
      amountFilter?: Array<string>,
      percentageFilter?: Array<string>,
      statusFilter?: 'expired' | 'used',
      sortTerm?: 'expireDate' | 'amount',
      sortOrder?: 'ASC' | 'DESC',
      options?: any,
    ): AxiosPromise<GetPromocodesResponseDto> {
      return localVarFp
        .companiesControllerGetPromocodes(
          isExpired,
          amountFilter,
          percentageFilter,
          statusFilter,
          sortTerm,
          sortOrder,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get company\'s statistics
     * @param {string} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesControllerGetStatistics(
      year: string,
      options?: any,
    ): AxiosPromise<GetStatisticsResponseDto> {
      return localVarFp
        .companiesControllerGetStatistics(year, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get company\'s statistics in xls format
     * @param {string} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesControllerGetXlsStatistics(
      year: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .companiesControllerGetXlsStatistics(year, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Send promocodes
     * @param {SendPromocodesRequestDto} sendPromocodesRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    companiesControllerSendPromocodes(
      sendPromocodesRequestDto: SendPromocodesRequestDto,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .companiesControllerSendPromocodes(sendPromocodesRequestDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
  /**
   *
   * @summary Create company create request
   * @param {CreateCompanyCreateRequestRequestDto} createCompanyCreateRequestRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyApi
   */
  public companiesControllerCreateCompanyCreateRequest(
    createCompanyCreateRequestRequestDto: CreateCompanyCreateRequestRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return CompanyApiFp(this.configuration)
      .companiesControllerCreateCompanyCreateRequest(
        createCompanyCreateRequestRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send promocode request
   * @param {PromocodeRequestRequestDto} promocodeRequestRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyApi
   */
  public companiesControllerCreatePromocodeRequest(
    promocodeRequestRequestDto: PromocodeRequestRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return CompanyApiFp(this.configuration)
      .companiesControllerCreatePromocodeRequest(
        promocodeRequestRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get promocodes
   * @param {boolean} isExpired
   * @param {Array<string>} [amountFilter]
   * @param {Array<string>} [percentageFilter]
   * @param {'expired' | 'used'} [statusFilter]
   * @param {'expireDate' | 'amount'} [sortTerm]
   * @param {'ASC' | 'DESC'} [sortOrder]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyApi
   */
  public companiesControllerGetPromocodes(
    isExpired: boolean,
    amountFilter?: Array<string>,
    percentageFilter?: Array<string>,
    statusFilter?: 'expired' | 'used',
    sortTerm?: 'expireDate' | 'amount',
    sortOrder?: 'ASC' | 'DESC',
    options?: AxiosRequestConfig,
  ) {
    return CompanyApiFp(this.configuration)
      .companiesControllerGetPromocodes(
        isExpired,
        amountFilter,
        percentageFilter,
        statusFilter,
        sortTerm,
        sortOrder,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get company\'s statistics
   * @param {string} year
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyApi
   */
  public companiesControllerGetStatistics(
    year: string,
    options?: AxiosRequestConfig,
  ) {
    return CompanyApiFp(this.configuration)
      .companiesControllerGetStatistics(year, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get company\'s statistics in xls format
   * @param {string} year
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyApi
   */
  public companiesControllerGetXlsStatistics(
    year: string,
    options?: AxiosRequestConfig,
  ) {
    return CompanyApiFp(this.configuration)
      .companiesControllerGetXlsStatistics(year, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Send promocodes
   * @param {SendPromocodesRequestDto} sendPromocodesRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CompanyApi
   */
  public companiesControllerSendPromocodes(
    sendPromocodesRequestDto: SendPromocodesRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return CompanyApiFp(this.configuration)
      .companiesControllerSendPromocodes(sendPromocodesRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {SetMiniAvatarKeyRequest} setMiniAvatarKeyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lambdaControllerSetMiniAvatarKey: async (
      setMiniAvatarKeyRequest: SetMiniAvatarKeyRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'setMiniAvatarKeyRequest' is not null or undefined
      assertParamExists(
        'lambdaControllerSetMiniAvatarKey',
        'setMiniAvatarKeyRequest',
        setMiniAvatarKeyRequest,
      );
      const localVarPath = `/api/v1/lambdas/set-mini-avatar-key`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        setMiniAvatarKeyRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {SetMiniAvatarKeyRequest} setMiniAvatarKeyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lambdaControllerSetMiniAvatarKey(
      setMiniAvatarKeyRequest: SetMiniAvatarKeyRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.lambdaControllerSetMiniAvatarKey(
          setMiniAvatarKeyRequest,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {SetMiniAvatarKeyRequest} setMiniAvatarKeyRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lambdaControllerSetMiniAvatarKey(
      setMiniAvatarKeyRequest: SetMiniAvatarKeyRequest,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .lambdaControllerSetMiniAvatarKey(setMiniAvatarKeyRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {SetMiniAvatarKeyRequest} setMiniAvatarKeyRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public lambdaControllerSetMiniAvatarKey(
    setMiniAvatarKeyRequest: SetMiniAvatarKeyRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .lambdaControllerSetMiniAvatarKey(setMiniAvatarKeyRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FAQApi - axios parameter creator
 * @export
 */
export const FAQApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Add FAQ group
     * @summary Add FAQ group
     * @param {AddFaqGroupRequestDto} addFaqGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faqControllerAddFaqGroup: async (
      addFaqGroupRequestDto: AddFaqGroupRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'addFaqGroupRequestDto' is not null or undefined
      assertParamExists(
        'faqControllerAddFaqGroup',
        'addFaqGroupRequestDto',
        addFaqGroupRequestDto,
      );
      const localVarPath = `/api/v1/faq/groups`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addFaqGroupRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Add FAQ question
     * @summary Add FAQ question
     * @param {string} groupId
     * @param {AddFaqQuestionRequestDto} addFaqQuestionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faqControllerAddFaqQuestion: async (
      groupId: string,
      addFaqQuestionRequestDto: AddFaqQuestionRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('faqControllerAddFaqQuestion', 'groupId', groupId);
      // verify required parameter 'addFaqQuestionRequestDto' is not null or undefined
      assertParamExists(
        'faqControllerAddFaqQuestion',
        'addFaqQuestionRequestDto',
        addFaqQuestionRequestDto,
      );
      const localVarPath = `/api/v1/faq/groups/{group_id}`.replace(
        `{${'group_id'}}`,
        encodeURIComponent(String(groupId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addFaqQuestionRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete FAQ group
     * @summary Delete FAQ group
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faqControllerDeleteFaqGroup: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('faqControllerDeleteFaqGroup', 'id', id);
      const localVarPath = `/api/v1/faq/groups/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete FAQ question
     * @summary Delete FAQ question
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faqControllerDeleteFaqQuestion: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('faqControllerDeleteFaqQuestion', 'id', id);
      const localVarPath = `/api/v1/faq/questions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update FAQ group
     * @summary Update FAQ group
     * @param {string} id
     * @param {UpdateFaqGroupRequestDto} updateFaqGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faqControllerUpdateFaqGroup: async (
      id: string,
      updateFaqGroupRequestDto: UpdateFaqGroupRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('faqControllerUpdateFaqGroup', 'id', id);
      // verify required parameter 'updateFaqGroupRequestDto' is not null or undefined
      assertParamExists(
        'faqControllerUpdateFaqGroup',
        'updateFaqGroupRequestDto',
        updateFaqGroupRequestDto,
      );
      const localVarPath = `/api/v1/faq/groups/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateFaqGroupRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update FAQ question
     * @summary Update FAQ question
     * @param {string} id
     * @param {UpdateFaqQuestionRequestDto} updateFaqQuestionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faqControllerUpdateFaqQuestion: async (
      id: string,
      updateFaqQuestionRequestDto: UpdateFaqQuestionRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('faqControllerUpdateFaqQuestion', 'id', id);
      // verify required parameter 'updateFaqQuestionRequestDto' is not null or undefined
      assertParamExists(
        'faqControllerUpdateFaqQuestion',
        'updateFaqQuestionRequestDto',
        updateFaqQuestionRequestDto,
      );
      const localVarPath = `/api/v1/faq/questions/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateFaqQuestionRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FAQApi - functional programming interface
 * @export
 */
export const FAQApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FAQApiAxiosParamCreator(configuration);
  return {
    /**
     * Add FAQ group
     * @summary Add FAQ group
     * @param {AddFaqGroupRequestDto} addFaqGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async faqControllerAddFaqGroup(
      addFaqGroupRequestDto: AddFaqGroupRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FaqGroupResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.faqControllerAddFaqGroup(
          addFaqGroupRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Add FAQ question
     * @summary Add FAQ question
     * @param {string} groupId
     * @param {AddFaqQuestionRequestDto} addFaqQuestionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async faqControllerAddFaqQuestion(
      groupId: string,
      addFaqQuestionRequestDto: AddFaqQuestionRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FaqQuestionResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.faqControllerAddFaqQuestion(
          groupId,
          addFaqQuestionRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Delete FAQ group
     * @summary Delete FAQ group
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async faqControllerDeleteFaqGroup(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.faqControllerDeleteFaqGroup(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Delete FAQ question
     * @summary Delete FAQ question
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async faqControllerDeleteFaqQuestion(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.faqControllerDeleteFaqQuestion(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update FAQ group
     * @summary Update FAQ group
     * @param {string} id
     * @param {UpdateFaqGroupRequestDto} updateFaqGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async faqControllerUpdateFaqGroup(
      id: string,
      updateFaqGroupRequestDto: UpdateFaqGroupRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FaqGroupResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.faqControllerUpdateFaqGroup(
          id,
          updateFaqGroupRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Update FAQ question
     * @summary Update FAQ question
     * @param {string} id
     * @param {UpdateFaqQuestionRequestDto} updateFaqQuestionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async faqControllerUpdateFaqQuestion(
      id: string,
      updateFaqQuestionRequestDto: UpdateFaqQuestionRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FaqQuestionResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.faqControllerUpdateFaqQuestion(
          id,
          updateFaqQuestionRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FAQApi - factory interface
 * @export
 */
export const FAQApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FAQApiFp(configuration);
  return {
    /**
     * Add FAQ group
     * @summary Add FAQ group
     * @param {AddFaqGroupRequestDto} addFaqGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faqControllerAddFaqGroup(
      addFaqGroupRequestDto: AddFaqGroupRequestDto,
      options?: any,
    ): AxiosPromise<FaqGroupResponseDto> {
      return localVarFp
        .faqControllerAddFaqGroup(addFaqGroupRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Add FAQ question
     * @summary Add FAQ question
     * @param {string} groupId
     * @param {AddFaqQuestionRequestDto} addFaqQuestionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faqControllerAddFaqQuestion(
      groupId: string,
      addFaqQuestionRequestDto: AddFaqQuestionRequestDto,
      options?: any,
    ): AxiosPromise<FaqQuestionResponseDto> {
      return localVarFp
        .faqControllerAddFaqQuestion(groupId, addFaqQuestionRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete FAQ group
     * @summary Delete FAQ group
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faqControllerDeleteFaqGroup(id: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .faqControllerDeleteFaqGroup(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete FAQ question
     * @summary Delete FAQ question
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faqControllerDeleteFaqQuestion(
      id: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .faqControllerDeleteFaqQuestion(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update FAQ group
     * @summary Update FAQ group
     * @param {string} id
     * @param {UpdateFaqGroupRequestDto} updateFaqGroupRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faqControllerUpdateFaqGroup(
      id: string,
      updateFaqGroupRequestDto: UpdateFaqGroupRequestDto,
      options?: any,
    ): AxiosPromise<FaqGroupResponseDto> {
      return localVarFp
        .faqControllerUpdateFaqGroup(id, updateFaqGroupRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update FAQ question
     * @summary Update FAQ question
     * @param {string} id
     * @param {UpdateFaqQuestionRequestDto} updateFaqQuestionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    faqControllerUpdateFaqQuestion(
      id: string,
      updateFaqQuestionRequestDto: UpdateFaqQuestionRequestDto,
      options?: any,
    ): AxiosPromise<FaqQuestionResponseDto> {
      return localVarFp
        .faqControllerUpdateFaqQuestion(
          id,
          updateFaqQuestionRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FAQApi - object-oriented interface
 * @export
 * @class FAQApi
 * @extends {BaseAPI}
 */
export class FAQApi extends BaseAPI {
  /**
   * Add FAQ group
   * @summary Add FAQ group
   * @param {AddFaqGroupRequestDto} addFaqGroupRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public faqControllerAddFaqGroup(
    addFaqGroupRequestDto: AddFaqGroupRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return FAQApiFp(this.configuration)
      .faqControllerAddFaqGroup(addFaqGroupRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Add FAQ question
   * @summary Add FAQ question
   * @param {string} groupId
   * @param {AddFaqQuestionRequestDto} addFaqQuestionRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public faqControllerAddFaqQuestion(
    groupId: string,
    addFaqQuestionRequestDto: AddFaqQuestionRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return FAQApiFp(this.configuration)
      .faqControllerAddFaqQuestion(groupId, addFaqQuestionRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete FAQ group
   * @summary Delete FAQ group
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public faqControllerDeleteFaqGroup(id: string, options?: AxiosRequestConfig) {
    return FAQApiFp(this.configuration)
      .faqControllerDeleteFaqGroup(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete FAQ question
   * @summary Delete FAQ question
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public faqControllerDeleteFaqQuestion(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return FAQApiFp(this.configuration)
      .faqControllerDeleteFaqQuestion(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update FAQ group
   * @summary Update FAQ group
   * @param {string} id
   * @param {UpdateFaqGroupRequestDto} updateFaqGroupRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public faqControllerUpdateFaqGroup(
    id: string,
    updateFaqGroupRequestDto: UpdateFaqGroupRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return FAQApiFp(this.configuration)
      .faqControllerUpdateFaqGroup(id, updateFaqGroupRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update FAQ question
   * @summary Update FAQ question
   * @param {string} id
   * @param {UpdateFaqQuestionRequestDto} updateFaqQuestionRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FAQApi
   */
  public faqControllerUpdateFaqQuestion(
    id: string,
    updateFaqQuestionRequestDto: UpdateFaqQuestionRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return FAQApiFp(this.configuration)
      .faqControllerUpdateFaqQuestion(id, updateFaqQuestionRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Get private signed url for file upload
     * @param {CreateFileRequestDto} createFileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signedUrlControllerGetPrivateSignedUrl: async (
      createFileRequestDto: CreateFileRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createFileRequestDto' is not null or undefined
      assertParamExists(
        'signedUrlControllerGetPrivateSignedUrl',
        'createFileRequestDto',
        createFileRequestDto,
      );
      const localVarPath = `/api/v1/get-private-signed-url`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFileRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get public signed url for file upload
     * @param {CreateFileRequestDto} createFileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signedUrlControllerGetPublicSignedUrl: async (
      createFileRequestDto: CreateFileRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createFileRequestDto' is not null or undefined
      assertParamExists(
        'signedUrlControllerGetPublicSignedUrl',
        'createFileRequestDto',
        createFileRequestDto,
      );
      const localVarPath = `/api/v1/get-public-signed-url`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createFileRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get private signed url for file upload
     * @param {CreateFileRequestDto} createFileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async signedUrlControllerGetPrivateSignedUrl(
      createFileRequestDto: CreateFileRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.signedUrlControllerGetPrivateSignedUrl(
          createFileRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get public signed url for file upload
     * @param {CreateFileRequestDto} createFileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async signedUrlControllerGetPublicSignedUrl(
      createFileRequestDto: CreateFileRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.signedUrlControllerGetPublicSignedUrl(
          createFileRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FilesApiFp(configuration);
  return {
    /**
     *
     * @summary Get private signed url for file upload
     * @param {CreateFileRequestDto} createFileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signedUrlControllerGetPrivateSignedUrl(
      createFileRequestDto: CreateFileRequestDto,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .signedUrlControllerGetPrivateSignedUrl(createFileRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get public signed url for file upload
     * @param {CreateFileRequestDto} createFileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signedUrlControllerGetPublicSignedUrl(
      createFileRequestDto: CreateFileRequestDto,
      options?: any,
    ): AxiosPromise<string> {
      return localVarFp
        .signedUrlControllerGetPublicSignedUrl(createFileRequestDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
  /**
   *
   * @summary Get private signed url for file upload
   * @param {CreateFileRequestDto} createFileRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public signedUrlControllerGetPrivateSignedUrl(
    createFileRequestDto: CreateFileRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return FilesApiFp(this.configuration)
      .signedUrlControllerGetPrivateSignedUrl(createFileRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get public signed url for file upload
   * @param {CreateFileRequestDto} createFileRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public signedUrlControllerGetPublicSignedUrl(
    createFileRequestDto: CreateFileRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return FilesApiFp(this.configuration)
      .signedUrlControllerGetPublicSignedUrl(createFileRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MedsiAuthApi - axios parameter creator
 * @export
 */
export const MedsiAuthApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {GenerateTokenRequestDto} generateTokenRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medsiAuthControllerCreateMedsiToken: async (
      generateTokenRequestDto: GenerateTokenRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'generateTokenRequestDto' is not null or undefined
      assertParamExists(
        'medsiAuthControllerCreateMedsiToken',
        'generateTokenRequestDto',
        generateTokenRequestDto,
      );
      const localVarPath = `/api/v1/medsi/auth/generate-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        generateTokenRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Recover medsi user
     * @param {MedsiSignUpRequestDto} medsiSignUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medsiAuthControllerRecover: async (
      medsiSignUpRequestDto: MedsiSignUpRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'medsiSignUpRequestDto' is not null or undefined
      assertParamExists(
        'medsiAuthControllerRecover',
        'medsiSignUpRequestDto',
        medsiSignUpRequestDto,
      );
      const localVarPath = `/api/v1/medsi/auth/recover`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        medsiSignUpRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Sign in medsi user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medsiAuthControllerSignIn: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/medsi/auth/sign-in`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Sign up medsi user
     * @param {MedsiSignUpRequestDto} medsiSignUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medsiAuthControllerSignUp: async (
      medsiSignUpRequestDto: MedsiSignUpRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'medsiSignUpRequestDto' is not null or undefined
      assertParamExists(
        'medsiAuthControllerSignUp',
        'medsiSignUpRequestDto',
        medsiSignUpRequestDto,
      );
      const localVarPath = `/api/v1/medsi/auth/sign-up`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        medsiSignUpRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Validate medsi token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medsiAuthControllerValidateToken: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/medsi/auth/validate-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MedsiAuthApi - functional programming interface
 * @export
 */
export const MedsiAuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    MedsiAuthApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {GenerateTokenRequestDto} generateTokenRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medsiAuthControllerCreateMedsiToken(
      generateTokenRequestDto: GenerateTokenRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GenerateTokenResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medsiAuthControllerCreateMedsiToken(
          generateTokenRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Recover medsi user
     * @param {MedsiSignUpRequestDto} medsiSignUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medsiAuthControllerRecover(
      medsiSignUpRequestDto: MedsiSignUpRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenAndMetadataResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medsiAuthControllerRecover(
          medsiSignUpRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Sign in medsi user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medsiAuthControllerSignIn(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenAndMetadataResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medsiAuthControllerSignIn(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Sign up medsi user
     * @param {MedsiSignUpRequestDto} medsiSignUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medsiAuthControllerSignUp(
      medsiSignUpRequestDto: MedsiSignUpRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithTokenAndMetadataResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medsiAuthControllerSignUp(
          medsiSignUpRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Validate medsi token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medsiAuthControllerValidateToken(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<ValidateMedsiTokenResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medsiAuthControllerValidateToken(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * MedsiAuthApi - factory interface
 * @export
 */
export const MedsiAuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MedsiAuthApiFp(configuration);
  return {
    /**
     *
     * @param {GenerateTokenRequestDto} generateTokenRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medsiAuthControllerCreateMedsiToken(
      generateTokenRequestDto: GenerateTokenRequestDto,
      options?: any,
    ): AxiosPromise<GenerateTokenResponseDto> {
      return localVarFp
        .medsiAuthControllerCreateMedsiToken(generateTokenRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Recover medsi user
     * @param {MedsiSignUpRequestDto} medsiSignUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medsiAuthControllerRecover(
      medsiSignUpRequestDto: MedsiSignUpRequestDto,
      options?: any,
    ): AxiosPromise<UserWithTokenAndMetadataResponseDto> {
      return localVarFp
        .medsiAuthControllerRecover(medsiSignUpRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Sign in medsi user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medsiAuthControllerSignIn(
      options?: any,
    ): AxiosPromise<UserWithTokenAndMetadataResponseDto> {
      return localVarFp
        .medsiAuthControllerSignIn(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Sign up medsi user
     * @param {MedsiSignUpRequestDto} medsiSignUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medsiAuthControllerSignUp(
      medsiSignUpRequestDto: MedsiSignUpRequestDto,
      options?: any,
    ): AxiosPromise<UserWithTokenAndMetadataResponseDto> {
      return localVarFp
        .medsiAuthControllerSignUp(medsiSignUpRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Validate medsi token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medsiAuthControllerValidateToken(
      options?: any,
    ): AxiosPromise<ValidateMedsiTokenResponse> {
      return localVarFp
        .medsiAuthControllerValidateToken(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MedsiAuthApi - object-oriented interface
 * @export
 * @class MedsiAuthApi
 * @extends {BaseAPI}
 */
export class MedsiAuthApi extends BaseAPI {
  /**
   *
   * @param {GenerateTokenRequestDto} generateTokenRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedsiAuthApi
   */
  public medsiAuthControllerCreateMedsiToken(
    generateTokenRequestDto: GenerateTokenRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return MedsiAuthApiFp(this.configuration)
      .medsiAuthControllerCreateMedsiToken(generateTokenRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Recover medsi user
   * @param {MedsiSignUpRequestDto} medsiSignUpRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedsiAuthApi
   */
  public medsiAuthControllerRecover(
    medsiSignUpRequestDto: MedsiSignUpRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return MedsiAuthApiFp(this.configuration)
      .medsiAuthControllerRecover(medsiSignUpRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Sign in medsi user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedsiAuthApi
   */
  public medsiAuthControllerSignIn(options?: AxiosRequestConfig) {
    return MedsiAuthApiFp(this.configuration)
      .medsiAuthControllerSignIn(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Sign up medsi user
   * @param {MedsiSignUpRequestDto} medsiSignUpRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedsiAuthApi
   */
  public medsiAuthControllerSignUp(
    medsiSignUpRequestDto: MedsiSignUpRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return MedsiAuthApiFp(this.configuration)
      .medsiAuthControllerSignUp(medsiSignUpRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Validate medsi token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedsiAuthApi
   */
  public medsiAuthControllerValidateToken(options?: AxiosRequestConfig) {
    return MedsiAuthApiFp(this.configuration)
      .medsiAuthControllerValidateToken(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PatientsApi - axios parameter creator
 * @export
 */
export const PatientsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create recommendation filter (only for patients)
     * @param {CreateRecommendedFilterRequestDto} createRecommendedFilterRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerAddRecommendedFilter: async (
      createRecommendedFilterRequestDto: CreateRecommendedFilterRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createRecommendedFilterRequestDto' is not null or undefined
      assertParamExists(
        'patientsControllerAddRecommendedFilter',
        'createRecommendedFilterRequestDto',
        createRecommendedFilterRequestDto,
      );
      const localVarPath = `/api/v1/patients/recommendation-filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRecommendedFilterRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete avatar (only for patients)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerDeleteAvatar: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/patients/avatar`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get active sessions (only for patient)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [therapistFirstname]
     * @param {string} [therapistLastname]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetActiveSessions: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      therapistFirstname?: string,
      therapistLastname?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('patientsControllerGetActiveSessions', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('patientsControllerGetActiveSessions', 'page', page);
      const localVarPath = `/api/v1/patients/sessions/active`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] =
          (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (therapistFirstname !== undefined) {
        localVarQueryParameter['therapistFirstname'] = therapistFirstname;
      }

      if (therapistLastname !== undefined) {
        localVarQueryParameter['therapistLastname'] = therapistLastname;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get patient profile (only for sessioned therapists)
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetPatient: async (
      patientId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists('patientsControllerGetPatient', 'patientId', patientId);
      const localVarPath = `/api/v1/patients/{patientId}`.replace(
        `{${'patientId'}}`,
        encodeURIComponent(String(patientId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get patient profile (only for patient)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetPatientProfile: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/patients/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get session history (only for patient)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [therapistFirstname]
     * @param {string} [therapistLastname]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetSessionHistory: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      therapistFirstname?: string,
      therapistLastname?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('patientsControllerGetSessionHistory', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('patientsControllerGetSessionHistory', 'page', page);
      const localVarPath = `/api/v1/patients/sessions/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] =
          (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (therapistFirstname !== undefined) {
        localVarQueryParameter['therapistFirstname'] = therapistFirstname;
      }

      if (therapistLastname !== undefined) {
        localVarQueryParameter['therapistLastname'] = therapistLastname;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get tiny sessions (only for patient)
     * @param {boolean} isActive
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetSessions: async (
      isActive: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'isActive' is not null or undefined
      assertParamExists('patientsControllerGetSessions', 'isActive', isActive);
      const localVarPath = `/api/v1/patients/sessions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] =
          (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (isActive !== undefined) {
        localVarQueryParameter['isActive'] = isActive;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get patient\'s therapists (only for patients)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'therapistName' | 'totalSessions' | 'firstSessionDate' | 'lastSessionDate'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetTherapists: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'therapistName'
        | 'totalSessions'
        | 'firstSessionDate'
        | 'lastSessionDate',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('patientsControllerGetTherapists', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('patientsControllerGetTherapists', 'page', page);
      const localVarPath = `/api/v1/patients/therapist`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (orderTerms !== undefined) {
        localVarQueryParameter['orderTerms'] = orderTerms;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get total session (only for patient)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {Array<string>} [statuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetTotalSessionSession: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      statuses?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists(
        'patientsControllerGetTotalSessionSession',
        'limit',
        limit,
      );
      // verify required parameter 'page' is not null or undefined
      assertParamExists(
        'patientsControllerGetTotalSessionSession',
        'page',
        page,
      );
      const localVarPath = `/api/v1/patients/sessions/total`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] =
          (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (statuses) {
        localVarQueryParameter['statuses'] = statuses;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Onboarding (only for patients)
     * @param {PatientOnboardingRequestDto} patientOnboardingRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerOnboarding: async (
      patientOnboardingRequestDto: PatientOnboardingRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientOnboardingRequestDto' is not null or undefined
      assertParamExists(
        'patientsControllerOnboarding',
        'patientOnboardingRequestDto',
        patientOnboardingRequestDto,
      );
      const localVarPath = `/api/v1/patients/onboarding`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patientOnboardingRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update avatar (only for patients)
     * @param {UpdateAvatarRequestDto} updateAvatarRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerUpdateAvatar: async (
      updateAvatarRequestDto: UpdateAvatarRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateAvatarRequestDto' is not null or undefined
      assertParamExists(
        'patientsControllerUpdateAvatar',
        'updateAvatarRequestDto',
        updateAvatarRequestDto,
      );
      const localVarPath = `/api/v1/patients/avatar`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAvatarRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update self information
     * @param {UpdatePatientProfileRequestDto} updatePatientProfileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerUpdateOne: async (
      updatePatientProfileRequestDto: UpdatePatientProfileRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updatePatientProfileRequestDto' is not null or undefined
      assertParamExists(
        'patientsControllerUpdateOne',
        'updatePatientProfileRequestDto',
        updatePatientProfileRequestDto,
      );
      const localVarPath = `/api/v1/patients`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePatientProfileRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PatientsApi - functional programming interface
 * @export
 */
export const PatientsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PatientsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create recommendation filter (only for patients)
     * @param {CreateRecommendedFilterRequestDto} createRecommendedFilterRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerAddRecommendedFilter(
      createRecommendedFilterRequestDto: CreateRecommendedFilterRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PatientProfileResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patientsControllerAddRecommendedFilter(
          createRecommendedFilterRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete avatar (only for patients)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerDeleteAvatar(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PatientProfileResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patientsControllerDeleteAvatar(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get active sessions (only for patient)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [therapistFirstname]
     * @param {string} [therapistLastname]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerGetActiveSessions(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      therapistFirstname?: string,
      therapistLastname?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedSessionResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patientsControllerGetActiveSessions(
          limit,
          page,
          order,
          from,
          to,
          therapistFirstname,
          therapistLastname,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get patient profile (only for sessioned therapists)
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerGetPatient(
      patientId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PublicPatientProfileResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patientsControllerGetPatient(
          patientId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get patient profile (only for patient)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerGetPatientProfile(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PatientProfileResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patientsControllerGetPatientProfile(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get session history (only for patient)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [therapistFirstname]
     * @param {string} [therapistLastname]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerGetSessionHistory(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      therapistFirstname?: string,
      therapistLastname?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedSessionResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patientsControllerGetSessionHistory(
          limit,
          page,
          order,
          from,
          to,
          therapistFirstname,
          therapistLastname,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get tiny sessions (only for patient)
     * @param {boolean} isActive
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerGetSessions(
      isActive: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionsWithStartDate>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patientsControllerGetSessions(
          isActive,
          from,
          to,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get patient\'s therapists (only for patients)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'therapistName' | 'totalSessions' | 'firstSessionDate' | 'lastSessionDate'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerGetTherapists(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'therapistName'
        | 'totalSessions'
        | 'firstSessionDate'
        | 'lastSessionDate',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PatientTherapistsShortInfosResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patientsControllerGetTherapists(
          limit,
          page,
          order,
          orderTerms,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get total session (only for patient)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {Array<string>} [statuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerGetTotalSessionSession(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      statuses?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedSessionResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patientsControllerGetTotalSessionSession(
          limit,
          page,
          order,
          from,
          to,
          statuses,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Onboarding (only for patients)
     * @param {PatientOnboardingRequestDto} patientOnboardingRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerOnboarding(
      patientOnboardingRequestDto: PatientOnboardingRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PatientProfileResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patientsControllerOnboarding(
          patientOnboardingRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update avatar (only for patients)
     * @param {UpdateAvatarRequestDto} updateAvatarRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerUpdateAvatar(
      updateAvatarRequestDto: UpdateAvatarRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PatientProfileResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patientsControllerUpdateAvatar(
          updateAvatarRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update self information
     * @param {UpdatePatientProfileRequestDto} updatePatientProfileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patientsControllerUpdateOne(
      updatePatientProfileRequestDto: UpdatePatientProfileRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PatientProfileResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.patientsControllerUpdateOne(
          updatePatientProfileRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PatientsApi - factory interface
 * @export
 */
export const PatientsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PatientsApiFp(configuration);
  return {
    /**
     *
     * @summary Create recommendation filter (only for patients)
     * @param {CreateRecommendedFilterRequestDto} createRecommendedFilterRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerAddRecommendedFilter(
      createRecommendedFilterRequestDto: CreateRecommendedFilterRequestDto,
      options?: any,
    ): AxiosPromise<PatientProfileResponseDto> {
      return localVarFp
        .patientsControllerAddRecommendedFilter(
          createRecommendedFilterRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete avatar (only for patients)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerDeleteAvatar(
      options?: any,
    ): AxiosPromise<PatientProfileResponseDto> {
      return localVarFp
        .patientsControllerDeleteAvatar(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get active sessions (only for patient)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [therapistFirstname]
     * @param {string} [therapistLastname]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetActiveSessions(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      therapistFirstname?: string,
      therapistLastname?: string,
      options?: any,
    ): AxiosPromise<PaginatedSessionResponseDto> {
      return localVarFp
        .patientsControllerGetActiveSessions(
          limit,
          page,
          order,
          from,
          to,
          therapistFirstname,
          therapistLastname,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get patient profile (only for sessioned therapists)
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetPatient(
      patientId: string,
      options?: any,
    ): AxiosPromise<PublicPatientProfileResponseDto> {
      return localVarFp
        .patientsControllerGetPatient(patientId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get patient profile (only for patient)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetPatientProfile(
      options?: any,
    ): AxiosPromise<PatientProfileResponseDto> {
      return localVarFp
        .patientsControllerGetPatientProfile(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get session history (only for patient)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [therapistFirstname]
     * @param {string} [therapistLastname]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetSessionHistory(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      therapistFirstname?: string,
      therapistLastname?: string,
      options?: any,
    ): AxiosPromise<PaginatedSessionResponseDto> {
      return localVarFp
        .patientsControllerGetSessionHistory(
          limit,
          page,
          order,
          from,
          to,
          therapistFirstname,
          therapistLastname,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get tiny sessions (only for patient)
     * @param {boolean} isActive
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetSessions(
      isActive: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<SessionsWithStartDate> {
      return localVarFp
        .patientsControllerGetSessions(isActive, from, to, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get patient\'s therapists (only for patients)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'therapistName' | 'totalSessions' | 'firstSessionDate' | 'lastSessionDate'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetTherapists(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'therapistName'
        | 'totalSessions'
        | 'firstSessionDate'
        | 'lastSessionDate',
      options?: any,
    ): AxiosPromise<PatientTherapistsShortInfosResponseDto> {
      return localVarFp
        .patientsControllerGetTherapists(
          limit,
          page,
          order,
          orderTerms,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get total session (only for patient)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {Array<string>} [statuses]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerGetTotalSessionSession(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      statuses?: Array<string>,
      options?: any,
    ): AxiosPromise<PaginatedSessionResponseDto> {
      return localVarFp
        .patientsControllerGetTotalSessionSession(
          limit,
          page,
          order,
          from,
          to,
          statuses,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Onboarding (only for patients)
     * @param {PatientOnboardingRequestDto} patientOnboardingRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerOnboarding(
      patientOnboardingRequestDto: PatientOnboardingRequestDto,
      options?: any,
    ): AxiosPromise<PatientProfileResponseDto> {
      return localVarFp
        .patientsControllerOnboarding(patientOnboardingRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update avatar (only for patients)
     * @param {UpdateAvatarRequestDto} updateAvatarRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerUpdateAvatar(
      updateAvatarRequestDto: UpdateAvatarRequestDto,
      options?: any,
    ): AxiosPromise<PatientProfileResponseDto> {
      return localVarFp
        .patientsControllerUpdateAvatar(updateAvatarRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update self information
     * @param {UpdatePatientProfileRequestDto} updatePatientProfileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patientsControllerUpdateOne(
      updatePatientProfileRequestDto: UpdatePatientProfileRequestDto,
      options?: any,
    ): AxiosPromise<PatientProfileResponseDto> {
      return localVarFp
        .patientsControllerUpdateOne(updatePatientProfileRequestDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PatientsApi - object-oriented interface
 * @export
 * @class PatientsApi
 * @extends {BaseAPI}
 */
export class PatientsApi extends BaseAPI {
  /**
   *
   * @summary Create recommendation filter (only for patients)
   * @param {CreateRecommendedFilterRequestDto} createRecommendedFilterRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientsApi
   */
  public patientsControllerAddRecommendedFilter(
    createRecommendedFilterRequestDto: CreateRecommendedFilterRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return PatientsApiFp(this.configuration)
      .patientsControllerAddRecommendedFilter(
        createRecommendedFilterRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete avatar (only for patients)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientsApi
   */
  public patientsControllerDeleteAvatar(options?: AxiosRequestConfig) {
    return PatientsApiFp(this.configuration)
      .patientsControllerDeleteAvatar(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get active sessions (only for patient)
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [therapistFirstname]
   * @param {string} [therapistLastname]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientsApi
   */
  public patientsControllerGetActiveSessions(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    from?: string,
    to?: string,
    therapistFirstname?: string,
    therapistLastname?: string,
    options?: AxiosRequestConfig,
  ) {
    return PatientsApiFp(this.configuration)
      .patientsControllerGetActiveSessions(
        limit,
        page,
        order,
        from,
        to,
        therapistFirstname,
        therapistLastname,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get patient profile (only for sessioned therapists)
   * @param {string} patientId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientsApi
   */
  public patientsControllerGetPatient(
    patientId: string,
    options?: AxiosRequestConfig,
  ) {
    return PatientsApiFp(this.configuration)
      .patientsControllerGetPatient(patientId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get patient profile (only for patient)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientsApi
   */
  public patientsControllerGetPatientProfile(options?: AxiosRequestConfig) {
    return PatientsApiFp(this.configuration)
      .patientsControllerGetPatientProfile(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get session history (only for patient)
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [therapistFirstname]
   * @param {string} [therapistLastname]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientsApi
   */
  public patientsControllerGetSessionHistory(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    from?: string,
    to?: string,
    therapistFirstname?: string,
    therapistLastname?: string,
    options?: AxiosRequestConfig,
  ) {
    return PatientsApiFp(this.configuration)
      .patientsControllerGetSessionHistory(
        limit,
        page,
        order,
        from,
        to,
        therapistFirstname,
        therapistLastname,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get tiny sessions (only for patient)
   * @param {boolean} isActive
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientsApi
   */
  public patientsControllerGetSessions(
    isActive: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return PatientsApiFp(this.configuration)
      .patientsControllerGetSessions(isActive, from, to, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get patient\'s therapists (only for patients)
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {'therapistName' | 'totalSessions' | 'firstSessionDate' | 'lastSessionDate'} [orderTerms]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientsApi
   */
  public patientsControllerGetTherapists(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    orderTerms?:
      | 'therapistName'
      | 'totalSessions'
      | 'firstSessionDate'
      | 'lastSessionDate',
    options?: AxiosRequestConfig,
  ) {
    return PatientsApiFp(this.configuration)
      .patientsControllerGetTherapists(limit, page, order, orderTerms, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get total session (only for patient)
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {string} [from]
   * @param {string} [to]
   * @param {Array<string>} [statuses]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientsApi
   */
  public patientsControllerGetTotalSessionSession(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    from?: string,
    to?: string,
    statuses?: Array<string>,
    options?: AxiosRequestConfig,
  ) {
    return PatientsApiFp(this.configuration)
      .patientsControllerGetTotalSessionSession(
        limit,
        page,
        order,
        from,
        to,
        statuses,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Onboarding (only for patients)
   * @param {PatientOnboardingRequestDto} patientOnboardingRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientsApi
   */
  public patientsControllerOnboarding(
    patientOnboardingRequestDto: PatientOnboardingRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return PatientsApiFp(this.configuration)
      .patientsControllerOnboarding(patientOnboardingRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update avatar (only for patients)
   * @param {UpdateAvatarRequestDto} updateAvatarRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientsApi
   */
  public patientsControllerUpdateAvatar(
    updateAvatarRequestDto: UpdateAvatarRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return PatientsApiFp(this.configuration)
      .patientsControllerUpdateAvatar(updateAvatarRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update self information
   * @param {UpdatePatientProfileRequestDto} updatePatientProfileRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PatientsApi
   */
  public patientsControllerUpdateOne(
    updatePatientProfileRequestDto: UpdatePatientProfileRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return PatientsApiFp(this.configuration)
      .patientsControllerUpdateOne(updatePatientProfileRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete credit card info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentsControllerDeletePaymentMethod: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/payments/payment_method`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get payment list
     * @param {number} limit
     * @param {number} page
     * @param {boolean} isSender
     * @param {'ASC' | 'DESC'} [order]
     * @param {'startDate' | 'value' | 'createdAt' | 'dealStartDate'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentsControllerGetPaymentList: async (
      limit: number,
      page: number,
      isSender: boolean,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'startDate' | 'value' | 'createdAt' | 'dealStartDate',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('paymentsControllerGetPaymentList', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('paymentsControllerGetPaymentList', 'page', page);
      // verify required parameter 'isSender' is not null or undefined
      assertParamExists(
        'paymentsControllerGetPaymentList',
        'isSender',
        isSender,
      );
      const localVarPath = `/api/v1/payments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (orderTerms !== undefined) {
        localVarQueryParameter['orderTerms'] = orderTerms;
      }

      if (isSender !== undefined) {
        localVarQueryParameter['isSender'] = isSender;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get credit card info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentsControllerGetPaymentMethod: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/payments/payment_method`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get checkout url for attach new credit card or replace old
     * @param {CheckoutLinkRequestDto} checkoutLinkRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentsControllerGetPaymentMethodCheckoutUrl: async (
      checkoutLinkRequestDto: CheckoutLinkRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'checkoutLinkRequestDto' is not null or undefined
      assertParamExists(
        'paymentsControllerGetPaymentMethodCheckoutUrl',
        'checkoutLinkRequestDto',
        checkoutLinkRequestDto,
      );
      const localVarPath = `/api/v1/payments/payment_method`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkoutLinkRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get yearly payment statistics for therapist
     * @param {number} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentsControllerGetYearlyStatistics: async (
      year: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      assertParamExists('paymentsControllerGetYearlyStatistics', 'year', year);
      const localVarPath = `/api/v1/payments/statistics`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete credit card info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentsControllerDeletePaymentMethod(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.paymentsControllerDeletePaymentMethod(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get payment list
     * @param {number} limit
     * @param {number} page
     * @param {boolean} isSender
     * @param {'ASC' | 'DESC'} [order]
     * @param {'startDate' | 'value' | 'createdAt' | 'dealStartDate'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentsControllerGetPaymentList(
      limit: number,
      page: number,
      isSender: boolean,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'startDate' | 'value' | 'createdAt' | 'dealStartDate',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaymentsListResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.paymentsControllerGetPaymentList(
          limit,
          page,
          isSender,
          order,
          orderTerms,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get credit card info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentsControllerGetPaymentMethod(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaymentMethodResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.paymentsControllerGetPaymentMethod(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get checkout url for attach new credit card or replace old
     * @param {CheckoutLinkRequestDto} checkoutLinkRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentsControllerGetPaymentMethodCheckoutUrl(
      checkoutLinkRequestDto: CheckoutLinkRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CheckoutPaymentMethodResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.paymentsControllerGetPaymentMethodCheckoutUrl(
          checkoutLinkRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get yearly payment statistics for therapist
     * @param {number} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paymentsControllerGetYearlyStatistics(
      year: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaymentStatisticsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.paymentsControllerGetYearlyStatistics(
          year,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PaymentsApiFp(configuration);
  return {
    /**
     *
     * @summary Delete credit card info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentsControllerDeletePaymentMethod(
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .paymentsControllerDeletePaymentMethod(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get payment list
     * @param {number} limit
     * @param {number} page
     * @param {boolean} isSender
     * @param {'ASC' | 'DESC'} [order]
     * @param {'startDate' | 'value' | 'createdAt' | 'dealStartDate'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentsControllerGetPaymentList(
      limit: number,
      page: number,
      isSender: boolean,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'startDate' | 'value' | 'createdAt' | 'dealStartDate',
      options?: any,
    ): AxiosPromise<PaymentsListResponseDto> {
      return localVarFp
        .paymentsControllerGetPaymentList(
          limit,
          page,
          isSender,
          order,
          orderTerms,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get credit card info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentsControllerGetPaymentMethod(
      options?: any,
    ): AxiosPromise<PaymentMethodResponseDto> {
      return localVarFp
        .paymentsControllerGetPaymentMethod(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get checkout url for attach new credit card or replace old
     * @param {CheckoutLinkRequestDto} checkoutLinkRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentsControllerGetPaymentMethodCheckoutUrl(
      checkoutLinkRequestDto: CheckoutLinkRequestDto,
      options?: any,
    ): AxiosPromise<CheckoutPaymentMethodResponseDto> {
      return localVarFp
        .paymentsControllerGetPaymentMethodCheckoutUrl(
          checkoutLinkRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get yearly payment statistics for therapist
     * @param {number} year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paymentsControllerGetYearlyStatistics(
      year: number,
      options?: any,
    ): AxiosPromise<PaymentStatisticsResponseDto> {
      return localVarFp
        .paymentsControllerGetYearlyStatistics(year, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
  /**
   *
   * @summary Delete credit card info
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public paymentsControllerDeletePaymentMethod(options?: AxiosRequestConfig) {
    return PaymentsApiFp(this.configuration)
      .paymentsControllerDeletePaymentMethod(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get payment list
   * @param {number} limit
   * @param {number} page
   * @param {boolean} isSender
   * @param {'ASC' | 'DESC'} [order]
   * @param {'startDate' | 'value' | 'createdAt' | 'dealStartDate'} [orderTerms]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public paymentsControllerGetPaymentList(
    limit: number,
    page: number,
    isSender: boolean,
    order?: 'ASC' | 'DESC',
    orderTerms?: 'startDate' | 'value' | 'createdAt' | 'dealStartDate',
    options?: AxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .paymentsControllerGetPaymentList(
        limit,
        page,
        isSender,
        order,
        orderTerms,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get credit card info
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public paymentsControllerGetPaymentMethod(options?: AxiosRequestConfig) {
    return PaymentsApiFp(this.configuration)
      .paymentsControllerGetPaymentMethod(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get checkout url for attach new credit card or replace old
   * @param {CheckoutLinkRequestDto} checkoutLinkRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public paymentsControllerGetPaymentMethodCheckoutUrl(
    checkoutLinkRequestDto: CheckoutLinkRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .paymentsControllerGetPaymentMethodCheckoutUrl(
        checkoutLinkRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get yearly payment statistics for therapist
   * @param {number} year
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentsApi
   */
  public paymentsControllerGetYearlyStatistics(
    year: number,
    options?: AxiosRequestConfig,
  ) {
    return PaymentsApiFp(this.configuration)
      .paymentsControllerGetYearlyStatistics(year, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PromocodeApi - axios parameter creator
 * @export
 */
export const PromocodeApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Check promocode
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promocodesControllerCheckPromocode: async (
      code: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('promocodesControllerCheckPromocode', 'code', code);
      const localVarPath = `/api/v1/promocodes/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PromocodeApi - functional programming interface
 * @export
 */
export const PromocodeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PromocodeApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Check promocode
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async promocodesControllerCheckPromocode(
      code: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PromocodeResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.promocodesControllerCheckPromocode(
          code,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * PromocodeApi - factory interface
 * @export
 */
export const PromocodeApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PromocodeApiFp(configuration);
  return {
    /**
     *
     * @summary Check promocode
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    promocodesControllerCheckPromocode(
      code: string,
      options?: any,
    ): AxiosPromise<PromocodeResponseDto> {
      return localVarFp
        .promocodesControllerCheckPromocode(code, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PromocodeApi - object-oriented interface
 * @export
 * @class PromocodeApi
 * @extends {BaseAPI}
 */
export class PromocodeApi extends BaseAPI {
  /**
   *
   * @summary Check promocode
   * @param {string} code
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PromocodeApi
   */
  public promocodesControllerCheckPromocode(
    code: string,
    options?: AxiosRequestConfig,
  ) {
    return PromocodeApiFp(this.configuration)
      .promocodesControllerCheckPromocode(code, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SessionPaymentApi - axios parameter creator
 * @export
 */
export const SessionPaymentApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Book session (only for patient)
     * @param {BookSessionRequestDto} bookSessionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionPaymentsControllerBookSession: async (
      bookSessionRequestDto: BookSessionRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'bookSessionRequestDto' is not null or undefined
      assertParamExists(
        'sessionPaymentsControllerBookSession',
        'bookSessionRequestDto',
        bookSessionRequestDto,
      );
      const localVarPath = `/api/v1/session-payment`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bookSessionRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cancel session
     * @param {string} sessionId
     * @param {CancelSessionRequestDto} cancelSessionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionPaymentsControllerCancelSession: async (
      sessionId: string,
      cancelSessionRequestDto: CancelSessionRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists(
        'sessionPaymentsControllerCancelSession',
        'sessionId',
        sessionId,
      );
      // verify required parameter 'cancelSessionRequestDto' is not null or undefined
      assertParamExists(
        'sessionPaymentsControllerCancelSession',
        'cancelSessionRequestDto',
        cancelSessionRequestDto,
      );
      const localVarPath = `/api/v1/session-payment/{sessionId}/cancel`.replace(
        `{${'sessionId'}}`,
        encodeURIComponent(String(sessionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cancelSessionRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get upcoming session payment info (only for patients)
     * @param {string} availabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionPaymentsControllerGetUpcomingSessionPaymentInfo: async (
      availabilityId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'availabilityId' is not null or undefined
      assertParamExists(
        'sessionPaymentsControllerGetUpcomingSessionPaymentInfo',
        'availabilityId',
        availabilityId,
      );
      const localVarPath =
        `/api/v1/session-payment/upcoming/{availabilityId}`.replace(
          `{${'availabilityId'}}`,
          encodeURIComponent(String(availabilityId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Shift session time
     * @param {string} sessionId
     * @param {ShiftSessionTimeRequestDto} shiftSessionTimeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionPaymentsControllerShiftSessionTime: async (
      sessionId: string,
      shiftSessionTimeRequestDto: ShiftSessionTimeRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists(
        'sessionPaymentsControllerShiftSessionTime',
        'sessionId',
        sessionId,
      );
      // verify required parameter 'shiftSessionTimeRequestDto' is not null or undefined
      assertParamExists(
        'sessionPaymentsControllerShiftSessionTime',
        'shiftSessionTimeRequestDto',
        shiftSessionTimeRequestDto,
      );
      const localVarPath =
        `/api/v1/session-payment/{sessionId}/time_shift`.replace(
          `{${'sessionId'}}`,
          encodeURIComponent(String(sessionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        shiftSessionTimeRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SessionPaymentApi - functional programming interface
 * @export
 */
export const SessionPaymentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SessionPaymentApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Book session (only for patient)
     * @param {BookSessionRequestDto} bookSessionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionPaymentsControllerBookSession(
      bookSessionRequestDto: BookSessionRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionPaymentsControllerBookSession(
          bookSessionRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cancel session
     * @param {string} sessionId
     * @param {CancelSessionRequestDto} cancelSessionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionPaymentsControllerCancelSession(
      sessionId: string,
      cancelSessionRequestDto: CancelSessionRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionBaseResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionPaymentsControllerCancelSession(
          sessionId,
          cancelSessionRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get upcoming session payment info (only for patients)
     * @param {string} availabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionPaymentsControllerGetUpcomingSessionPaymentInfo(
      availabilityId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UpcomingSessionPaymentInfoResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionPaymentsControllerGetUpcomingSessionPaymentInfo(
          availabilityId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Shift session time
     * @param {string} sessionId
     * @param {ShiftSessionTimeRequestDto} shiftSessionTimeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionPaymentsControllerShiftSessionTime(
      sessionId: string,
      shiftSessionTimeRequestDto: ShiftSessionTimeRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionBaseResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionPaymentsControllerShiftSessionTime(
          sessionId,
          shiftSessionTimeRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SessionPaymentApi - factory interface
 * @export
 */
export const SessionPaymentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SessionPaymentApiFp(configuration);
  return {
    /**
     *
     * @summary Book session (only for patient)
     * @param {BookSessionRequestDto} bookSessionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionPaymentsControllerBookSession(
      bookSessionRequestDto: BookSessionRequestDto,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .sessionPaymentsControllerBookSession(bookSessionRequestDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cancel session
     * @param {string} sessionId
     * @param {CancelSessionRequestDto} cancelSessionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionPaymentsControllerCancelSession(
      sessionId: string,
      cancelSessionRequestDto: CancelSessionRequestDto,
      options?: any,
    ): AxiosPromise<SessionBaseResponseDto> {
      return localVarFp
        .sessionPaymentsControllerCancelSession(
          sessionId,
          cancelSessionRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get upcoming session payment info (only for patients)
     * @param {string} availabilityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionPaymentsControllerGetUpcomingSessionPaymentInfo(
      availabilityId: string,
      options?: any,
    ): AxiosPromise<UpcomingSessionPaymentInfoResponseDto> {
      return localVarFp
        .sessionPaymentsControllerGetUpcomingSessionPaymentInfo(
          availabilityId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Shift session time
     * @param {string} sessionId
     * @param {ShiftSessionTimeRequestDto} shiftSessionTimeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionPaymentsControllerShiftSessionTime(
      sessionId: string,
      shiftSessionTimeRequestDto: ShiftSessionTimeRequestDto,
      options?: any,
    ): AxiosPromise<SessionBaseResponseDto> {
      return localVarFp
        .sessionPaymentsControllerShiftSessionTime(
          sessionId,
          shiftSessionTimeRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SessionPaymentApi - object-oriented interface
 * @export
 * @class SessionPaymentApi
 * @extends {BaseAPI}
 */
export class SessionPaymentApi extends BaseAPI {
  /**
   *
   * @summary Book session (only for patient)
   * @param {BookSessionRequestDto} bookSessionRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionPaymentApi
   */
  public sessionPaymentsControllerBookSession(
    bookSessionRequestDto: BookSessionRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return SessionPaymentApiFp(this.configuration)
      .sessionPaymentsControllerBookSession(bookSessionRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cancel session
   * @param {string} sessionId
   * @param {CancelSessionRequestDto} cancelSessionRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionPaymentApi
   */
  public sessionPaymentsControllerCancelSession(
    sessionId: string,
    cancelSessionRequestDto: CancelSessionRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return SessionPaymentApiFp(this.configuration)
      .sessionPaymentsControllerCancelSession(
        sessionId,
        cancelSessionRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get upcoming session payment info (only for patients)
   * @param {string} availabilityId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionPaymentApi
   */
  public sessionPaymentsControllerGetUpcomingSessionPaymentInfo(
    availabilityId: string,
    options?: AxiosRequestConfig,
  ) {
    return SessionPaymentApiFp(this.configuration)
      .sessionPaymentsControllerGetUpcomingSessionPaymentInfo(
        availabilityId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Shift session time
   * @param {string} sessionId
   * @param {ShiftSessionTimeRequestDto} shiftSessionTimeRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionPaymentApi
   */
  public sessionPaymentsControllerShiftSessionTime(
    sessionId: string,
    shiftSessionTimeRequestDto: ShiftSessionTimeRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return SessionPaymentApiFp(this.configuration)
      .sessionPaymentsControllerShiftSessionTime(
        sessionId,
        shiftSessionTimeRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SessionsApi - axios parameter creator
 * @export
 */
export const SessionsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Accept session request (only for therapist)
     * @param {string} sessionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerAcceptSessionRequest: async (
      sessionRequestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionRequestId' is not null or undefined
      assertParamExists(
        'sessionsControllerAcceptSessionRequest',
        'sessionRequestId',
        sessionRequestId,
      );
      const localVarPath =
        `/api/v1/sessions/request/{sessionRequestId}/accept`.replace(
          `{${'sessionRequestId'}}`,
          encodeURIComponent(String(sessionRequestId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add session note
     * @param {string} sessionId
     * @param {UpdateSessionNoteRequestDto} updateSessionNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerAddSessionNotes: async (
      sessionId: string,
      updateSessionNoteRequestDto: UpdateSessionNoteRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists(
        'sessionsControllerAddSessionNotes',
        'sessionId',
        sessionId,
      );
      // verify required parameter 'updateSessionNoteRequestDto' is not null or undefined
      assertParamExists(
        'sessionsControllerAddSessionNotes',
        'updateSessionNoteRequestDto',
        updateSessionNoteRequestDto,
      );
      const localVarPath = `/api/v1/sessions/{sessionId}/notes`.replace(
        `{${'sessionId'}}`,
        encodeURIComponent(String(sessionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateSessionNoteRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Add session outcome (only for patient)
     * @param {string} sessionId
     * @param {AddSessionOutcomeRequestDto} addSessionOutcomeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerAddSessionOutcome: async (
      sessionId: string,
      addSessionOutcomeRequestDto: AddSessionOutcomeRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists(
        'sessionsControllerAddSessionOutcome',
        'sessionId',
        sessionId,
      );
      // verify required parameter 'addSessionOutcomeRequestDto' is not null or undefined
      assertParamExists(
        'sessionsControllerAddSessionOutcome',
        'addSessionOutcomeRequestDto',
        addSessionOutcomeRequestDto,
      );
      const localVarPath = `/api/v1/sessions/{sessionId}/outcome`.replace(
        `{${'sessionId'}}`,
        encodeURIComponent(String(sessionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addSessionOutcomeRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cancel session request (only for patient)
     * @param {string} sessionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerCancelSessionRequest: async (
      sessionRequestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionRequestId' is not null or undefined
      assertParamExists(
        'sessionsControllerCancelSessionRequest',
        'sessionRequestId',
        sessionRequestId,
      );
      const localVarPath =
        `/api/v1/sessions/request/{sessionRequestId}/cancel`.replace(
          `{${'sessionRequestId'}}`,
          encodeURIComponent(String(sessionRequestId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Complete session
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerCompleteSession: async (
      sessionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists(
        'sessionsControllerCompleteSession',
        'sessionId',
        sessionId,
      );
      const localVarPath = `/api/v1/sessions/{sessionId}/complete`.replace(
        `{${'sessionId'}}`,
        encodeURIComponent(String(sessionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Accept session request (only for therapist)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerCreateTestSession: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/sessions/get-test-session-token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Archive sessions with therapist (only for patient)
     * @param {string} therapistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerDeleteSession: async (
      therapistId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'therapistId' is not null or undefined
      assertParamExists(
        'sessionsControllerDeleteSession',
        'therapistId',
        therapistId,
      );
      const localVarPath = `/api/v1/sessions/therapist/{therapistId}`.replace(
        `{${'therapistId'}}`,
        encodeURIComponent(String(therapistId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete session notes
     * @param {string} notesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerDeleteSessionNotes: async (
      notesId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'notesId' is not null or undefined
      assertParamExists(
        'sessionsControllerDeleteSessionNotes',
        'notesId',
        notesId,
      );
      const localVarPath =
        `/api/v1/sessions/{sessionId}/notes/{notesId}`.replace(
          `{${'notesId'}}`,
          encodeURIComponent(String(notesId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update session notes
     * @param {string} notesId
     * @param {UpdateSessionNoteRequestDto} updateSessionNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerEditSessionNote: async (
      notesId: string,
      updateSessionNoteRequestDto: UpdateSessionNoteRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'notesId' is not null or undefined
      assertParamExists(
        'sessionsControllerEditSessionNote',
        'notesId',
        notesId,
      );
      // verify required parameter 'updateSessionNoteRequestDto' is not null or undefined
      assertParamExists(
        'sessionsControllerEditSessionNote',
        'updateSessionNoteRequestDto',
        updateSessionNoteRequestDto,
      );
      const localVarPath = `/api/v1/sessions/notes/{notesId}`.replace(
        `{${'notesId'}}`,
        encodeURIComponent(String(notesId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateSessionNoteRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get session notes
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerGetSessionNotes: async (
      sessionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists(
        'sessionsControllerGetSessionNotes',
        'sessionId',
        sessionId,
      );
      const localVarPath = `/api/v1/sessions/{sessionId}/notes`.replace(
        `{${'sessionId'}}`,
        encodeURIComponent(String(sessionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get sessions by therapist id
     * @param {string} therapistId
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerGetSessionsByTherapistId: async (
      therapistId: string,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'therapistId' is not null or undefined
      assertParamExists(
        'sessionsControllerGetSessionsByTherapistId',
        'therapistId',
        therapistId,
      );
      const localVarPath = `/api/v1/sessions/therapist/{therapistId}`.replace(
        `{${'therapistId'}}`,
        encodeURIComponent(String(therapistId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] =
          (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start/join session
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerJoinSession: async (
      sessionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionId' is not null or undefined
      assertParamExists(
        'sessionsControllerJoinSession',
        'sessionId',
        sessionId,
      );
      const localVarPath = `/api/v1/sessions/{sessionId}/join`.replace(
        `{${'sessionId'}}`,
        encodeURIComponent(String(sessionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reject session request (only for therapist)
     * @param {string} sessionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerRejectSessionRequest: async (
      sessionRequestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sessionRequestId' is not null or undefined
      assertParamExists(
        'sessionsControllerRejectSessionRequest',
        'sessionRequestId',
        sessionRequestId,
      );
      const localVarPath =
        `/api/v1/sessions/request/{sessionRequestId}/reject`.replace(
          `{${'sessionRequestId'}}`,
          encodeURIComponent(String(sessionRequestId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SessionsApi - functional programming interface
 * @export
 */
export const SessionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SessionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Accept session request (only for therapist)
     * @param {string} sessionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionsControllerAcceptSessionRequest(
      sessionRequestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionCreateResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionsControllerAcceptSessionRequest(
          sessionRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Add session note
     * @param {string} sessionId
     * @param {UpdateSessionNoteRequestDto} updateSessionNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionsControllerAddSessionNotes(
      sessionId: string,
      updateSessionNoteRequestDto: UpdateSessionNoteRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionNoteResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionsControllerAddSessionNotes(
          sessionId,
          updateSessionNoteRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Add session outcome (only for patient)
     * @param {string} sessionId
     * @param {AddSessionOutcomeRequestDto} addSessionOutcomeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionsControllerAddSessionOutcome(
      sessionId: string,
      addSessionOutcomeRequestDto: AddSessionOutcomeRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionsControllerAddSessionOutcome(
          sessionId,
          addSessionOutcomeRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cancel session request (only for patient)
     * @param {string} sessionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionsControllerCancelSessionRequest(
      sessionRequestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionRequestResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionsControllerCancelSessionRequest(
          sessionRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Complete session
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionsControllerCompleteSession(
      sessionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionsControllerCompleteSession(
          sessionId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Accept session request (only for therapist)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionsControllerCreateTestSession(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateTestSessionResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionsControllerCreateTestSession(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Archive sessions with therapist (only for patient)
     * @param {string} therapistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionsControllerDeleteSession(
      therapistId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionsControllerDeleteSession(
          therapistId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete session notes
     * @param {string} notesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionsControllerDeleteSessionNotes(
      notesId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionNoteResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionsControllerDeleteSessionNotes(
          notesId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update session notes
     * @param {string} notesId
     * @param {UpdateSessionNoteRequestDto} updateSessionNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionsControllerEditSessionNote(
      notesId: string,
      updateSessionNoteRequestDto: UpdateSessionNoteRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionNoteResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionsControllerEditSessionNote(
          notesId,
          updateSessionNoteRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get session notes
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionsControllerGetSessionNotes(
      sessionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionNotesResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionsControllerGetSessionNotes(
          sessionId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get sessions by therapist id
     * @param {string} therapistId
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionsControllerGetSessionsByTherapistId(
      therapistId: string,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionsControllerGetSessionsByTherapistId(
          therapistId,
          from,
          to,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Start/join session
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionsControllerJoinSession(
      sessionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JoinSessionResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionsControllerJoinSession(
          sessionId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Reject session request (only for therapist)
     * @param {string} sessionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sessionsControllerRejectSessionRequest(
      sessionRequestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionRequestResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.sessionsControllerRejectSessionRequest(
          sessionRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SessionsApi - factory interface
 * @export
 */
export const SessionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SessionsApiFp(configuration);
  return {
    /**
     *
     * @summary Accept session request (only for therapist)
     * @param {string} sessionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerAcceptSessionRequest(
      sessionRequestId: string,
      options?: any,
    ): AxiosPromise<SessionCreateResponseDto> {
      return localVarFp
        .sessionsControllerAcceptSessionRequest(sessionRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add session note
     * @param {string} sessionId
     * @param {UpdateSessionNoteRequestDto} updateSessionNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerAddSessionNotes(
      sessionId: string,
      updateSessionNoteRequestDto: UpdateSessionNoteRequestDto,
      options?: any,
    ): AxiosPromise<SessionNoteResponseDto> {
      return localVarFp
        .sessionsControllerAddSessionNotes(
          sessionId,
          updateSessionNoteRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Add session outcome (only for patient)
     * @param {string} sessionId
     * @param {AddSessionOutcomeRequestDto} addSessionOutcomeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerAddSessionOutcome(
      sessionId: string,
      addSessionOutcomeRequestDto: AddSessionOutcomeRequestDto,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .sessionsControllerAddSessionOutcome(
          sessionId,
          addSessionOutcomeRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cancel session request (only for patient)
     * @param {string} sessionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerCancelSessionRequest(
      sessionRequestId: string,
      options?: any,
    ): AxiosPromise<SessionRequestResponseDto> {
      return localVarFp
        .sessionsControllerCancelSessionRequest(sessionRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Complete session
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerCompleteSession(
      sessionId: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .sessionsControllerCompleteSession(sessionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Accept session request (only for therapist)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerCreateTestSession(
      options?: any,
    ): AxiosPromise<CreateTestSessionResponse> {
      return localVarFp
        .sessionsControllerCreateTestSession(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Archive sessions with therapist (only for patient)
     * @param {string} therapistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerDeleteSession(
      therapistId: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .sessionsControllerDeleteSession(therapistId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete session notes
     * @param {string} notesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerDeleteSessionNotes(
      notesId: string,
      options?: any,
    ): AxiosPromise<SessionNoteResponseDto> {
      return localVarFp
        .sessionsControllerDeleteSessionNotes(notesId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update session notes
     * @param {string} notesId
     * @param {UpdateSessionNoteRequestDto} updateSessionNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerEditSessionNote(
      notesId: string,
      updateSessionNoteRequestDto: UpdateSessionNoteRequestDto,
      options?: any,
    ): AxiosPromise<SessionNoteResponseDto> {
      return localVarFp
        .sessionsControllerEditSessionNote(
          notesId,
          updateSessionNoteRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get session notes
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerGetSessionNotes(
      sessionId: string,
      options?: any,
    ): AxiosPromise<SessionNotesResponseDto> {
      return localVarFp
        .sessionsControllerGetSessionNotes(sessionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get sessions by therapist id
     * @param {string} therapistId
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerGetSessionsByTherapistId(
      therapistId: string,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<SessionsResponseDto> {
      return localVarFp
        .sessionsControllerGetSessionsByTherapistId(
          therapistId,
          from,
          to,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Start/join session
     * @param {string} sessionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerJoinSession(
      sessionId: string,
      options?: any,
    ): AxiosPromise<JoinSessionResponseDto> {
      return localVarFp
        .sessionsControllerJoinSession(sessionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reject session request (only for therapist)
     * @param {string} sessionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sessionsControllerRejectSessionRequest(
      sessionRequestId: string,
      options?: any,
    ): AxiosPromise<SessionRequestResponseDto> {
      return localVarFp
        .sessionsControllerRejectSessionRequest(sessionRequestId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SessionsApi - object-oriented interface
 * @export
 * @class SessionsApi
 * @extends {BaseAPI}
 */
export class SessionsApi extends BaseAPI {
  /**
   *
   * @summary Accept session request (only for therapist)
   * @param {string} sessionRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionsApi
   */
  public sessionsControllerAcceptSessionRequest(
    sessionRequestId: string,
    options?: AxiosRequestConfig,
  ) {
    return SessionsApiFp(this.configuration)
      .sessionsControllerAcceptSessionRequest(sessionRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add session note
   * @param {string} sessionId
   * @param {UpdateSessionNoteRequestDto} updateSessionNoteRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionsApi
   */
  public sessionsControllerAddSessionNotes(
    sessionId: string,
    updateSessionNoteRequestDto: UpdateSessionNoteRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return SessionsApiFp(this.configuration)
      .sessionsControllerAddSessionNotes(
        sessionId,
        updateSessionNoteRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Add session outcome (only for patient)
   * @param {string} sessionId
   * @param {AddSessionOutcomeRequestDto} addSessionOutcomeRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionsApi
   */
  public sessionsControllerAddSessionOutcome(
    sessionId: string,
    addSessionOutcomeRequestDto: AddSessionOutcomeRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return SessionsApiFp(this.configuration)
      .sessionsControllerAddSessionOutcome(
        sessionId,
        addSessionOutcomeRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cancel session request (only for patient)
   * @param {string} sessionRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionsApi
   */
  public sessionsControllerCancelSessionRequest(
    sessionRequestId: string,
    options?: AxiosRequestConfig,
  ) {
    return SessionsApiFp(this.configuration)
      .sessionsControllerCancelSessionRequest(sessionRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Complete session
   * @param {string} sessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionsApi
   */
  public sessionsControllerCompleteSession(
    sessionId: string,
    options?: AxiosRequestConfig,
  ) {
    return SessionsApiFp(this.configuration)
      .sessionsControllerCompleteSession(sessionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Accept session request (only for therapist)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionsApi
   */
  public sessionsControllerCreateTestSession(options?: AxiosRequestConfig) {
    return SessionsApiFp(this.configuration)
      .sessionsControllerCreateTestSession(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Archive sessions with therapist (only for patient)
   * @param {string} therapistId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionsApi
   */
  public sessionsControllerDeleteSession(
    therapistId: string,
    options?: AxiosRequestConfig,
  ) {
    return SessionsApiFp(this.configuration)
      .sessionsControllerDeleteSession(therapistId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete session notes
   * @param {string} notesId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionsApi
   */
  public sessionsControllerDeleteSessionNotes(
    notesId: string,
    options?: AxiosRequestConfig,
  ) {
    return SessionsApiFp(this.configuration)
      .sessionsControllerDeleteSessionNotes(notesId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update session notes
   * @param {string} notesId
   * @param {UpdateSessionNoteRequestDto} updateSessionNoteRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionsApi
   */
  public sessionsControllerEditSessionNote(
    notesId: string,
    updateSessionNoteRequestDto: UpdateSessionNoteRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return SessionsApiFp(this.configuration)
      .sessionsControllerEditSessionNote(
        notesId,
        updateSessionNoteRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get session notes
   * @param {string} sessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionsApi
   */
  public sessionsControllerGetSessionNotes(
    sessionId: string,
    options?: AxiosRequestConfig,
  ) {
    return SessionsApiFp(this.configuration)
      .sessionsControllerGetSessionNotes(sessionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get sessions by therapist id
   * @param {string} therapistId
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionsApi
   */
  public sessionsControllerGetSessionsByTherapistId(
    therapistId: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return SessionsApiFp(this.configuration)
      .sessionsControllerGetSessionsByTherapistId(
        therapistId,
        from,
        to,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Start/join session
   * @param {string} sessionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionsApi
   */
  public sessionsControllerJoinSession(
    sessionId: string,
    options?: AxiosRequestConfig,
  ) {
    return SessionsApiFp(this.configuration)
      .sessionsControllerJoinSession(sessionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reject session request (only for therapist)
   * @param {string} sessionRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SessionsApi
   */
  public sessionsControllerRejectSessionRequest(
    sessionRequestId: string,
    options?: AxiosRequestConfig,
  ) {
    return SessionsApiFp(this.configuration)
      .sessionsControllerRejectSessionRequest(sessionRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SupervisionNotesApi - axios parameter creator
 * @export
 */
export const SupervisionNotesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add session note
     * @param {string} supervisionId
     * @param {SupervisionNoteRequestDto} supervisionNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionNotesControllerAddNote: async (
      supervisionId: string,
      supervisionNoteRequestDto: SupervisionNoteRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'supervisionId' is not null or undefined
      assertParamExists(
        'supervisionNotesControllerAddNote',
        'supervisionId',
        supervisionId,
      );
      // verify required parameter 'supervisionNoteRequestDto' is not null or undefined
      assertParamExists(
        'supervisionNotesControllerAddNote',
        'supervisionNoteRequestDto',
        supervisionNoteRequestDto,
      );
      const localVarPath = `/api/v1/supervisions/{supervisionId}/notes`.replace(
        `{${'supervisionId'}}`,
        encodeURIComponent(String(supervisionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        supervisionNoteRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete session notes
     * @param {string} notesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionNotesControllerDeleteNote: async (
      notesId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'notesId' is not null or undefined
      assertParamExists(
        'supervisionNotesControllerDeleteNote',
        'notesId',
        notesId,
      );
      const localVarPath =
        `/api/v1/supervisions/{supervisionId}/notes/{notesId}`.replace(
          `{${'notesId'}}`,
          encodeURIComponent(String(notesId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update session notes
     * @param {string} notesId
     * @param {SupervisionNoteRequestDto} supervisionNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionNotesControllerEditSessionNote: async (
      notesId: string,
      supervisionNoteRequestDto: SupervisionNoteRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'notesId' is not null or undefined
      assertParamExists(
        'supervisionNotesControllerEditSessionNote',
        'notesId',
        notesId,
      );
      // verify required parameter 'supervisionNoteRequestDto' is not null or undefined
      assertParamExists(
        'supervisionNotesControllerEditSessionNote',
        'supervisionNoteRequestDto',
        supervisionNoteRequestDto,
      );
      const localVarPath =
        `/api/v1/supervisions/{supervisionId}/notes/{notesId}`.replace(
          `{${'notesId'}}`,
          encodeURIComponent(String(notesId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        supervisionNoteRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get supervision notes
     * @param {string} supervisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionNotesControllerGetNotes: async (
      supervisionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'supervisionId' is not null or undefined
      assertParamExists(
        'supervisionNotesControllerGetNotes',
        'supervisionId',
        supervisionId,
      );
      const localVarPath = `/api/v1/supervisions/{supervisionId}/notes`.replace(
        `{${'supervisionId'}}`,
        encodeURIComponent(String(supervisionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SupervisionNotesApi - functional programming interface
 * @export
 */
export const SupervisionNotesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SupervisionNotesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add session note
     * @param {string} supervisionId
     * @param {SupervisionNoteRequestDto} supervisionNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisionNotesControllerAddNote(
      supervisionId: string,
      supervisionNoteRequestDto: SupervisionNoteRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupervisionNoteResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisionNotesControllerAddNote(
          supervisionId,
          supervisionNoteRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete session notes
     * @param {string} notesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisionNotesControllerDeleteNote(
      notesId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupervisionNoteResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisionNotesControllerDeleteNote(
          notesId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update session notes
     * @param {string} notesId
     * @param {SupervisionNoteRequestDto} supervisionNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisionNotesControllerEditSessionNote(
      notesId: string,
      supervisionNoteRequestDto: SupervisionNoteRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupervisionNoteResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisionNotesControllerEditSessionNote(
          notesId,
          supervisionNoteRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get supervision notes
     * @param {string} supervisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisionNotesControllerGetNotes(
      supervisionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupervisionNotesResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisionNotesControllerGetNotes(
          supervisionId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SupervisionNotesApi - factory interface
 * @export
 */
export const SupervisionNotesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SupervisionNotesApiFp(configuration);
  return {
    /**
     *
     * @summary Add session note
     * @param {string} supervisionId
     * @param {SupervisionNoteRequestDto} supervisionNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionNotesControllerAddNote(
      supervisionId: string,
      supervisionNoteRequestDto: SupervisionNoteRequestDto,
      options?: any,
    ): AxiosPromise<SupervisionNoteResponseDto> {
      return localVarFp
        .supervisionNotesControllerAddNote(
          supervisionId,
          supervisionNoteRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete session notes
     * @param {string} notesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionNotesControllerDeleteNote(
      notesId: string,
      options?: any,
    ): AxiosPromise<SupervisionNoteResponseDto> {
      return localVarFp
        .supervisionNotesControllerDeleteNote(notesId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update session notes
     * @param {string} notesId
     * @param {SupervisionNoteRequestDto} supervisionNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionNotesControllerEditSessionNote(
      notesId: string,
      supervisionNoteRequestDto: SupervisionNoteRequestDto,
      options?: any,
    ): AxiosPromise<SupervisionNoteResponseDto> {
      return localVarFp
        .supervisionNotesControllerEditSessionNote(
          notesId,
          supervisionNoteRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get supervision notes
     * @param {string} supervisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionNotesControllerGetNotes(
      supervisionId: string,
      options?: any,
    ): AxiosPromise<SupervisionNotesResponseDto> {
      return localVarFp
        .supervisionNotesControllerGetNotes(supervisionId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SupervisionNotesApi - object-oriented interface
 * @export
 * @class SupervisionNotesApi
 * @extends {BaseAPI}
 */
export class SupervisionNotesApi extends BaseAPI {
  /**
   *
   * @summary Add session note
   * @param {string} supervisionId
   * @param {SupervisionNoteRequestDto} supervisionNoteRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisionNotesApi
   */
  public supervisionNotesControllerAddNote(
    supervisionId: string,
    supervisionNoteRequestDto: SupervisionNoteRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return SupervisionNotesApiFp(this.configuration)
      .supervisionNotesControllerAddNote(
        supervisionId,
        supervisionNoteRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete session notes
   * @param {string} notesId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisionNotesApi
   */
  public supervisionNotesControllerDeleteNote(
    notesId: string,
    options?: AxiosRequestConfig,
  ) {
    return SupervisionNotesApiFp(this.configuration)
      .supervisionNotesControllerDeleteNote(notesId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update session notes
   * @param {string} notesId
   * @param {SupervisionNoteRequestDto} supervisionNoteRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisionNotesApi
   */
  public supervisionNotesControllerEditSessionNote(
    notesId: string,
    supervisionNoteRequestDto: SupervisionNoteRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return SupervisionNotesApiFp(this.configuration)
      .supervisionNotesControllerEditSessionNote(
        notesId,
        supervisionNoteRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get supervision notes
   * @param {string} supervisionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisionNotesApi
   */
  public supervisionNotesControllerGetNotes(
    supervisionId: string,
    options?: AxiosRequestConfig,
  ) {
    return SupervisionNotesApiFp(this.configuration)
      .supervisionNotesControllerGetNotes(supervisionId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SupervisionRequestsApi - axios parameter creator
 * @export
 */
export const SupervisionRequestsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Accept supervision request (only for supervisor)
     * @param {string} supervisionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionRequestsControllerAcceptSessionRequest: async (
      supervisionRequestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'supervisionRequestId' is not null or undefined
      assertParamExists(
        'supervisionRequestsControllerAcceptSessionRequest',
        'supervisionRequestId',
        supervisionRequestId,
      );
      const localVarPath =
        `/api/v1/supervisions/requests/{supervisionRequestId}/accept`.replace(
          `{${'supervisionRequestId'}}`,
          encodeURIComponent(String(supervisionRequestId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cancel supervision request (only for supervised therapist)
     * @param {string} supervisionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionRequestsControllerCancelSessionRequest: async (
      supervisionRequestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'supervisionRequestId' is not null or undefined
      assertParamExists(
        'supervisionRequestsControllerCancelSessionRequest',
        'supervisionRequestId',
        supervisionRequestId,
      );
      const localVarPath =
        `/api/v1/supervisions/requests/{supervisionRequestId}/cancel`.replace(
          `{${'supervisionRequestId'}}`,
          encodeURIComponent(String(supervisionRequestId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create supervision (only for supervised therapist)
     * @param {CreateSupervisionRequestDto} createSupervisionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionRequestsControllerCreateSession: async (
      createSupervisionRequestDto: CreateSupervisionRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createSupervisionRequestDto' is not null or undefined
      assertParamExists(
        'supervisionRequestsControllerCreateSession',
        'createSupervisionRequestDto',
        createSupervisionRequestDto,
      );
      const localVarPath = `/api/v1/supervisions/requests`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSupervisionRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reject supervision request (only for supervisor)
     * @param {string} supervisionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionRequestsControllerRejectSessionRequest: async (
      supervisionRequestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'supervisionRequestId' is not null or undefined
      assertParamExists(
        'supervisionRequestsControllerRejectSessionRequest',
        'supervisionRequestId',
        supervisionRequestId,
      );
      const localVarPath =
        `/api/v1/supervisions/requests/{supervisionRequestId}/reject`.replace(
          `{${'supervisionRequestId'}}`,
          encodeURIComponent(String(supervisionRequestId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SupervisionRequestsApi - functional programming interface
 * @export
 */
export const SupervisionRequestsApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    SupervisionRequestsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Accept supervision request (only for supervisor)
     * @param {string} supervisionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisionRequestsControllerAcceptSessionRequest(
      supervisionRequestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupervisionCreateResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisionRequestsControllerAcceptSessionRequest(
          supervisionRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Cancel supervision request (only for supervised therapist)
     * @param {string} supervisionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisionRequestsControllerCancelSessionRequest(
      supervisionRequestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupervisionRequestResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisionRequestsControllerCancelSessionRequest(
          supervisionRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create supervision (only for supervised therapist)
     * @param {CreateSupervisionRequestDto} createSupervisionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisionRequestsControllerCreateSession(
      createSupervisionRequestDto: CreateSupervisionRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupervisionCreateResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisionRequestsControllerCreateSession(
          createSupervisionRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Reject supervision request (only for supervisor)
     * @param {string} supervisionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisionRequestsControllerRejectSessionRequest(
      supervisionRequestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupervisionRequestResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisionRequestsControllerRejectSessionRequest(
          supervisionRequestId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SupervisionRequestsApi - factory interface
 * @export
 */
export const SupervisionRequestsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SupervisionRequestsApiFp(configuration);
  return {
    /**
     *
     * @summary Accept supervision request (only for supervisor)
     * @param {string} supervisionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionRequestsControllerAcceptSessionRequest(
      supervisionRequestId: string,
      options?: any,
    ): AxiosPromise<SupervisionCreateResponseDto> {
      return localVarFp
        .supervisionRequestsControllerAcceptSessionRequest(
          supervisionRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Cancel supervision request (only for supervised therapist)
     * @param {string} supervisionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionRequestsControllerCancelSessionRequest(
      supervisionRequestId: string,
      options?: any,
    ): AxiosPromise<SupervisionRequestResponseDto> {
      return localVarFp
        .supervisionRequestsControllerCancelSessionRequest(
          supervisionRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create supervision (only for supervised therapist)
     * @param {CreateSupervisionRequestDto} createSupervisionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionRequestsControllerCreateSession(
      createSupervisionRequestDto: CreateSupervisionRequestDto,
      options?: any,
    ): AxiosPromise<SupervisionCreateResponseDto> {
      return localVarFp
        .supervisionRequestsControllerCreateSession(
          createSupervisionRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reject supervision request (only for supervisor)
     * @param {string} supervisionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionRequestsControllerRejectSessionRequest(
      supervisionRequestId: string,
      options?: any,
    ): AxiosPromise<SupervisionRequestResponseDto> {
      return localVarFp
        .supervisionRequestsControllerRejectSessionRequest(
          supervisionRequestId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SupervisionRequestsApi - object-oriented interface
 * @export
 * @class SupervisionRequestsApi
 * @extends {BaseAPI}
 */
export class SupervisionRequestsApi extends BaseAPI {
  /**
   *
   * @summary Accept supervision request (only for supervisor)
   * @param {string} supervisionRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisionRequestsApi
   */
  public supervisionRequestsControllerAcceptSessionRequest(
    supervisionRequestId: string,
    options?: AxiosRequestConfig,
  ) {
    return SupervisionRequestsApiFp(this.configuration)
      .supervisionRequestsControllerAcceptSessionRequest(
        supervisionRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cancel supervision request (only for supervised therapist)
   * @param {string} supervisionRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisionRequestsApi
   */
  public supervisionRequestsControllerCancelSessionRequest(
    supervisionRequestId: string,
    options?: AxiosRequestConfig,
  ) {
    return SupervisionRequestsApiFp(this.configuration)
      .supervisionRequestsControllerCancelSessionRequest(
        supervisionRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create supervision (only for supervised therapist)
   * @param {CreateSupervisionRequestDto} createSupervisionRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisionRequestsApi
   */
  public supervisionRequestsControllerCreateSession(
    createSupervisionRequestDto: CreateSupervisionRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return SupervisionRequestsApiFp(this.configuration)
      .supervisionRequestsControllerCreateSession(
        createSupervisionRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reject supervision request (only for supervisor)
   * @param {string} supervisionRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisionRequestsApi
   */
  public supervisionRequestsControllerRejectSessionRequest(
    supervisionRequestId: string,
    options?: AxiosRequestConfig,
  ) {
    return SupervisionRequestsApiFp(this.configuration)
      .supervisionRequestsControllerRejectSessionRequest(
        supervisionRequestId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SupervisionsApi - axios parameter creator
 * @export
 */
export const SupervisionsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Cancel supervision
     * @param {string} supervisionId
     * @param {CancelSupervisionRequestDto} cancelSupervisionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionsControllerCancelSupervision: async (
      supervisionId: string,
      cancelSupervisionRequestDto: CancelSupervisionRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'supervisionId' is not null or undefined
      assertParamExists(
        'supervisionsControllerCancelSupervision',
        'supervisionId',
        supervisionId,
      );
      // verify required parameter 'cancelSupervisionRequestDto' is not null or undefined
      assertParamExists(
        'supervisionsControllerCancelSupervision',
        'cancelSupervisionRequestDto',
        cancelSupervisionRequestDto,
      );
      const localVarPath =
        `/api/v1/supervisions/{supervisionId}/cancel`.replace(
          `{${'supervisionId'}}`,
          encodeURIComponent(String(supervisionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        cancelSupervisionRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Complete supervision
     * @param {string} supervisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionsControllerCompleteSupervision: async (
      supervisionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'supervisionId' is not null or undefined
      assertParamExists(
        'supervisionsControllerCompleteSupervision',
        'supervisionId',
        supervisionId,
      );
      const localVarPath =
        `/api/v1/supervisions/{supervisionId}/complete`.replace(
          `{${'supervisionId'}}`,
          encodeURIComponent(String(supervisionId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Start/join supervision
     * @param {string} supervisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionsControllerJoinSupervision: async (
      supervisionId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'supervisionId' is not null or undefined
      assertParamExists(
        'supervisionsControllerJoinSupervision',
        'supervisionId',
        supervisionId,
      );
      const localVarPath = `/api/v1/supervisions/{supervisionId}/join`.replace(
        `{${'supervisionId'}}`,
        encodeURIComponent(String(supervisionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Shift supervision
     * @param {string} supervisionId
     * @param {ShiftSupervisionRequestDto} shiftSupervisionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionsControllerShiftSupervision: async (
      supervisionId: string,
      shiftSupervisionRequestDto: ShiftSupervisionRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'supervisionId' is not null or undefined
      assertParamExists(
        'supervisionsControllerShiftSupervision',
        'supervisionId',
        supervisionId,
      );
      // verify required parameter 'shiftSupervisionRequestDto' is not null or undefined
      assertParamExists(
        'supervisionsControllerShiftSupervision',
        'shiftSupervisionRequestDto',
        shiftSupervisionRequestDto,
      );
      const localVarPath = `/api/v1/supervisions/{supervisionId}/shift`.replace(
        `{${'supervisionId'}}`,
        encodeURIComponent(String(supervisionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        shiftSupervisionRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SupervisionsApi - functional programming interface
 * @export
 */
export const SupervisionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SupervisionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Cancel supervision
     * @param {string} supervisionId
     * @param {CancelSupervisionRequestDto} cancelSupervisionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisionsControllerCancelSupervision(
      supervisionId: string,
      cancelSupervisionRequestDto: CancelSupervisionRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupervisionBaseResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisionsControllerCancelSupervision(
          supervisionId,
          cancelSupervisionRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Complete supervision
     * @param {string} supervisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisionsControllerCompleteSupervision(
      supervisionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisionsControllerCompleteSupervision(
          supervisionId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Start/join supervision
     * @param {string} supervisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisionsControllerJoinSupervision(
      supervisionId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<JoinSupervisionResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisionsControllerJoinSupervision(
          supervisionId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Shift supervision
     * @param {string} supervisionId
     * @param {ShiftSupervisionRequestDto} shiftSupervisionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisionsControllerShiftSupervision(
      supervisionId: string,
      shiftSupervisionRequestDto: ShiftSupervisionRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupervisionBaseResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisionsControllerShiftSupervision(
          supervisionId,
          shiftSupervisionRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SupervisionsApi - factory interface
 * @export
 */
export const SupervisionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SupervisionsApiFp(configuration);
  return {
    /**
     *
     * @summary Cancel supervision
     * @param {string} supervisionId
     * @param {CancelSupervisionRequestDto} cancelSupervisionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionsControllerCancelSupervision(
      supervisionId: string,
      cancelSupervisionRequestDto: CancelSupervisionRequestDto,
      options?: any,
    ): AxiosPromise<SupervisionBaseResponseDto> {
      return localVarFp
        .supervisionsControllerCancelSupervision(
          supervisionId,
          cancelSupervisionRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Complete supervision
     * @param {string} supervisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionsControllerCompleteSupervision(
      supervisionId: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .supervisionsControllerCompleteSupervision(supervisionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Start/join supervision
     * @param {string} supervisionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionsControllerJoinSupervision(
      supervisionId: string,
      options?: any,
    ): AxiosPromise<JoinSupervisionResponseDto> {
      return localVarFp
        .supervisionsControllerJoinSupervision(supervisionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Shift supervision
     * @param {string} supervisionId
     * @param {ShiftSupervisionRequestDto} shiftSupervisionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisionsControllerShiftSupervision(
      supervisionId: string,
      shiftSupervisionRequestDto: ShiftSupervisionRequestDto,
      options?: any,
    ): AxiosPromise<SupervisionBaseResponseDto> {
      return localVarFp
        .supervisionsControllerShiftSupervision(
          supervisionId,
          shiftSupervisionRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SupervisionsApi - object-oriented interface
 * @export
 * @class SupervisionsApi
 * @extends {BaseAPI}
 */
export class SupervisionsApi extends BaseAPI {
  /**
   *
   * @summary Cancel supervision
   * @param {string} supervisionId
   * @param {CancelSupervisionRequestDto} cancelSupervisionRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisionsApi
   */
  public supervisionsControllerCancelSupervision(
    supervisionId: string,
    cancelSupervisionRequestDto: CancelSupervisionRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return SupervisionsApiFp(this.configuration)
      .supervisionsControllerCancelSupervision(
        supervisionId,
        cancelSupervisionRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Complete supervision
   * @param {string} supervisionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisionsApi
   */
  public supervisionsControllerCompleteSupervision(
    supervisionId: string,
    options?: AxiosRequestConfig,
  ) {
    return SupervisionsApiFp(this.configuration)
      .supervisionsControllerCompleteSupervision(supervisionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Start/join supervision
   * @param {string} supervisionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisionsApi
   */
  public supervisionsControllerJoinSupervision(
    supervisionId: string,
    options?: AxiosRequestConfig,
  ) {
    return SupervisionsApiFp(this.configuration)
      .supervisionsControllerJoinSupervision(supervisionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Shift supervision
   * @param {string} supervisionId
   * @param {ShiftSupervisionRequestDto} shiftSupervisionRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisionsApi
   */
  public supervisionsControllerShiftSupervision(
    supervisionId: string,
    shiftSupervisionRequestDto: ShiftSupervisionRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return SupervisionsApiFp(this.configuration)
      .supervisionsControllerShiftSupervision(
        supervisionId,
        shiftSupervisionRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SupervisorsApi - axios parameter creator
 * @export
 */
export const SupervisorsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Archive supervised therapists
     * @param {string} therapistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisorsControllerArchiveSupervisedTherapist: async (
      therapistId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'therapistId' is not null or undefined
      assertParamExists(
        'supervisorsControllerArchiveSupervisedTherapist',
        'therapistId',
        therapistId,
      );
      const localVarPath =
        `/api/v1/supervisors/therapists/{therapistId}/archive`.replace(
          `{${'therapistId'}}`,
          encodeURIComponent(String(therapistId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Archive supervisions and supervisor (only for supervised therapist)
     * @param {string} supervisorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisorsControllerArchiveSupervisions: async (
      supervisorId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'supervisorId' is not null or undefined
      assertParamExists(
        'supervisorsControllerArchiveSupervisions',
        'supervisorId',
        supervisorId,
      );
      const localVarPath = `/api/v1/supervisors/{supervisorId}`.replace(
        `{${'supervisorId'}}`,
        encodeURIComponent(String(supervisorId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get supervisor\'s therapists
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'therapistName' | 'supervisionCount' | 'firstSupervisionDate' | 'lastSupervisionDate'} [orderTerms]
     * @param {boolean} [archivedBySupervisor]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisorsControllerGetSupervisorTherapists: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'therapistName'
        | 'supervisionCount'
        | 'firstSupervisionDate'
        | 'lastSupervisionDate',
      archivedBySupervisor?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists(
        'supervisorsControllerGetSupervisorTherapists',
        'limit',
        limit,
      );
      // verify required parameter 'page' is not null or undefined
      assertParamExists(
        'supervisorsControllerGetSupervisorTherapists',
        'page',
        page,
      );
      const localVarPath = `/api/v1/supervisors/therapists`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (orderTerms !== undefined) {
        localVarQueryParameter['orderTerms'] = orderTerms;
      }

      if (archivedBySupervisor !== undefined) {
        localVarQueryParameter['archivedBySupervisor'] = archivedBySupervisor;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get therapist\'s supervisors
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'therapistName' | 'supervisionCount' | 'firstSupervisionDate' | 'lastSupervisionDate'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisorsControllerGetSupervisors: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'therapistName'
        | 'supervisionCount'
        | 'firstSupervisionDate'
        | 'lastSupervisionDate',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('supervisorsControllerGetSupervisors', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('supervisorsControllerGetSupervisors', 'page', page);
      const localVarPath = `/api/v1/supervisors`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (orderTerms !== undefined) {
        localVarQueryParameter['orderTerms'] = orderTerms;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reject supervised therapist
     * @param {string} therapistId
     * @param {RejectTherapistRequestDto} rejectTherapistRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisorsControllerRejectSupervisedTherapist: async (
      therapistId: string,
      rejectTherapistRequestDto: RejectTherapistRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'therapistId' is not null or undefined
      assertParamExists(
        'supervisorsControllerRejectSupervisedTherapist',
        'therapistId',
        therapistId,
      );
      // verify required parameter 'rejectTherapistRequestDto' is not null or undefined
      assertParamExists(
        'supervisorsControllerRejectSupervisedTherapist',
        'rejectTherapistRequestDto',
        rejectTherapistRequestDto,
      );
      const localVarPath =
        `/api/v1/supervisors/therapists/{therapistId}`.replace(
          `{${'therapistId'}}`,
          encodeURIComponent(String(therapistId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        rejectTherapistRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unarchive supervised therapist
     * @param {string} therapistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisorsControllerUnarchiveSupervisedTherapist: async (
      therapistId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'therapistId' is not null or undefined
      assertParamExists(
        'supervisorsControllerUnarchiveSupervisedTherapist',
        'therapistId',
        therapistId,
      );
      const localVarPath =
        `/api/v1/supervisors/therapists/{therapistId}/unarchive`.replace(
          `{${'therapistId'}}`,
          encodeURIComponent(String(therapistId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SupervisorsApi - functional programming interface
 * @export
 */
export const SupervisorsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    SupervisorsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Archive supervised therapists
     * @param {string} therapistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisorsControllerArchiveSupervisedTherapist(
      therapistId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisorsControllerArchiveSupervisedTherapist(
          therapistId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Archive supervisions and supervisor (only for supervised therapist)
     * @param {string} supervisorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisorsControllerArchiveSupervisions(
      supervisorId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisorsControllerArchiveSupervisions(
          supervisorId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get supervisor\'s therapists
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'therapistName' | 'supervisionCount' | 'firstSupervisionDate' | 'lastSupervisionDate'} [orderTerms]
     * @param {boolean} [archivedBySupervisor]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisorsControllerGetSupervisorTherapists(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'therapistName'
        | 'supervisionCount'
        | 'firstSupervisionDate'
        | 'lastSupervisionDate',
      archivedBySupervisor?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupervisorTherapistsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisorsControllerGetSupervisorTherapists(
          limit,
          page,
          order,
          orderTerms,
          archivedBySupervisor,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get therapist\'s supervisors
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'therapistName' | 'supervisionCount' | 'firstSupervisionDate' | 'lastSupervisionDate'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisorsControllerGetSupervisors(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'therapistName'
        | 'supervisionCount'
        | 'firstSupervisionDate'
        | 'lastSupervisionDate',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapistSupervisorsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisorsControllerGetSupervisors(
          limit,
          page,
          order,
          orderTerms,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Reject supervised therapist
     * @param {string} therapistId
     * @param {RejectTherapistRequestDto} rejectTherapistRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisorsControllerRejectSupervisedTherapist(
      therapistId: string,
      rejectTherapistRequestDto: RejectTherapistRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisorsControllerRejectSupervisedTherapist(
          therapistId,
          rejectTherapistRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Unarchive supervised therapist
     * @param {string} therapistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async supervisorsControllerUnarchiveSupervisedTherapist(
      therapistId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.supervisorsControllerUnarchiveSupervisedTherapist(
          therapistId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SupervisorsApi - factory interface
 * @export
 */
export const SupervisorsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SupervisorsApiFp(configuration);
  return {
    /**
     *
     * @summary Archive supervised therapists
     * @param {string} therapistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisorsControllerArchiveSupervisedTherapist(
      therapistId: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .supervisorsControllerArchiveSupervisedTherapist(therapistId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Archive supervisions and supervisor (only for supervised therapist)
     * @param {string} supervisorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisorsControllerArchiveSupervisions(
      supervisorId: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .supervisorsControllerArchiveSupervisions(supervisorId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get supervisor\'s therapists
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'therapistName' | 'supervisionCount' | 'firstSupervisionDate' | 'lastSupervisionDate'} [orderTerms]
     * @param {boolean} [archivedBySupervisor]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisorsControllerGetSupervisorTherapists(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'therapistName'
        | 'supervisionCount'
        | 'firstSupervisionDate'
        | 'lastSupervisionDate',
      archivedBySupervisor?: boolean,
      options?: any,
    ): AxiosPromise<SupervisorTherapistsResponseDto> {
      return localVarFp
        .supervisorsControllerGetSupervisorTherapists(
          limit,
          page,
          order,
          orderTerms,
          archivedBySupervisor,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get therapist\'s supervisors
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'therapistName' | 'supervisionCount' | 'firstSupervisionDate' | 'lastSupervisionDate'} [orderTerms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisorsControllerGetSupervisors(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'therapistName'
        | 'supervisionCount'
        | 'firstSupervisionDate'
        | 'lastSupervisionDate',
      options?: any,
    ): AxiosPromise<TherapistSupervisorsResponseDto> {
      return localVarFp
        .supervisorsControllerGetSupervisors(
          limit,
          page,
          order,
          orderTerms,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reject supervised therapist
     * @param {string} therapistId
     * @param {RejectTherapistRequestDto} rejectTherapistRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisorsControllerRejectSupervisedTherapist(
      therapistId: string,
      rejectTherapistRequestDto: RejectTherapistRequestDto,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .supervisorsControllerRejectSupervisedTherapist(
          therapistId,
          rejectTherapistRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Unarchive supervised therapist
     * @param {string} therapistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    supervisorsControllerUnarchiveSupervisedTherapist(
      therapistId: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .supervisorsControllerUnarchiveSupervisedTherapist(therapistId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * SupervisorsApi - object-oriented interface
 * @export
 * @class SupervisorsApi
 * @extends {BaseAPI}
 */
export class SupervisorsApi extends BaseAPI {
  /**
   *
   * @summary Archive supervised therapists
   * @param {string} therapistId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisorsApi
   */
  public supervisorsControllerArchiveSupervisedTherapist(
    therapistId: string,
    options?: AxiosRequestConfig,
  ) {
    return SupervisorsApiFp(this.configuration)
      .supervisorsControllerArchiveSupervisedTherapist(therapistId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Archive supervisions and supervisor (only for supervised therapist)
   * @param {string} supervisorId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisorsApi
   */
  public supervisorsControllerArchiveSupervisions(
    supervisorId: string,
    options?: AxiosRequestConfig,
  ) {
    return SupervisorsApiFp(this.configuration)
      .supervisorsControllerArchiveSupervisions(supervisorId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get supervisor\'s therapists
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {'therapistName' | 'supervisionCount' | 'firstSupervisionDate' | 'lastSupervisionDate'} [orderTerms]
   * @param {boolean} [archivedBySupervisor]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisorsApi
   */
  public supervisorsControllerGetSupervisorTherapists(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    orderTerms?:
      | 'therapistName'
      | 'supervisionCount'
      | 'firstSupervisionDate'
      | 'lastSupervisionDate',
    archivedBySupervisor?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return SupervisorsApiFp(this.configuration)
      .supervisorsControllerGetSupervisorTherapists(
        limit,
        page,
        order,
        orderTerms,
        archivedBySupervisor,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get therapist\'s supervisors
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {'therapistName' | 'supervisionCount' | 'firstSupervisionDate' | 'lastSupervisionDate'} [orderTerms]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisorsApi
   */
  public supervisorsControllerGetSupervisors(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    orderTerms?:
      | 'therapistName'
      | 'supervisionCount'
      | 'firstSupervisionDate'
      | 'lastSupervisionDate',
    options?: AxiosRequestConfig,
  ) {
    return SupervisorsApiFp(this.configuration)
      .supervisorsControllerGetSupervisors(
        limit,
        page,
        order,
        orderTerms,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reject supervised therapist
   * @param {string} therapistId
   * @param {RejectTherapistRequestDto} rejectTherapistRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisorsApi
   */
  public supervisorsControllerRejectSupervisedTherapist(
    therapistId: string,
    rejectTherapistRequestDto: RejectTherapistRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return SupervisorsApiFp(this.configuration)
      .supervisorsControllerRejectSupervisedTherapist(
        therapistId,
        rejectTherapistRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Unarchive supervised therapist
   * @param {string} therapistId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SupervisorsApi
   */
  public supervisorsControllerUnarchiveSupervisedTherapist(
    therapistId: string,
    options?: AxiosRequestConfig,
  ) {
    return SupervisorsApiFp(this.configuration)
      .supervisorsControllerUnarchiveSupervisedTherapist(therapistId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TherapistUpdatesApi - axios parameter creator
 * @export
 */
export const TherapistUpdatesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Create therapist profile update (only for therapist)
     * @param {CreateTherapistProfileRequestDto} createTherapistProfileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistUpdatesControllerCreateProfileUpdate: async (
      createTherapistProfileRequestDto: CreateTherapistProfileRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createTherapistProfileRequestDto' is not null or undefined
      assertParamExists(
        'therapistUpdatesControllerCreateProfileUpdate',
        'createTherapistProfileRequestDto',
        createTherapistProfileRequestDto,
      );
      const localVarPath = `/api/v1/therapist-update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTherapistProfileRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete therapist profile update
     * @param {string} therapistProfileUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistUpdatesControllerDeleteProfileUpdate: async (
      therapistProfileUpdateId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'therapistProfileUpdateId' is not null or undefined
      assertParamExists(
        'therapistUpdatesControllerDeleteProfileUpdate',
        'therapistProfileUpdateId',
        therapistProfileUpdateId,
      );
      const localVarPath =
        `/api/v1/therapist-update/{therapistProfileUpdateId}`.replace(
          `{${'therapistProfileUpdateId'}}`,
          encodeURIComponent(String(therapistProfileUpdateId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get therapist profile update (only for therapist)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistUpdatesControllerGetProfileUpdates: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/therapist-update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TherapistUpdatesApi - functional programming interface
 * @export
 */
export const TherapistUpdatesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    TherapistUpdatesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create therapist profile update (only for therapist)
     * @param {CreateTherapistProfileRequestDto} createTherapistProfileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistUpdatesControllerCreateProfileUpdate(
      createTherapistProfileRequestDto: CreateTherapistProfileRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapistProfileUpdateResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistUpdatesControllerCreateProfileUpdate(
          createTherapistProfileRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete therapist profile update
     * @param {string} therapistProfileUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistUpdatesControllerDeleteProfileUpdate(
      therapistProfileUpdateId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapistProfileUpdateResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistUpdatesControllerDeleteProfileUpdate(
          therapistProfileUpdateId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get therapist profile update (only for therapist)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistUpdatesControllerGetProfileUpdates(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TherapistProfileUpdatesResponseDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistUpdatesControllerGetProfileUpdates(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TherapistUpdatesApi - factory interface
 * @export
 */
export const TherapistUpdatesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TherapistUpdatesApiFp(configuration);
  return {
    /**
     *
     * @summary Create therapist profile update (only for therapist)
     * @param {CreateTherapistProfileRequestDto} createTherapistProfileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistUpdatesControllerCreateProfileUpdate(
      createTherapistProfileRequestDto: CreateTherapistProfileRequestDto,
      options?: any,
    ): AxiosPromise<TherapistProfileUpdateResponseDto> {
      return localVarFp
        .therapistUpdatesControllerCreateProfileUpdate(
          createTherapistProfileRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete therapist profile update
     * @param {string} therapistProfileUpdateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistUpdatesControllerDeleteProfileUpdate(
      therapistProfileUpdateId: string,
      options?: any,
    ): AxiosPromise<TherapistProfileUpdateResponseDto> {
      return localVarFp
        .therapistUpdatesControllerDeleteProfileUpdate(
          therapistProfileUpdateId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get therapist profile update (only for therapist)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistUpdatesControllerGetProfileUpdates(
      options?: any,
    ): AxiosPromise<Array<TherapistProfileUpdatesResponseDto>> {
      return localVarFp
        .therapistUpdatesControllerGetProfileUpdates(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TherapistUpdatesApi - object-oriented interface
 * @export
 * @class TherapistUpdatesApi
 * @extends {BaseAPI}
 */
export class TherapistUpdatesApi extends BaseAPI {
  /**
   *
   * @summary Create therapist profile update (only for therapist)
   * @param {CreateTherapistProfileRequestDto} createTherapistProfileRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistUpdatesApi
   */
  public therapistUpdatesControllerCreateProfileUpdate(
    createTherapistProfileRequestDto: CreateTherapistProfileRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return TherapistUpdatesApiFp(this.configuration)
      .therapistUpdatesControllerCreateProfileUpdate(
        createTherapistProfileRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete therapist profile update
   * @param {string} therapistProfileUpdateId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistUpdatesApi
   */
  public therapistUpdatesControllerDeleteProfileUpdate(
    therapistProfileUpdateId: string,
    options?: AxiosRequestConfig,
  ) {
    return TherapistUpdatesApiFp(this.configuration)
      .therapistUpdatesControllerDeleteProfileUpdate(
        therapistProfileUpdateId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get therapist profile update (only for therapist)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistUpdatesApi
   */
  public therapistUpdatesControllerGetProfileUpdates(
    options?: AxiosRequestConfig,
  ) {
    return TherapistUpdatesApiFp(this.configuration)
      .therapistUpdatesControllerGetProfileUpdates(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TherapistsApi - axios parameter creator
 * @export
 */
export const TherapistsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Add patient\'s note (only for therapist)
     * @param {string} patientId
     * @param {TherapistPatientNoteRequestDto} therapistPatientNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistPatientNoteControllerAddNote: async (
      patientId: string,
      therapistPatientNoteRequestDto: TherapistPatientNoteRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists(
        'therapistPatientNoteControllerAddNote',
        'patientId',
        patientId,
      );
      // verify required parameter 'therapistPatientNoteRequestDto' is not null or undefined
      assertParamExists(
        'therapistPatientNoteControllerAddNote',
        'therapistPatientNoteRequestDto',
        therapistPatientNoteRequestDto,
      );
      const localVarPath =
        `/api/v1/therapists/patient/{patientId}/notes`.replace(
          `{${'patientId'}}`,
          encodeURIComponent(String(patientId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        therapistPatientNoteRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete patient note (only for therapist)
     * @param {string} patientId
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistPatientNoteControllerDeleteNote: async (
      patientId: string,
      noteId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists(
        'therapistPatientNoteControllerDeleteNote',
        'patientId',
        patientId,
      );
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists(
        'therapistPatientNoteControllerDeleteNote',
        'noteId',
        noteId,
      );
      const localVarPath =
        `/api/v1/therapists/patient/{patientId}/notes/{noteId}`
          .replace(`{${'patientId'}}`, encodeURIComponent(String(patientId)))
          .replace(`{${'noteId'}}`, encodeURIComponent(String(noteId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update patient note (only for therapist)
     * @param {string} patientId
     * @param {string} noteId
     * @param {TherapistPatientNoteRequestDto} therapistPatientNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistPatientNoteControllerEditNote: async (
      patientId: string,
      noteId: string,
      therapistPatientNoteRequestDto: TherapistPatientNoteRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists(
        'therapistPatientNoteControllerEditNote',
        'patientId',
        patientId,
      );
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists(
        'therapistPatientNoteControllerEditNote',
        'noteId',
        noteId,
      );
      // verify required parameter 'therapistPatientNoteRequestDto' is not null or undefined
      assertParamExists(
        'therapistPatientNoteControllerEditNote',
        'therapistPatientNoteRequestDto',
        therapistPatientNoteRequestDto,
      );
      const localVarPath =
        `/api/v1/therapists/patient/{patientId}/notes/{noteId}`
          .replace(`{${'patientId'}}`, encodeURIComponent(String(patientId)))
          .replace(`{${'noteId'}}`, encodeURIComponent(String(noteId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        therapistPatientNoteRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get patient\'s notes (only for therapist)
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistPatientNoteControllerGetPatientNotes: async (
      patientId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists(
        'therapistPatientNoteControllerGetPatientNotes',
        'patientId',
        patientId,
      );
      const localVarPath =
        `/api/v1/therapists/patient/{patientId}/notes`.replace(
          `{${'patientId'}}`,
          encodeURIComponent(String(patientId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get active supervisions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistSupervisionsControllerGetActiveSupervisions: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/therapists/supervisions/active`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get supervisions history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistSupervisionsControllerGetSupervisionsHistory: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/therapists/supervisions/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Archive patient and all sessions (only for therapist)
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerArchivePatient: async (
      patientId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists(
        'therapistsControllerArchivePatient',
        'patientId',
        patientId,
      );
      const localVarPath =
        `/api/v1/therapists/patients/{patientId}/archive`.replace(
          `{${'patientId'}}`,
          encodeURIComponent(String(patientId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create availability (only for therapists)
     * @param {CreateAvailabilitiesRequestDto} createAvailabilitiesRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerCreateAvailability: async (
      createAvailabilitiesRequestDto: CreateAvailabilitiesRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createAvailabilitiesRequestDto' is not null or undefined
      assertParamExists(
        'therapistsControllerCreateAvailability',
        'createAvailabilitiesRequestDto',
        createAvailabilitiesRequestDto,
      );
      const localVarPath = `/api/v1/therapists/availability`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAvailabilitiesRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete availability (only for therapist)
     * @param {string} availabilityId
     * @param {DeleteAvailabilityRequestDto} deleteAvailabilityRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerDeleteAvailability: async (
      availabilityId: string,
      deleteAvailabilityRequestDto: DeleteAvailabilityRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'availabilityId' is not null or undefined
      assertParamExists(
        'therapistsControllerDeleteAvailability',
        'availabilityId',
        availabilityId,
      );
      // verify required parameter 'deleteAvailabilityRequestDto' is not null or undefined
      assertParamExists(
        'therapistsControllerDeleteAvailability',
        'deleteAvailabilityRequestDto',
        deleteAvailabilityRequestDto,
      );
      const localVarPath =
        `/api/v1/therapists/availability/{availabilityId}`.replace(
          `{${'availabilityId'}}`,
          encodeURIComponent(String(availabilityId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        deleteAvailabilityRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete avatar (only for therapists)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerDeleteAvatar: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/therapists/avatar`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete video (only for therapists)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerDeleteVideo: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/therapists/video`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get therapist self info (only for therapists)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerFindOne: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/therapists/profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get active sessions (only for therapists)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [patientName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetActiveSessions: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      patientName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists(
        'therapistsControllerGetActiveSessions',
        'limit',
        limit,
      );
      // verify required parameter 'page' is not null or undefined
      assertParamExists('therapistsControllerGetActiveSessions', 'page', page);
      const localVarPath = `/api/v1/therapists/sessions/active`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] =
          (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (patientName !== undefined) {
        localVarQueryParameter['patientName'] = patientName;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all therapists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetAllTherapists: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/therapists/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get availabilities of therapists
     * @param {string} therapistId
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetAvailability: async (
      therapistId: string,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'therapistId' is not null or undefined
      assertParamExists(
        'therapistsControllerGetAvailability',
        'therapistId',
        therapistId,
      );
      const localVarPath =
        `/api/v1/therapists/{therapistId}/availability`.replace(
          `{${'therapistId'}}`,
          encodeURIComponent(String(therapistId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] =
          (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get history sessions (only for therapists)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [patientName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetHistorySessions: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      patientName?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists(
        'therapistsControllerGetHistorySessions',
        'limit',
        limit,
      );
      // verify required parameter 'page' is not null or undefined
      assertParamExists('therapistsControllerGetHistorySessions', 'page', page);
      const localVarPath = `/api/v1/therapists/sessions/history`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] =
          (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (patientName !== undefined) {
        localVarQueryParameter['patientName'] = patientName;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create therapist create request (only for therapists)
     * @param {CreateTherapistProfileRequestDto} createTherapistProfileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetProfile: async (
      createTherapistProfileRequestDto: CreateTherapistProfileRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createTherapistProfileRequestDto' is not null or undefined
      assertParamExists(
        'therapistsControllerGetProfile',
        'createTherapistProfileRequestDto',
        createTherapistProfileRequestDto,
      );
      const localVarPath = `/api/v1/therapists`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createTherapistProfileRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get therapist schedule (only for therapists)
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetSchedule: async (
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/therapists/schedule`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] =
          (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get tiny sessions (only for therapists)
     * @param {boolean} isActive
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetSessions: async (
      isActive: boolean,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'isActive' is not null or undefined
      assertParamExists(
        'therapistsControllerGetSessions',
        'isActive',
        isActive,
      );
      const localVarPath = `/api/v1/therapists/sessions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] =
          (to as any) instanceof Date ? (to as any).toISOString() : to;
      }

      if (isActive !== undefined) {
        localVarQueryParameter['isActive'] = isActive;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Search all supervisors
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'price' | 'experience' | 'availability'} [orderTerms]
     * @param {'male' | 'female' | 'transgender' | 'unknown'} [gender]
     * @param {Array<string>} [therapyMethods]
     * @param {Array<string>} [diseases]
     * @param {number} [dayLimit]
     * @param {boolean} [workWithLgbt]
     * @param {boolean} [workWithEthnicGroups]
     * @param {number} [workingExperience]
     * @param {string} [firstname]
     * @param {string} [lastname]
     * @param {number} [priceMin]
     * @param {number} [priceMax]
     * @param {Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>} [religions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetSupervisors: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'price' | 'experience' | 'availability',
      gender?: 'male' | 'female' | 'transgender' | 'unknown',
      therapyMethods?: Array<string>,
      diseases?: Array<string>,
      dayLimit?: number,
      workWithLgbt?: boolean,
      workWithEthnicGroups?: boolean,
      workingExperience?: number,
      firstname?: string,
      lastname?: string,
      priceMin?: number,
      priceMax?: number,
      religions?: Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('therapistsControllerGetSupervisors', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('therapistsControllerGetSupervisors', 'page', page);
      const localVarPath = `/api/v1/therapists/supervisors/with_filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (orderTerms !== undefined) {
        localVarQueryParameter['orderTerms'] = orderTerms;
      }

      if (gender !== undefined) {
        localVarQueryParameter['gender'] = gender;
      }

      if (therapyMethods) {
        localVarQueryParameter['therapyMethods'] = therapyMethods;
      }

      if (diseases) {
        localVarQueryParameter['diseases'] = diseases;
      }

      if (dayLimit !== undefined) {
        localVarQueryParameter['dayLimit'] = dayLimit;
      }

      if (workWithLgbt !== undefined) {
        localVarQueryParameter['workWithLgbt'] = workWithLgbt;
      }

      if (workWithEthnicGroups !== undefined) {
        localVarQueryParameter['workWithEthnicGroups'] = workWithEthnicGroups;
      }

      if (workingExperience !== undefined) {
        localVarQueryParameter['workingExperience'] = workingExperience;
      }

      if (firstname !== undefined) {
        localVarQueryParameter['firstname'] = firstname;
      }

      if (lastname !== undefined) {
        localVarQueryParameter['lastname'] = lastname;
      }

      if (priceMin !== undefined) {
        localVarQueryParameter['priceMin'] = priceMin;
      }

      if (priceMax !== undefined) {
        localVarQueryParameter['priceMax'] = priceMax;
      }

      if (religions) {
        localVarQueryParameter['religions'] = religions;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all therapist patients (only for therapist)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'firstname' | 'totalSessions' | 'firstSessionDate' | 'lastSessionDate'} [orderTerms]
     * @param {boolean} [archivedByTherapist]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerPatientTherapists: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'firstname'
        | 'totalSessions'
        | 'firstSessionDate'
        | 'lastSessionDate',
      archivedByTherapist?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists(
        'therapistsControllerPatientTherapists',
        'limit',
        limit,
      );
      // verify required parameter 'page' is not null or undefined
      assertParamExists('therapistsControllerPatientTherapists', 'page', page);
      const localVarPath = `/api/v1/therapists/patients`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (orderTerms !== undefined) {
        localVarQueryParameter['orderTerms'] = orderTerms;
      }

      if (archivedByTherapist !== undefined) {
        localVarQueryParameter['archivedByTherapist'] = archivedByTherapist;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Reject patient and cancel all sessions (only for therapist)
     * @param {string} patientId
     * @param {RejectPatientRequestDto} rejectPatientRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerRejectPatient: async (
      patientId: string,
      rejectPatientRequestDto: RejectPatientRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists(
        'therapistsControllerRejectPatient',
        'patientId',
        patientId,
      );
      // verify required parameter 'rejectPatientRequestDto' is not null or undefined
      assertParamExists(
        'therapistsControllerRejectPatient',
        'rejectPatientRequestDto',
        rejectPatientRequestDto,
      );
      const localVarPath = `/api/v1/therapists/patients/{patientId}`.replace(
        `{${'patientId'}}`,
        encodeURIComponent(String(patientId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        rejectPatientRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Request session from therapist through admins
     * @param {string} therapistId
     * @param {RequestSessionRequestDto} requestSessionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerRequestTherapistSession: async (
      therapistId: string,
      requestSessionRequestDto: RequestSessionRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'therapistId' is not null or undefined
      assertParamExists(
        'therapistsControllerRequestTherapistSession',
        'therapistId',
        therapistId,
      );
      // verify required parameter 'requestSessionRequestDto' is not null or undefined
      assertParamExists(
        'therapistsControllerRequestTherapistSession',
        'requestSessionRequestDto',
        requestSessionRequestDto,
      );
      const localVarPath =
        `/api/v1/therapists/{therapistId}/request_session`.replace(
          `{${'therapistId'}}`,
          encodeURIComponent(String(therapistId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestSessionRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get therapist list by search filter
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'price' | 'experience' | 'availability'} [orderTerms]
     * @param {'male' | 'female' | 'transgender' | 'unknown'} [gender]
     * @param {Array<string>} [therapyMethods]
     * @param {Array<string>} [diseases]
     * @param {number} [dayLimit]
     * @param {boolean} [workWithLgbt]
     * @param {boolean} [workWithEthnicGroups]
     * @param {number} [workingExperience]
     * @param {boolean} [isApproved]
     * @param {string} [firstname]
     * @param {string} [lastname]
     * @param {number} [priceMin]
     * @param {number} [priceMax]
     * @param {Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>} [religions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerSearch: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'price' | 'experience' | 'availability',
      gender?: 'male' | 'female' | 'transgender' | 'unknown',
      therapyMethods?: Array<string>,
      diseases?: Array<string>,
      dayLimit?: number,
      workWithLgbt?: boolean,
      workWithEthnicGroups?: boolean,
      workingExperience?: number,
      isApproved?: boolean,
      firstname?: string,
      lastname?: string,
      priceMin?: number,
      priceMax?: number,
      religions?: Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('therapistsControllerSearch', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('therapistsControllerSearch', 'page', page);
      const localVarPath = `/api/v1/therapists/search/with_filter`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (orderTerms !== undefined) {
        localVarQueryParameter['orderTerms'] = orderTerms;
      }

      if (gender !== undefined) {
        localVarQueryParameter['gender'] = gender;
      }

      if (therapyMethods) {
        localVarQueryParameter['therapyMethods'] = therapyMethods;
      }

      if (diseases) {
        localVarQueryParameter['diseases'] = diseases;
      }

      if (dayLimit !== undefined) {
        localVarQueryParameter['dayLimit'] = dayLimit;
      }

      if (workWithLgbt !== undefined) {
        localVarQueryParameter['workWithLgbt'] = workWithLgbt;
      }

      if (workWithEthnicGroups !== undefined) {
        localVarQueryParameter['workWithEthnicGroups'] = workWithEthnicGroups;
      }

      if (workingExperience !== undefined) {
        localVarQueryParameter['workingExperience'] = workingExperience;
      }

      if (isApproved !== undefined) {
        localVarQueryParameter['isApproved'] = isApproved;
      }

      if (firstname !== undefined) {
        localVarQueryParameter['firstname'] = firstname;
      }

      if (lastname !== undefined) {
        localVarQueryParameter['lastname'] = lastname;
      }

      if (priceMin !== undefined) {
        localVarQueryParameter['priceMin'] = priceMin;
      }

      if (priceMax !== undefined) {
        localVarQueryParameter['priceMax'] = priceMax;
      }

      if (religions) {
        localVarQueryParameter['religions'] = religions;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get therapist list by recommendation params (only for patients)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'price' | 'experience' | 'availability'} [orderTerms]
     * @param {'male' | 'female' | 'transgender' | 'unknown'} [gender]
     * @param {Array<string>} [therapyMethods]
     * @param {Array<string>} [diseases]
     * @param {number} [dayLimit]
     * @param {boolean} [workWithLgbt]
     * @param {boolean} [workWithEthnicGroups]
     * @param {number} [workingExperience]
     * @param {boolean} [isApproved]
     * @param {string} [firstname]
     * @param {string} [lastname]
     * @param {number} [priceMin]
     * @param {number} [priceMax]
     * @param {Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>} [religions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerSearchRecommended: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'price' | 'experience' | 'availability',
      gender?: 'male' | 'female' | 'transgender' | 'unknown',
      therapyMethods?: Array<string>,
      diseases?: Array<string>,
      dayLimit?: number,
      workWithLgbt?: boolean,
      workWithEthnicGroups?: boolean,
      workingExperience?: number,
      isApproved?: boolean,
      firstname?: string,
      lastname?: string,
      priceMin?: number,
      priceMax?: number,
      religions?: Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists(
        'therapistsControllerSearchRecommended',
        'limit',
        limit,
      );
      // verify required parameter 'page' is not null or undefined
      assertParamExists('therapistsControllerSearchRecommended', 'page', page);
      const localVarPath = `/api/v1/therapists/search/recommended`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      if (orderTerms !== undefined) {
        localVarQueryParameter['orderTerms'] = orderTerms;
      }

      if (gender !== undefined) {
        localVarQueryParameter['gender'] = gender;
      }

      if (therapyMethods) {
        localVarQueryParameter['therapyMethods'] = therapyMethods;
      }

      if (diseases) {
        localVarQueryParameter['diseases'] = diseases;
      }

      if (dayLimit !== undefined) {
        localVarQueryParameter['dayLimit'] = dayLimit;
      }

      if (workWithLgbt !== undefined) {
        localVarQueryParameter['workWithLgbt'] = workWithLgbt;
      }

      if (workWithEthnicGroups !== undefined) {
        localVarQueryParameter['workWithEthnicGroups'] = workWithEthnicGroups;
      }

      if (workingExperience !== undefined) {
        localVarQueryParameter['workingExperience'] = workingExperience;
      }

      if (isApproved !== undefined) {
        localVarQueryParameter['isApproved'] = isApproved;
      }

      if (firstname !== undefined) {
        localVarQueryParameter['firstname'] = firstname;
      }

      if (lastname !== undefined) {
        localVarQueryParameter['lastname'] = lastname;
      }

      if (priceMin !== undefined) {
        localVarQueryParameter['priceMin'] = priceMin;
      }

      if (priceMax !== undefined) {
        localVarQueryParameter['priceMax'] = priceMax;
      }

      if (religions) {
        localVarQueryParameter['religions'] = religions;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get therapist public info by id
     * @param {string} therapistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerTherapistPublicInfo: async (
      therapistId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'therapistId' is not null or undefined
      assertParamExists(
        'therapistsControllerTherapistPublicInfo',
        'therapistId',
        therapistId,
      );
      const localVarPath = `/api/v1/therapists/{therapistId}`.replace(
        `{${'therapistId'}}`,
        encodeURIComponent(String(therapistId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Unarchive patient and all sessions (only for therapist)
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerUnarchivePatient: async (
      patientId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'patientId' is not null or undefined
      assertParamExists(
        'therapistsControllerUnarchivePatient',
        'patientId',
        patientId,
      );
      const localVarPath =
        `/api/v1/therapists/patients/{patientId}/unarchive`.replace(
          `{${'patientId'}}`,
          encodeURIComponent(String(patientId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update availability (only for therapist)
     * @param {string} availabilityId
     * @param {UpdateAvailabilityRequestDto} updateAvailabilityRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerUpdateAvailability: async (
      availabilityId: string,
      updateAvailabilityRequestDto: UpdateAvailabilityRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'availabilityId' is not null or undefined
      assertParamExists(
        'therapistsControllerUpdateAvailability',
        'availabilityId',
        availabilityId,
      );
      // verify required parameter 'updateAvailabilityRequestDto' is not null or undefined
      assertParamExists(
        'therapistsControllerUpdateAvailability',
        'updateAvailabilityRequestDto',
        updateAvailabilityRequestDto,
      );
      const localVarPath =
        `/api/v1/therapists/availability/{availabilityId}`.replace(
          `{${'availabilityId'}}`,
          encodeURIComponent(String(availabilityId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAvailabilityRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TherapistsApi - functional programming interface
 * @export
 */
export const TherapistsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    TherapistsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add patient\'s note (only for therapist)
     * @param {string} patientId
     * @param {TherapistPatientNoteRequestDto} therapistPatientNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistPatientNoteControllerAddNote(
      patientId: string,
      therapistPatientNoteRequestDto: TherapistPatientNoteRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapistPatientNoteResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistPatientNoteControllerAddNote(
          patientId,
          therapistPatientNoteRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete patient note (only for therapist)
     * @param {string} patientId
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistPatientNoteControllerDeleteNote(
      patientId: string,
      noteId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistPatientNoteControllerDeleteNote(
          patientId,
          noteId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update patient note (only for therapist)
     * @param {string} patientId
     * @param {string} noteId
     * @param {TherapistPatientNoteRequestDto} therapistPatientNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistPatientNoteControllerEditNote(
      patientId: string,
      noteId: string,
      therapistPatientNoteRequestDto: TherapistPatientNoteRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapistPatientNoteResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistPatientNoteControllerEditNote(
          patientId,
          noteId,
          therapistPatientNoteRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get patient\'s notes (only for therapist)
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistPatientNoteControllerGetPatientNotes(
      patientId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<TherapistPatientNoteResponseDto>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistPatientNoteControllerGetPatientNotes(
          patientId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get active supervisions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistSupervisionsControllerGetActiveSupervisions(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupervisionsBaseResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistSupervisionsControllerGetActiveSupervisions(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get supervisions history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistSupervisionsControllerGetSupervisionsHistory(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SupervisionsBaseResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistSupervisionsControllerGetSupervisionsHistory(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Archive patient and all sessions (only for therapist)
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerArchivePatient(
      patientId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerArchivePatient(
          patientId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create availability (only for therapists)
     * @param {CreateAvailabilitiesRequestDto} createAvailabilitiesRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerCreateAvailability(
      createAvailabilitiesRequestDto: CreateAvailabilitiesRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AvailabilitiesCreateResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerCreateAvailability(
          createAvailabilitiesRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete availability (only for therapist)
     * @param {string} availabilityId
     * @param {DeleteAvailabilityRequestDto} deleteAvailabilityRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerDeleteAvailability(
      availabilityId: string,
      deleteAvailabilityRequestDto: DeleteAvailabilityRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerDeleteAvailability(
          availabilityId,
          deleteAvailabilityRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete avatar (only for therapists)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerDeleteAvatar(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerDeleteAvatar(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Delete video (only for therapists)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerDeleteVideo(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerDeleteVideo(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get therapist self info (only for therapists)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerFindOne(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PublicTherapistResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerFindOne(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get active sessions (only for therapists)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [patientName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerGetActiveSessions(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      patientName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedSessionResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerGetActiveSessions(
          limit,
          page,
          order,
          from,
          to,
          patientName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all therapists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerGetAllTherapists(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerGetAllTherapists(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get availabilities of therapists
     * @param {string} therapistId
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerGetAvailability(
      therapistId: string,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<AvailabilitiesResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerGetAvailability(
          therapistId,
          from,
          to,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get history sessions (only for therapists)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [patientName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerGetHistorySessions(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      patientName?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedSessionResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerGetHistorySessions(
          limit,
          page,
          order,
          from,
          to,
          patientName,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Create therapist create request (only for therapists)
     * @param {CreateTherapistProfileRequestDto} createTherapistProfileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerGetProfile(
      createTherapistProfileRequestDto: CreateTherapistProfileRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerGetProfile(
          createTherapistProfileRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get therapist schedule (only for therapists)
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerGetSchedule(
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapistScheduleResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerGetSchedule(
          from,
          to,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get tiny sessions (only for therapists)
     * @param {boolean} isActive
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerGetSessions(
      isActive: boolean,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<SessionsWithStartDate>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerGetSessions(
          isActive,
          from,
          to,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Search all supervisors
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'price' | 'experience' | 'availability'} [orderTerms]
     * @param {'male' | 'female' | 'transgender' | 'unknown'} [gender]
     * @param {Array<string>} [therapyMethods]
     * @param {Array<string>} [diseases]
     * @param {number} [dayLimit]
     * @param {boolean} [workWithLgbt]
     * @param {boolean} [workWithEthnicGroups]
     * @param {number} [workingExperience]
     * @param {string} [firstname]
     * @param {string} [lastname]
     * @param {number} [priceMin]
     * @param {number} [priceMax]
     * @param {Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>} [religions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerGetSupervisors(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'price' | 'experience' | 'availability',
      gender?: 'male' | 'female' | 'transgender' | 'unknown',
      therapyMethods?: Array<string>,
      diseases?: Array<string>,
      dayLimit?: number,
      workWithLgbt?: boolean,
      workWithEthnicGroups?: boolean,
      workingExperience?: number,
      firstname?: string,
      lastname?: string,
      priceMin?: number,
      priceMax?: number,
      religions?: Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PublicTherapistsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerGetSupervisors(
          limit,
          page,
          order,
          orderTerms,
          gender,
          therapyMethods,
          diseases,
          dayLimit,
          workWithLgbt,
          workWithEthnicGroups,
          workingExperience,
          firstname,
          lastname,
          priceMin,
          priceMax,
          religions,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get all therapist patients (only for therapist)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'firstname' | 'totalSessions' | 'firstSessionDate' | 'lastSessionDate'} [orderTerms]
     * @param {boolean} [archivedByTherapist]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerPatientTherapists(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'firstname'
        | 'totalSessions'
        | 'firstSessionDate'
        | 'lastSessionDate',
      archivedByTherapist?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapistPatientsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerPatientTherapists(
          limit,
          page,
          order,
          orderTerms,
          archivedByTherapist,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Reject patient and cancel all sessions (only for therapist)
     * @param {string} patientId
     * @param {RejectPatientRequestDto} rejectPatientRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerRejectPatient(
      patientId: string,
      rejectPatientRequestDto: RejectPatientRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerRejectPatient(
          patientId,
          rejectPatientRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Request session from therapist through admins
     * @param {string} therapistId
     * @param {RequestSessionRequestDto} requestSessionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerRequestTherapistSession(
      therapistId: string,
      requestSessionRequestDto: RequestSessionRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerRequestTherapistSession(
          therapistId,
          requestSessionRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get therapist list by search filter
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'price' | 'experience' | 'availability'} [orderTerms]
     * @param {'male' | 'female' | 'transgender' | 'unknown'} [gender]
     * @param {Array<string>} [therapyMethods]
     * @param {Array<string>} [diseases]
     * @param {number} [dayLimit]
     * @param {boolean} [workWithLgbt]
     * @param {boolean} [workWithEthnicGroups]
     * @param {number} [workingExperience]
     * @param {boolean} [isApproved]
     * @param {string} [firstname]
     * @param {string} [lastname]
     * @param {number} [priceMin]
     * @param {number} [priceMax]
     * @param {Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>} [religions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerSearch(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'price' | 'experience' | 'availability',
      gender?: 'male' | 'female' | 'transgender' | 'unknown',
      therapyMethods?: Array<string>,
      diseases?: Array<string>,
      dayLimit?: number,
      workWithLgbt?: boolean,
      workWithEthnicGroups?: boolean,
      workingExperience?: number,
      isApproved?: boolean,
      firstname?: string,
      lastname?: string,
      priceMin?: number,
      priceMax?: number,
      religions?: Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapistPaginationSearchResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerSearch(
          limit,
          page,
          order,
          orderTerms,
          gender,
          therapyMethods,
          diseases,
          dayLimit,
          workWithLgbt,
          workWithEthnicGroups,
          workingExperience,
          isApproved,
          firstname,
          lastname,
          priceMin,
          priceMax,
          religions,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get therapist list by recommendation params (only for patients)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'price' | 'experience' | 'availability'} [orderTerms]
     * @param {'male' | 'female' | 'transgender' | 'unknown'} [gender]
     * @param {Array<string>} [therapyMethods]
     * @param {Array<string>} [diseases]
     * @param {number} [dayLimit]
     * @param {boolean} [workWithLgbt]
     * @param {boolean} [workWithEthnicGroups]
     * @param {number} [workingExperience]
     * @param {boolean} [isApproved]
     * @param {string} [firstname]
     * @param {string} [lastname]
     * @param {number} [priceMin]
     * @param {number} [priceMax]
     * @param {Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>} [religions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerSearchRecommended(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'price' | 'experience' | 'availability',
      gender?: 'male' | 'female' | 'transgender' | 'unknown',
      therapyMethods?: Array<string>,
      diseases?: Array<string>,
      dayLimit?: number,
      workWithLgbt?: boolean,
      workWithEthnicGroups?: boolean,
      workingExperience?: number,
      isApproved?: boolean,
      firstname?: string,
      lastname?: string,
      priceMin?: number,
      priceMax?: number,
      religions?: Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<TherapistPaginationSearchResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerSearchRecommended(
          limit,
          page,
          order,
          orderTerms,
          gender,
          therapyMethods,
          diseases,
          dayLimit,
          workWithLgbt,
          workWithEthnicGroups,
          workingExperience,
          isApproved,
          firstname,
          lastname,
          priceMin,
          priceMax,
          religions,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get therapist public info by id
     * @param {string} therapistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerTherapistPublicInfo(
      therapistId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PublicTherapistResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerTherapistPublicInfo(
          therapistId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Unarchive patient and all sessions (only for therapist)
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerUnarchivePatient(
      patientId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerUnarchivePatient(
          patientId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update availability (only for therapist)
     * @param {string} availabilityId
     * @param {UpdateAvailabilityRequestDto} updateAvailabilityRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async therapistsControllerUpdateAvailability(
      availabilityId: string,
      updateAvailabilityRequestDto: UpdateAvailabilityRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MessageSuccessResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.therapistsControllerUpdateAvailability(
          availabilityId,
          updateAvailabilityRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TherapistsApi - factory interface
 * @export
 */
export const TherapistsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TherapistsApiFp(configuration);
  return {
    /**
     *
     * @summary Add patient\'s note (only for therapist)
     * @param {string} patientId
     * @param {TherapistPatientNoteRequestDto} therapistPatientNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistPatientNoteControllerAddNote(
      patientId: string,
      therapistPatientNoteRequestDto: TherapistPatientNoteRequestDto,
      options?: any,
    ): AxiosPromise<TherapistPatientNoteResponseDto> {
      return localVarFp
        .therapistPatientNoteControllerAddNote(
          patientId,
          therapistPatientNoteRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete patient note (only for therapist)
     * @param {string} patientId
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistPatientNoteControllerDeleteNote(
      patientId: string,
      noteId: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .therapistPatientNoteControllerDeleteNote(patientId, noteId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update patient note (only for therapist)
     * @param {string} patientId
     * @param {string} noteId
     * @param {TherapistPatientNoteRequestDto} therapistPatientNoteRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistPatientNoteControllerEditNote(
      patientId: string,
      noteId: string,
      therapistPatientNoteRequestDto: TherapistPatientNoteRequestDto,
      options?: any,
    ): AxiosPromise<TherapistPatientNoteResponseDto> {
      return localVarFp
        .therapistPatientNoteControllerEditNote(
          patientId,
          noteId,
          therapistPatientNoteRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get patient\'s notes (only for therapist)
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistPatientNoteControllerGetPatientNotes(
      patientId: string,
      options?: any,
    ): AxiosPromise<Array<TherapistPatientNoteResponseDto>> {
      return localVarFp
        .therapistPatientNoteControllerGetPatientNotes(patientId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get active supervisions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistSupervisionsControllerGetActiveSupervisions(
      options?: any,
    ): AxiosPromise<SupervisionsBaseResponseDto> {
      return localVarFp
        .therapistSupervisionsControllerGetActiveSupervisions(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get supervisions history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistSupervisionsControllerGetSupervisionsHistory(
      options?: any,
    ): AxiosPromise<SupervisionsBaseResponseDto> {
      return localVarFp
        .therapistSupervisionsControllerGetSupervisionsHistory(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Archive patient and all sessions (only for therapist)
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerArchivePatient(
      patientId: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .therapistsControllerArchivePatient(patientId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create availability (only for therapists)
     * @param {CreateAvailabilitiesRequestDto} createAvailabilitiesRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerCreateAvailability(
      createAvailabilitiesRequestDto: CreateAvailabilitiesRequestDto,
      options?: any,
    ): AxiosPromise<AvailabilitiesCreateResponseDto> {
      return localVarFp
        .therapistsControllerCreateAvailability(
          createAvailabilitiesRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete availability (only for therapist)
     * @param {string} availabilityId
     * @param {DeleteAvailabilityRequestDto} deleteAvailabilityRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerDeleteAvailability(
      availabilityId: string,
      deleteAvailabilityRequestDto: DeleteAvailabilityRequestDto,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .therapistsControllerDeleteAvailability(
          availabilityId,
          deleteAvailabilityRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete avatar (only for therapists)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerDeleteAvatar(
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .therapistsControllerDeleteAvatar(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete video (only for therapists)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerDeleteVideo(
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .therapistsControllerDeleteVideo(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get therapist self info (only for therapists)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerFindOne(
      options?: any,
    ): AxiosPromise<PublicTherapistResponseDto> {
      return localVarFp
        .therapistsControllerFindOne(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get active sessions (only for therapists)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [patientName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetActiveSessions(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      patientName?: string,
      options?: any,
    ): AxiosPromise<PaginatedSessionResponseDto> {
      return localVarFp
        .therapistsControllerGetActiveSessions(
          limit,
          page,
          order,
          from,
          to,
          patientName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all therapists
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetAllTherapists(
      options?: any,
    ): AxiosPromise<Array<object>> {
      return localVarFp
        .therapistsControllerGetAllTherapists(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get availabilities of therapists
     * @param {string} therapistId
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetAvailability(
      therapistId: string,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<AvailabilitiesResponseDto> {
      return localVarFp
        .therapistsControllerGetAvailability(therapistId, from, to, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get history sessions (only for therapists)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {string} [from]
     * @param {string} [to]
     * @param {string} [patientName]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetHistorySessions(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      from?: string,
      to?: string,
      patientName?: string,
      options?: any,
    ): AxiosPromise<PaginatedSessionResponseDto> {
      return localVarFp
        .therapistsControllerGetHistorySessions(
          limit,
          page,
          order,
          from,
          to,
          patientName,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create therapist create request (only for therapists)
     * @param {CreateTherapistProfileRequestDto} createTherapistProfileRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetProfile(
      createTherapistProfileRequestDto: CreateTherapistProfileRequestDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .therapistsControllerGetProfile(
          createTherapistProfileRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get therapist schedule (only for therapists)
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetSchedule(
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<TherapistScheduleResponseDto> {
      return localVarFp
        .therapistsControllerGetSchedule(from, to, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get tiny sessions (only for therapists)
     * @param {boolean} isActive
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetSessions(
      isActive: boolean,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<SessionsWithStartDate> {
      return localVarFp
        .therapistsControllerGetSessions(isActive, from, to, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Search all supervisors
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'price' | 'experience' | 'availability'} [orderTerms]
     * @param {'male' | 'female' | 'transgender' | 'unknown'} [gender]
     * @param {Array<string>} [therapyMethods]
     * @param {Array<string>} [diseases]
     * @param {number} [dayLimit]
     * @param {boolean} [workWithLgbt]
     * @param {boolean} [workWithEthnicGroups]
     * @param {number} [workingExperience]
     * @param {string} [firstname]
     * @param {string} [lastname]
     * @param {number} [priceMin]
     * @param {number} [priceMax]
     * @param {Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>} [religions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerGetSupervisors(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'price' | 'experience' | 'availability',
      gender?: 'male' | 'female' | 'transgender' | 'unknown',
      therapyMethods?: Array<string>,
      diseases?: Array<string>,
      dayLimit?: number,
      workWithLgbt?: boolean,
      workWithEthnicGroups?: boolean,
      workingExperience?: number,
      firstname?: string,
      lastname?: string,
      priceMin?: number,
      priceMax?: number,
      religions?: Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>,
      options?: any,
    ): AxiosPromise<PublicTherapistsResponseDto> {
      return localVarFp
        .therapistsControllerGetSupervisors(
          limit,
          page,
          order,
          orderTerms,
          gender,
          therapyMethods,
          diseases,
          dayLimit,
          workWithLgbt,
          workWithEthnicGroups,
          workingExperience,
          firstname,
          lastname,
          priceMin,
          priceMax,
          religions,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all therapist patients (only for therapist)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'firstname' | 'totalSessions' | 'firstSessionDate' | 'lastSessionDate'} [orderTerms]
     * @param {boolean} [archivedByTherapist]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerPatientTherapists(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?:
        | 'firstname'
        | 'totalSessions'
        | 'firstSessionDate'
        | 'lastSessionDate',
      archivedByTherapist?: boolean,
      options?: any,
    ): AxiosPromise<TherapistPatientsResponseDto> {
      return localVarFp
        .therapistsControllerPatientTherapists(
          limit,
          page,
          order,
          orderTerms,
          archivedByTherapist,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Reject patient and cancel all sessions (only for therapist)
     * @param {string} patientId
     * @param {RejectPatientRequestDto} rejectPatientRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerRejectPatient(
      patientId: string,
      rejectPatientRequestDto: RejectPatientRequestDto,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .therapistsControllerRejectPatient(
          patientId,
          rejectPatientRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Request session from therapist through admins
     * @param {string} therapistId
     * @param {RequestSessionRequestDto} requestSessionRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerRequestTherapistSession(
      therapistId: string,
      requestSessionRequestDto: RequestSessionRequestDto,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .therapistsControllerRequestTherapistSession(
          therapistId,
          requestSessionRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get therapist list by search filter
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'price' | 'experience' | 'availability'} [orderTerms]
     * @param {'male' | 'female' | 'transgender' | 'unknown'} [gender]
     * @param {Array<string>} [therapyMethods]
     * @param {Array<string>} [diseases]
     * @param {number} [dayLimit]
     * @param {boolean} [workWithLgbt]
     * @param {boolean} [workWithEthnicGroups]
     * @param {number} [workingExperience]
     * @param {boolean} [isApproved]
     * @param {string} [firstname]
     * @param {string} [lastname]
     * @param {number} [priceMin]
     * @param {number} [priceMax]
     * @param {Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>} [religions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerSearch(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'price' | 'experience' | 'availability',
      gender?: 'male' | 'female' | 'transgender' | 'unknown',
      therapyMethods?: Array<string>,
      diseases?: Array<string>,
      dayLimit?: number,
      workWithLgbt?: boolean,
      workWithEthnicGroups?: boolean,
      workingExperience?: number,
      isApproved?: boolean,
      firstname?: string,
      lastname?: string,
      priceMin?: number,
      priceMax?: number,
      religions?: Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>,
      options?: any,
    ): AxiosPromise<TherapistPaginationSearchResponseDto> {
      return localVarFp
        .therapistsControllerSearch(
          limit,
          page,
          order,
          orderTerms,
          gender,
          therapyMethods,
          diseases,
          dayLimit,
          workWithLgbt,
          workWithEthnicGroups,
          workingExperience,
          isApproved,
          firstname,
          lastname,
          priceMin,
          priceMax,
          religions,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get therapist list by recommendation params (only for patients)
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {'price' | 'experience' | 'availability'} [orderTerms]
     * @param {'male' | 'female' | 'transgender' | 'unknown'} [gender]
     * @param {Array<string>} [therapyMethods]
     * @param {Array<string>} [diseases]
     * @param {number} [dayLimit]
     * @param {boolean} [workWithLgbt]
     * @param {boolean} [workWithEthnicGroups]
     * @param {number} [workingExperience]
     * @param {boolean} [isApproved]
     * @param {string} [firstname]
     * @param {string} [lastname]
     * @param {number} [priceMin]
     * @param {number} [priceMax]
     * @param {Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>} [religions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerSearchRecommended(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      orderTerms?: 'price' | 'experience' | 'availability',
      gender?: 'male' | 'female' | 'transgender' | 'unknown',
      therapyMethods?: Array<string>,
      diseases?: Array<string>,
      dayLimit?: number,
      workWithLgbt?: boolean,
      workWithEthnicGroups?: boolean,
      workingExperience?: number,
      isApproved?: boolean,
      firstname?: string,
      lastname?: string,
      priceMin?: number,
      priceMax?: number,
      religions?: Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>,
      options?: any,
    ): AxiosPromise<TherapistPaginationSearchResponseDto> {
      return localVarFp
        .therapistsControllerSearchRecommended(
          limit,
          page,
          order,
          orderTerms,
          gender,
          therapyMethods,
          diseases,
          dayLimit,
          workWithLgbt,
          workWithEthnicGroups,
          workingExperience,
          isApproved,
          firstname,
          lastname,
          priceMin,
          priceMax,
          religions,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get therapist public info by id
     * @param {string} therapistId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerTherapistPublicInfo(
      therapistId: string,
      options?: any,
    ): AxiosPromise<PublicTherapistResponseDto> {
      return localVarFp
        .therapistsControllerTherapistPublicInfo(therapistId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Unarchive patient and all sessions (only for therapist)
     * @param {string} patientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerUnarchivePatient(
      patientId: string,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .therapistsControllerUnarchivePatient(patientId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update availability (only for therapist)
     * @param {string} availabilityId
     * @param {UpdateAvailabilityRequestDto} updateAvailabilityRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    therapistsControllerUpdateAvailability(
      availabilityId: string,
      updateAvailabilityRequestDto: UpdateAvailabilityRequestDto,
      options?: any,
    ): AxiosPromise<MessageSuccessResponseDto> {
      return localVarFp
        .therapistsControllerUpdateAvailability(
          availabilityId,
          updateAvailabilityRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TherapistsApi - object-oriented interface
 * @export
 * @class TherapistsApi
 * @extends {BaseAPI}
 */
export class TherapistsApi extends BaseAPI {
  /**
   *
   * @summary Add patient\'s note (only for therapist)
   * @param {string} patientId
   * @param {TherapistPatientNoteRequestDto} therapistPatientNoteRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistPatientNoteControllerAddNote(
    patientId: string,
    therapistPatientNoteRequestDto: TherapistPatientNoteRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistPatientNoteControllerAddNote(
        patientId,
        therapistPatientNoteRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete patient note (only for therapist)
   * @param {string} patientId
   * @param {string} noteId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistPatientNoteControllerDeleteNote(
    patientId: string,
    noteId: string,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistPatientNoteControllerDeleteNote(patientId, noteId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update patient note (only for therapist)
   * @param {string} patientId
   * @param {string} noteId
   * @param {TherapistPatientNoteRequestDto} therapistPatientNoteRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistPatientNoteControllerEditNote(
    patientId: string,
    noteId: string,
    therapistPatientNoteRequestDto: TherapistPatientNoteRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistPatientNoteControllerEditNote(
        patientId,
        noteId,
        therapistPatientNoteRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get patient\'s notes (only for therapist)
   * @param {string} patientId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistPatientNoteControllerGetPatientNotes(
    patientId: string,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistPatientNoteControllerGetPatientNotes(patientId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get active supervisions
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistSupervisionsControllerGetActiveSupervisions(
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistSupervisionsControllerGetActiveSupervisions(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get supervisions history
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistSupervisionsControllerGetSupervisionsHistory(
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistSupervisionsControllerGetSupervisionsHistory(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Archive patient and all sessions (only for therapist)
   * @param {string} patientId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerArchivePatient(
    patientId: string,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerArchivePatient(patientId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create availability (only for therapists)
   * @param {CreateAvailabilitiesRequestDto} createAvailabilitiesRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerCreateAvailability(
    createAvailabilitiesRequestDto: CreateAvailabilitiesRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerCreateAvailability(
        createAvailabilitiesRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete availability (only for therapist)
   * @param {string} availabilityId
   * @param {DeleteAvailabilityRequestDto} deleteAvailabilityRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerDeleteAvailability(
    availabilityId: string,
    deleteAvailabilityRequestDto: DeleteAvailabilityRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerDeleteAvailability(
        availabilityId,
        deleteAvailabilityRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete avatar (only for therapists)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerDeleteAvatar(options?: AxiosRequestConfig) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerDeleteAvatar(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete video (only for therapists)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerDeleteVideo(options?: AxiosRequestConfig) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerDeleteVideo(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get therapist self info (only for therapists)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerFindOne(options?: AxiosRequestConfig) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerFindOne(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get active sessions (only for therapists)
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [patientName]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerGetActiveSessions(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    from?: string,
    to?: string,
    patientName?: string,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerGetActiveSessions(
        limit,
        page,
        order,
        from,
        to,
        patientName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all therapists
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerGetAllTherapists(options?: AxiosRequestConfig) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerGetAllTherapists(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get availabilities of therapists
   * @param {string} therapistId
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerGetAvailability(
    therapistId: string,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerGetAvailability(therapistId, from, to, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get history sessions (only for therapists)
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {string} [from]
   * @param {string} [to]
   * @param {string} [patientName]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerGetHistorySessions(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    from?: string,
    to?: string,
    patientName?: string,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerGetHistorySessions(
        limit,
        page,
        order,
        from,
        to,
        patientName,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create therapist create request (only for therapists)
   * @param {CreateTherapistProfileRequestDto} createTherapistProfileRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerGetProfile(
    createTherapistProfileRequestDto: CreateTherapistProfileRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerGetProfile(createTherapistProfileRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get therapist schedule (only for therapists)
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerGetSchedule(
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerGetSchedule(from, to, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get tiny sessions (only for therapists)
   * @param {boolean} isActive
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerGetSessions(
    isActive: boolean,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerGetSessions(isActive, from, to, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Search all supervisors
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {'price' | 'experience' | 'availability'} [orderTerms]
   * @param {'male' | 'female' | 'transgender' | 'unknown'} [gender]
   * @param {Array<string>} [therapyMethods]
   * @param {Array<string>} [diseases]
   * @param {number} [dayLimit]
   * @param {boolean} [workWithLgbt]
   * @param {boolean} [workWithEthnicGroups]
   * @param {number} [workingExperience]
   * @param {string} [firstname]
   * @param {string} [lastname]
   * @param {number} [priceMin]
   * @param {number} [priceMax]
   * @param {Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>} [religions]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerGetSupervisors(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    orderTerms?: 'price' | 'experience' | 'availability',
    gender?: 'male' | 'female' | 'transgender' | 'unknown',
    therapyMethods?: Array<string>,
    diseases?: Array<string>,
    dayLimit?: number,
    workWithLgbt?: boolean,
    workWithEthnicGroups?: boolean,
    workingExperience?: number,
    firstname?: string,
    lastname?: string,
    priceMin?: number,
    priceMax?: number,
    religions?: Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerGetSupervisors(
        limit,
        page,
        order,
        orderTerms,
        gender,
        therapyMethods,
        diseases,
        dayLimit,
        workWithLgbt,
        workWithEthnicGroups,
        workingExperience,
        firstname,
        lastname,
        priceMin,
        priceMax,
        religions,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get all therapist patients (only for therapist)
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {'firstname' | 'totalSessions' | 'firstSessionDate' | 'lastSessionDate'} [orderTerms]
   * @param {boolean} [archivedByTherapist]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerPatientTherapists(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    orderTerms?:
      | 'firstname'
      | 'totalSessions'
      | 'firstSessionDate'
      | 'lastSessionDate',
    archivedByTherapist?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerPatientTherapists(
        limit,
        page,
        order,
        orderTerms,
        archivedByTherapist,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Reject patient and cancel all sessions (only for therapist)
   * @param {string} patientId
   * @param {RejectPatientRequestDto} rejectPatientRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerRejectPatient(
    patientId: string,
    rejectPatientRequestDto: RejectPatientRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerRejectPatient(
        patientId,
        rejectPatientRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Request session from therapist through admins
   * @param {string} therapistId
   * @param {RequestSessionRequestDto} requestSessionRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerRequestTherapistSession(
    therapistId: string,
    requestSessionRequestDto: RequestSessionRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerRequestTherapistSession(
        therapistId,
        requestSessionRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get therapist list by search filter
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {'price' | 'experience' | 'availability'} [orderTerms]
   * @param {'male' | 'female' | 'transgender' | 'unknown'} [gender]
   * @param {Array<string>} [therapyMethods]
   * @param {Array<string>} [diseases]
   * @param {number} [dayLimit]
   * @param {boolean} [workWithLgbt]
   * @param {boolean} [workWithEthnicGroups]
   * @param {number} [workingExperience]
   * @param {boolean} [isApproved]
   * @param {string} [firstname]
   * @param {string} [lastname]
   * @param {number} [priceMin]
   * @param {number} [priceMax]
   * @param {Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>} [religions]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerSearch(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    orderTerms?: 'price' | 'experience' | 'availability',
    gender?: 'male' | 'female' | 'transgender' | 'unknown',
    therapyMethods?: Array<string>,
    diseases?: Array<string>,
    dayLimit?: number,
    workWithLgbt?: boolean,
    workWithEthnicGroups?: boolean,
    workingExperience?: number,
    isApproved?: boolean,
    firstname?: string,
    lastname?: string,
    priceMin?: number,
    priceMax?: number,
    religions?: Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerSearch(
        limit,
        page,
        order,
        orderTerms,
        gender,
        therapyMethods,
        diseases,
        dayLimit,
        workWithLgbt,
        workWithEthnicGroups,
        workingExperience,
        isApproved,
        firstname,
        lastname,
        priceMin,
        priceMax,
        religions,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get therapist list by recommendation params (only for patients)
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {'price' | 'experience' | 'availability'} [orderTerms]
   * @param {'male' | 'female' | 'transgender' | 'unknown'} [gender]
   * @param {Array<string>} [therapyMethods]
   * @param {Array<string>} [diseases]
   * @param {number} [dayLimit]
   * @param {boolean} [workWithLgbt]
   * @param {boolean} [workWithEthnicGroups]
   * @param {number} [workingExperience]
   * @param {boolean} [isApproved]
   * @param {string} [firstname]
   * @param {string} [lastname]
   * @param {number} [priceMin]
   * @param {number} [priceMax]
   * @param {Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>} [religions]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerSearchRecommended(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    orderTerms?: 'price' | 'experience' | 'availability',
    gender?: 'male' | 'female' | 'transgender' | 'unknown',
    therapyMethods?: Array<string>,
    diseases?: Array<string>,
    dayLimit?: number,
    workWithLgbt?: boolean,
    workWithEthnicGroups?: boolean,
    workingExperience?: number,
    isApproved?: boolean,
    firstname?: string,
    lastname?: string,
    priceMin?: number,
    priceMax?: number,
    religions?: Array<'christianity' | 'islamism' | 'judaism' | 'buddhism'>,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerSearchRecommended(
        limit,
        page,
        order,
        orderTerms,
        gender,
        therapyMethods,
        diseases,
        dayLimit,
        workWithLgbt,
        workWithEthnicGroups,
        workingExperience,
        isApproved,
        firstname,
        lastname,
        priceMin,
        priceMax,
        religions,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get therapist public info by id
   * @param {string} therapistId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerTherapistPublicInfo(
    therapistId: string,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerTherapistPublicInfo(therapistId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Unarchive patient and all sessions (only for therapist)
   * @param {string} patientId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerUnarchivePatient(
    patientId: string,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerUnarchivePatient(patientId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update availability (only for therapist)
   * @param {string} availabilityId
   * @param {UpdateAvailabilityRequestDto} updateAvailabilityRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TherapistsApi
   */
  public therapistsControllerUpdateAvailability(
    availabilityId: string,
    updateAvailabilityRequestDto: UpdateAvailabilityRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return TherapistsApiFp(this.configuration)
      .therapistsControllerUpdateAvailability(
        availabilityId,
        updateAvailabilityRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserNotificationsApi - axios parameter creator
 * @export
 */
export const UserNotificationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Clear all notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerClearAll: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Clear notification by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerClearOne: async (
      id: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('notificationsControllerClearOne', 'id', id);
      const localVarPath = `/api/v1/notifications/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user notifications, order by popUpDate
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerGetNotifications: async (
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists(
        'notificationsControllerGetNotifications',
        'limit',
        limit,
      );
      // verify required parameter 'page' is not null or undefined
      assertParamExists(
        'notificationsControllerGetNotifications',
        'page',
        page,
      );
      const localVarPath = `/api/v1/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (order !== undefined) {
        localVarQueryParameter['order'] = order;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Mark all user notifications as viewed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerMarkNotificationsAsViewed: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserNotificationsApi - functional programming interface
 * @export
 */
export const UserNotificationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UserNotificationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Clear all notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsControllerClearAll(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsControllerClearAll(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Clear notification by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsControllerClearOne(
      id: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsControllerClearOne(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get user notifications, order by popUpDate
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsControllerGetNotifications(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NotificationsResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsControllerGetNotifications(
          limit,
          page,
          order,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Mark all user notifications as viewed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsControllerMarkNotificationsAsViewed(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsControllerMarkNotificationsAsViewed(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UserNotificationsApi - factory interface
 * @export
 */
export const UserNotificationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserNotificationsApiFp(configuration);
  return {
    /**
     *
     * @summary Clear all notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerClearAll(options?: any): AxiosPromise<void> {
      return localVarFp
        .notificationsControllerClearAll(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Clear notification by id
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerClearOne(
      id: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .notificationsControllerClearOne(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get user notifications, order by popUpDate
     * @param {number} limit
     * @param {number} page
     * @param {'ASC' | 'DESC'} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerGetNotifications(
      limit: number,
      page: number,
      order?: 'ASC' | 'DESC',
      options?: any,
    ): AxiosPromise<NotificationsResponseDto> {
      return localVarFp
        .notificationsControllerGetNotifications(limit, page, order, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Mark all user notifications as viewed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsControllerMarkNotificationsAsViewed(
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .notificationsControllerMarkNotificationsAsViewed(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserNotificationsApi - object-oriented interface
 * @export
 * @class UserNotificationsApi
 * @extends {BaseAPI}
 */
export class UserNotificationsApi extends BaseAPI {
  /**
   *
   * @summary Clear all notifications
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserNotificationsApi
   */
  public notificationsControllerClearAll(options?: AxiosRequestConfig) {
    return UserNotificationsApiFp(this.configuration)
      .notificationsControllerClearAll(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Clear notification by id
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserNotificationsApi
   */
  public notificationsControllerClearOne(
    id: string,
    options?: AxiosRequestConfig,
  ) {
    return UserNotificationsApiFp(this.configuration)
      .notificationsControllerClearOne(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get user notifications, order by popUpDate
   * @param {number} limit
   * @param {number} page
   * @param {'ASC' | 'DESC'} [order]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserNotificationsApi
   */
  public notificationsControllerGetNotifications(
    limit: number,
    page: number,
    order?: 'ASC' | 'DESC',
    options?: AxiosRequestConfig,
  ) {
    return UserNotificationsApiFp(this.configuration)
      .notificationsControllerGetNotifications(limit, page, order, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Mark all user notifications as viewed
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserNotificationsApi
   */
  public notificationsControllerMarkNotificationsAsViewed(
    options?: AxiosRequestConfig,
  ) {
    return UserNotificationsApiFp(this.configuration)
      .notificationsControllerMarkNotificationsAsViewed(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Delete user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerDelete: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get self information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerFindOne: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/users/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update notification time
     * @param {UpdateNotificationTimeRequestDto} updateNotificationTimeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerUpdateNotifications: async (
      updateNotificationTimeRequestDto: UpdateNotificationTimeRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'updateNotificationTimeRequestDto' is not null or undefined
      assertParamExists(
        'usersControllerUpdateNotifications',
        'updateNotificationTimeRequestDto',
        updateNotificationTimeRequestDto,
      );
      const localVarPath = `/api/v1/users/notification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateNotificationTimeRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Delete user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerDelete(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersControllerDelete(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get self information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerFindOne(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserWithMetadataResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersControllerFindOne(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Update notification time
     * @param {UpdateNotificationTimeRequestDto} updateNotificationTimeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersControllerUpdateNotifications(
      updateNotificationTimeRequestDto: UpdateNotificationTimeRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<NotificationTimeResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersControllerUpdateNotifications(
          updateNotificationTimeRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @summary Delete user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerDelete(options?: any): AxiosPromise<UserResponseDto> {
      return localVarFp
        .usersControllerDelete(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get self information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerFindOne(
      options?: any,
    ): AxiosPromise<UserWithMetadataResponseDto> {
      return localVarFp
        .usersControllerFindOne(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update notification time
     * @param {UpdateNotificationTimeRequestDto} updateNotificationTimeRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerUpdateNotifications(
      updateNotificationTimeRequestDto: UpdateNotificationTimeRequestDto,
      options?: any,
    ): AxiosPromise<NotificationTimeResponseDto> {
      return localVarFp
        .usersControllerUpdateNotifications(
          updateNotificationTimeRequestDto,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @summary Delete user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersControllerDelete(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersControllerDelete(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get self information
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersControllerFindOne(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersControllerFindOne(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update notification time
   * @param {UpdateNotificationTimeRequestDto} updateNotificationTimeRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersControllerUpdateNotifications(
    updateNotificationTimeRequestDto: UpdateNotificationTimeRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersControllerUpdateNotifications(
        updateNotificationTimeRequestDto,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * V1PusherApi - axios parameter creator
 * @export
 */
export const V1PusherApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Auth for presence and private pusher channels
     * @param {PusherAuthRequestDto} pusherAuthRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pusherControllerAuthenticate: async (
      pusherAuthRequestDto: PusherAuthRequestDto,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pusherAuthRequestDto' is not null or undefined
      assertParamExists(
        'pusherControllerAuthenticate',
        'pusherAuthRequestDto',
        pusherAuthRequestDto,
      );
      const localVarPath = `/api/v1/pusher/auth`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pusherAuthRequestDto,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * V1PusherApi - functional programming interface
 * @export
 */
export const V1PusherApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = V1PusherApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Auth for presence and private pusher channels
     * @param {PusherAuthRequestDto} pusherAuthRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pusherControllerAuthenticate(
      pusherAuthRequestDto: PusherAuthRequestDto,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PusherAuthResponseDto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.pusherControllerAuthenticate(
          pusherAuthRequestDto,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * V1PusherApi - factory interface
 * @export
 */
export const V1PusherApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = V1PusherApiFp(configuration);
  return {
    /**
     *
     * @summary Auth for presence and private pusher channels
     * @param {PusherAuthRequestDto} pusherAuthRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pusherControllerAuthenticate(
      pusherAuthRequestDto: PusherAuthRequestDto,
      options?: any,
    ): AxiosPromise<PusherAuthResponseDto> {
      return localVarFp
        .pusherControllerAuthenticate(pusherAuthRequestDto, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * V1PusherApi - object-oriented interface
 * @export
 * @class V1PusherApi
 * @extends {BaseAPI}
 */
export class V1PusherApi extends BaseAPI {
  /**
   *
   * @summary Auth for presence and private pusher channels
   * @param {PusherAuthRequestDto} pusherAuthRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof V1PusherApi
   */
  public pusherControllerAuthenticate(
    pusherAuthRequestDto: PusherAuthRequestDto,
    options?: AxiosRequestConfig,
  ) {
    return V1PusherApiFp(this.configuration)
      .pusherControllerAuthenticate(pusherAuthRequestDto, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
