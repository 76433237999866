import { IconComponent } from '@/app/types';
import { FC, memo } from 'react';

const SocialLinkTelegram: FC<IconComponent> = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0754 24.4186L23.0761 24.4168L23.094 24.3721L26.1778 8.82412V8.77362C26.1778 8.38624 26.0347 8.04805 25.7232 7.84519C25.4506 7.66763 25.1372 7.65499 24.9174 7.67167C24.685 7.68931 24.4658 7.74732 24.3141 7.79562C24.236 7.82046 24.1707 7.8444 24.124 7.86251C24.1005 7.87159 24.0815 7.87931 24.0677 7.88505L24.0541 7.89077L6.86002 14.6358L6.85529 14.6375C6.846 14.6408 6.83372 14.6454 6.81889 14.6512C6.78932 14.6627 6.74907 14.6792 6.70179 14.7006C6.60894 14.7427 6.48067 14.8076 6.35058 14.8967C6.12963 15.048 5.70891 15.4062 5.77995 15.9741C5.8388 16.4445 6.16304 16.7432 6.38164 16.8979C6.49882 16.9808 6.61093 17.0406 6.69302 17.0797C6.73462 17.0995 6.76995 17.1147 6.79626 17.1254C6.80946 17.1307 6.82049 17.135 6.82902 17.1383L6.83995 17.1424L6.84693 17.1449L9.85516 18.1577C9.845 18.3464 9.86372 18.5389 9.91377 18.7287L11.4206 24.4448C11.5993 25.1226 12.2124 25.5947 12.9133 25.5942C13.5416 25.5937 14.0988 25.2137 14.3355 24.6466L16.6881 22.1311L20.7285 25.2288L20.786 25.2539C21.1531 25.4141 21.496 25.4648 21.8096 25.422C22.1227 25.3792 22.3716 25.2479 22.5585 25.0984C22.7424 24.9512 22.8685 24.7851 22.9478 24.6601C22.988 24.5966 23.0181 24.5407 23.0389 24.4987C23.0493 24.4776 23.0576 24.4597 23.0638 24.4458L23.0715 24.4279L23.0743 24.4213L23.0754 24.4186ZM11.4053 18.3355C11.3711 18.2056 11.4248 18.0685 11.5383 17.9964L21.7417 11.5168C21.7417 11.5168 22.3419 11.1524 22.3204 11.5168C22.3204 11.5168 22.4275 11.5811 22.1059 11.8811C21.8009 12.1661 14.8337 18.8927 14.1287 19.5733C14.088 19.6126 14.0637 19.6577 14.0493 19.7124L12.9122 24.0516L11.4053 18.3355Z"
        fill="#8D98A5"
      />
      <circle
        cx="16.4626"
        cy="16.6301"
        r="15.6877"
        stroke="#8D98A5"
      />
    </svg>
  );
};

const Memo = memo(SocialLinkTelegram);
export default Memo;
