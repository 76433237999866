import { IconComponent } from '@/app/types';
import { FC, memo } from 'react';

const SocialLinkDzen: FC<IconComponent> = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16.4628"
        cy="15.9838"
        r="15.1996"
        fill="white"
        stroke="#8D98A5"
      />
      <path
        d="M14.5835 11.4174L16.4628 2.66568L18.3421 11.4174C18.6313 12.7637 19.683 13.8154 21.0293 14.1045L29.781 15.9838L21.0293 17.8631C19.683 18.1522 18.6313 19.204 18.3421 20.5503L16.4628 29.302L14.5835 20.5503C14.2944 19.204 13.2427 18.1522 11.8964 17.8631L3.14469 15.9838L11.8964 14.1045C13.2427 13.8154 14.2944 12.7637 14.5835 11.4174Z"
        fill="#8D98A5"
        stroke="#8D98A5"
      />
    </svg>
  );
};

const Memo = memo(SocialLinkDzen);
export default Memo;
